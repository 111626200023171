import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Select, Date, FieldIcon } from 'components/Form'
import { Grid } from 'components/Grid'
import { Insurance } from 'types'
import { parseInitValues } from 'utils/form'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  insurance?: Insurance
  saving?: boolean
  onSave: (insurance: Partial<Insurance>) => void
  onCancel: () => void
}

const Schema = createScheme({
  premium: required,
  effective: required,
  expiration: required,
  policyNumber: required,
  carrier: required,
  type: required,
})

function ModalAddressInsurance({ insurance, saving, onSave, onCancel }: Props) {
  const initialValue: Partial<Insurance> = {
    premium: undefined,
    effective: '',
    expiration: '',
    policyNumber: '',
    carrier: '',
    type: 'Property',
    ...parseInitValues(insurance || {}),
  }

  return (
    <Modal
      title={insurance ? 'Edit Insurance' : 'Add New Insurance'}
      onClose={onCancel}
      className={styles.modal}
      asChild
    >
      <Formik
        initialValues={initialValue}
        enableReinitialize={true}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnChange={false}
      >
        <Form modal>
          <Grid className={styles.form} columnGap={24}>
            <Grid.Item xs={6}>
              <FieldIcon
                type="text"
                name="policyNumber"
                label="Policy Number"
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon name="premium" type="currency" label="Premium" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Date name="effective" label="Effective" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Date name="expiration" label="Expiration" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="text" name="carrier" label="Carrier" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="type"
                label="Type"
                options={[
                  { label: 'Condo Flood', value: 'Condo Flood' },
                  { label: 'Condo Liability', value: 'Condo Liability' },
                  { label: 'Condo Property', value: 'Condo Property' },
                  { label: 'Dwelling', value: 'Dwelling' },
                  { label: 'Flood', value: 'Flood' },
                  {
                    label: 'Force Place Insurance',
                    value: 'Force Place Insurance',
                  },
                  {
                    label: 'Liability Insurance',
                    value: 'Liability Insurance',
                  },
                  { label: 'Property', value: 'Property' },
                  { label: 'Windstorm Only', value: 'Windstorm Only' },
                ]}
                portal
              />
            </Grid.Item>
          </Grid>
          <Grid.Item xs={12} className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={saving} type="submit">
              Save
            </Button>
          </Grid.Item>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalAddressInsurance
