import { get } from 'lodash'
import { useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import {
  contextmenu,
  jspreadsheet,
  Spreadsheet,
  Worksheet,
} from 'components/Spreadsheet'
import { useBudgetWorksheet } from 'hooks/use-budget-worksheet'
import { useSession } from 'hooks/use-session'
import { Field, FieldType } from 'types'
import { formatField } from 'utils/fields'

const attributeTypeToJssType = new Map<FieldType, jspreadsheet.Column['type']>([
  ['number', 'number'],
  ['currency', 'number'],
  ['percentage', 'percent'],
  ['text', 'text'],
  ['decimal', 'number'],
  ['option', 'dropdown'],
  ['date', 'calendar'],
  ['document', 'text'],
  ['phone', 'text'],
  ['email', 'email'],
  ['yes-no', 'checkbox'],
  ['multiline', 'text'],
])

const SettingsWorksheetAttributesPreview = () => {
  const navigate = useNavigate()
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    jspreadsheetLicense: undefined,
  })
  jspreadsheet.setLicense(
    settings.jspreadsheetLicense ?? import.meta.env.VITE_APP_JSPREADSHEET
  )
  const jssRef = useRef<jspreadsheet.Spreadsheet>()
  const { id } = useParams() as { id: string }
  const { data: worksheet } = useBudgetWorksheet(id)

  const columns = useMemo(() => {
    return (
      worksheet?.attributes.map((attribute) => {
        const type = attribute.type || 'text'
        return {
          title: attribute.name,
          name: attribute.name,
          type: attributeTypeToJssType.get(type),
          autoCasting: false,
          source: attribute.options?.map(({ label }) => label),
          locale: [
            'number',
            'decimal',
            'percentage',
            'currency',
            'date',
          ].includes(type)
            ? 'en-US'
            : undefined,
          width: 150,
          align: ['number', 'decimal', 'percentage', 'currency'].includes(type)
            ? 'right'
            : 'left',
          render: (td, value) => {
            if (td) {
              td.innerText = formatField({
                type: [attribute.type],
                property: { value: [value] },
              } as Field)
            }
          },
          options: {
            ...(type === 'option' && { newOptions: false }),
            ...(type === 'currency' && {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          },
        }
      }) || []
    )
  }, [worksheet])

  return (
    <MainContent className="p-0">
      {worksheet ? (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className="md:h-12 pl-2"
          >
            <Flex alignItems="center" gap={4}>
              <Button
                variant="ghost"
                icon
                onClick={() => navigate(pathTo('settingsWorksheet', id))}
              >
                <Icon name={IconName.arrowLeftLong} />
              </Button>
              <Header variant="h4">Application Preview</Header>
            </Flex>
            <Flex alignItems="center" gap={24} className="pr-5">
              <Flex alignItems="center" gap={4}>
                <Icon name={IconName.info} className="text-grey-600" />
                This is a preview of the worksheet your borrowers will complete
              </Flex>
              <Button
                variant="primary"
                onClick={() => navigate(pathTo('settingsWorksheet', id))}
              >
                Exit
              </Button>
            </Flex>
          </Flex>
          <div className="relative">
            <div className="bg-[#f3f3f3] absolute p-3 w-full border border-solid border-[#ccc] leading-4">
              <div className="border border-solid border-transparent">
                &nbsp;
              </div>
            </div>
            {columns.length > 0 && (
              <Spreadsheet
                ref={jssRef}
                allowExport={false}
                calculations={true}
                secureFormulas={false}
                editorFormulas={false}
                parseFormulas={false}
                debugFormulas={false}
                contextMenu={contextmenu}
              >
                <Worksheet
                  data={[]}
                  columns={columns}
                  allowInsertColumn={false}
                  allowManualInsertColumn={false}
                  allowDeleteColumn={false}
                  allowRenameColumn={false}
                  columnDrag={false}
                  allowComments={false}
                  columnSorting={false}
                  minDimensions={[columns.length, 15]}
                />
              </Spreadsheet>
            )}
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { SettingsWorksheetAttributesPreview }
