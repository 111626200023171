import { request } from 'services/request'
import { IMail, IThread, LoanDocument } from 'types'

const addMailAttachment = async ({
  mailId,
  documentIds,
}: {
  mailId: string
  documentIds: string[]
}): Promise<{ mail: IMail; thread: IThread }> => {
  const { data } = await request.put(`/mail/${mailId}/document`, {
    ids: documentIds,
  })
  return data
}

const removeMailAttachment = async ({
  mailId,
  attachmentId,
}: {
  mailId: string
  attachmentId: string
}) => {
  const { data } = await request.delete(
    `/mail/${mailId}/attachment/${attachmentId}`
  )
  return data
}

const saveMailAttachment = async ({
  mailId,
  document,
  attachmentId,
}: {
  mailId: string
  document: LoanDocument
  attachmentId: string
  location: string | undefined
}): Promise<{ document: LoanDocument }> => {
  const { data } = await request.put(
    `/document/${document.id}/mail/${mailId}/${attachmentId}`
  )
  return data
}

export { addMailAttachment, removeMailAttachment, saveMailAttachment }
