import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { FormQuestion } from './FormQuestion'
import { fields } from './fields'

interface Props {
  isSignatureDisabled: boolean
}

function FormQuestions({ isSignatureDisabled }: Props) {
  return (
    <Flex
      stack
      className="sticky top-0 h-screen w-[320px] flex-grow-0 border-0 border-l border-solid border-grey-200 bg-white-100 px-4 flex-shrink-0"
    >
      <Header
        variant="h3"
        className="h-[65px] border-0 border-b border-solid border-grey-200 leading-[65px]"
      >
        Form Questions
      </Header>
      <Flex
        alignItems="center"
        className="border-0 border-b border-solid border-grey-200 px-2 pb-4"
      >
        <Icon name={IconName.dndCross} />
        <Text>
          Drag a Question From <br /> the List Below
        </Text>
      </Flex>
      <Flex stack gap={0} className="overflow-y-auto">
        {fields
          .filter(({ type }) => type !== 'document')
          .map((field) => (
            <FormQuestion
              key={field.type}
              field={field}
              disabled={field.type === 'signature' && isSignatureDisabled}
            />
          ))}
      </Flex>
    </Flex>
  )
}

export { FormQuestions }
