import { LoanStatus } from 'constants/loan-status'
import { Badge } from './index'

interface Props {
  status: LoanStatus
  daysPastDue?: number
  daysLate?: number
  dateDefault?: string
}

export const ServicingLoanStatusBadge = ({
  status,
  daysPastDue = 0,
  daysLate = 0,
  dateDefault,
}: Props) => {
  let substatus = 'Performing'
  let color = 'green'
  if (status === 'liquidated') {
    substatus = 'Liquidated'
    color = 'gray'
  } else if (dateDefault) {
    substatus = 'Default'
    color = 'red'
  } else {
    if (daysPastDue > 0) {
      substatus = 'Due'
      color = 'yellow'
    }
    if (daysLate > 0) {
      substatus = `Late (${daysLate} days)`
      color = 'red'
    }
  }

  return (
    <Badge color={color as 'green' | 'gray' | 'red' | 'yellow'}>
      {substatus}
    </Badge>
  )
}
