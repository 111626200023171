import clsx from 'clsx'
import { compact, without } from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import {
  IActionConfig,
  IActionValue,
  IConditionConfig,
  IConditionValue,
  ITriggerConfig,
  ITriggerValue,
} from 'types'
import { MenuHeader } from './MenuHeader'
import { Pills } from './Pills'

interface Props {
  selectedMenuItem: ITriggerConfig | IConditionConfig | IActionConfig
  innerValue: ITriggerValue | IConditionValue | IActionValue | undefined
  setInnerValue: Dispatch<
    SetStateAction<ITriggerValue | IConditionValue | IActionValue | undefined>
  >
  onBack: () => void
  onDone: () => void
}

const MenuOptionItems = ({
  selectedMenuItem,
  innerValue,
  setInnerValue,
  onBack,
  onDone,
}: Props) => {
  const [selectedMenuItemOption, setSelectedMenuItemOption] = useState<{
    group?: string
    value: string
    label: string
    options: { group?: string; value: string; label: string }[]
  }>()
  const innerValues = (innerValue?.value as string[]) || []
  const selectedPills = useMemo(
    () =>
      compact(
        innerValues.map((val) => {
          const options = [
            ...compact(selectedMenuItem.options),
            ...compact(
              selectedMenuItem.options?.map((option) => option.options).flat()
            ),
          ]
          return options.find((option) => option.value === val)
        })
      ),
    [innerValues, selectedMenuItem]
  )

  return selectedMenuItemOption ? (
    <div>
      <MenuHeader
        sentence={selectedMenuItemOption.label}
        disabled={innerValues.length === 0}
        onBack={() => setSelectedMenuItemOption(undefined)}
        onDone={onDone}
      />
      <div className="p-3 pt-2 border-0 border-b border-solid border-grey-200">
        <Pills
          pills={selectedPills}
          onRemove={(val: string) =>
            setInnerValue((currInnerValue) => ({
              ...currInnerValue!,
              value: without(innerValues, val),
            }))
          }
        />
      </div>
      <div className="p-1 max-h-[200px] overflow-y-auto">
        {selectedMenuItemOption.options.map(
          ({ group, value, label }, index) => (
            <div key={value}>
              {group !==
                selectedMenuItemOption?.options?.[index - 1]?.group && (
                <div
                  className={clsx(
                    'p-2 text-sm text-grey-600',
                    index !== 0 &&
                      'border-0 border-t border-solid border-grey-200 mt-2'
                  )}
                >
                  {group}
                </div>
              )}
              <Flex
                alignItems="center"
                gap={8}
                className="p-2 rounded cursor-pointer hover:bg-grey-75"
                onClick={() => {
                  setInnerValue((currInnerValue) => ({
                    ...currInnerValue!,
                    value: innerValues.includes(value)
                      ? without(innerValues, value)
                      : compact([innerValues, value].flat()),
                  }))
                }}
              >
                <Checkbox
                  checked={innerValues.includes(value) || false}
                  onChange={() => {}}
                />
                {label}
              </Flex>
            </div>
          )
        )}
      </div>
    </div>
  ) : (
    <>
      <MenuHeader
        sentence={selectedMenuItem.sentence}
        disabled={innerValues.length === 0}
        onBack={onBack}
        onDone={onDone}
      />
      <div className="p-3 pt-2 border-0 border-b border-solid border-grey-200">
        <Pills
          pills={selectedPills}
          onRemove={(val: string) =>
            setInnerValue((currInnerValue) => ({
              ...currInnerValue!,
              value: without(innerValues, val),
            }))
          }
        />
      </div>
      <div className="p-1 max-h-[200px] overflow-y-auto">
        {selectedMenuItem?.options?.map(
          ({ group, value, label, options }, index) => (
            <div key={value}>
              {group !== selectedMenuItem?.options?.[index - 1]?.group && (
                <div
                  className={clsx(
                    'p-2 text-sm text-grey-600',
                    index !== 0 &&
                      'border-0 border-t border-solid border-grey-200 mt-2'
                  )}
                >
                  {group}
                </div>
              )}
              <Flex
                alignItems="center"
                gap={8}
                justifyContent={options ? 'space-between' : 'flex-start'}
                className={clsx(
                  'p-2 rounded cursor-pointer hover:bg-grey-75 group'
                )}
                onClick={() => {
                  if (options) {
                    setSelectedMenuItemOption({ group, value, label, options })
                  } else {
                    setInnerValue((currInnerValue) => ({
                      ...currInnerValue!,
                      value: innerValues.includes(value)
                        ? without(innerValues, value)
                        : compact([innerValues, value].flat()),
                    }))
                  }
                }}
              >
                {!options && (
                  <Checkbox
                    checked={innerValues.includes(value) || false}
                    onChange={() => {}}
                  />
                )}
                {label}
                {options && (
                  <Icon
                    name={IconName.arrowRight}
                    className="text-grey-600 flex-shrink-0 invisible group-hover:visible"
                  />
                )}
              </Flex>
            </div>
          )
        )}
      </div>
    </>
  )
}

export { MenuOptionItems }
