import { createContext, useContext } from 'react'
import { ITaskFilterName } from 'admin/pages/Tasks/LoanTasksDrawer'
import { Loan, LoanModification, IComment } from 'types'

interface ILoanContext {
  loan: Loan
  comments: { comments: IComment[]; total: number } | undefined
  isServicing: boolean
  isOrigination: boolean
  isTimelineOpen: boolean
  openTimeline: () => void
  closeTimeline: () => void
  isCommentsOpen: boolean
  openComments: (options?: { commentId?: string }) => void
  closeComments: () => void
  editModification: LoanModification | null
  openEditModification: (loanModification: LoanModification | null) => void
  closeEditModification: () => void
  modifications: LoanModification[]
  isModificationsLoading: boolean
  isTasksOpen: boolean
  openTasks: (options?: { filterName?: ITaskFilterName }) => void
  closeTasks: () => void
  handleOwnersChange: (owners: string[]) => void
  tasksFilterName: ITaskFilterName
  setTasksFilterName: (filterName: ITaskFilterName) => void
  settings: {
    showBudget: boolean
    showDrawRequests: boolean
  }
}

const LoanContext = createContext<ILoanContext>({} as ILoanContext)

const useLoanContext = () => useContext(LoanContext)

export { LoanContext, useLoanContext }
