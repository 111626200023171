import {
  useMutation,
  useQuery,
  UseQueryOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  getApplicationFields,
  getApplicationScheme,
  saveApplicationScheme,
  deleteApplicationScheme,
} from 'admin/services/api/application'
import {
  KEY_APPLICATION_FIELDS,
  KEY_CUSTOM_APPLICATION,
} from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { IApplicationScheme } from 'types'
import { message } from 'utils/message'

const useApplicationFields = () =>
  useQuery({
    queryKey: [KEY_APPLICATION_FIELDS],
    queryFn: () => getApplicationFields(),
  })

const useApplicationScheme = (
  { clientId }: { clientId: string },
  options: Partial<UseQueryOptions> = {}
) =>
  useQuery({
    queryKey: [KEY_CUSTOM_APPLICATION],
    queryFn: () => getApplicationScheme({ clientId }),
    ...options,
  })

const useSaveApplicationScheme = () =>
  useMutation({
    mutationFn: (scheme: IApplicationScheme) => saveApplicationScheme(scheme),
    onSuccess: () => message.success('Application saved'),
    onError: handleErrorResponse,
  })

const useDeleteApplicationScheme = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => deleteApplicationScheme(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_APPLICATION] })
      message.success('Application reset')
    },
    onError: handleErrorResponse,
  })
}

export {
  useApplicationFields,
  useApplicationScheme,
  useSaveApplicationScheme,
  useDeleteApplicationScheme,
}
