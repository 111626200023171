import { createMongoAbility } from '@casl/ability'
import { PropsWithChildren, useMemo } from 'react'
import { useSession } from 'hooks/use-session'
import { AbilitiesContext, IAction, ISubject } from './AbilitiesContext'

const AbilitiesProvider = ({ children }: PropsWithChildren) => {
  const { user } = useSession()
  const ability = useMemo(
    () => createMongoAbility<[IAction, ISubject]>(user?.permissions || []),
    [user?.permissions]
  )

  return (
    <AbilitiesContext.Provider value={ability}>
      {children}
    </AbilitiesContext.Provider>
  )
}

export { AbilitiesProvider }
