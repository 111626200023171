import clsx from 'clsx'
import { Field, ErrorMessage, FieldAttributes, FieldProps } from 'formik'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Flex } from 'components/Flex'
import { Icon, IconName } from '../Icon'
import { Tooltip } from '../Tooltip'
import { Counter } from './Counter'
import styles from './styles.module.scss'

type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>

interface CustomFieldProps
  extends FieldAttributes<any>,
    Omit<HTMLInputProps, keyof FieldAttributes<any>> {
  fieldClassName?: string
  children?: (props: any) => JSX.Element
  label?: string | JSX.Element
  bottomHint?: string
  hint?: string
  counter?: boolean
  showPasswordStrength?: boolean
}

type FieldChildProps = FieldProps

function CustomField({
  label,
  name,
  bottomHint,
  hint,
  children,
  required,
  placeholder,
  type,
  autoComplete = 'off',
  fieldClassName,
  className,
  disabled,
  readonly,
  maxLength,
  autoFocus,
  counter,
  showPasswordStrength,
  ...restProps
}: CustomFieldProps) {
  return (
    <Field name={name}>
      {({ form, meta: { touched, error }, field }: FieldChildProps) => (
        <div className={clsx(styles.field, fieldClassName)}>
          <div className={clsx(styles.inputWithLabel, className)}>
            {label && (
              <label
                htmlFor={name}
                className={clsx(styles.label, {
                  [styles.errorLabel]: touched && error,
                })}
              >
                <Flex gap={4}>
                  {label}
                  {required ? '*' : ''}
                  {hint && (
                    <Tooltip content={hint}>
                      <Icon name={IconName.info} className={styles.info} />
                    </Tooltip>
                  )}
                </Flex>
                {counter && maxLength && (
                  <Counter
                    valueLength={field.value.length}
                    maxLength={maxLength}
                    error={touched && !!error}
                  />
                )}
              </label>
            )}
            {children ? (
              children({ form, meta: { touched, error }, field })
            ) : (
              <input
                id={name}
                className={clsx(styles.input, {
                  [styles.errorField]: touched && error,
                })}
                placeholder={placeholder}
                type={type}
                autoComplete={autoComplete}
                disabled={disabled}
                readOnly={readonly}
                maxLength={maxLength}
                autoFocus={autoFocus}
                {...field}
                {...restProps}
              />
            )}
            {bottomHint && <div className="text-grey-700">{bottomHint}</div>}
          </div>
          <ErrorMessage
            component="div"
            name={name}
            className={styles.errorMessage}
          />
          {showPasswordStrength && (
            <PasswordStrengthBar
              password={form.values[name]}
              scoreWordClassName="!text-sm"
              className="-mb-4"
            />
          )}
        </div>
      )}
    </Field>
  )
}

export type { CustomFieldProps, FieldChildProps }
export default CustomField
