import InputCheckbox from '../Checkbox/Checkbox'
import { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'
import { Field } from './index'

function Checkbox({
  name,
  label,
  disabled,
  hint,
  fieldClassName,
}: CustomFieldProps) {
  return (
    <Field
      name={name}
      label={label}
      className={styles.checkbox}
      disabled={disabled}
      hint={hint}
      fieldClassName={fieldClassName}
    >
      {({ field }: FieldChildProps) => (
        <InputCheckbox id={name} {...field} disabled={disabled} />
      )}
    </Field>
  )
}

export default Checkbox
