import { max } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  useAddLoanSubstatus,
  useDeleteLoanSubstatus,
  useInvalidateLoanSubstatuses,
  useUpdateLoanSubstatus,
} from 'admin/hooks/use-loan-substatuses'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { LoanStatus } from 'constants/loan-status'
import { useSession } from 'hooks/use-session'
import { IPipelineSettings } from 'services/api/session'
import { LoanSubstatus, LoanSubstatusPayload } from 'types'
import { ModalEditStatus } from './ModalEditStatus'
import { ModalSubstatus } from './ModalSubstatus'
import { SubstatusItem } from './SubstatusItem'

interface Props {
  status: { label: string; value: LoanStatus }
  substatuses: LoanSubstatus[] | undefined
  isLoading: boolean
}

function PanelStatus({ status, substatuses, isLoading }: Props) {
  const [relatedSubstatuses, setRelatedSubstatuses] = useState<LoanSubstatus[]>(
    substatuses
      ?.filter((substatus) => substatus.status === status.label)
      .sort((a, b) => (a.order || 0) - (b.order || 0)) || []
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingStatus, setEditingStatus] = useState<{
    label: string
    value: LoanStatus
  } | null>(null)
  const [editingId, setEditingId] = useState<string>()
  const [deletingId, setDeletingId] = useState<string>()
  const { user } = useSession()

  const isFlag = useCallback(
    (status: string) => {
      const pipelineSettings = user?.client.pipelineSettings as
        | IPipelineSettings
        | undefined

      return (
        pipelineSettings?.[status]?.criticalEnabled ||
        pipelineSettings?.[status]?.attentionEnabled ||
        false
      )
    },
    [user]
  )

  const { mutate: add, isPending: isAdding } = useAddLoanSubstatus()
  const {
    mutate: update,
    mutateAsync: updateAsync,
    isPending: isUpdating,
  } = useUpdateLoanSubstatus()
  const { mutate: remove, isPending: isDeleting } = useDeleteLoanSubstatus()
  const invalidate = useInvalidateLoanSubstatuses()

  const handleColourChange = useCallback(
    ({ id, colour }) => {
      update({ colour, id })
    },
    [substatuses, update]
  )

  const handleSaveSubstatus = useCallback(
    (substatus: LoanSubstatusPayload) => {
      if (editingId) {
        update(
          { ...substatus, id: editingId },
          {
            onSuccess: () => {
              setEditingId(undefined)
              setIsModalOpen(false)
            },
          }
        )
      } else {
        add(
          {
            ...substatus,
            order:
              max(relatedSubstatuses.map(({ order }) => (order || 0) + 1)) || 1,
          },
          { onSuccess: () => setIsModalOpen(false) }
        )
      }
    },
    [editingId, add, update, relatedSubstatuses.length]
  )

  const handleDrop = useCallback(
    async (dragSubstatus: LoanSubstatus, targetSubstatus: LoanSubstatus) => {
      if (dragSubstatus && targetSubstatus) {
        const reorderedSubstatuses = [...relatedSubstatuses]
        const fieldIndex = reorderedSubstatuses.findIndex(
          ({ id }) => id === dragSubstatus.id
        )
        reorderedSubstatuses.splice(fieldIndex, 1)
        const targetIndex = reorderedSubstatuses.findIndex(
          ({ id }) => id === targetSubstatus.id
        )
        reorderedSubstatuses.splice(targetIndex + 1, 0, dragSubstatus)

        setRelatedSubstatuses(reorderedSubstatuses)

        await Promise.all(
          reorderedSubstatuses.map((substatus, index) =>
            updateAsync({
              id: substatus.id,
              order: index,
              silent: true,
            })
          )
        )

        invalidate()
      }
    },
    [relatedSubstatuses, invalidate, updateAsync]
  )

  useEffect(() => {
    const nextRelatedSubstatuses = substatuses?.filter(
      (substatus) => substatus.status === status.value
    )
    setRelatedSubstatuses(
      nextRelatedSubstatuses?.sort((a, b) => (a.order || 0) - (b.order || 0)) ||
        []
    )
  }, [substatuses, status.label])

  return (
    <>
      <Panel
        title={
          <Flex gap={10}>
            {status.label}
            {isFlag(status.value) && (
              <Icon name={IconName.flag} className="text-grey-500" />
            )}
          </Flex>
        }
        loading={isLoading}
        className="max-w-panel"
        actionChildren={
          <EllipsesActions>
            <EllipsesActions.Item
              icon
              onSelect={() => {
                setEditingStatus(status)
              }}
            >
              <Icon name={IconName.edit} />
              Edit Stage
            </EllipsesActions.Item>
          </EllipsesActions>
        }
      >
        <Flex stack gap={0}>
          {relatedSubstatuses.map((substatus) => (
            <SubstatusItem
              key={substatus.id}
              substatus={substatus}
              onEdit={(editingId) => {
                setIsModalOpen(true)
                setEditingId(editingId)
              }}
              onColourChange={handleColourChange}
              onDelete={setDeletingId}
              onDrop={handleDrop}
            />
          ))}
          {relatedSubstatuses.length === 0 && (
            <div className="mt-2 mb-4">None have been created yet</div>
          )}
        </Flex>
        <div>
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="ghost"
            className="mt-2"
          >
            <Icon name={IconName.addCircle} className="text-grey-600" />
            Add Substage
          </Button>
        </div>
      </Panel>
      {isModalOpen && (
        <ModalSubstatus
          substatus={relatedSubstatuses?.find(({ id }) => id === editingId)}
          onCancel={() => {
            setIsModalOpen(false)
            setEditingId(undefined)
          }}
          onSave={handleSaveSubstatus}
          saving={isAdding || isUpdating}
          status={status}
        />
      )}
      {deletingId && (
        <ModalDelete
          resource="substage"
          loading={isDeleting}
          onDelete={() => {
            remove(deletingId, {
              onSuccess: () => setDeletingId(undefined),
            })
          }}
          onCancel={() => setDeletingId(undefined)}
        />
      )}
      {editingStatus && (
        <ModalEditStatus
          status={editingStatus}
          onCancel={() => setEditingStatus(null)}
        />
      )}
    </>
  )
}

export { PanelStatus }
