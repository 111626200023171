import { Formik } from 'formik'
import { toNumber } from 'lodash'
import { DragEvent, useCallback, useMemo, useState } from 'react'
import * as yup from 'yup'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { Drawer } from 'components/Drawer'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { FieldIcon, Form, Textarea } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import {
  useBudgetItemActivity,
  useAddBudgetItemActivityImage,
  useAddBudgetItemActivity,
  useUpdateBudgetItemActivity,
  useDeleteBudgetItemActivity,
  useDeleteBudgetItemActivityImage,
} from 'hooks/use-loan-budget-draw'
import { BudgetItem } from 'types'
import { formatUsd } from 'utils/currency'
import { openBrowseFile } from 'utils/file'
import { sumDecimal } from 'utils/math'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  addressId: string
  drawId: string
  budgetItem: BudgetItem
  maxAmount: number
  activityId?: string
  onClose: () => void
}

const DrawerActivity = ({
  loanId,
  addressId,
  drawId,
  budgetItem,
  maxAmount,
  activityId: editActivityId,
  onClose,
}: Props) => {
  const [isDragActive, setIsDragActive] = useState(false)
  const [activityId, setActivityId] = useState<string | undefined>(
    editActivityId
  )
  const { data: activity, isPending } = useBudgetItemActivity(
    loanId,
    addressId,
    activityId
  )
  const { mutate: addActivity, mutateAsync: addActivityAsync } =
    useAddBudgetItemActivity(loanId, addressId, budgetItem.id)
  const { mutate: editActivity } = useUpdateBudgetItemActivity(
    loanId,
    addressId
  )
  const { mutate: deleteActivity } = useDeleteBudgetItemActivity(
    loanId,
    addressId
  )
  const { mutate: deleteImage } = useDeleteBudgetItemActivityImage(
    loanId,
    addressId,
    activityId as string
  )
  const { mutate: upload } = useAddBudgetItemActivityImage(
    loanId,
    addressId,
    activity?.images.length || 0
  )
  const handleUpload = useCallback(() => {
    openBrowseFile({
      onChoose: async (files) => {
        if (activityId) {
          upload({ files, activityId })
        } else {
          const createdActivity = await addActivityAsync({
            budgetDrawId: drawId,
          })
          setActivityId(createdActivity.id)
          upload({ files, activityId: createdActivity.id })
        }
      },
      accept: '.png, .jpg, .jpeg',
    })
  }, [activityId, upload, addActivity])

  const handleCancel = useCallback(() => {
    if (!editActivityId && activityId) {
      deleteActivity(activityId as string, {
        onSuccess: onClose,
      })
    } else {
      onClose()
    }
  }, [activityId])
  const Schema = useMemo(() => {
    const availableMaxAmount = sumDecimal([
      maxAmount,
      activity?.amount as number,
    ])
    return createScheme({
      description: required,
      amount: yup
        .number()
        .required('The field is required')
        .moreThan(0, 'The amount must be more than 0')
        .max(
          availableMaxAmount,
          availableMaxAmount <= 0
            ? 'There is no outstanding balance left'
            : `The amount must be less than ${formatUsd(availableMaxAmount)}`
        ),
    })
  }, [maxAmount, activity?.amount])
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = async (files: FileList) => {
    setIsDragActive(false)
    if (activityId) {
      upload({ files, activityId })
    } else {
      const createdActivity = await addActivityAsync({
        budgetDrawId: drawId,
      })
      setActivityId(createdActivity.id)
      upload({ files, activityId: createdActivity.id })
    }
  }

  return (
    <Drawer
      loading={!!editActivityId && isPending}
      onClose={onClose}
      transparentOverlay
    >
      <Formik
        initialValues={{
          description: activity?.description || '',
          amount: activity?.amount || '',
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          const save = (activityId ? editActivity : addActivity) as any
          save(
            {
              ...values,
              amount: toNumber(values.amount),
              budgetDrawId: drawId,
              id: activityId,
            },
            { onSuccess: onClose }
          )
        }}
        validateOnChange={false}
      >
        <Form className={styles.form}>
          <Drawer.Header
            title={editActivityId ? 'Edit activity' : 'Add activity'}
            onClose={handleCancel}
            className="h-auto pb-2"
          >
            <Header variant="h4" className="relative -top-1">
              {budgetItem.name}
            </Header>
          </Drawer.Header>
          <Drawer.Content className={styles.content} onDragEnter={handleDrag}>
            {isDragActive && (
              <DragDropFile
                className={styles.dropZone}
                onLeave={() => setIsDragActive(false)}
                onDrop={handleDrop}
                accept={['png', 'jpg', 'jpeg']}
              />
            )}
            <Textarea
              name="description"
              label="Description"
              placeholder="List materials being used. if this is a repair or a replacement, and quantities. More Details → Better"
            />
            <FieldIcon type="currency" name="amount" label="Price" />
            <div className={styles.images}>
              <div className={styles.addImage} onClick={handleUpload}></div>
              {activity?.images.map(({ id, viewUrl }) => (
                <div
                  key={id}
                  style={{ backgroundImage: `url(${viewUrl})` }}
                  className={styles.image}
                >
                  <div className={styles.ellipses}>
                    <EllipsesActions>
                      <EllipsesActions.Item
                        icon
                        onSelect={() => deleteImage(id)}
                        className="text-red-100"
                      >
                        <Icon name={IconName.delete} />
                        Delete
                      </EllipsesActions.Item>
                    </EllipsesActions>
                  </div>
                </div>
              ))}
            </div>
          </Drawer.Content>
          <Drawer.Footer>
            <Flex className={styles.buttons}>
              <Button
                variant="tertiary"
                className={styles.buttons}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button type="submit" className={styles.buttons}>
                Save
              </Button>
            </Flex>
          </Drawer.Footer>
        </Form>
      </Formik>
    </Drawer>
  )
}

export { DrawerActivity }
