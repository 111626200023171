import { UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createContext, useContext } from 'react'
import { IMail, IThread, IThreadRequest } from 'types'

interface IThreadContext {
  thread: IThread
  updateThread: (
    newThread: IThreadRequest,
    options?: UseMutationOptions<
      IThread,
      AxiosError<unknown, any>,
      IThreadRequest,
      unknown
    >
  ) => void
  isUpdating: boolean
  deleteThread: () => void
  isDeleting: boolean
  isSending: boolean
  isAdding: boolean
  addMail: (
    payload: {
      replyTo?: string
      forwardTo?: string
      replyAllTo?: string
    },
    options?: any
  ) => void
  deleteMail: (data: { threadId: string; mailId: string }) => void
  sendMail: ({ mailId }: { mailId: string }) => void
  updateMail: (payload: Partial<IMail>) => void
}

const ThreadContext = createContext<IThreadContext>({} as IThreadContext)

const useThreadContext = () => useContext(ThreadContext)

export { ThreadContext, useThreadContext }
