import { ImportEntity } from 'admin/components/ImportEntity'
import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewVendorsImg from 'images/empty-view-vendors.png'

interface Props {
  onClick: () => void
  onImport: () => void
}

export const EmptyVendors = ({ onClick, onImport }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className="center h-50 w-50 !bg-cover !bg-center !bg-no-repeat"
            style={{ background: `url(${emptyViewVendorsImg})` }}
          />
        }
      />
      <EmptyView.Title title="Add your vendors" />
      <EmptyView.Description description="See all the contacts who interact with your loans, such as title agents, brokers, inspectors and more." />
      <Flex className="mt-6" justifyContent="center">
        <ImportEntity entityType="vendor" setAlertData={onImport} />
        <Button onClick={onClick}>Add Vendor</Button>
      </Flex>
    </EmptyView>
  )
}
