import clsx from 'clsx'
import { compact, takeRight } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Actions, Conditions, Trigger } from 'admin/components/Conditions'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Modal } from 'components/Modal'
import {
  IActionConfig,
  IActionValue,
  IApplicationCondition,
  IApplicationFullCondition,
  IApplicationScheme,
  IAutomationItemConfig,
  IConditionConfig,
  IConditionValue,
  ITriggerConfig,
  ITriggerValue,
} from 'types'
import { uuid } from 'utils/uuid'
import { getFieldById, mapFieldTypeToConditionType } from '../helpers'

interface Props {
  scheme: IApplicationScheme
  condition?: IApplicationFullCondition
  onSave: (condition: IApplicationCondition) => void
  onClose: () => void
}

const ModalCondition = ({
  scheme,
  condition: initialCondition,
  onSave,
  onClose,
}: Props) => {
  const [condition, setCondition] = useState<IApplicationFullCondition>(
    initialCondition || {
      id: uuid(),
      conditions: [],
      actions: [],
    }
  )
  const isEditing = !!initialCondition
  const handleSave = useCallback(() => {
    onSave({
      id: condition.id,
      conditions: condition.conditions.map((condition) => ({
        id: condition.id,
        fieldId: condition.field.fullId || condition.field.id,
        operator: condition.operator,
        value: condition.value,
      })),
      actions: condition.actions.map((action) => ({
        id: action.id,
        action: action.action,
        fieldIds: action.fields?.map((field) => field.fullId || field.id),
        pageIds: action.pages?.map((page) => page.id),
      })),
    })
    onClose()
  }, [condition, onSave, onClose])

  const conditions = useMemo(
    () =>
      condition.conditions.map((condition) => ({
        id: condition.id,
        condition: condition.operator,
        value: condition.value,
        config: {
          id: condition.id,
          name: 'trigger',
          type: mapFieldTypeToConditionType(condition.field.type),
          sentence: `<b>${condition.field.label}</b> is {{value}}`,
        },
      })),
    [condition]
  )

  const triggerConfig: IAutomationItemConfig[] = useMemo(
    () =>
      scheme?.pages.flatMap((page, index) =>
        page.fields
          .filter(
            (type) =>
              ![
                'date',
                'document',
                'phone',
                'email',
                'address',
                'signature',
              ].includes(type.type)
          )
          .map((field) => {
            return {
              id: field.id,
              name: field.label,
              type: mapFieldTypeToConditionType(field.type),
              options:
                field.type === 'yes-no'
                  ? [
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]
                  : field.options,
              sentence: `<b>${field.label}</b> is {{value}}`,
              group: `Page ${index + 1}: ${page.header}`,
              children: ['borrower', 'guarantor'].includes(field.type)
                ? [
                    ...field.entityFields!.map((childField) => ({
                      id: childField.fullId!,
                      name: childField.label,
                      type: mapFieldTypeToConditionType(childField.type),
                      options:
                        childField.type === 'yes-no'
                          ? [
                              { value: 'Yes', label: 'Yes' },
                              { value: 'No', label: 'No' },
                            ]
                          : childField.options,
                      sentence: `<b>${childField.label}</b> is {{value}}`,
                      group: 'Company',
                    })),
                    ...field.individualFields!.map((childField) => ({
                      id: childField.fullId!,
                      name: childField.label,
                      type: mapFieldTypeToConditionType(childField.type),
                      options:
                        childField.type === 'yes-no'
                          ? [
                              { value: 'Yes', label: 'Yes' },
                              { value: 'No', label: 'No' },
                            ]
                          : childField.options,
                      sentence: `<b>${childField.label}</b> is {{value}}`,
                      group: 'Individual',
                    })),
                  ]
                : undefined,
            }
          })
      ) || [],
    [scheme]
  )

  const handleTriggerChange = useCallback(
    (nextTrigger: ITriggerValue | undefined) => {
      setCondition((currentCondition) => {
        const nextCondition = nextTrigger
          ? {
              id: nextTrigger.id,
              field: getFieldById(scheme, nextTrigger.config!.id)!,
              operator: nextTrigger.condition || 'eq',
              value: nextTrigger.value,
            }
          : undefined
        return {
          ...currentCondition,
          conditions: compact(
            currentCondition.conditions.length
              ? currentCondition.conditions.map((condition, index) =>
                  index === 0 ? nextCondition : condition
                )
              : [nextCondition]
          ),
        }
      })
    },
    []
  )

  const handleConditionsChange = useCallback(
    (nextConditions: IConditionValue[]) => {
      setCondition((currentCondition) => {
        return {
          ...currentCondition,
          conditions: [
            currentCondition.conditions[0],
            ...nextConditions.map((nextCondition) => ({
              id: nextCondition.id,
              field: getFieldById(scheme, nextCondition.config!.id)!,
              operator: nextCondition.condition || 'eq',
              value: nextCondition.value,
            })),
          ],
        }
      })
    },
    [condition]
  )

  const actionConfigs: IActionConfig[] = useMemo(() => {
    const fields =
      scheme?.pages.flatMap((page) =>
        page.fields.map((field) => ({
          group: page.header,
          value: field.fullId || field.id,
          label: field.label,
          options: ['borrower', 'guarantor'].includes(field.type)
            ? [
                ...field.entityFields!.map(({ id, fullId, label }) => ({
                  group: 'Company',
                  value: fullId || id,
                  label,
                })),
                ...field.individualFields!.map(({ id, fullId, label }) => ({
                  group: 'Individual',
                  value: fullId || id,
                  label,
                })),
              ]
            : undefined,
        }))
      ) || []

    return [
      {
        id: 'hide-fields',
        name: 'Hide field(s)',
        options: fields,
        sentence: 'Hide field(s) {{value}}',
        type: 'options',
        logic: 'and',
      },
      {
        id: 'hide-pages',
        name: 'Hide page(s)',
        options: scheme?.pages.map((page) => ({
          value: page.id,
          label: page.header,
        })),
        sentence: 'Hide page(s) {{value}}',
        type: 'options',
        logic: 'and',
      },
      {
        id: 'require-fields',
        name: 'Require field(s)',
        options: fields,
        sentence: 'Require {{value}}',
        type: 'options',
        logic: 'and',
      },
      {
        id: 'not-require-fields',
        name: "Don't require field(s)",
        options: fields,
        sentence: "Don't require {{value}}",
        type: 'options',
        logic: 'and',
      },
    ]
  }, [scheme])

  const actions: IActionValue[] = useMemo(() => {
    return condition.actions.map((action) => ({
      ...action,
      id: action.action,
      action: action.action,
      value:
        action.fields?.map((field) => field.fullId || field.id) ||
        action.pages?.map((page) => page.id) ||
        [],
      config: actionConfigs.find((config) => config.id === action.action),
    }))
  }, [condition])

  const handleActionChange = useCallback((actions: IActionValue[]) => {
    setCondition((currentCondition) => {
      return {
        ...currentCondition,
        actions: actions.map((action) => {
          const actionName = (action.action ||
            action.id) as IApplicationFullCondition['actions'][0]['action']

          return {
            id: actionName,
            action: actionName,
            fields:
              actionName === 'hide-pages'
                ? undefined
                : action.value?.map((value) => getFieldById(scheme, value)!),
            pages:
              actionName !== 'hide-pages'
                ? undefined
                : action.value?.map(
                    (value) => scheme.pages.find((page) => page.id === value)!
                  ),
          }
        }),
      }
    })
  }, [])

  return (
    <Modal
      title={isEditing ? 'Edit Condition' : 'Create Condition'}
      crossClose
      onClose={onClose}
    >
      <Flex stack gap={0} className="mb-4">
        <Trigger
          triggersConfig={triggerConfig as ITriggerConfig[]}
          rule={conditions[0]}
          hasConditions={condition.conditions.length > 1}
          onChange={handleTriggerChange}
        />
        {!!condition.conditions.length && (
          <Conditions
            conditionsConfig={triggerConfig as IConditionConfig[]}
            rules={takeRight(conditions, conditions.length - 1)}
            onChange={handleConditionsChange}
          />
        )}
        <div
          className={clsx(
            !!conditions.length && 'mt-2',
            'border-0 border-l border-solid border-grey-200 h-6 ml-[19px] mb-2'
          )}
        />
        <Actions
          actionConfigs={actionConfigs}
          rules={actions}
          onChange={handleActionChange}
        />
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" gap={8}>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={!conditions.length || !actions.length}
        >
          {isEditing ? 'Save' : 'Create'}
        </Button>
      </Flex>
    </Modal>
  )
}

export { ModalCondition }
