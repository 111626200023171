import { debounce } from 'lodash'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as React from 'react'
import { createPortal } from 'react-dom'
import styles from '../styles.module.scss'

type DropDownContextType = {
  registerItem: (ref: React.RefObject<HTMLButtonElement>) => void
}

const DropDownContext = React.createContext<DropDownContextType | null>(null)

export function DropDownItem({
  children,
  className,
  onClick,
  title,
}: {
  children: React.ReactNode
  className: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
}) {
  const ref = useRef<HTMLButtonElement>(null)

  const dropDownContext = React.useContext(DropDownContext)

  if (dropDownContext === null) {
    throw new Error('DropDownItem must be used within a DropDown')
  }

  const { registerItem } = dropDownContext

  useEffect(() => {
    if (ref && ref.current) {
      registerItem(ref)
    }
  }, [ref, registerItem])

  return (
    <button
      className={className}
      onClick={onClick}
      ref={ref}
      title={title}
      type="button"
    >
      {children}
    </button>
  )
}

function DropDownItems({
  children,
  dropDownRef,
  onClose,
}: {
  children: React.ReactNode
  dropDownRef: React.Ref<HTMLDivElement>
  onClose: () => void
}) {
  const [items, setItems] = useState<React.RefObject<HTMLButtonElement>[]>()
  const [highlightedItem, setHighlightedItem] =
    useState<React.RefObject<HTMLButtonElement>>()

  const registerItem = useCallback(
    (itemRef: React.RefObject<HTMLButtonElement>) => {
      setItems((prev) => (prev ? [...prev, itemRef] : [itemRef]))
    },
    [setItems]
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!items) {
      return
    }

    const key = event.key

    if (['Escape', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
      event.preventDefault()
    }

    if (key === 'Escape' || key === 'Tab') {
      onClose()
    } else if (key === 'ArrowUp') {
      setHighlightedItem((prev) => {
        if (!prev) {
          return items[0]
        }
        const index = items.indexOf(prev) - 1
        return items[index === -1 ? items.length - 1 : index]
      })
    } else if (key === 'ArrowDown') {
      setHighlightedItem((prev) => {
        if (!prev) {
          return items[0]
        }
        return items[items.indexOf(prev) + 1]
      })
    }
  }

  const contextValue = useMemo(
    () => ({
      registerItem,
    }),
    [registerItem]
  )

  useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0])
    }

    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus()
    }
  }, [items, highlightedItem])

  return (
    <DropDownContext.Provider value={contextValue}>
      <div
        className={styles.dropdown}
        ref={dropDownRef}
        onKeyDown={handleKeyDown}
      >
        {children}
      </div>
    </DropDownContext.Provider>
  )
}

export default function DropDown({
  buttonLabel,
  buttonAriaLabel,
  buttonClassName,
  buttonIcon,
  children,
}: {
  buttonAriaLabel?: string
  buttonClassName: string
  buttonIcon?: ReactNode
  buttonLabel?: string
  children: ReactNode
}): JSX.Element {
  const dropDownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [showDropDown, setShowDropDown] = useState(false)

  const handleClose = useCallback(() => {
    setShowDropDown(false)
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [])

  const drawDropDown = useCallback(() => {
    const button = buttonRef.current
    const dropDown = dropDownRef.current

    if (button !== null && dropDown !== null) {
      const { top, left, height: buttonHeight } = button.getBoundingClientRect()
      const dropdownTop = top + buttonHeight
      const dropDownHeight = dropDown.offsetHeight
      const availableSpaceUnderDropDown = window.innerHeight - dropdownTop
      const availableSpaceAboveDropDown = top
      const offset = 16
      // check if there is enough space at the bottom to show it
      if (availableSpaceUnderDropDown > dropDownHeight) {
        dropDown.style.top = `${dropdownTop}px`
        dropDown.style.height = 'auto'
        // check if there is enough space at the top to show it
      } else if (availableSpaceAboveDropDown > dropDownHeight) {
        dropDown.style.top = `${dropdownTop - dropDownHeight - buttonHeight}px`
        dropDown.style.height = 'auto'
        // check where is more space at the top or bottom and show it there with fixed height
      } else if (availableSpaceUnderDropDown > availableSpaceAboveDropDown) {
        dropDown.style.top = `${dropdownTop}px`
        dropDown.style.height = `${availableSpaceUnderDropDown - offset}px`
      } else {
        dropDown.style.top = `${dropdownTop - availableSpaceAboveDropDown - buttonHeight + offset}px`
        dropDown.style.height = `${availableSpaceAboveDropDown - offset}px`
      }
      dropDown.style.left = `${Math.min(
        left,
        window.innerWidth - dropDown.offsetWidth - 20
      )}px`
    }
  }, [])
  const drawDropDownDebounced = useMemo(
    () => debounce(drawDropDown, 100),
    [drawDropDown]
  )

  useEffect(() => {
    if (showDropDown) {
      drawDropDown()
    }
  }, [drawDropDown, showDropDown])

  useEffect(() => {
    window.addEventListener('resize', drawDropDownDebounced)
    window.addEventListener('scroll', drawDropDownDebounced)
    return () => {
      window.removeEventListener('scroll', drawDropDownDebounced)
      window.removeEventListener('resize', drawDropDownDebounced)
    }
  }, [drawDropDownDebounced])

  useEffect(() => {
    const button = buttonRef.current

    if (button !== null && showDropDown) {
      const handle = (event: MouseEvent) => {
        const target = event.target
        if (!button.contains(target as Node)) {
          setShowDropDown(false)
        }
      }
      document.addEventListener('click', handle)

      return () => {
        document.removeEventListener('click', handle)
      }
    }
  }, [dropDownRef, buttonRef, showDropDown])

  return (
    <>
      <button
        type="button"
        aria-label={buttonAriaLabel || buttonLabel}
        className={buttonClassName}
        onClick={() => setShowDropDown(!showDropDown)}
        ref={buttonRef}
      >
        {buttonIcon}
        {buttonLabel && (
          <span className={styles.dropdownItemText}>{buttonLabel}</span>
        )}
      </button>

      {showDropDown &&
        createPortal(
          <DropDownItems dropDownRef={dropDownRef} onClose={handleClose}>
            {children}
          </DropDownItems>,
          document.body
        )}
    </>
  )
}
