import { ColumnDef } from '@tanstack/react-table'
import { isUndefined, toNumber, toString } from 'lodash'
import { useMemo } from 'react'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { InputCurrency } from 'components/InputCurrency'
import { Redact } from 'components/Redact'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'
import { IDistribution } from './types'

type ITableFundingSourceRow = IDistribution['fundingSources']['items'][0] & {
  onChangePrincipal: (rowId: string | null, value: string) => void
  onChangeInterest: (rowId: string, value: string) => void
  onChangeUnpaidInterest: (rowId: string, value: string) => void
}

type Props = {
  fundingSourceItems?: IDistribution['fundingSources']['items']
  onChangePrincipal: (rowId: string, value: string) => void
  onChangeInterest: (rowId: string, value: string) => void
  onChangeUnpaidInterest: (rowId: string, value: string) => void
  setIsExpandedUnpaidInterest: (value: boolean) => void
  isExpandedUnpaidInterest?: boolean
  loading: boolean
}

export const TableFundingSources = ({
  fundingSourceItems = [],
  onChangePrincipal,
  onChangeInterest,
  onChangeUnpaidInterest,
  setIsExpandedUnpaidInterest,
  isExpandedUnpaidInterest,
  loading,
}: Props) => {
  const ability = useAbilitiesContext()
  const rows = fundingSourceItems?.map((item) => ({
    ...item,
    principalAmount: toString(item?.principalAmount),
    interestAmount: toString(item?.interestAmount),
    unpaidInterestAmount: toString(item?.unpaidInterestAmount),
    onChangePrincipal,
    onChangeInterest,
    onChangeUnpaidInterest,
  })) as unknown as ITableFundingSourceRow[]

  const columns: ColumnDef<ITableFundingSourceRow>[] = useMemo(
    () => [
      {
        header: 'Investor',
        id: 'fundingSource.investor.name',
        size: isExpandedUnpaidInterest ? 248 : 358,
        cell: ({ row }) => {
          const isSpread = !row.original.fundingSource
          const name = (
            <Redact
              value={
                row.original.fundingSource?.investor.name ||
                row.original.spread?.person.name
              }
              hide={ability.cannot('read', 'investors')}
            />
          )
          return (
            <Flex gap={4} alignItems="center">
              <div>
                {name}
                <Text className={styles.additionalInfo} variant="s">
                  {row.original.fundingSource?.class ?? ''}
                </Text>
              </div>
              {isSpread && (
                <div className="text-sm text-blue-200 bg-blue-25 px-2 py-0.5 rounded font-bold whitespace-nowrap">
                  Spread
                </div>
              )}
            </Flex>
          )
        },
      },
      {
        header: 'Yield',
        accessorKey: 'fundingSource.rate',
        size: 82,
        cell: ({ row }) => {
          const rate = row.original.fundingSource?.rate
          const baseInterestAmount = row.original.baseInterestAmount
          return (
            <>
              {isUndefined(rate) ? '' : formatPercent(rate * 100)}
              {toNumber(baseInterestAmount || 0) !== 0 ? (
                <Text className={styles.additionalInfo} variant="s">
                  {formatUsd(baseInterestAmount)}
                </Text>
              ) : null}
            </>
          )
        },
        meta: {
          align: 'left',
        },
      },
      {
        header: 'Principal',
        accessorKey: 'principalAmount',
        size: 110,
        cell: ({ row, getValue }) => {
          const isSpread = !row.original.fundingSource
          return isSpread ? (
            <InputCurrency className={styles.alignRight} value="0" disabled />
          ) : (
            <InputCurrency
              className={styles.alignRight}
              value={getValue() as string}
              onChange={(e) =>
                row.original.onChangePrincipal(
                  row.original.fundingSource?.id || null,
                  e.target.value
                )
              }
            />
          )
        },
        meta: {
          align: 'left',
        },
      },
      {
        header: () => (
          <Flex gap={8} alignItems="center" className={styles.columnName}>
            Interest
            <div className={styles.icon}>
              <Icon
                name={isExpandedUnpaidInterest ? IconName.minus : IconName.plus}
                size="sm"
              />
            </div>
            <div
              className={styles.iconWrapper}
              onClick={() => {
                setIsExpandedUnpaidInterest(!isExpandedUnpaidInterest)
              }}
            />
          </Flex>
        ),
        accessorKey: 'interestAmount',
        size: 110,
        cell: ({ row, getValue }) => (
          <InputCurrency
            className={styles.alignRight}
            value={getValue() as string}
            onChange={(e) => {
              const isSpread = !row.original.fundingSource
              row.original.onChangeInterest(
                (isSpread
                  ? row.original.spread?.id
                  : row.original.fundingSource?.id) as string,
                e.target.value
              )
            }}
          />
        ),
        meta: {
          align: 'left',
        },
      },
      ...(isExpandedUnpaidInterest
        ? [
            {
              header: 'Unpaid Interest',
              accessorKey: 'unpaidInterestAmount',
              size: 110,
              cell: ({ row, getValue }) => {
                const isSpread = !row.original.fundingSource
                const unpaidInterest = toNumber(
                  row.original.fundingSource?.unpaidInterest ||
                    row.original.fundingSourceUnpaidInterest ||
                    0
                )

                return (
                  <Flex stack gap={0} className={styles.unpaidInterestCell}>
                    <InputCurrency
                      className={styles.alignRight}
                      value={getValue() as string}
                      onChange={(e) =>
                        row.original.onChangeUnpaidInterest(
                          (isSpread
                            ? row.original.spread?.id
                            : row.original.fundingSource?.id) as string,
                          e.target.value
                        )
                      }
                    />
                    {!!unpaidInterest && (
                      <span className={styles.unpaidInterest}>
                        Unpaid {formatUsd(unpaidInterest)}
                      </span>
                    )}
                  </Flex>
                )
              },
              meta: {
                align: 'left',
              },
            },
          ]
        : []),
    ],
    [isExpandedUnpaidInterest]
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={rows}
      loading={loading}
    />
  )
}
