import { ImportEntity } from 'admin/components/ImportEntity'
import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewServicingImg from 'images/empty-view-servicing.png'

interface Props {
  onClick: () => void
  onImport: () => void
}

export const EmptyServicing = ({ onClick, onImport }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={'center h-50 w-50 !bg-cover !bg-center !bg-no-repeat'}
            style={{ background: `url(${emptyViewServicingImg})` }}
          />
        }
      />
      <EmptyView.Title title="Create a loan to get started" />
      <EmptyView.Description description="Access and manage every detail of your loans, including documents, properties, funding, payments and more." />
      <Flex className="mt-6" justifyContent="center">
        <ImportEntity entityType="loan" setAlertData={onImport} />
        <Button onClick={onClick}>Add Loan</Button>
      </Flex>
    </EmptyView>
  )
}
