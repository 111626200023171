import { ImportEntity } from 'admin/components/ImportEntity'
import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewInvestorsImg from 'images/empty-view-investors.png'
interface Props {
  onClick: () => void
  onImport: () => void
}

export const EmptyInvestors = ({ onClick, onImport }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className="center h-50 w-50 !bg-cover !bg-center !bg-no-repeat"
            style={{ background: `url(${emptyViewInvestorsImg})` }}
          />
        }
      />
      <EmptyView.Title title="Add your investors" />
      <EmptyView.Description description="Add your investors to match them with investment opportunities and give them access to your branded investor portal." />
      <Flex className="mt-6" justifyContent="center">
        <ImportEntity entityType="investor" setAlertData={onImport} />
        <Button onClick={onClick}>Add Investor</Button>
      </Flex>
    </EmptyView>
  )
}
