import clsx from 'clsx'
import { useState } from 'react'
import { Button, ButtonProps } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'

const colors = [
  'grey',
  'red',
  'peach',
  'orange',
  'yellow',
  'lime',
  'green',
  'blueberry',
  'blue',
  'purple',
  'dark-purple',
  'raspberry',
]

interface Props {
  colour: string
  onSelect: (color: string) => void
  size?: ButtonProps['size']
}

function ColourPicker({ colour, onSelect, size }: Props) {
  const [open, setOpen] = useState(false)
  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      trigger={
        <Button variant="secondary" className="cursor-pointer" size={size}>
          <Flex gap={8} alignItems="center">
            <div
              className={clsx(
                'rounded w-4 h-4',
                colour === 'grey' && 'bg-grey-500',
                colour === 'red' && 'bg-red-50',
                colour === 'peach' && 'bg-peach-100',
                colour === 'orange' && 'bg-orange-100',
                colour === 'yellow' && 'bg-yellow-100',
                colour === 'lime' && 'bg-lime-100',
                colour === 'green' && 'bg-green-100',
                colour === 'blueberry' && 'bg-blueberry-100',
                colour === 'blue' && 'bg-blue-100',
                colour === 'purple' && 'bg-purple-100',
                colour === 'dark-purple' && 'bg-purple-200',
                colour === 'raspberry' && 'bg-raspberry-100'
              )}
            ></div>
            <Icon name={IconName.arrowDown} />
          </Flex>
        </Button>
      }
    >
      <Grid className="p-2" gap={10}>
        {colors.map((key) => (
          <Grid.Item md={3} key={key}>
            <div
              onClick={() => {
                onSelect(key)
                setOpen(false)
              }}
              className={clsx(
                'p-2 cursor-pointer rounded border border-solid border-white-100',
                colour === 'grey' &&
                  key === 'grey' &&
                  'bg-grey-50 !border-grey-500',
                key === 'grey' && 'hover:bg-grey-50 hover:!border-grey-500',
                colour === 'red' && key === 'red' && 'bg-red-25 !border-red-50',
                key === 'red' && 'hover:bg-red-25 hover:!border-red-50',
                colour === 'peach' &&
                  key === 'peach' &&
                  'bg-peach-25 !border-peach-100',
                key === 'peach' && 'hover:bg-peach-25 hover:!border-peach-100',
                colour === 'orange' &&
                  key === 'orange' &&
                  'bg-orange-25 !border-orange-100',
                key === 'orange' &&
                  'hover:bg-orange-25 hover:!border-orange-100',
                colour === 'yellow' &&
                  key === 'yellow' &&
                  'bg-yellow-50 border-yellow-100',
                key === 'yellow' &&
                  'hover:bg-yellow-50 hover:border-yellow-100',
                colour === 'lime' &&
                  key === 'lime' &&
                  'bg-lime-25 !border-lime-100',
                key === 'lime' && 'hover:bg-lime-25 hover:border-lime-100',
                colour === 'green' &&
                  key === 'green' &&
                  'bg-green-25 !border-green-50',
                key === 'green' && 'hover:bg-green-25 hover:border-green-50',
                colour === 'blueberry' &&
                  key === 'blueberry' &&
                  'bg-blueberry-25 !border-blueberry-100',
                key === 'blueberry' &&
                  'hover:bg-blueberry-25 hover:border-blueberry-100',
                colour === 'blue' &&
                  key === 'blue' &&
                  'bg-blue-25 !border-blue-100',
                key === 'blue' && 'hover:bg-blue-25 hover:!border-blue-100',
                colour === 'purple' &&
                  key === 'purple' &&
                  'bg-purple-25 !border-purple-100',
                key === 'purple' &&
                  'hover:bg-purple-25 hover:!border-purple-100',
                colour === 'dark-purple' &&
                  key === 'dark-purple' &&
                  'bg-purple-50 !border-purple-200',
                key === 'dark-purple' &&
                  'hover:bg-purple-50 hover:!border-purple-200',
                colour === 'raspberry' &&
                  key === 'raspberry' &&
                  'bg-raspberry-25 !border-raspberry-100',
                key === 'raspberry' &&
                  'hover:bg-raspberry-25 hover:border-raspberry-100'
              )}
            >
              <div
                className={clsx(
                  'rounded w-4 h-4',
                  key === 'grey' && 'bg-grey-500',
                  key === 'red' && 'bg-red-50',
                  key === 'peach' && 'bg-peach-100',
                  key === 'orange' && 'bg-orange-100',
                  key === 'yellow' && 'bg-yellow-100',
                  key === 'lime' && 'bg-lime-100',
                  key === 'green' && 'bg-green-50',
                  key === 'blueberry' && 'bg-blueberry-100',
                  key === 'blue' && 'bg-blue-100',
                  key === 'purple' && 'bg-purple-100',
                  key === 'dark-purple' && 'bg-purple-200',
                  key === 'raspberry' && 'bg-raspberry-100'
                )}
              ></div>
            </div>
          </Grid.Item>
        ))}
      </Grid>
    </Dropdown>
  )
}

export { ColourPicker }
