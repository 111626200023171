import { Fragment, useState } from 'react'
import { ListDocument } from 'admin/pages/Thread/ThreadForm/ModalAttachDocs/ListDocument'
import { LoanDocument } from 'types'
import { Section } from 'utils/loan-document-sections'
import { ItemWithDocuments } from './ModalAttachDocs'

interface Props {
  selectedDocuments: string[]
  selectedItems: ItemWithDocuments[]
  onSelectDocument: (section: Section, document: LoanDocument) => void
}

const SelectedDocuments = ({
  selectedItems: initialSelectedItems,
  onSelectDocument,
  selectedDocuments,
}: Props) => {
  const [selectedItems] = useState<ItemWithDocuments[]>(initialSelectedItems)

  return (
    <div>
      {selectedItems.map((selectedItem) => (
        <Fragment key={selectedItem.item.id}>
          {selectedItem.sections.map((section) => (
            <Fragment key={section.name}>
              <div className="py-2.5 pr-2 pl-3 text-sm text-grey-600 leading-5 border-0 border-b border-solid border-grey-100">
                {section.name
                  ? `${selectedItem.item.name} / ${section.name}`
                  : selectedItem.item.name}
              </div>
              {section.documents.map((document) => (
                <ListDocument
                  key={document.id}
                  document={document}
                  selected={selectedDocuments.includes(document.id)}
                  onSelect={() => onSelectDocument(section, document)}
                />
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </div>
  )
}

export { SelectedDocuments }
