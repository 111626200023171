import { ColumnDef } from '@tanstack/react-table'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathToLoan } from 'admin/path-to'
import { ChargeStatusBadge } from 'components/Badge/ChargeStatusBadge'
import { Table } from 'components/Table'
import { Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

interface Props {
  data?: any[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TablePaidCharges({ data = [], sort, onSort, loading }: Props) {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'loan.name',
        header: 'Loan',
        accessorKey: 'loan.name',
        cell: ({ row }) => {
          const loan = row.original.loan
          return loan ? (
            <NavLink to={pathToLoan(loan, 'loanCharges')} className="link">
              {loan.name}
            </NavLink>
          ) : (
            '-'
          )
        },
      },
      {
        header: 'Date',
        accessorKey: 'dateCharged',
        size: 180,
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Description',
        accessorKey: 'name',
      },
      {
        header: 'Payable To',
        id: 'person.name',
        cell: ({ row }) => {
          const { person } = row.original
          return compact([person?.companyName, person?.name]).join(' - ')
        },
      },
      {
        header: 'Due Date',
        accessorKey: 'dateDue',
        size: 180,
        cell: ({ getValue }) => friendlyDate(getValue() as string) || '-',
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          const { amount, amountDue } = row.original
          return <ChargeStatusBadge amount={amount} amountDue={amountDue} />
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TablePaidCharges
