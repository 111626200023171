import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import styles from 'pages/Session/styles.module.scss'

interface Props {
  signInUrl: string
  onBack: () => void
}

const SentInviteError = ({ signInUrl, onBack }: Props) => {
  const handleBack = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onBack()
    },
    [onBack]
  )

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo type="statement" to={signInUrl} />
        <div className={styles.form}>
          <Header variant="h1" className={styles.title}>
            Check your email for your invitation
          </Header>
          <Text className={styles.description}>
            We’ve sent you an email with a link to log in and get started. If
            you don’t see it, check your spam folder.
          </Text>
        </div>
        <Link to={signInUrl} onClick={handleBack} className={styles.back}>
          <Icon name={IconName.arrowLeft} />
          Back to login
        </Link>
      </div>
    </div>
  )
}

export { SentInviteError }
