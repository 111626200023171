import clsx from 'clsx'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'

interface Props {
  title: string
  className?: string
}

export const Title = ({ title, className }: Props) => {
  return (
    <Flex className={clsx('mt-3', className)} justifyContent="center">
      <Header className="max-w-115 leading-5" variant="h4">
        {title}
      </Header>
    </Flex>
  )
}
