import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewFundsImg from 'images/empty-view-funds.png'

interface Props {
  onClick: () => void
}

export const EmptyFunds = ({ onClick }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className="center h-50 w-50 !bg-cover !bg-center !bg-no-repeat"
            style={{ background: `url(${emptyViewFundsImg})` }}
          />
        }
      />
      <EmptyView.Title title="Create your first fund in Baseline" />
      <EmptyView.Description description="Manage your fund’s portfolio, and track investor contributions, returned capital and distributions in one place." />
      <Flex className="mt-6" justifyContent="center">
        <Button onClick={onClick}>Add Fund</Button>
      </Flex>
    </EmptyView>
  )
}
