import { Formik } from 'formik'
import { useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, Form } from 'components/Form'
import { Modal } from 'components/Modal'
import { LoanStatus } from 'constants/loan-status'
import { LoanSubstatus, LoanSubstatusColor, LoanSubstatusPayload } from 'types'
import { createScheme, mergeRules, required } from 'utils/schemas'
import { ColourPicker } from './ColourPicker'
import {
  TimeBasedAlertSettings,
  TimeBasedAlertFormValues,
  TimeBasedAlertFormScheme,
} from './TimeBasedAlertSettings'

const FormSchema = mergeRules(
  createScheme({
    name: required,
  }),
  TimeBasedAlertFormScheme
)

type FormValues = TimeBasedAlertFormValues & {
  name: string
  colour: LoanSubstatusColor
}

interface Props {
  substatus?: LoanSubstatus
  saving: boolean
  onSave: (substatus: LoanSubstatusPayload) => void
  onCancel: () => void
  status: { value: string; label: string }
}

function ModalSubstatus({
  saving,
  substatus,
  onSave,
  onCancel,
  status,
}: Props) {
  const isEditing = substatus?.id
  const initialValue: FormValues = {
    name: substatus?.name || '',
    colour: substatus?.colour || 'grey',
    enabled:
      !!substatus?.attentionEnabled || !!substatus?.criticalEnabled || false,
    attentionEnabled: substatus?.attentionEnabled || false,
    attentionTime: substatus?.attentionTime || null,
    attentionUnit: substatus?.attentionUnit || 'hours',
    criticalEnabled: substatus?.criticalEnabled || false,
    criticalTime: substatus?.criticalTime || null,
    criticalUnit: substatus?.criticalUnit || 'hours',
    attentionNotificationEnabled:
      substatus?.attentionNotificationEnabled || false,
    criticalNotificationEnabled:
      substatus?.criticalNotificationEnabled || false,
    owners: substatus?.owners || [],
    otherOwners: substatus?.otherOwners || [],
  }
  const [colour, setColour] = useState<LoanSubstatusColor>(initialValue.colour)

  return (
    <Modal
      title={isEditing ? 'Edit Substage' : `Create ${status.label} Substage`}
      onClose={onCancel}
    >
      <Formik
        initialValues={initialValue}
        onSubmit={(values) => {
          onSave({
            ...values,
            owners: values.owners.map(({ id }) => id),
            status: status.value as LoanStatus,
            colour: colour,
          })
        }}
        validationSchema={FormSchema}
      >
        <Form>
          <Flex gap={16} justifyContent="space-between">
            <Field name="name" label="Name" />
            <Flex alignItems="center" className="inline-block pt-6">
              <ColourPicker
                size="large"
                colour={colour}
                onSelect={(colour) => {
                  setColour(colour as LoanSubstatusColor)
                }}
              />
            </Flex>
          </Flex>
          <TimeBasedAlertSettings />
          <Flex gap={16} justifyContent="flex-end" className="mt-6">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              {isEditing ? 'Save' : 'Create Substage'}
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalSubstatus }
