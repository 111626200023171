import { MongoAbility } from '@casl/ability'
import { createContext, useContext } from 'react'

type IAction = 'list' | 'read'
type ISubject =
  | 'quotes'
  | 'quote-sizer'
  | 'loan-documents'
  | 'loan-settings'
  | 'borrowers'
  | 'investors'
  | 'payments'
  | 'vendors'
  | 'change-log'
  | 'communications'
  | 'reports'
  | 'funds'
  | 'pipeline'
  | 'payouts'
  | 'servicing'
  | 'dashboard'
  | 'banking'
  | 'products'
  | 'product'
  | 'tasks'
  | 'generate-documents'

const AbilitiesContext = createContext<MongoAbility<[IAction, ISubject]>>(
  {} as MongoAbility<[IAction, ISubject]>
)

const useAbilitiesContext = () => useContext(AbilitiesContext)

export type { IAction, ISubject }
export { AbilitiesContext, useAbilitiesContext }
