import { PropsWithChildren } from 'react'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Text } from 'components/Text'
import styles from './styles.module.scss'

const Success = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      stack
      justifyContent="center"
      alignItems="center"
      gap={32}
      className={styles.successStep}
    >
      <div className={styles.successImage} />
      <Flex stack gap={8} justifyContent="center" alignItems="center">
        <Header variant="h2">Thank you!</Header>
        <Text className={styles.successText}>
          Your application has been processed. A team member will reach out to
          you shortly.
        </Text>
      </Flex>
      {children}
    </Flex>
  )
}

export { Success }
