import clsx from 'clsx'
import { capitalize } from 'lodash'
import { MenuItem } from 'admin/components/Conditions/MenuItem'
import { AutomationStatusBadge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import {
  ITriggerConfig,
  ITriggerValue,
  IConditionConfig,
  IConditionValue,
  IActionConfig,
  IActionValue,
} from 'types'

interface Props {
  itemConfig: ITriggerConfig | IConditionConfig | IActionConfig
  value?: ITriggerValue | IConditionValue | IActionValue
  className?: string
  onActionEvent?: () => void
  statusDetails?: {
    actionStatusIconKey: string
    status: 'success' | 'failed'
    actionStatusIconStyles?: string
  }
}

const StatusIcon = ({
  iconName,
  className,
}: {
  iconName: string
  className?: string
}) => {
  return (
    <Flex className={className}>
      <Icon name={IconName[iconName]} />
    </Flex>
  )
}

const getEllipsesIconKey = (action) => {
  const icons = {
    remove: 'close',
    retry: 'restore',
    undo: 'restore',
  }
  return icons[action] || icons.undo
}

const MenuTrigger = ({
  itemConfig,
  value: item,
  onActionEvent,
  className,
  statusDetails,
  ...props
}: Props) => {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      gap={0}
      className={clsx(
        'min-h-16 border border-solid border-grey-200 rounded cursor-pointer',
        className
      )}
      {...props}
    >
      {item && (
        <Flex
          key={itemConfig?.id}
          alignItems={
            (itemConfig as IActionConfig).error ? 'flex-start' : 'center'
          }
          justifyContent="space-between"
          className="p-4 w-full flex-grow"
        >
          <Flex alignItems="flex-start" justifyContent="flex-start" gap={10}>
            {statusDetails && (
              <StatusIcon
                iconName={statusDetails.actionStatusIconKey}
                className={statusDetails.actionStatusIconStyles}
              />
            )}
            <Flex stack gap={10}>
              <MenuItem itemConfig={itemConfig} value={item} />
              {(itemConfig as IActionConfig).error && (
                <div>{(itemConfig as IActionConfig).error}</div>
              )}
            </Flex>
          </Flex>
          <Flex>
            {!(itemConfig as IActionConfig).success && statusDetails && (
              <AutomationStatusBadge status={statusDetails.status} />
            )}
            {onActionEvent && (itemConfig as IActionConfig).success && (
              <EllipsesActions>
                <EllipsesActions.Item icon onSelect={() => onActionEvent()}>
                  <Icon
                    name={
                      IconName[
                        getEllipsesIconKey((itemConfig as IActionConfig).action)
                      ]
                    }
                  />
                  {capitalize((itemConfig as IActionConfig).action || 'undo')}
                </EllipsesActions.Item>
              </EllipsesActions>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export { MenuTrigger }
