import { CellContext } from '@tanstack/react-table'
import clsx from 'clsx'
import { isFunction, isNaN } from 'lodash'
import { memo, ReactNode } from 'react'
import { ColumnMeta } from 'types'
import styles from './styles.module.scss'

interface TableProps extends CellContext<any, any> {
  children: ReactNode
}

const Cell = memo(({ children, column, cell }: TableProps) => {
  const meta = column.columnDef.meta as ColumnMeta
  const align = meta?.align || 'left'
  const columnContentType =
    (isFunction(cell.column.columnDef?.cell) &&
      typeof cell.column.columnDef.cell(cell.getContext())) ||
    'unknown'

  return (
    <div
      className={clsx(styles.cell, {
        [styles.alignLeft]: align === 'left',
        [styles.alignRight]:
          align === 'right' && columnContentType !== 'string',
        [styles.alignRightText]:
          align === 'right' && columnContentType === 'string',
        [styles.alignCenter]: align === 'center',
      })}
      style={{
        width: isNaN(column.getSize()) ? 'auto' : column.getSize(),
      }}
    >
      {children}
    </div>
  )
})

Cell.displayName = 'Cell'

export default Cell
