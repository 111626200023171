import { clsx } from 'clsx'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { useAuthIntegrations } from 'hooks/use-auth-integrations'
import styles from 'pages/Session/styles.module.scss'
import { getRedirectOnLoginUrl } from 'services/request'

interface Props {
  signInUrl: string
}

const AuthIntegrations = ({ signInUrl }: Props) => {
  const { data: logins } = useAuthIntegrations()

  const redirectUrl = encodeURIComponent(
    `${document.location.origin}${signInUrl}?redirect=${getRedirectOnLoginUrl().replaceAll('#', '%23')}`
  )

  return logins ? (
    <>
      {logins?.map((auth) => (
        <Button
          key={auth.name}
          iconLeft={<img src={auth.logoUrl} width={20} height={20} />}
          variant="secondary"
          data-testid={`auth-provider-${auth.type}`}
          className={clsx(styles.authButton, {
            [styles.lastAuthButton]: logins[logins.length - 1] === auth,
          })}
          onClick={() => {
            document.location.href = `https://api.unified.to/unified/integration/login/6670ecd29da376c87a94a653/${auth.type}?redirect=true&success_redirect=${redirectUrl}`
          }}
        >
          Continue with {auth.name}
        </Button>
      ))}
      <Flex
        gap={24}
        alignItems="center"
        className="w-full mt-4 mb-9"
        data-testid="auth-divider"
      >
        <div className="w-full border-0 border-b border-solid border-grey-200" />
        <Text variant="l" className="text-grey-700 text-nowrap">
          or, sign in with your email
        </Text>
        <div className="w-full border-0 border-b border-solid border-grey-200" />
      </Flex>
    </>
  ) : null
}

export { AuthIntegrations }
