import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface BadgeProps {
  color?: 'gray' | 'grey' | 'green' | 'red' | 'yellow' | 'blue' | 'purple'
  size?: 'md' | 'sm'
  className?: string
  children: ReactNode
}

function Badge({
  color = 'gray',
  size = 'md',
  className,
  children,
}: BadgeProps) {
  return (
    <div
      className={clsx(
        styles.badge,
        styles.variantDefault,
        {
          [styles.colorGray]: color === 'gray',
          [styles.colorGreen]: color === 'green',
          [styles.colorRed]: color === 'red',
          [styles.colorYellow]: color === 'yellow',
          [styles.colorBlue]: color === 'blue',
          [styles.colorPurple]: color === 'purple',
          [styles.sizeSm]: size === 'sm',
          [styles.sizeMd]: size === 'md',
        },
        className
      )}
    >
      {children}
    </div>
  )
}

export type { BadgeProps }
export default Badge
