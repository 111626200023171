import { PersonTitle } from 'types'

export const personTitles: PersonTitle[] = [
  'Authorized Member',
  'Co-trustee',
  'General Partner',
  'Manager',
  'Managing Member',
  'Member',
  'President',
  'Sole Member',
  'Trustee',
  'Vice President',
]
