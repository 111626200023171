import { capitalize } from 'lodash'
import { useState } from 'react'
import BankingStatusBadge from 'components/Badge/BankingStatusBadge'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { ModalEntityBanking } from 'components/Modal/EntityBanking'
import { Panel } from 'components/Panel'
import { Redact } from 'components/Redact'
import { Summary } from 'components/Summary'
import { BankingData } from 'types'

interface Props {
  banking: BankingData | null
  lock?: boolean
  title?: string
  updateBanking: (banking: BankingData, { onSuccess }) => void
  isSaving: boolean
}

export const PanelEntityBanking = ({
  banking,
  lock = false,
  title = 'Pre-Authorized Debit',
  updateBanking,
  isSaving,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <Panel
      title={
        <Flex>
          <Header variant="h4">{title}</Header>
          {!!banking?.accountName &&
            !!banking?.accountNumber &&
            !!banking?.routingNumber &&
            !!banking?.accountType && (
              <BankingStatusBadge
                status={banking?.enabled !== false}
                info={banking?.disabledReason}
              />
            )}
        </Flex>
      }
      alwaysShowEdit
      onEdit={lock ? undefined : () => setIsModalVisible(true)}
    >
      <Grid gap={16}>
        <Grid.Item xs={6}>
          <Summary name="Account Name">{banking?.accountName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Account Number">
            {banking?.accountNumber ? (
              <Redact value={banking?.accountNumber} showLast={3} />
            ) : (
              ''
            )}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Routing Number">
            {banking?.routingNumber ? (
              <Redact value={banking?.routingNumber} showLast={3} />
            ) : (
              ''
            )}
            {banking?.bankName ? ` (${banking?.bankName})` : ''}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Type">
            {banking?.accountType && capitalize(banking?.accountType)}
          </Summary>
        </Grid.Item>
      </Grid>
      {isModalVisible && (
        <ModalEntityBanking
          banking={banking}
          saving={isSaving}
          onSave={(banking) => {
            updateBanking(banking, {
              onSuccess: () => setIsModalVisible(false),
            })
          }}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}
