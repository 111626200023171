import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { Loan, LoanDocument } from 'types'

interface Section {
  name: string
  section: string
  guarantorId?: string
  borrowerId?: string
  documents: LoanDocument[]
}

const getLoanDocumentSections = ({
  ability,
  loan,
  documents,
}: {
  ability: ReturnType<typeof useAbilitiesContext>
  loan?: Loan
  documents?: LoanDocument[]
}): Section[] => {
  const investorAgreements = ability.can('read', 'investors')
    ? (documents || []).filter(
        ({ section }) => section === 'Investor Agreements'
      )
    : []

  return !loan
    ? []
    : [
        ...loan.borrowers.map(({ id, name }) => ({
          name: `Borrower${loan.borrowers.length > 1 ? ` - ${name}` : ''}`,
          section: 'Borrower',
          borrowerId: id,
          documents: (documents || []).filter(
            ({ section, borrowerId }) =>
              section === 'Borrower' && borrowerId === id
          ),
        })),
        ...loan.guarantors.map(({ id, name }) => ({
          name: `Guarantor${loan.guarantors.length > 1 ? ` - ${name}` : ''}`,
          section: 'Guarantor',
          guarantorId: id,
          documents: (documents || []).filter(
            ({ section, guarantorId }) =>
              section === 'Guarantor' && guarantorId === id
          ),
        })),
        {
          name: 'Collateral',
          section: 'Collateral',
          documents: (documents || []).filter(
            ({ section }) => section === 'Collateral'
          ),
        },
        {
          name: 'Loan',
          section: 'Loan',
          documents: (documents || []).filter(
            ({ section }) => section === 'Loan'
          ),
        },
        {
          name: 'Closing',
          section: 'Closing',
          documents: (documents || []).filter(
            ({ section }) => section === 'Closing'
          ),
        },
        ...(investorAgreements.length > 0
          ? [
              {
                name: 'Investor Agreements',
                section: 'Investor Agreements',
                documents: investorAgreements,
              },
            ]
          : []),
        {
          name: 'Other',
          section: 'Other',
          documents: (documents || []).filter(
            ({ section }) =>
              ![
                'Borrower',
                'Guarantor',
                'Collateral',
                'Loan',
                'Closing',
                'Investor Agreements',
              ].includes(section)
          ),
        },
      ]
}

export type { Section }
export { getLoanDocumentSections }
