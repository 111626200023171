import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

function ConditionsEmptyView() {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <Flex
            justifyContent="center"
            alignItems="center"
            className="h-20 w-20 bg-purple-50 rounded-[20px]"
          >
            <Icon
              name={IconName.conditions}
              className="text-purple-200 h-12 w-12"
            />
          </Flex>
        }
      />
      <EmptyView.Title className="mt-8" title="Add your first condition" />
      <EmptyView.Description
        className="!mt-3"
        description="Conditions allow you to change how your application appears to borrowers based on their responses to other fields"
      />
    </EmptyView>
  )
}

export { ConditionsEmptyView }
