import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useUpdateQuoteLenders } from 'admin/hooks/use-quote-lenders'
import {
  useGenerateQuoteOptions,
  useQuoteOptions,
} from 'admin/hooks/use-quote-options'
import { useUpdateQuote } from 'admin/hooks/use-quotes'
import { pathTo } from 'admin/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Steps } from 'components/Steps'
import { Quote, QuoteOption } from 'types'
import { Borrowers } from './Borrowers'
import { LoanDetails } from './LoanDetails'
import { LoanOptions } from './LoanOptions'
import { Property } from './Property'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
}

const breadcrumbs = { title: 'Quotes', link: pathTo('quotes') }
const steps = ['Type', 'Borrower', 'Details', 'Options']
const isNextDisabled = (step, quote: Quote, options?: QuoteOption[]) => {
  return (
    (step === 'Type' && !quote.lenders.length) ||
    (step === 'Borrower' &&
      (!quote.borrowers.length || !quote.guarantors.length)) ||
    (step === 'Details' &&
      (!quote.lenders.length ||
        !quote.fields.every(
          ({ type, property }) =>
            type.includes('option') ||
            property?.value?.[0] === 0 ||
            property?.value?.[0]
        ))) ||
    (step === 'Options' &&
      !options?.filter(({ isSelected }) => isSelected).length)
  )
}
const getCurrentStep = (quote: Quote) => {
  if (
    quote.lenders.length &&
    quote.borrowers.length &&
    quote.guarantors.length &&
    quote.address?.street1 &&
    quote.fields.every(
      ({ property }) => property?.value?.[0] === 0 || property?.value?.[0]
    ) &&
    quote.dateOptions
  ) {
    return 'Options'
  } else if (
    quote.lenders.length &&
    quote.borrowers.length &&
    quote.guarantors.length
  ) {
    return 'Details'
  } else if (quote.lenders.length) {
    return 'Borrower'
  }
  return 'Type'
}

export const QuoteDraft = ({ quote }: Props) => {
  const navigate = useNavigate()
  const [loanType, setLoanType] = useState(quote.loanType)
  const [transactionType, setTransactionType] = useState(quote.transactionType)
  const [selectedLenders, setSelectedLenders] = useState<string[]>(
    quote.lenders.map(({ id }) => id)
  )
  const [step, setStep] = useState<string>('')
  const [initiatedGeneration, setInitiatedGeneration] = useState<boolean>(false)
  const { mutate: updateQuote } = useUpdateQuote()
  const { mutate: updateQuoteLenders } = useUpdateQuoteLenders(quote.id)
  const { data: options } = useQuoteOptions(quote.id)
  const { mutateAsync: generate, isPending: isGenerating } =
    useGenerateQuoteOptions(quote.id)

  useEffect(() => {
    if (!step) {
      setStep(getCurrentStep(quote))
    }
  }, [quote, step])
  useEffect(() => {
    if (
      loanType !== quote.loanType ||
      transactionType !== quote.transactionType
    ) {
      updateQuote({
        id: quote.id,
        loanType,
        transactionType,
      })
    }
  }, [loanType, transactionType])
  useEffect(() => {
    if (
      !isEqual(quote.lenders.map(({ id }) => id).sort(), selectedLenders.sort())
    ) {
      updateQuoteLenders({ lenderId: selectedLenders })
    }
  }, [quote.lenders, selectedLenders])

  const handleBack = () => {
    const currentStepIndex = steps.findIndex((name) => name === step)
    setStep(steps[currentStepIndex - 1])
  }
  const handleNext = async () => {
    if (step === 'Options') {
      navigate(pathTo('quotePreview', 'all', quote.id))
    } else {
      if (step === 'Details') {
        setInitiatedGeneration(true)
        await generate()
      }
      const currentStepIndex = steps.findIndex((name) => name === step)
      setStep(steps[currentStepIndex + 1])
    }
  }

  return (
    <Flex stack gap={32} className={styles.container}>
      <Helmet>
        <title>{quote.name}</title>
      </Helmet>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={styles.header}
      >
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Steps
          steps={steps}
          step={step}
          onlyPassedClickable
          onClick={(nextStep) => setStep(nextStep)}
        />
        <Button variant="tertiary" onClick={() => navigate(pathTo('quotes'))}>
          Save &amp; Exit
        </Button>
      </Flex>

      <div className={styles.content}>
        {step === 'Type' && (
          <LoanDetails
            loanType={loanType}
            setLoanType={setLoanType}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            selectedLenders={selectedLenders}
            setSelectedLenders={setSelectedLenders}
          />
        )}
        {step === 'Borrower' && <Borrowers quote={quote} />}
        {step === 'Details' && <Property quote={quote} />}
        {step === 'Options' && (
          <LoanOptions
            quote={quote}
            generatingInProgress={initiatedGeneration}
            onGoToStep={setStep}
            onBack={handleBack}
          />
        )}
      </div>

      <Flex justifyContent="space-between" className={styles.footer}>
        <Button
          onClick={() => handleBack()}
          variant="tertiary"
          disabled={step === 'Type'}
        >
          Back
        </Button>

        <Button
          loading={isGenerating}
          disabled={isGenerating || isNextDisabled(step, quote, options)}
          onClick={() => handleNext()}
        >
          {['Type', 'Borrower'].includes(step) && 'Next'}
          {step === 'Details' && 'See Options'}
          {step === 'Options' && 'Preview & Send'}
        </Button>
      </Flex>
    </Flex>
  )
}
