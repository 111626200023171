import clsx from 'clsx'
import { compact } from 'lodash'
import { useCallback, useState } from 'react'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IActionConfig, IActionValue } from 'types'
import { Menu } from './Menu'
import { Rule } from './Rule'

interface Props {
  actionConfigs: IActionConfig[]
  rules: IActionValue[]
  onChange: (value: IActionValue[]) => void
}

export function Actions({ actionConfigs, rules, onChange }: Props) {
  const [open, setOpen] = useState(false)
  const handleChange = useCallback(
    (index: number, nextValue: IActionValue) => {
      rules[index] = nextValue
      onChange(compact([...rules]))
      setOpen(false)
    },
    [rules, onChange]
  )

  return (
    <Flex stack gap={8}>
      <div className="text-grey-700">Do the following</div>
      {rules.length > 0 && (
        <Flex stack gap={0}>
          {rules.map((val, index) => (
            <Rule
              key={index}
              configs={actionConfigs}
              value={val}
              className={clsx(
                index !== rules.length - 1 && 'rounded-b-none',
                index !== 0 && 'rounded-t-none border-t-0'
              )}
              onChange={(value) => handleChange(index, value as IActionValue)}
            />
          ))}
        </Flex>
      )}

      <Dropdown
        open={open}
        onOpenChange={setOpen}
        triggerFixedWidth
        trigger={
          rules.length > 0 ? (
            <Flex
              alignItems="center"
              gap={8}
              className="font-bold cursor-pointer pl-3"
            >
              <Icon name={IconName.addCircle} />
              Add additional action
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              gap={8}
              className="min-h-16 border border-solid border-grey-200 rounded font-bold cursor-pointer"
            >
              <Icon name={IconName.addCircle} />
              Add action
            </Flex>
          )
        }
      >
        <Menu
          menuItems={actionConfigs}
          value={undefined}
          onSelect={(value) =>
            handleChange(rules.length, value as IActionValue)
          }
        />
      </Dropdown>
    </Flex>
  )
}
