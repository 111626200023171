import {
  autoUpdate,
  FloatingPortal,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { AssociatedLabels } from 'admin/components/AssociatedLabels'
import { useLoanVendors } from 'admin/hooks/use-loan-vendors'
import { usePersons } from 'admin/hooks/use-persons'
import { useUsers } from 'admin/hooks/use-users'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { PageLoader } from 'components/LoaderOverlay'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { Loan, Person, LoanAdmin } from 'types'

interface Props {
  className?: string
  size?: 'medium' | 'large'
  person: Person | LoanAdmin | undefined
  loan: Loan | undefined
  onSelect: (person: Person | LoanAdmin) => void
}

function PersonSelect({
  person,
  loan,
  className,
  size = 'medium',
  onSelect,
}: Props) {
  const { user } = useSession()
  const ability = useAbilitiesContext()
  const [search, setSearch] = useState('')
  const [searchRequest, setSearchRequest] = useState('')
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [open, setOpen] = useState(false)

  const showInvestors = ability.can('read', 'investors')
  const showBorrowers = ability.can('read', 'borrowers')
  const showVendors = ability.can('read', 'vendors')

  const personTypeFilter = {
    ...(!showVendors ? { isVendor: [['not', true]] } : {}),
    ...(!showInvestors ? { isInvestor: [['not', true]] } : {}),
    ...(!showBorrowers ? { isBorrower: [['not', true]] } : {}),
  }

  const { data: personResult, isLoading: isLoadingOtherContacts } = usePersons({
    search: searchRequest,
    filter: personTypeFilter,
  })
  const { data: owners } = useUsers({
    user,
    clientId: user?.client?.id,
  })
  const { data: loanVendors, isLoading: isLoadingLoanContacts } =
    useLoanVendors(
      {
        loanId: loan?.id as string,
        search: searchRequest,
        details: true,
        filter: personTypeFilter,
      },
      { enabled: !!loan?.id }
    )

  const persons = useMemo(() => personResult?.people || [], [personResult])

  const loanAdmins = useMemo(
    () =>
      (loan?.owners || [])
        .map((admin) => owners?.find((o) => o.id === admin.id))
        .filter(Boolean) as unknown as (LoanAdmin & {
        associatedWith: undefined
        email: string
      })[],
    [loan, owners]
  )

  const loanContacts = useMemo(
    () =>
      [
        ...[
          ...((showBorrowers && loan?.borrowers) || []),
          ...((showBorrowers && loan?.guarantors) || []).map((guarantor) => ({
            ...guarantor,
            isBorrower: false,
            isGuarantor: true,
          })),
          ...((showVendors && loanVendors?.vendors) || []),
        ].filter(
          (person) =>
            person.name.toLowerCase().includes(search.toLowerCase()) &&
            person.type === 'individual' &&
            !!person.email
        ),
        ...loanAdmins
          .filter(
            (owner) =>
              !searchRequest ||
              owner.name.toLowerCase().includes(searchRequest.toLowerCase())
          )
          .map((guarantor) => ({
            ...guarantor,
            isBorrower: false,
            isGuarantor: false,
            isLoanAdmin: true,
          })),
      ].sort((a, b) =>
        (a.name || '').localeCompare(b.name || '', undefined, {
          sensitivity: 'base',
        })
      ),
    [loan, loanVendors, search]
  )

  const loanPersons = useMemo(
    () =>
      [
        ...(
          (owners || []) as (LoanAdmin & {
            email: string
            associatedWith?: any[]
          })[]
        ).filter(
          (owner) =>
            loanAdmins.every((admin) => admin.id !== owner.id) &&
            (!searchRequest ||
              owner.name.toLowerCase().includes(searchRequest.toLowerCase()))
        ),
        ...(persons?.filter(
          (person) => person.type === 'individual' && !!person.email
        ) || []),
      ].sort((a, b) =>
        (a.name || '').localeCompare(b.name || '', undefined, {
          sensitivity: 'base',
        })
      ),
    [persons, owners, searchRequest]
  )

  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift()],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [useRole(context, { role: 'menu' }), useDismiss(context)]
  )

  const handleSelect = useCallback(
    (person: Person | LoanAdmin) => {
      onSelect(person)
      setIsSearchMode(false)
      setOpen(false)
      setSearch('')
    },
    [onSelect]
  )

  const handleSearch = useCallback(
    debounce((search: string) => {
      setSearchRequest(search)
    }, 500),
    []
  )

  useEffect(() => {
    if (!open) {
      setIsSearchMode(false)
      setSearch('')
    }
  }, [open])

  useEffect(() => {
    handleSearch(search)
  }, [search])

  return (
    <div className={clsx('inline-block', className)}>
      <Flex
        className={clsx(
          'rounded py-1 cursor-pointer',
          size === 'large' && 'pl-3 pr-1.5 py-2'
        )}
        justifyContent="space-between"
        alignItems="center"
        gap={6}
        {...getReferenceProps({ ref: refs.setReference })}
      >
        <Input
          placeholder="Find or search for a signer"
          value={isSearchMode ? search : person?.name}
          onChange={(e) => {
            setIsSearchMode(true)
            setSearch(e.target.value)
          }}
          onFocus={() => setOpen(true)}
          className="w-full"
        />
      </Flex>
      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: refs.reference.current?.getBoundingClientRect().width,
              },
            })}
            className="z-2 border-solid border p-1 bg-white-100 rounded border-grey-100 cursor-pointer shadow-300 overflow-y-auto max-h-[300px]"
          >
            {isLoadingLoanContacts || isLoadingOtherContacts ? (
              <PageLoader />
            ) : (
              <>
                {!loanPersons?.length ? (
                  <Flex
                    stack
                    alignItems="center"
                    justifyContent="center"
                    className="pt-12 pb-16"
                  >
                    <Icon
                      name={IconName.magnifyingGlass}
                      className="text-grey-500 w-7 h-7"
                    />
                    <Text variant="l">No search results</Text>
                  </Flex>
                ) : (
                  <div>
                    {!!loanContacts.length && (
                      <div className="text-grey-700 px-2 pt-3 pb-2 font-bold text-sm">
                        Contacts in this loan
                      </div>
                    )}
                    {loanContacts.map((person) => {
                      return (
                        <Fragment key={person.id}>
                          <Flex
                            justifyContent="left"
                            alignItems="center"
                            gap={8}
                            className="p-2 rounded hover:bg-grey-75"
                            {...getItemProps({
                              onClick: () => handleSelect(person),
                            })}
                          >
                            <Avatar id={person.id} name={person.name} />
                            <Flex stack gap={4} className="flex-grow">
                              <div className="whitespace-nowrap">
                                <AssociatedLabels
                                  name={person.name}
                                  associatedWith={person.associatedWith}
                                />
                              </div>
                              {person.email && (
                                <div className="whitespace-nowrap text-grey-700">
                                  {person.email}
                                </div>
                              )}
                            </Flex>
                            {person.isBorrower && (
                              <Badge color="green">
                                {(person as any).borrow?.primary
                                  ? 'Primary Borrower'
                                  : 'Borrower'}
                              </Badge>
                            )}
                            {(person as any).isGuarantor && (
                              <Badge color="green">
                                {(person as any).guarantee?.primary
                                  ? 'Primary Guarantor'
                                  : 'Guarantor'}
                              </Badge>
                            )}
                            {(person as any).isLoanAdmin && (
                              <Badge color="green">Loan Admin</Badge>
                            )}
                          </Flex>
                        </Fragment>
                      )
                    })}
                    <div
                      className={clsx(
                        'text-grey-700 mx-2 pt-3 pb-2 font-bold text-sm border-solid border-grey-200 border-0',
                        !!loanContacts.length && 'mt-3 border-t'
                      )}
                    >
                      Other contacts
                    </div>
                    {loanPersons?.map((person) => {
                      return (
                        <Fragment key={person.id}>
                          <Flex
                            justifyContent="left"
                            alignItems="center"
                            gap={8}
                            className="p-2 rounded hover:bg-grey-75"
                            {...getItemProps({
                              onClick: () => handleSelect(person),
                            })}
                          >
                            <Avatar id={person.id} name={person.name} />
                            <Flex stack gap={4}>
                              <div className="whitespace-nowrap">
                                <AssociatedLabels
                                  name={person.name}
                                  associatedWith={person.associatedWith}
                                />
                              </div>
                              {person.email && (
                                <div className="whitespace-nowrap text-grey-700">
                                  {person.email}
                                </div>
                              )}
                            </Flex>
                          </Flex>
                        </Fragment>
                      )
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export { PersonSelect }
