import { orderBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ApplicationsTiles } from 'borrower/components/BorrowerDashboard/ApplicationsTiles'
import { NoEntities } from 'borrower/components/BorrowerDashboard/NoEntities'
import { PageTop } from 'borrower/components/PageTop'
import { MainContent } from 'borrower/components/layout/MainContent'
import {
  useApplications,
  useDraftApplications,
} from 'borrower/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

function Applications() {
  const navigate = useNavigate()
  const { user, isManager } = useSession()
  const isPersonalAccount =
    user?.actor?.id === user?.person?.id && user?.person?.isAccount
  const isEditable = isManager || isPersonalAccount
  const {
    data: submittedApplications,
    isLoading: isLoadingSubmittedApplications,
  } = useApplications()
  const { data: draftApplications, isLoading: isLoadingDraftApplications } =
    useDraftApplications()
  const handleAddApplication = () => {
    navigate(pathTo('applicationDraft', 'new'))
  }

  const isLoading = isLoadingSubmittedApplications || isLoadingDraftApplications
  const applications = orderBy(
    [
      ...(submittedApplications?.map((application) => ({
        ...application,
        isDraft: false,
      })) || []),
      ...(draftApplications?.map((application) => ({
        ...application,
        isDraft: true,
      })) || []),
    ],
    ['createdAt', 'name'],
    ['desc', 'asc']
  )
  const isEmpty = applications.length === 0

  return (
    <MainContent>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex flexDirection="column" gap={48} className={styles.container}>
          <PageTop title={'Applications'} hideTopMenu>
            {!isEmpty && isEditable && (
              <Button
                variant="primary"
                size="large"
                className="rounded-full"
                data-testid="add-application-button"
                onClick={handleAddApplication}
              >
                Add Application
              </Button>
            )}
          </PageTop>

          {isEmpty ? (
            <NoEntities
              data-testid="applications-empty-state"
              iconName={IconName.documentPlus}
              title="You don’t have any active applications"
              subtitle={
                isEditable &&
                'Click the button below to start a new loan application.'
              }
              action={
                isEditable && (
                  <Button
                    variant="primary"
                    size="large"
                    onClick={handleAddApplication}
                    className="w-min rounded-full"
                    data-testid="add-application-empty-state"
                  >
                    Add Application
                  </Button>
                )
              }
            />
          ) : (
            <ApplicationsTiles
              applications={applications}
              isEditable={isEditable}
              data-testid="applications-list"
            />
          )}
        </Flex>
      )}
    </MainContent>
  )
}

export { Applications }
