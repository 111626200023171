import { useCallback, useMemo, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useDockContext } from 'admin/components/Dock/DockContext'
import { DockThreadTab } from 'admin/components/DockThreadTab/DockThreadTab'
import { useMailSignatures } from 'admin/hooks/use-thread-signature'
import { useAddThread } from 'admin/hooks/use-threads'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { BannerConnectMail } from 'admin/pages/Threads/BannerConnectMail'
import { ThreadsMainCheckbox } from 'admin/pages/Threads/ThreadsMainCheckbox'
import { ThreadsTab } from 'admin/pages/Threads/ThreadsTab'
import { pathToLoan } from 'admin/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Search } from 'components/Search'
import { IThreadCheckboxes, IThreadListItem, IThreadTab } from 'types'

const tabs: { tab: '' | IThreadTab; name: string }[] = [
  // {
  //   tab: '',
  //   name: 'All Mail',
  // },
  {
    tab: 'inbox',
    name: 'All Mail',
  },
  {
    tab: 'drafts',
    name: 'Drafts',
  },
  {
    tab: 'sent',
    name: 'Sent',
  },
]

function TabMail() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { loan } = useLoanContext()
  const { addTab } = useDockContext()
  const { data: signatures, isLoading: isLoadingSignatures } =
    useMailSignatures()
  const { mutate: addThread, isPending: isAddingThread } = useAddThread()
  const selectedTab = (searchParams.get('tab') as IThreadTab) || 'inbox'
  const [checkboxes, setCheckboxes] = useState<IThreadCheckboxes>({
    mode: null,
    includeIds: [],
    excludeIds: [],
  })
  const [search, setSearch] = useState<string>()
  const signature = useMemo(
    () => signatures?.find(({ isDefault }) => isDefault),
    [signatures]
  )
  const handleRowClick = useCallback(
    (row: IThreadListItem | undefined) => {
      addTab({
        id: row?.id as string,
        title: row?.subject as string,
        content: <DockThreadTab threadId={row?.id as string} />,
        expandByDefault: true,
        onMaximizeClick: () =>
          navigate(
            pathToLoan(loan, 'loanTabEntityId', 'mail', row?.id as string)
          ),
      })
    },
    [addTab, loan, navigate]
  )
  const handleComposeClick = useCallback(() => {
    if (!isLoadingSignatures) {
      addThread(
        {
          loanId: loan.id,
          body: signature ? `<p></p><p></p><p>--</p>${signature.body}` : '',
        },
        {
          onSuccess: (thread) => {
            addTab({
              id: thread.id as string,
              title: thread.mails[0]?.subject as string,
              content: <DockThreadTab threadId={thread.id as string} />,
              expandByDefault: true,
              onMaximizeClick: () =>
                navigate(
                  pathToLoan(
                    loan,
                    'loanTabEntityId',
                    'mail',
                    thread.id as string
                  )
                ),
            })
          },
        }
      )
    }
  }, [loan.id, addThread, signatures, isLoadingSignatures])
  const handleTabSelect = useCallback(
    (tab) =>
      navigate(
        {
          pathname: pathToLoan(loan, 'loanTab', 'mail'),
          search: createSearchParams({ tab }).toString(),
        },
        { replace: true }
      ),
    [navigate]
  )

  return (
    <Flex stack gap={0} className="-mt-2">
      <BannerConnectMail />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="w-full"
      >
        <Flex alignItems="center" className="h-12">
          <ThreadsMainCheckbox
            checkboxes={checkboxes}
            onChange={() =>
              setCheckboxes(({ mode }) => ({
                mode: mode ? null : 'all',
                includeIds: [],
                excludeIds: [],
              }))
            }
            onSelect={(mode) =>
              setCheckboxes({
                mode,
                includeIds: [],
                excludeIds: [],
              })
            }
          />
          <EllipsesActions
            placement="bottom-start"
            trigger={
              <Flex gap={8} alignItems="center" className="cursor-pointer">
                <Header className="text-2xl ">
                  {tabs.find(({ tab }) => tab === selectedTab)?.name}
                </Header>
                <Icon
                  name={IconName.arrowDownFilled}
                  className="text-grey-600"
                />
              </Flex>
            }
          >
            {tabs.map(({ tab, name }) => (
              <EllipsesActions.Item
                key={tab}
                onSelect={() => handleTabSelect(tab)}
              >
                {name}
              </EllipsesActions.Item>
            ))}
          </EllipsesActions>
        </Flex>
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          className="bg-white-100 relative py-1 px-3 -my-1 -mx-3"
          gap={10}
        >
          <Search search={search} onSearch={setSearch} placeholder="Search" />
          <Button
            variant="primary"
            loading={isAddingThread}
            onClick={handleComposeClick}
          >
            <Icon name={IconName.draft} className="mr-1" />
            Compose
          </Button>
        </Flex>
      </Flex>
      <ThreadsTab
        loanId={loan?.id}
        tab={selectedTab}
        search={search}
        onRowClick={handleRowClick}
        checkboxes={checkboxes}
        onCheckboxesChange={setCheckboxes}
      />
    </Flex>
  )
}

export { TabMail }
