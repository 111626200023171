import clsx from 'clsx'
import { ForwardedRef, forwardRef } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import {
  ITriggerConfig,
  ITriggerValue,
  IConditionConfig,
  IConditionValue,
  IActionConfig,
  IActionValue,
} from 'types'
import { MenuItem } from './MenuItem'

interface Props {
  itemConfig: ITriggerConfig | IConditionConfig | IActionConfig
  value?: ITriggerValue | IConditionValue | IActionValue
  className?: string
  onSelect?: () => void
  onDelete?: () => void
}

const MenuTrigger = forwardRef(
  (
    { itemConfig, value: item, onSelect, onDelete, className, ...props }: Props,
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    return (
      <Flex
        ref={ref}
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        gap={0}
        className={clsx(
          'min-h-16 max-h-52 overflow-auto border border-solid border-grey-200 rounded cursor-pointer',
          className
        )}
        {...props}
      >
        {item && (
          <Flex
            key={itemConfig?.id}
            alignItems={(item as IActionValue).error ? 'flex-start' : 'center'}
            justifyContent="space-between"
            className="p-4 w-full flex-grow"
            onClick={() => onSelect?.()}
          >
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              gap={10}
              className="overflow-hidden"
            >
              <Flex stack gap={10} className="overflow-hidden">
                <MenuItem itemConfig={itemConfig} value={item} />
                {(item as IActionValue).error && (
                  <div>{(item as IActionValue).error}</div>
                )}
              </Flex>
            </Flex>
            <Flex>
              {(!!onSelect || !!onDelete) && (
                <EllipsesActions>
                  {!!onSelect && (
                    <EllipsesActions.Item icon onSelect={() => onSelect()}>
                      <Icon name={IconName.edit} />
                      Edit
                    </EllipsesActions.Item>
                  )}
                  {!!onDelete && (
                    <EllipsesActions.Item
                      icon
                      onSelect={() => onDelete()}
                      className="text-red-100"
                    >
                      <Icon name={IconName.delete} />
                      Delete
                    </EllipsesActions.Item>
                  )}
                </EllipsesActions>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    )
  }
)

MenuTrigger.displayName = 'ActionsButton'

export { MenuTrigger }
