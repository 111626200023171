import { request } from 'services/request'
import {
  ReportNumberType,
  ReportChartData,
  ReportChartType,
  ReportInsuranceType,
  ReportInsuranceData,
  ReportTrustBalanceType,
  ReportTrustBalanceData,
  ReportTax1098,
  ReportTax1099,
  ReportTax1098Type,
  ReportTax1099Type,
  Filter,
  Pagination,
  Sort,
  ReportTrustTransactionsData,
  ReportDrawRequestsType,
  ReportDrawRequestsData,
  ReportLoanToBorrowerKpiType,
  ReportLoanToBorrowerKpiData,
  ReportCashBalancesType,
  ReportCashBalancesData,
  ReportUnpaidChargesType,
  ReportUnpaidChargesData,
  ReportPaidChargesType,
  ReportPaidChargesData,
  ReportUnpaidInterestType,
  ReportUnpaidInterestData,
} from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

interface TableProps {
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
}

function getReport(data: { type: ReportNumberType }): Promise<number>
function getReport(data: {
  type: ReportCashBalancesType
  data?: { date?: string }
}): Promise<ReportCashBalancesData>
function getReport(data: { type: ReportChartType }): Promise<ReportChartData>
function getReport(data: {
  type: ReportInsuranceType
  params?: TableProps
}): Promise<ReportInsuranceData>
function getReport(data: {
  type: ReportTrustBalanceType
  params?: TableProps
}): Promise<ReportTrustBalanceData>
function getReport(data: {
  type: ReportTax1098Type
  params?: TableProps
  data?: { year?: string }
}): Promise<ReportTax1098>
function getReport(data: {
  type: ReportTax1099Type
  params?: TableProps
  data?: { year?: string }
}): Promise<ReportTax1099>
function getReport(data: {
  type: ReportDrawRequestsType
  params?: TableProps
}): Promise<ReportDrawRequestsData>
function getReport(data: {
  type: ReportLoanToBorrowerKpiType
  data?: { loanId: string }
  params?: TableProps
}): Promise<ReportLoanToBorrowerKpiData>
function getReport(data: {
  type: ReportUnpaidChargesType
  params?: TableProps
}): Promise<ReportUnpaidChargesData>
function getReport(data: {
  type: ReportPaidChargesType
  params?: TableProps
}): Promise<ReportPaidChargesData>
function getReport(data: {
  type: ReportUnpaidInterestType
  params?: TableProps
}): Promise<ReportUnpaidInterestData>

async function getReport({
  type,
  data,
  params,
}: {
  type:
    | ReportNumberType
    | ReportChartType
    | ReportInsuranceType
    | ReportTrustBalanceType
    | ReportTax1098Type
    | ReportTax1099Type
    | ReportDrawRequestsType
    | ReportLoanToBorrowerKpiType
    | ReportUnpaidChargesType
    | ReportUnpaidInterestType
  data?: { loanId?: string; date?: string; year?: string }
  params?: TableProps
}): Promise<
  | number
  | ReportCashBalancesData
  | ReportChartData
  | ReportInsuranceData
  | ReportTrustBalanceData
  | ReportTax1098
  | ReportTax1099
  | ReportDrawRequestsData
  | ReportLoanToBorrowerKpiData
  | ReportUnpaidChargesData
  | ReportUnpaidInterestData
> {
  const {
    data: { report },
  } = await request.post(`/report/${type}`, data, {
    params: updateRequestTable(params as Record<string, unknown>),
  })
  if (type === 'loan-insurance') {
    return {
      insurances: report.insurances,
      meta: report.pagination,
    }
  }
  if (type === 'loan-trust-balances') {
    return {
      loans: report.loans,
      trusts: report.trusts,
      meta: report.pagination,
    }
  }

  if (type === '1098-tax') {
    return { loans: report.loans, meta: report.pagination }
  }
  if (type === '1099-tax') {
    return { investors: report.investors, meta: report.pagination }
  }
  if (type === 'draw-requests') {
    return { draws: report.draws, meta: report.pagination }
  }
  if (type === 'unpaid-charges') {
    return { charges: report.charges, meta: report.pagination }
  }
  if (type === 'paid-charges') {
    return { charges: report.charges, meta: report.pagination }
  }
  if (type === 'unpaid-interest') {
    return { unpaidInterests: report.unpaidInterests, meta: report.pagination }
  }
  return report
}

const getReportTrustTransactions = async (
  data: {
    loanId?: string | null
    trustId?: string
  },
  params?: TableProps
): Promise<ReportTrustTransactionsData> => {
  const {
    data: { report },
  } = await request.post('/report/loan-trust-transactions', data, {
    params: updateRequestTable(params as Record<string, unknown>),
  })

  return {
    transactions: report.transactions,
    trusts: report.trusts,
    trust: report.trust,
    meta: report.pagination,
  }
}

const getLoanTapeDownloadUrl = async (): Promise<{ url: string }> => {
  const {
    data: {
      report: { downloadUrl: url },
    },
  } = await request.post('/report/loan-tape')
  return { url }
}

export { getReport, getReportTrustTransactions, getLoanTapeDownloadUrl }
