import { ColumnDef } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities'
import { pathToLoan, canLinkToLoan } from 'admin/path-to'
import { LoanStatusBadge } from 'components/Badge'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

type Props = {
  data?: Loan[]
}

function TableLoans({ data = [] }: Props) {
  const ability = useAbilitiesContext()
  const navigate = useNavigate()
  const columns: ColumnDef<Loan>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row, getValue }) =>
          canLinkToLoan(ability, row.original) ? (
            <TextLink onClick={() => navigate(pathToLoan(row.original))}>
              {getValue() as string}
            </TextLink>
          ) : (
            getValue()
          ),
      },
      {
        header: 'Principal Balance',
        accessorKey: 'amount',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
      },
      {
        header: 'Closing Date',
        accessorKey: 'dateClosing',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 120,
        cell: ({ getValue }) => (
          <LoanStatusBadge status={getValue() as Loan['status']} />
        ),
      },
    ],
    []
  )

  return <Table columns={columns} data={data} />
}

export default TableLoans
