import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useApplicationScheme } from 'admin/hooks/use-application'
import { DraftApplication } from 'borrower/components/DraftApplication'
import { MainContent } from 'borrower/components/layout/MainContent'
import { pathTo } from 'borrower/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import {
  useAddDraftApplication,
  useDraftApplication,
} from 'hooks/use-application'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { IApplicationScheme } from 'types'

function ApplicationDraft() {
  const navigate = useNavigate()
  const { id } = useParams() as { id: string }
  const { user } = useSession()
  const clientId = (user as CurrentUser)?.client?.id
  const { data: defaultScheme } = useApplicationScheme(
    { clientId },
    { enabled: id === 'new' }
  )
  const { data: applicationScheme } = useDraftApplication(id, {
    enabled: id !== 'new',
  })
  const { mutate: create } = useAddDraftApplication(clientId)
  const scheme = (applicationScheme as any)?.data as IApplicationScheme

  useEffect(() => {
    if (id === 'new' && defaultScheme) {
      create(
        {
          ...(defaultScheme as IApplicationScheme),
          submitted: false,
        },
        {
          onSuccess: ({ id }) => {
            navigate(pathTo('applicationDraft', id), {
              replace: true,
            })
          },
        }
      )
    }
  }, [id, defaultScheme])

  return (
    <MainContent className="p-0 pt-16">
      {!scheme && <PageLoader />}
      {scheme && (
        <DraftApplication
          applicationId={id}
          clientId={clientId}
          initialApplicationScheme={scheme}
        />
      )}
    </MainContent>
  )
}

export { ApplicationDraft }
