import { ReactNode, useState } from 'react'
import { Modal } from 'components/Modal'
import { LoanDocument } from 'types'
import { Section } from 'utils/loan-document-sections'
import { BrowseDocuments } from './BrowseDocuments'

interface Props {
  attachmentId: string
  mailId: string
  onClose: () => void
}

interface Item {
  id: string
  name: string
  type: 'loan' | 'borrower' | 'investor'
}

interface ItemWithDocument {
  item: Item
  section: Section
  document: LoanDocument
}

const ModalSaveDocs = ({ attachmentId, mailId, onClose }: Props) => {
  const [title, setTitle] = useState<ReactNode>('Save to...')

  return (
    <Modal onClose={onClose} title={title} className="w-[564px]">
      <BrowseDocuments
        attachmentId={attachmentId}
        mailId={mailId}
        setTitle={setTitle}
        onClose={onClose}
      />
    </Modal>
  )
}

export type { Item, ItemWithDocument }
export { ModalSaveDocs }
