import clsx from 'clsx'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { InputCurrency } from 'components/InputCurrency'
import { InputNumber } from 'components/InputNumber'
import { InputPercentage } from 'components/InputPercentage'
import { Select } from 'components/Select'
import { Text } from 'components/Text'
import {
  IActionConfig,
  ITriggerConfig,
  IConditionConfig,
  ITriggerValue,
  IConditionValue,
  IActionValue,
} from 'types'
import { MenuHeader } from './MenuHeader'
import { MenuOptionItems } from './MenuOptionItems'

interface Props {
  menuItems: ITriggerConfig[] | IConditionConfig[] | IActionConfig[]
  header?: ReactNode
  type?: 'condition'
  value?: ITriggerValue | IConditionValue | IActionValue
  onSelect: (
    item: ITriggerValue | IConditionValue | IActionValue | undefined
  ) => void
}

const Menu = ({ menuItems, header, type, value, onSelect }: Props) => {
  const [search, setSearch] = useState('')
  const [innerValue, setInnerValue] = useState<
    ITriggerValue | IConditionValue | IActionValue | undefined
  >(value)

  const visibleMenuItems = useMemo(
    () =>
      menuItems.filter((menuItem) =>
        menuItem.name.toLowerCase().includes(search.toLowerCase())
      ),
    [menuItems, search]
  )
  const selectedMenuItem = useMemo(
    () =>
      menuItems.find((actionConfig) => {
        if (
          actionConfig.children?.some((child) => child.id === innerValue?.id)
        ) {
          return actionConfig.children.some(
            (child) => child.id === innerValue?.id
          )
        }
        return actionConfig.id === innerValue?.id
      }),
    [menuItems, innerValue]
  )

  const handleFirstLevelClick = useCallback(
    (menuItem: ITriggerConfig | IConditionConfig | IActionConfig) => {
      setInnerValue({ id: menuItem.id, config: menuItem })
      if (menuItem.type === 'single') {
        onSelect({ id: menuItem.id, config: menuItem })
      }
    },
    [onSelect]
  )
  const handleBack = useCallback(() => {
    setInnerValue(undefined)
  }, [])
  const handleDone = useCallback(() => {
    onSelect(innerValue)
  }, [onSelect, innerValue])

  const isFirstLevelVisible =
    !selectedMenuItem || selectedMenuItem?.type === 'single'

  return (
    <>
      {isFirstLevelVisible && (
        <>
          {header ? (
            <div className="pb-3 border-0 border-b border-solid border-grey-200">
              {header}
            </div>
          ) : (
            <div className="p-3 border-0 border-b border-solid border-grey-200">
              {type === 'condition' ? (
                <div className="font-bold">And</div>
              ) : (
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                />
              )}
            </div>
          )}
          <div className="p-1 max-h-[200px] overflow-y-auto">
            {visibleMenuItems.map((item, index) => (
              <div key={item.id}>
                {item.group !== visibleMenuItems[index - 1]?.group && (
                  <div
                    className={clsx(
                      'p-2 text-sm text-grey-600',
                      index !== 0 &&
                        'border-0 border-t border-solid border-grey-200 mt-2'
                    )}
                  >
                    {item.group}
                  </div>
                )}
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  className="p-2 rounded cursor-pointer group hover:bg-grey-75"
                  onClick={() => handleFirstLevelClick(item)}
                >
                  {item.name}
                  {item.type !== 'single' && (
                    <Icon
                      name={IconName.arrowRight}
                      className="text-grey-600 flex-shrink-0 invisible group-hover:visible"
                    />
                  )}
                </Flex>
              </div>
            ))}
            {!visibleMenuItems.length && (
              <Flex
                stack
                alignItems="center"
                justifyContent="center"
                className="pt-12 pb-16"
              >
                <Icon
                  name={IconName.magnifyingGlass}
                  className="text-grey-500 w-7 h-7"
                />
                <Text variant="l">No search results</Text>
              </Flex>
            )}
          </div>
        </>
      )}
      {selectedMenuItem?.type === 'children' && (
        <Menu
          header={
            <MenuHeader
              sentence={selectedMenuItem.sentence}
              onBack={handleBack}
            />
          }
          menuItems={selectedMenuItem.children!}
          type={type}
          value={innerValue}
          onSelect={onSelect}
        />
      )}
      {selectedMenuItem?.type === 'text' && (
        <>
          <MenuHeader
            sentence={selectedMenuItem.sentence}
            disabled={
              !(innerValue as IConditionValue)?.condition || !innerValue?.value
            }
            onBack={handleBack}
            onDone={handleDone}
          />
          <Flex alignItems="center" gap={10} className="p-3">
            <Select
              className="basis-1/2"
              options={[{ value: 'eq', label: 'Is' }]}
              value={(innerValue as IConditionValue)?.condition}
              onChange={(option) =>
                setInnerValue((currInnerValue) => ({
                  ...(currInnerValue as IConditionValue),
                  condition: option.value as IConditionValue['condition'],
                }))
              }
            />
            <Input
              className="basis-1/2"
              value={innerValue?.value as string}
              onChange={(e) => {
                const value = e.target.value
                setInnerValue((currInnerValue) => ({
                  ...(currInnerValue as IConditionValue),
                  value,
                }))
              }}
            />
          </Flex>
        </>
      )}
      {selectedMenuItem &&
        ['currency', 'percentage', 'number'].includes(
          selectedMenuItem.type
        ) && (
          <>
            <MenuHeader
              sentence={selectedMenuItem.sentence}
              disabled={
                !(innerValue as IConditionValue)?.condition ||
                !innerValue?.value
              }
              onBack={handleBack}
              onDone={handleDone}
            />
            <Flex alignItems="center" gap={10} className="p-3">
              <Select
                className="basis-1/2"
                options={[
                  { value: 'eq', label: 'Is' },
                  { value: 'gt', label: 'Is greater than' },
                  { value: 'gte', label: 'Is greater than or equal to' },
                  { value: 'lt', label: 'Is less than' },
                  { value: 'lte', label: 'Is less than or equal to' },
                ]}
                value={(innerValue as IConditionValue)?.condition}
                onChange={(option) =>
                  setInnerValue((currInnerValue) => ({
                    ...(currInnerValue as IConditionValue),
                    condition: option.value as IConditionValue['condition'],
                  }))
                }
              />
              {selectedMenuItem.type === 'currency' && (
                <InputCurrency
                  className="basis-1/2"
                  value={innerValue?.value as string}
                  onChange={(e) => {
                    const value = e.target.value
                    setInnerValue((currInnerValue) => ({
                      ...(currInnerValue as IConditionValue),
                      value,
                    }))
                  }}
                />
              )}
              {selectedMenuItem.type === 'percentage' && (
                <InputPercentage
                  className="basis-1/2"
                  value={innerValue?.value as string}
                  onChange={(e) => {
                    const value = e.target.value
                    setInnerValue((currInnerValue) => ({
                      ...(currInnerValue as IConditionValue),
                      value,
                    }))
                  }}
                />
              )}
              {selectedMenuItem.type === 'number' && (
                <InputNumber
                  className="basis-1/2"
                  value={innerValue?.value as string}
                  onChange={(e) => {
                    const value = e.target.value
                    setInnerValue((currInnerValue) => ({
                      ...(currInnerValue as IConditionValue),
                      value,
                    }))
                  }}
                />
              )}
            </Flex>
          </>
        )}
      {selectedMenuItem?.type === 'options' && (
        <MenuOptionItems
          selectedMenuItem={selectedMenuItem}
          innerValue={innerValue}
          setInnerValue={setInnerValue}
          onBack={handleBack}
          onDone={handleDone}
        />
      )}
    </>
  )
}

export { Menu }
