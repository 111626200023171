import {
  compact,
  isArray,
  isNumber,
  isString,
  toString,
  omit,
  capitalize,
  uniq,
  isUndefined,
} from 'lodash'
import {
  PERSON_FIELD_CONTEXT_ENTITY,
  PERSON_FIELD_CONTEXT_INDIVIDUAL,
} from 'admin/pages/Settings/CustomFields/constants'
import { LinkEmail } from 'components/LinkEmail'
import {
  Address,
  IApplicationScheme,
  IApplicationSchemeField,
  IApplicationSchemePage,
  BaseField,
  FieldType,
  PersonField,
  IApplicationFullCondition,
  IApplicationCondition,
  IApplicationSchemeFieldType,
  IAutomationItemConfig,
} from 'types'
import { formatAddress } from 'utils/address'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatNumber } from 'utils/number'
import { formatPercent } from 'utils/percent'
import { formatPhone } from 'utils/phone'

function parseScheme(scheme: IApplicationScheme): IApplicationScheme {
  return {
    ...scheme,
    pages: scheme.pages.map((page) => ({
      ...page,
      fields: page.fields.map((field) => {
        if (field.individualFields && field.entityFields) {
          return {
            ...field,
            individualFields: field.individualFields.map((individualField) => ({
              ...individualField,
              fullId:
                individualField.fullId ||
                `${field.type}-individual-${individualField.id}`,
            })),
            entityFields: field.entityFields.map((entityField) => ({
              ...entityField,
              fullId:
                entityField.fullId || `${field.type}-entity-${entityField.id}`,
            })),
          }
        }
        return field
      }),
    })),
  }
}

function deleteFieldById(
  scheme: IApplicationScheme,
  fieldId: string
): IApplicationScheme {
  return {
    ...scheme,
    pages: scheme.pages.map((page) => ({
      ...page,
      fields: page.fields
        .map((field) =>
          field.individualFields && field.entityFields
            ? {
                ...field,
                individualFields: field.individualFields.filter(
                  ({ id }) => id !== fieldId
                ),
                entityFields: field.entityFields.filter(
                  ({ id }) => id !== fieldId
                ),
              }
            : field
        )
        .filter(({ id }) => id !== fieldId),
    })),
  }
}

function updateField(
  scheme: IApplicationScheme,
  field: IApplicationSchemeField
): IApplicationScheme {
  return {
    ...scheme,
    pages: scheme.pages.map((page) => ({
      ...page,
      fields: page.fields.map((schemeField) => {
        if (schemeField.id === field.id) {
          return { ...schemeField, ...field }
        } else if (schemeField.individualFields && schemeField.entityFields) {
          return {
            ...schemeField,
            individualFields: schemeField.individualFields.map(
              (individualField) =>
                field.fullId ===
                `${schemeField.type}-individual-${individualField.id}`
                  ? { ...individualField, ...field }
                  : individualField
            ),
            entityFields: schemeField.entityFields.map((entityField) =>
              field.fullId === `${schemeField.type}-entity-${entityField.id}`
                ? { ...entityField, ...field }
                : entityField
            ),
          }
        }
        return schemeField
      }),
    })),
  }
}

function getPageByField(
  scheme: IApplicationScheme,
  field: IApplicationSchemeField
): IApplicationSchemePage {
  return scheme.pages.filter(({ fields }) =>
    fields.some(({ id }) => id === field.id)
  )[0]
}

function formatField(field) {
  return omit(
    {
      ...field,
      ...(isNumber(field.min) ? { min: toString(field.min) } : {}),
      ...(isNumber(field.max) ? { max: toString(field.max) } : {}),
      ...(isString(field.options)
        ? {
            options: compact(field.options.split(/\r?\n/)).map((value) => ({
              value,
              label: value,
            })),
          }
        : {}),
    },
    'allowMinMax'
  )
}

function parseField(field) {
  return {
    ...field,
    ...(isArray(field.options)
      ? {
          options: field.options.map(({ value }) => value).join('\r\n'),
        }
      : {}),
  }
}

const getIndividualMapToFields = (
  prefix: 'borrower' | 'guarantor'
): BaseField[] =>
  [
    {
      id: `${prefix}-individual-name`,
      section: capitalize(prefix),
      name: 'Name',
      type: ['text'],
    },
    {
      id: `${prefix}-individual-score`,
      section: capitalize(prefix),
      name: 'Credit Score',
      type: ['number'],
    },
    {
      id: `${prefix}-individual-citizenship`,
      section: capitalize(prefix),
      name: 'Citizenship',
      type: ['option'],
      options: [
        { label: 'U.S. Citizen', value: 'U.S. Citizen' },
        { label: 'Permanent Resident', value: 'Permanent Resident' },
        { label: 'Foreign National', value: 'Foreign National' },
      ],
    },
    {
      id: `${prefix}-individual-flips`,
      section: capitalize(prefix),
      name: 'Number of Flips',
      type: ['number'],
    },
    {
      id: `${prefix}-individual-phone`,
      section: capitalize(prefix),
      name: 'Phone',
      type: ['phone'],
    },
    {
      id: `${prefix}-individual-email`,
      section: capitalize(prefix),
      name: 'Email',
      type: ['email'],
    },
    {
      id: `${prefix}-individual-primary-address`,
      section: capitalize(prefix),
      name: 'Primary Address',
      type: ['address'] as unknown as FieldType[],
    },
    {
      id: `${prefix}-individual-mailing-address`,
      section: capitalize(prefix),
      name: 'Mailing Address',
      type: ['address'] as unknown as FieldType[],
    },
    {
      id: `${prefix}-individual-ssn`,
      section: capitalize(prefix),
      name: 'Social Security Number',
      type: ['ssn-number'],
    },
    {
      id: `${prefix}-individual-date-of-birth`,
      section: capitalize(prefix),
      name: 'Date of Birth',
      type: ['date'],
    },
    {
      id: `${prefix}-individual-gender`,
      section: capitalize(prefix),
      name: 'Gender',
      type: ['option'],
      options: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
      ],
    },
    {
      id: `${prefix}-individual-marital-status`,
      section: capitalize(prefix),
      name: 'Marital Status',
      type: ['option'],
      options: [
        { label: 'Married', value: 'Married' },
        { label: 'Unmarried', value: 'Unmarried' },
      ],
    },
  ] as BaseField[]

const getEntityMapToFields = (prefix: 'borrower' | 'guarantor'): BaseField[] =>
  [
    {
      id: `${prefix}-entity-name`,
      section: capitalize(prefix),
      name: 'Company Name',
      type: ['text'],
    },
    {
      id: `${prefix}-entity-type`,
      section: capitalize(prefix),
      name: 'Type',
      type: ['option'],
      options: [
        {
          label: 'Corporation',
          value: 'Corporation',
        },
        {
          label: 'General Partnership',
          value: 'General Partnership',
        },
        {
          label: 'Limited Liability Company',
          value: 'Limited Liability Company',
        },
        {
          label: 'Limited Partnership',
          value: 'Limited Partnership',
        },
        {
          label: 'Trust',
          value: 'Trust',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    },
    {
      id: `${prefix}-entity-jurisdiction`,
      section: capitalize(prefix),
      name: 'Jurisdiction',
      type: ['option'],
      options: [
        { label: 'United States', value: 'United States' },
        { label: 'Canada', value: 'Canada' },
        { value: 'Alabama', label: 'Alabama' },
        { value: 'Alaska', label: 'Alaska' },
        { value: 'Arizona', label: 'Arizona' },
        { value: 'Arkansas', label: 'Arkansas' },
        { value: 'California', label: 'California' },
        { value: 'Colorado', label: 'Colorado' },
        { value: 'Connecticut', label: 'Connecticut' },
        { value: 'District of Columbia', label: 'District of Columbia' },
        { value: 'Delaware', label: 'Delaware' },
        { value: 'Florida', label: 'Florida' },
        { value: 'Micronesia', label: 'Micronesia' },
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Hawaii', label: 'Hawaii' },
        { value: 'Idaho', label: 'Idaho' },
        { value: 'Illinois', label: 'Illinois' },
        { value: 'Indiana', label: 'Indiana' },
        { value: 'Iowa', label: 'Iowa' },
        { value: 'Kansas', label: 'Kansas' },
        { value: 'Kentucky', label: 'Kentucky' },
        { value: 'Louisiana', label: 'Louisiana' },
        { value: 'Maine', label: 'Maine' },
        { value: 'Maryland', label: 'Maryland' },
        { value: 'Massachusetts', label: 'Massachusetts' },
        { value: 'Michigan', label: 'Michigan' },
        { value: 'Minnesota', label: 'Minnesota' },
        { value: 'Marshall Islands', label: 'Marshall Islands' },
        { value: 'Mississippi', label: 'Mississippi' },
        { value: 'Missouri', label: 'Missouri' },
        { value: 'Montana', label: 'Montana' },
        { value: 'Nebraska', label: 'Nebraska' },
        { value: 'Nevada', label: 'Nevada' },
        { value: 'New Hampshire', label: 'New Hampshire' },
        { value: 'New Jersey', label: 'New Jersey' },
        { value: 'New Mexico', label: 'New Mexico' },
        { value: 'New York', label: 'New York' },
        { value: 'North Carolina', label: 'North Carolina' },
        { value: 'North Dakota', label: 'North Dakota' },
        { value: 'Ohio', label: 'Ohio' },
        { value: 'Oklahoma', label: 'Oklahoma' },
        { value: 'Oregon', label: 'Oregon' },
        { value: 'Pennsylvania', label: 'Pennsylvania' },
        { value: 'Palau', label: 'Palau' },
        { value: 'Rhode Island', label: 'Rhode Island' },
        { value: 'South Carolina', label: 'South Carolina' },
        { value: 'South Dakota', label: 'South Dakota' },
        { value: 'Tennessee', label: 'Tennessee' },
        { value: 'Texas', label: 'Texas' },
        { value: 'Utah', label: 'Utah' },
        { value: 'Vermont', label: 'Vermont' },
        { value: 'Virginia', label: 'Virginia' },
        { value: 'Washington', label: 'Washington' },
        { value: 'West Virginia', label: 'West Virginia' },
        { value: 'Wisconsin', label: 'Wisconsin' },
        { value: 'Wyoming', label: 'Wyoming' },
        { value: 'American Samoa', label: 'American Samoa' },
        { value: 'Guam', label: 'Guam' },
        {
          value: 'Northern Mariana Islands',
          label: 'Northern Mariana Islands',
        },
        { value: 'Puerto Rico', label: 'Puerto Rico' },
        {
          value: 'United States Minor Outlying Islands',
          label: 'United States Minor Outlying Islands',
        },
        { value: 'Virgin Islands', label: 'Virgin Islands' },
        { value: 'Alberta', label: 'Alberta' },
        { value: 'British Columbia', label: 'British Columbia' },
        { value: 'Manitoba', label: 'Manitoba' },
        { value: 'New Brunswick', label: 'New Brunswick' },
        {
          value: 'Newfoundland and Labrador',
          label: 'Newfoundland and Labrador',
        },
        { value: 'Nova Scotia', label: 'Nova Scotia' },
        { value: 'Nunavut', label: 'Nunavut' },
        { value: 'Northwest Territories', label: 'Northwest Territories' },
        { value: 'Ontario', label: 'Ontario' },
        { value: 'Prince Edward Island', label: 'Prince Edward Island' },
        { value: 'Quebec', label: 'Quebec' },
        { value: 'Saskatchewan', label: 'Saskatchewan' },
        { value: 'Yukon', label: 'Yukon' },
      ],
    },
    {
      id: `${prefix}-entity-flips`,
      section: capitalize(prefix),
      name: 'Number of Flips',
      type: ['number'],
    },
    {
      id: `${prefix}-entity-phone`,
      section: capitalize(prefix),
      name: 'Phone',
      type: ['phone'],
    },
    {
      id: `${prefix}-entity-primary-address`,
      section: capitalize(prefix),
      name: 'Primary Address',
      type: ['address'] as unknown as FieldType[],
    },
    {
      id: `${prefix}-entity-mailing-address`,
      section: capitalize(prefix),
      name: 'Mailing Address',
      type: ['address'] as unknown as FieldType[],
    },
    {
      id: `${prefix}-entity-tax`,
      section: capitalize(prefix),
      name: 'Tax ID',
      type: ['tax-number'],
    },
  ] as BaseField[]

function getMapToFields(
  scheme: IApplicationScheme,
  mapToFields: BaseField[],
  field: IApplicationSchemeField
) {
  const borrowerFields = mapToFields.filter(
    ({ page }) => page === 'borrower'
  ) as PersonField[]
  const individualFields = borrowerFields.filter(
    ({ context }) => context !== PERSON_FIELD_CONTEXT_ENTITY
  )
  const entityFields = borrowerFields.filter(
    ({ context }) => context !== PERSON_FIELD_CONTEXT_INDIVIDUAL
  )

  let additionalMapToFields: BaseField[] = []
  if (field.fullId?.includes('borrower-individual')) {
    additionalMapToFields = [
      ...getIndividualMapToFields('borrower'),
      ...individualFields.map((field) => ({
        ...field,
        name: `Borrower - ${field.name}`,
      })),
    ]
  } else if (field.fullId?.includes('borrower-entity')) {
    additionalMapToFields = [
      ...getEntityMapToFields('borrower'),
      ...entityFields.map((field) => ({
        ...field,
        name: `Borrower - ${field.name}`,
      })),
    ]
  } else if (field.fullId?.includes('guarantor-individual')) {
    additionalMapToFields = [
      ...getIndividualMapToFields('guarantor'),
      ...individualFields.map((field) => ({
        ...field,
        name: `Guarantor - ${field.name}`,
      })),
    ]
  } else if (field.fullId?.includes('guarantor-entity')) {
    additionalMapToFields = [
      ...getEntityMapToFields('guarantor'),
      ...entityFields.map((field) => ({
        ...field,
        name: `Guarantor - ${field.name}`,
      })),
    ]
  } else if (field.type === 'address') {
    additionalMapToFields = [
      {
        id: 'collateral-address',
        section: 'Collateral',
        name: 'Address',
        type: ['address'] as unknown as FieldType[],
      },
    ] as BaseField[]
  }

  // allow to use uniq mapTo field only once except borrower/guarantor that can be used for individual and entity
  const usingMapToIds = compact(
    uniq(
      scheme.pages
        .map((page) =>
          page.fields.map(({ type, mapTo, individualFields, entityFields }) => {
            if (
              (field.fullId?.includes('borrower') && type === 'borrower') ||
              (field.fullId?.includes('guarantor') && type === 'guarantor')
            ) {
              return mapTo
            } else {
              return [
                mapTo,
                ...[...(individualFields || []), ...(entityFields || [])].map(
                  ({ mapTo }) => mapTo
                ),
              ]
            }
          })
        )
        .flat(3)
    ).filter(
      (
        mapToId // we allow custom fields to be used for borrower and guarantor
      ) => !borrowerFields.find(({ id }) => id === mapToId)
    )
  )

  return [
    ...mapToFields.filter(({ page }) => page !== 'borrower'),
    ...additionalMapToFields,
  ].filter(({ id }) => !usingMapToIds.includes(id) || id === field.mapTo)
}

const displayField = (field: IApplicationSchemeField) => {
  switch (field.type) {
    case 'address':
      return formatAddress(field.value as Address)
    case 'currency':
      return formatUsd(
        isNumber(field.value)
          ? field.value
          : parseFloat((field.value as string) || '0')
      )
    case 'percentage':
      return formatPercent(
        isNumber(field.value)
          ? field.value
          : parseFloat((field.value as string) || '0')
      )
    case 'number': {
      return formatNumber(
        isNumber(field.value)
          ? field.value
          : parseFloat((field.value as string) || '0')
      )
    }
    case 'date':
      return formatDate(field.value as string) || '-'
    case 'phone':
      return formatPhone(field.value as string) || '-'
    case 'signature':
      return <img src={field.value as string} className="max-h-60 max-w-60" />
    case 'email':
      return <LinkEmail email={field.value as string} />
    default:
      return field.value as string
  }
}

const getFieldById = (
  scheme: IApplicationScheme,
  fieldId: string
): IApplicationSchemeField | undefined => {
  if (fieldId.startsWith('borrower-') || fieldId.startsWith('guarantor-')) {
    const [personType, personEntityType, ...rest] = fieldId.split('-')
    const personFieldId = rest.join('-')
    const personField = getFieldById(scheme, personType)
    const fields =
      personEntityType === 'entity'
        ? personField?.entityFields
        : personField?.individualFields
    return fields?.find(({ id }) => id === personFieldId)
  }

  return scheme.pages.reduce(
    (foundField: IApplicationSchemeField | undefined, { fields }) => {
      return foundField || fields.find(({ id }) => id === fieldId)
    },
    undefined
  )
}

const getValidFullConditions = (
  scheme: IApplicationScheme,
  conditions?: IApplicationCondition[]
) => {
  const fullConditions: IApplicationFullCondition[] =
    (conditions || scheme.conditions)
      ?.map((condition) => ({
        ...condition,
        conditions: condition.conditions
          .map((condition) => ({
            ...condition,
            field: getFieldById(scheme, condition.fieldId)!,
          }))
          .filter((condition) => !isUndefined(condition.field)),
        actions: condition.actions
          .map((action) => ({
            ...action,
            fields: action.fieldIds
              ?.map((fieldId) => getFieldById(scheme, fieldId)!)
              .filter(Boolean),
            pages: action.pageIds
              ?.map((pageId) => scheme.pages.find(({ id }) => id === pageId)!)
              .filter(Boolean),
          }))
          .filter((action) => action.fields?.length || action.pages?.length),
      }))
      .filter(
        (condition) => condition.conditions.length && condition.actions.length
      ) || []

  return fullConditions.filter((fullCondition) => {
    const condition = (conditions || scheme.conditions)?.find(
      ({ id }) => id === fullCondition.id
    ) as IApplicationCondition
    return (
      fullCondition.conditions.length === condition.conditions.length &&
      fullCondition.actions.length === condition.actions.length
    )
  })
}

const mapFieldTypeToConditionType = (
  fieldType: IApplicationSchemeFieldType
): IAutomationItemConfig['type'] => {
  switch (fieldType) {
    case 'currency':
      return 'currency'
    case 'percentage':
      return 'percentage'
    case 'number':
    case 'decimal':
      return 'number'
    case 'option':
    case 'yes-no':
      return 'options'
    // we filter out 'date', 'document', 'phone', 'email', 'address', 'signature'
    // other types 'text', 'multiline', 'ssn-number', 'tax-number' we show as text
    case 'borrower':
    case 'guarantor':
      return 'children'
    default:
      return 'text'
  }
}

export {
  parseScheme,
  getPageByField,
  formatField,
  parseField,
  updateField,
  deleteFieldById,
  getMapToFields,
  displayField,
  getFieldById,
  getValidFullConditions,
  mapFieldTypeToConditionType,
}
