import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { KEY_THREAD_SIGNATURES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import {
  getMailSignatures,
  addMailSignature,
  updateMailSignature,
  removeMailSignature,
} from '../services/api/thread-signature'

const useMailSignatures = () => {
  return useQuery({
    queryKey: [KEY_THREAD_SIGNATURES],
    queryFn: getMailSignatures,
  })
}

const useAddMailSignature = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addMailSignature,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREAD_SIGNATURES] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateMailSignature = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateMailSignature,
    onSuccess: (mailsignature) => {
      queryClient.setQueryData(
        [KEY_THREAD_SIGNATURES, mailsignature.id],
        mailsignature
      )
      queryClient.invalidateQueries({ queryKey: [KEY_THREAD_SIGNATURES] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveMailSignature = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeMailSignature,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREAD_SIGNATURES] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useMailSignatures,
  useAddMailSignature,
  useUpdateMailSignature,
  useRemoveMailSignature,
}
