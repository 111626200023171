import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useProducts } from 'admin/hooks/use-products'
import { useUsers } from 'admin/hooks/use-users'
import { pathTo, pathToLoan } from 'admin/path-to'
import { downloadLoansReport } from 'admin/services/csv/download-loans'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { LoanStatus } from 'constants/loan-status'
import { useOfFundsOptions } from 'constants/use-of-funds'
import { useLoans } from 'hooks/use-loans'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import LoansReportTable from './LoansReportTable'

const getTitleByReportId = (id: string) => {
  switch (id) {
    case 'servicing-loans':
      return 'Sent to Servicing'
    case 'archived-loans':
      return 'Archived Loans'
    case 'liquidated-loans':
      return 'Liquidated Loans'
    default:
      return 'Report'
  }
}

const getStatusFilterByReportId = (id: string): LoanStatus | undefined => {
  switch (id) {
    case 'servicing-loans':
      return 'servicing'
    case 'archived-loans':
      return 'archived'
    case 'liquidated-loans':
      return 'liquidated'
    default:
      return undefined
  }
}

type RouterParams = Readonly<{
  id: string
}>

function LoansReport() {
  const navigate = useNavigate()
  const { id } = useParams() as RouterParams
  const { user } = useSession()
  const [searchParams, setSearchParams] = useSearchParams()
  const [title, setTitle] = useState(getTitleByReportId(id))
  const [statusFilter, setStatusFilter] = useState<LoanStatus | undefined>(
    getStatusFilterByReportId(id)
  )

  const filtersValue: IFilterValue = useMemo(() => {
    return searchParams.get('filter')
      ? urlToFilterValue(searchParams.get('filter') as string)
      : []
  }, [searchParams, statusFilter])

  const {
    visibleItems,
    result,
    setPagination,
    search,
    setSearch,
    sort,
    setSort,
  } = usePagination<Loan>({
    property: 'loans',
    useData: (params) =>
      useLoans(
        {
          ...params,
          filter: {
            ...params.filter,
            ...filterValueToTableFilter(filtersValue),
            ...(statusFilter ? { status: [statusFilter] } : {}),
          },
        },
        {
          keepPreviousData: true,
        }
      ),
  })
  const { data: products } = useProducts()
  const { data: owners } = useUsers({
    user,
    clientId: user?.client?.id,
  })

  const filterConfig: IFilterConfig[] = useMemo(
    () => [
      {
        id: 'dateClosing',
        type: 'date',
        label: 'Closing Date',
      },
      {
        id: 'amount',
        type: 'currency',
        label: 'Loan Amount',
      },
      {
        id: 'product_id',
        type: 'select',
        label: 'Product',
        options: products?.products.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        id: 'admin_id',
        type: 'select',
        label: 'Owners',
        options: owners?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        id: 'use_of_funds',
        type: 'select',
        label: 'Use of Funds',
        options: useOfFundsOptions,
      },
    ],
    [products, owners]
  )
  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
    },
    [setSearchParams]
  )

  useEffect(() => {
    setTitle(getTitleByReportId(id))
    setStatusFilter(getStatusFilterByReportId(id))
  }, [id])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title={title}
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download
            filename="loans"
            download={() =>
              downloadLoansReport({
                ...filterValueToTableFilter(filtersValue),
                ...(statusFilter ? { status: [statusFilter] } : {}),
              })
            }
          />
        </PageTop>
        <Panel>
          <Flex gap={8} justifyContent="end" flexWrap="wrap" className="pb-4">
            <Filter
              config={filterConfig}
              value={filtersValue}
              onApply={handleFilterChange}
            />
            <Search search={search} onSearch={setSearch} />
          </Flex>
          <LoansReportTable
            data={visibleItems}
            loading={result.isPending}
            statusFilter={statusFilter}
            sort={sort}
            onSort={setSort}
            onClick={(loan) => {
              if (loan) {
                navigate(pathToLoan(loan))
              }
            }}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { LoansReport }
