import clsx from 'clsx'
import { Formik } from 'formik'
import { debounce, isEqual, isUndefined } from 'lodash'
import { useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SwitcherContext } from 'borrower/components/Switcher'
import { useSignApplication } from 'borrower/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { useUpdateDraftApplication } from 'hooks/use-application'
import { useSession } from 'hooks/use-session'
import { IApplicationScheme, Person } from 'types'
import { ApplicationField } from './ApplicationField'
import { Success } from './Success'
import {
  isFieldVisible,
  isFieldRequired,
  schemeToValidationSchema,
  schemeToValues,
  isPageVisible,
} from './helpers'
import styles from './styles.module.scss'

interface Props {
  applicationId: string
  clientId: string
  initialApplicationScheme: IApplicationScheme
}

export const DraftApplication = ({
  applicationId,
  clientId,
  initialApplicationScheme,
}: Props) => {
  const navigate = useNavigate()
  const { user, isManager } = useSession()
  const { setOpen: setOpenAccountSwitcher } = useContext(SwitcherContext)
  const isPersonalAccount =
    user?.actor?.id === user?.person?.id && user?.person?.isAccount
  const isEditable = isManager || isPersonalAccount
  const { mutate: submit } = useSignApplication(applicationId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [scheme, setScheme] = useState<IApplicationScheme>(
    initialApplicationScheme
  )
  const { id, tab: urlPageId } = useParams() as {
    id: string
    tab: string
  }
  const actorName = user?.actor?.name
  const pages = useMemo(
    () => scheme.pages.filter((page) => isPageVisible(scheme, page)),
    [scheme]
  )
  const pageIds = useMemo(() => pages.map(({ id }) => id), [pages])
  const defaultPageId = useMemo(() => {
    const defaultPageIndex = Math.max(
      0,
      ...pages
        .map(({ complete }, index) => ({ index, complete }))
        .filter(({ complete }) => complete)
        .map(({ index }) => index)
    )
    return pages[defaultPageIndex].id
  }, [pages])
  const [currentPageId, setCurrentPageId] = useState<string>(defaultPageId)
  const currentPageIndex = useMemo(
    () => pageIds.indexOf(currentPageId),
    [pageIds, currentPageId]
  )
  const maxAvailablePageIndex = useMemo(() => {
    return Math.max(
      Number(currentPageIndex),
      ...pages
        .map(({ complete }, index) => ({ index, complete }))
        .filter(({ complete }) => complete)
        .map(({ index }) => index)
    )
  }, [currentPageIndex, pages])
  const page = useMemo(
    () => pages[currentPageIndex]!,
    [pages, currentPageIndex]
  )
  const { mutate: update } = useUpdateDraftApplication({
    applicationId: id,
    clientId,
  })
  const save = useCallback(
    debounce((scheme: IApplicationScheme, submitted: boolean = false) => {
      update(
        {
          ...scheme,
          submitted,
        },
        {
          onSuccess: () => {
            if (submitted) {
              submit(undefined, {
                onSettled: () => {
                  setIsSubmitting(false)
                },
                onSuccess: () => {
                  setIsSubmitted(true)
                },
              })
            }
          },
        }
      )
    }, 500),
    [update]
  )
  const goToPage = useCallback(
    (pageId: string) => {
      const nextPage = pages.find((p) => p.id === pageId)
      if (isUndefined(nextPage)) {
        setIsSubmitting(true)
        save(scheme, true)
      } else {
        navigate(pathTo('applicationDraft', id, nextPage.id))
      }
    },
    [save, scheme, pages, navigate, id]
  )
  const goToNextPage = useCallback(() => {
    goToPage(pageIds[currentPageIndex + 1])
  }, [goToPage, pageIds, currentPageIndex])
  const handleBack = useCallback(() => {
    goToPage(pageIds[currentPageIndex - 1])
  }, [goToPage, pageIds, currentPageIndex])
  const initialValues = useMemo(
    () => schemeToValues(page.fields, user?.actor as unknown as Person),
    [page, user]
  )
  const handleSwitch = useCallback(
    () => setOpenAccountSwitcher(true),
    [setOpenAccountSwitcher]
  )
  const Scheme = useMemo(
    () =>
      schemeToValidationSchema(scheme, {
        ...page,
        fields: page.fields.map((field) => {
          return {
            ...field,
            required:
              isFieldVisible(scheme, field) && isFieldRequired(scheme, field),
          }
        }),
      }),
    [page, scheme]
  )
  const updateScheme = useCallback(
    (values, complete = false) => {
      const nextScheme = {
        ...scheme,
        pages: scheme.pages.map((page) => ({
          ...page,
          complete: page.id === currentPageId ? complete : page.complete,
          fields: page.fields.map((field) => ({
            ...field,
            ...(field.type === 'signature' &&
            !isUndefined(values[`${field.id}_checkbox`])
              ? {
                  signatureConfirmationCheckbox: values[`${field.id}_checkbox`]
                    ? new Date().toISOString()
                    : '',
                }
              : {}),
            value: isUndefined(values[field.id])
              ? field.value
              : values[field.id],
          })),
        })),
      }
      if (!isEqual(nextScheme, scheme)) {
        setScheme(nextScheme)
        save(nextScheme)
      }
    },
    [scheme, currentPageId, save]
  )

  useEffect(() => {
    setCurrentPageId(urlPageId || defaultPageId)
  }, [urlPageId, defaultPageId])

  return isSubmitted ? (
    <Success>
      <Button
        onClick={() => navigate(pathTo('applications'))}
        data-testid="back-to-applications"
      >
        Back to applications
      </Button>
    </Success>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Scheme}
      enableReinitialize
      onSubmit={(values) => {
        updateScheme(values, true)
        goToNextPage()
      }}
    >
      <Form
        onChange={updateScheme}
        className={clsx(currentPageIndex === 0 && '-mt-8')}
        data-testid="draft-application-form"
      >
        {currentPageIndex === 0 && (
          <Flex
            justifyContent="center"
            alignItems="center"
            className="mx-auto my-0 px-0 pb-8"
          >
            <Flex className="bg-grey-100 max-w-[680px] px-6 py-4 rounded-full text-grey-800 font-bold">
              This application is for {actorName}
              <TextLink
                className="font-bold !text-blue-100 hover:!text-blue-200"
                onClick={handleSwitch}
              >
                Switch account
              </TextLink>
            </Flex>
          </Flex>
        )}
        <Flex stack gap={32} className={styles.panel}>
          <Flex stack gap={16}>
            <Flex>
              <div className={styles.steps}>
                Step {currentPageIndex + 1}/{pageIds.length}
              </div>
              <EllipsesActions
                placement="bottom-start"
                trigger={
                  <Flex className={styles.step} alignItems="center" gap={4}>
                    {page.tag} <Icon name={IconName.arrowDown} />
                  </Flex>
                }
              >
                {pages.map(({ id, tag }, index) => (
                  <EllipsesActions.Item
                    key={id}
                    disabled={index > maxAvailablePageIndex}
                    onSelect={() => goToPage(id)}
                  >
                    {tag}
                  </EllipsesActions.Item>
                ))}
              </EllipsesActions>
            </Flex>
            <Header>{page.header}</Header>
            {page.description && (
              <Text className="text-grey-600">{page.description}</Text>
            )}
          </Flex>
          <div className={styles.labels}>
            {page.fields
              .filter((field) => isFieldVisible(scheme, field))
              .map((field) => (
                <ApplicationField
                  key={field.id}
                  scheme={scheme}
                  field={field}
                  disabled={!isEditable}
                />
              ))}
          </div>
          <div className={styles.controls}>
            {currentPageIndex === 0 ? (
              <Button
                variant="tertiary"
                className={clsx('rounded-full h-11 px-6 text-xl')}
                onClick={() => navigate(pathTo('applications'))}
              >
                Cancel
              </Button>
            ) : (
              <div />
            )}
            <div className={styles.buttons}>
              {currentPageIndex !== 0 && (
                <Button
                  variant="tertiary"
                  className={clsx('rounded-full h-11 w-11 text-xl')}
                  onClick={handleBack}
                >
                  ←
                </Button>
              )}
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={
                  currentPageIndex + 1 === pageIds.length && !isEditable
                }
                className={clsx('rounded-full h-11 px-6 text-xl')}
              >
                {currentPageIndex + 1 === pageIds.length
                  ? 'Submit'
                  : 'Continue →'}
              </Button>
            </div>
          </div>
        </Flex>
      </Form>
    </Formik>
  )
}
