// Extend Window interface to include Heap Analytics
declare global {
  interface Window {
    heap?: {
      load: (id: string) => void
      identify: (id: string) => void
      addUserProperties: (properties: Record<string, any>) => void
      track: (event: string, properties?: Record<string, any>) => void
    }
  }
}

import { gtag, install } from 'ga-gtag'
import { useCallback, useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { devDomains, rootDomain, devEmailDomains } from 'constants/domain'
import { useSession } from './use-session'

export const useAnalytics = () => {
  const { user, isLoading } = useSession()
  const isGAEnabled =
    !!import.meta.env.VITE_APP_GA && window.location.hostname !== 'localhost'
  const isDebugMode = devDomains.includes(rootDomain)
  const isDevEmail = devEmailDomains.includes(
    user?.admin?.email?.split('@')[1] as string
  )
  const isHotJarEnabled =
    !!import.meta.env.VITE_APP_HJID &&
    !!import.meta.env.VITE_APP_HJSV &&
    window.location.hostname !== 'localhost' &&
    !isDevEmail
  const isHeapEnabled =
    window.location.hostname !== 'localhost' && !!import.meta.env.VITE_APP_HEAP

  const pageView = useCallback(
    (url = window.location.pathname + window.location.search) => {
      if (!isLoading && isGAEnabled) {
        gtag('event', 'page_view', url as any)
      }
      if (!isLoading && isHotJarEnabled && hotjar.initialized()) {
        hotjar.stateChange(url)
      }
    },
    [isLoading, isGAEnabled, isHotJarEnabled]
  )

  const trackEvent = useCallback(
    ({ eventName }: { eventName: string }) => {
      if (isHeapEnabled) {
        window.heap?.track(eventName)
      }
    },
    [isHeapEnabled]
  )

  // INIT HEAP

  useEffect(() => {
    if (isHeapEnabled && window.heap && !!window.heap.load) {
      window.heap.load(import.meta.env.VITE_APP_HEAP)
    }
  }, [isHeapEnabled])

  // INIT HOTJAR

  useEffect(() => {
    if (!isLoading && isHotJarEnabled && !hotjar.initialized()) {
      hotjar.initialize({
        id: import.meta.env.VITE_APP_HJID,
        sv: import.meta.env.VITE_APP_HJSV,
      })
      hotjar.identify(user?.admin?.id || user?.person?.id || null, {
        email: user?.admin?.email || user?.person?.email,
      })
    }
  }, [isLoading, isHotJarEnabled, user])

  // INIT GA

  useEffect(() => {
    if (!isLoading && isGAEnabled) {
      install(import.meta.env.VITE_APP_GA as string, {
        debug_mode: isDebugMode,
      })
      if (isDevEmail) {
        gtag('set', { traffic_type: 'internal' })
      }
      pageView()
    }
  }, [isLoading, isGAEnabled, isDebugMode, isDevEmail, pageView])

  useEffect(() => {
    // Identify user in Heap Analytics when logged in
    if (!isLoading && isHeapEnabled && user) {
      const userId = user.admin?.id || user.person?.id
      const userEmail = user.admin?.email || user.person?.email
      if (userId) {
        // Identify user in Heap
        window.heap?.identify(userId)
        window.heap?.addUserProperties({
          email: userEmail,
          Name: user?.admin?.name || user?.person?.name,
          admin_id: user.admin?.id,
          person_id: user.person?.id,
          client_id: user.client?.id,
          client_name: user.client?.name,
          client_url: user.client?.url,
        })
      }
    }
  }, [isLoading, isHeapEnabled, user])

  return {
    pageView,
    trackEvent,
  }
}
