import { useMutation } from '@tanstack/react-query'
import { flatten } from 'lodash'
import { useState } from 'react'
import { PanelAddress } from 'admin/components/PanelAddress'
import { BorrowerDetails, inviteEmail } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import PanelEmail from 'components/Panel/PanelEmail'
import PanelEntityInformation from 'components/Panel/PanelEntityInformation'
import { PanelPersonCustom } from 'components/Panel/PanelPersonCustom'
import PanelPersonalInformation from 'components/Panel/PanelPersonalInformation'
import {
  useAddBorrowerAddress,
  useUpdateBorrower,
  useUpdateBorrowerAddress,
} from 'hooks/use-borrower'
import { useSession } from 'hooks/use-session'
import { handleErrorResponse } from 'services/request'
import { Field } from 'types'
import { message } from 'utils/message'
import PanelLinkedAccounts from './PanelLinkedAccounts'
import styles from './styles.module.scss'

interface Props {
  borrower: BorrowerDetails
  fields?: Field[]
  onSecure: (secure: boolean) => void
}

function TabGeneral({ borrower, onSecure, fields }: Props) {
  const { user } = useSession()
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [sentInvite, setSentInvite] = useState(false)
  const { mutate: updateBorrower, isPending: isUpdatingBorrower } =
    useUpdateBorrower()
  const resetPassword = useMutation({
    mutationFn: inviteEmail,
    onError: handleErrorResponse,
    onSuccess: () => {
      message.success('Password reset')
    },
  })
  const inviteBorrower = useMutation({
    mutationFn: inviteEmail,
    onError: handleErrorResponse,
    onSuccess: () => {
      setSentInvite(true)
      message.success('Invitation sent')
    },
  })
  const { mutate: addAddress, isPending: addingAddress } =
    useAddBorrowerAddress(borrower.id)
  const { mutate: updateAddress, isPending: updatingAddress } =
    useUpdateBorrowerAddress(borrower.id)

  const isIndividual = !borrower.type || borrower.type === 'individual'

  const isOpenId = user?.client?.settings?.openid

  return (
    <div className={styles.tabContent}>
      {isIndividual &&
        !isOpenId &&
        ['draft', 'invited', 'active'].includes(borrower.status) && (
          <Button
            className={styles.tabButton}
            onClick={() => inviteBorrower.mutate({ id: borrower.id })}
            loading={inviteBorrower.isPending}
            iconLeft={<Icon name={IconName.send} />}
          >
            {borrower.status !== 'draft' || sentInvite
              ? 'Re-invite User'
              : 'Invite User'}
          </Button>
        )}

      <Grid gap={16}>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          {isIndividual ? (
            <PanelPersonalInformation
              person={borrower}
              onSecure={onSecure}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              onResetPassword={resetPassword.mutate}
              isPasswordResetting={resetPassword.isPending}
              personType="borrower"
            />
          ) : (
            <PanelEntityInformation
              person={borrower}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              onSecure={onSecure}
            />
          )}
          <PanelPersonCustom
            personRoleType="borrower"
            fields={fields}
            person={borrower}
          />
          <PanelAddress
            person={borrower}
            isLoading={addingAddress || updatingAddress}
            addAddress={addAddress}
            updateAddress={updateAddress}
          />
        </Grid.Item>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelEmail
            id={borrower.id}
            type={isIndividual ? 'individual' : 'entity'}
            emails={borrower.emails}
            isInvited={['invited', 'active'].includes(borrower.status)}
            readonlyEmails={flatten(
              borrower.managers?.map((m) => m.emails || [])
            )}
          />
          <PanelLinkedAccounts borrower={borrower} />
        </Grid.Item>
        {isInfoModalVisible && (
          <ModalPersonInfo
            personType="borrower"
            person={borrower}
            saving={isUpdatingBorrower}
            onSave={(values) =>
              updateBorrower(
                { ...values, id: borrower.id },
                {
                  onSuccess: () => {
                    message.success('Information saved')
                    setIsInfoModalVisible(false)
                  },
                }
              )
            }
            onCancel={() => setIsInfoModalVisible(false)}
          />
        )}
      </Grid>
    </div>
  )
}

export default TabGeneral
