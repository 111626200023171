import { ColumnDef } from '@tanstack/react-table'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { IBudgetWorksheet } from 'types'

function TableWorksheets({
  data = [],
  loading,
  onClick,
  onDuplicate,
  onDelete,
  onRename,
}: {
  data?: IBudgetWorksheet[]
  loading: boolean
  onClick: (worksheet?: IBudgetWorksheet) => void
  onDuplicate: (worksheet: IBudgetWorksheet) => void
  onRename: (worksheet: IBudgetWorksheet) => void
  onDelete: (worksheet: IBudgetWorksheet) => void
}) {
  const columns: ColumnDef<IBudgetWorksheet>[] = useMemo(
    () =>
      compact([
        {
          header: 'Name',
          accessorKey: 'name',
        },
        {
          header: 'Work Items',
          accessorKey: 'items',
          cell: ({ row }) => row.original.items.length,
        },
        {
          id: 'actions',
          header: '',
          size: 40,
          cell: ({ row }) => {
            return (
              <EllipsesActions>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onDuplicate(row.original)}
                >
                  <Icon className="text-grey-600" name={IconName.duplicate} />
                  Duplicate
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onRename(row.original)}
                >
                  <Icon className="text-grey-600" name={IconName.edit} />
                  Rename
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onDelete(row.original)}
                  className="text-red-100"
                >
                  <Icon name={IconName.delete} />
                  Delete
                </EllipsesActions.Item>
              </EllipsesActions>
            )
          },
        },
      ]),
    []
  )

  return (
    <Table columns={columns} data={data} loading={loading} onClick={onClick} />
  )
}

export { TableWorksheets }
