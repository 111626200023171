import { ColumnDef } from '@tanstack/react-table'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo, pathToLoan } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { LoanStatus } from 'constants/loan-status'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'

interface Props {
  data?: Transaction[]
  isReferenceLink?: boolean
  onEdit?: (transaction: Transaction) => void
  onDelete?: (paymentId: string) => void
  loading: boolean
}
function TableVendorTransactions({
  data = [],
  isReferenceLink = true,
  loading,
  onEdit,
  onDelete,
}: Props) {
  const navigate = useNavigate()

  const columns: ColumnDef<Transaction>[] = useMemo(
    () =>
      compact([
        {
          header: 'Date',
          accessorKey: 'date',
          size: 150,
          cell: ({ getValue }) => formatDate(getValue() as string),
        },
        {
          header: 'Type',
          accessorKey: 'gl',
          size: 150,
          cell: ({ row }) => getFundingType(row.original),
        },
        {
          header: 'Reference',
          cell: ({ row }) => {
            let path

            if (row.original.loan?.id) {
              path = pathToLoan(
                row.original.loan as {
                  id: string
                  status: LoanStatus
                }
              )
            }

            if (row.original.fund?.id) {
              path = pathTo('fund', row.original.fund?.id, 'funding')
            }

            return (
              <Flex gap={8}>
                {(row.original.loan?.name || row.original.fund?.name) &&
                  (isReferenceLink && path ? (
                    <TextLink onClick={() => navigate(path)}>
                      {row.original.loan?.name || row.original.fund?.name || ''}
                    </TextLink>
                  ) : (
                    row.original.loan?.name || row.original.fund?.name || ''
                  ))}
                {row.original.payment?.description}
              </Flex>
            )
          },
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          size: 130,
          cell: ({ getValue }) => formatUsd(getValue() as string),
          meta: {
            align: 'right',
          },
        },
        {
          header: 'Balance',
          accessorKey: 'balance',
          size: 130,
          cell: ({ getValue }) => formatUsd(getValue() as string),
          meta: {
            align: 'right',
          },
        },
        onEdit || onDelete
          ? {
              header: '',
              id: 'actions',
              size: 40,
              cell: ({ row }) => {
                const id = row.original.payment?.id

                return (
                  <EllipsesActions>
                    {onEdit ? (
                      <EllipsesActions.Item
                        icon
                        onSelect={() => onEdit(row.original)}
                        disabled={
                          !['Withdrawal', 'Deposit'].includes(
                            row.original.payment?.type
                          )
                        }
                      >
                        <Icon name={IconName.edit} />
                        Edit
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onDelete ? (
                      <EllipsesActions.Item
                        icon
                        onSelect={() => onDelete(id)}
                        disabled={
                          !id ||
                          !['Withdrawal', 'Deposit'].includes(
                            row.original.payment?.type
                          )
                        }
                        className="text-red-100"
                      >
                        <Icon name={IconName.delete} />
                        Delete
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                  </EllipsesActions>
                )
              },
            }
          : undefined,
      ]),
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export { TableVendorTransactions }
