import clsx from 'clsx'
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAddMailAttachment } from 'admin/hooks/use-thread-attachments'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IMail, IThread, LoanDocument } from 'types'
import { Section } from 'utils/loan-document-sections'
import { pluralize } from 'utils/pluralize'
import { Initial } from './Initial'
import { ListOfContactDocuments } from './ListOfContactDocuments'
import { ListOfLoanDocuments } from './ListOfLoanDocuments'
import { Item, ItemWithDocuments } from './ModalAttachDocs'
import { SelectedDocuments } from './SelectedDocuments'

type Step = 'initial' | 'listOfDocuments' | 'selectedDocuments'

interface Props {
  thread: IThread
  mail: IMail
  setTitle: (title: ReactNode) => void
  onClose: () => void
}

const TabBrowseDocuments = ({ thread, mail, setTitle, onClose }: Props) => {
  const { mutate: addAttachment, isPending: isAttaching } =
    useAddMailAttachment()
  const [step, setStep] = useState<Step>('initial')
  const [currentItem, setCurrentItem] = useState<Item>()
  const [selectedItems, setSelectedItems] = useState<ItemWithDocuments[]>([])
  const selectedDocuments = useMemo(
    () =>
      selectedItems.flatMap(({ sections }) =>
        sections.flatMap(({ documents }) => documents.map(({ id }) => id))
      ),
    [selectedItems]
  )

  const handleSelectDocument = useCallback(
    (section: Section, document: LoanDocument) => {
      if (selectedDocuments.includes(document.id)) {
        setSelectedItems(
          selectedItems
            .map((itemWithDocuments) => ({
              ...itemWithDocuments,
              sections: itemWithDocuments.sections
                .map((section) => ({
                  ...section,
                  documents: section.documents.filter(
                    ({ id }) => id !== document.id
                  ),
                }))
                .filter(({ documents }) => !!documents.length),
            }))
            .filter(({ sections }) => !!sections.length)
        )
      } else {
        const existingItem = selectedItems.find(
          (itemWithDocuments) => itemWithDocuments.item.id === currentItem!.id
        )
        const existingSection = existingItem?.sections.find(
          ({ name }) => name === section.name
        )
        if (existingItem) {
          setSelectedItems(
            selectedItems.map(({ item, sections }) => ({
              item,
              sections: existingSection
                ? sections.map((itemSection) =>
                    itemSection.name === section.name
                      ? {
                          ...itemSection,
                          documents: [...itemSection.documents, document],
                        }
                      : itemSection
                  )
                : [...sections, { ...section, documents: [document] }],
            }))
          )
        } else {
          setSelectedItems([
            ...selectedItems,
            {
              item: currentItem as Item,
              sections: [{ ...section, documents: [document] }],
            },
          ])
        }
      }
    },
    [selectedDocuments, selectedItems, setSelectedItems, currentItem]
  )

  const handleAttach = useCallback(async () => {
    addAttachment(
      { mailId: mail.id, documentIds: selectedDocuments },
      { onSuccess: onClose }
    )
  }, [mail.id, selectedDocuments, addAttachment, onClose])

  useEffect(() => {
    if (step === 'initial') {
      setTitle('Attach Documents')
    }
    if (step === 'listOfDocuments') {
      setTitle(
        <Flex
          gap={12}
          alignItems="center"
          onClick={() => setStep('initial')}
          className="cursor-pointer"
        >
          <Icon name={IconName.arrowLeftLong} className="text-grey-600" />
          <div>{currentItem ? currentItem.name : 'Documents'}</div>
        </Flex>
      )
    }
    if (step === 'selectedDocuments') {
      setTitle(`${pluralize(selectedDocuments.length, 'Document')} Selected`)
    }
  }, [step, setTitle, currentItem, selectedDocuments])

  return (
    <Fragment>
      <div>
        {step === 'initial' && (
          <Initial
            thread={thread}
            onSelect={(person) => {
              setCurrentItem(person)
              setStep('listOfDocuments')
            }}
          />
        )}
        {step === 'listOfDocuments' && currentItem?.type === 'loan' && (
          <ListOfLoanDocuments
            currentItem={currentItem}
            selectedDocuments={selectedDocuments}
            onSelectDocument={handleSelectDocument}
          />
        )}
        {step === 'listOfDocuments' &&
          (currentItem?.type === 'borrower' ||
            currentItem?.type === 'investor') && (
            <ListOfContactDocuments
              currentItem={currentItem}
              selectedDocuments={selectedDocuments}
              onSelectDocument={handleSelectDocument}
            />
          )}
        {step === 'selectedDocuments' && (
          <SelectedDocuments
            selectedItems={selectedItems}
            onSelectDocument={handleSelectDocument}
            selectedDocuments={selectedDocuments}
          />
        )}
      </div>
      <Flex alignItems="center" justifyContent="space-between" className="mt-7">
        <Button variant="ghost">
          <Flex
            gap={8}
            alignItems="center"
            onClick={() =>
              (selectedDocuments.length || step === 'selectedDocuments') &&
              setStep(
                step === 'selectedDocuments' ? 'initial' : 'selectedDocuments'
              )
            }
            className={clsx(
              (selectedDocuments.length || step === 'selectedDocuments') &&
                'cursor-pointer'
            )}
          >
            <div className="text-grey-900 font-bold">
              {step === 'selectedDocuments' ? 'Hide Selected' : 'Show Selected'}
            </div>
            <div className="py-0.5 px-1.5 rounded-[5px] bg-purple-50 text-sm text-purple-300">
              {selectedDocuments.length}
            </div>
          </Flex>
        </Button>

        <Flex>
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            loading={isAttaching}
            disabled={!selectedDocuments.length}
            onClick={handleAttach}
          >
            Attach{' '}
            {selectedDocuments.length
              ? pluralize(selectedDocuments.length, 'File')
              : ''}
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export { TabBrowseDocuments }
