import { createContext, ReactNode, useContext } from 'react'

interface IDockTab {
  id: string
  title: ReactNode
  content: any
  expandByDefault?: boolean
  onMaximizeClick?: () => void
}

interface IDockContext {
  addTab: (tab: IDockTab) => void
  closeTab: (tabId: string) => void
  expandTab: (tabId: string) => void
  setTabTitle: (tabId: string, title: ReactNode) => void
  tabs: IDockTab[]
}

const DockContext = createContext<IDockContext>({} as IDockContext)

const useDockContext = () => useContext(DockContext)

export type { IDockTab, IDockContext }
export { DockContext, useDockContext }
