import { ImportEntity } from 'admin/components/ImportEntity'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewPaymentsImg from 'images/empty-view-payments.png'

interface Props {
  onImport: () => void
}

export const EmptyPayments = ({ onImport }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={'center h-50 w-50 !bg-cover !bg-center !bg-no-repeat'}
            style={{ background: `url(${emptyViewPaymentsImg})` }}
          />
        }
      />
      <EmptyView.Title title="Your scheduled payments from borrowers go here" />
      <EmptyView.Description description="Manage, collect and record payments from all your borrowers in one place." />
      <Flex className="mt-6" justifyContent="center">
        <ImportEntity entityType="payment" setAlertData={onImport} />
      </Flex>
    </EmptyView>
  )
}
