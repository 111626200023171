import clsx from 'clsx'
import { Button } from 'components/Button'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { useSession } from 'hooks/use-session'
import { DocumentVersion, LoanDocument } from 'types'
import { PanelChecklist } from './PanelChecklist'
import { PanelComments } from './PanelComments'
import { PanelDocumentInfo } from './PanelDocumentInfo'
import { PanelVersionHistory } from './PanelVersionHistory'
import styles from './styles.module.scss'

export type Tab = {
  id: 'checklist' | 'comments' | 'versionHistory' | 'documentInfo'
  icon: IconName
  title: string
}

interface Props {
  loanId?: string
  isLocked: boolean
  document: LoanDocument
  version?: DocumentVersion
  selectedTabId: Tab['id'] | undefined
  onSelectTabId: (tabId: Tab['id'] | undefined) => void
  onUpload: () => void
  onChangeVersion: (versionId: string) => void
}

export function SideMenu({
  loanId,
  isLocked,
  document,
  version,
  selectedTabId,
  onSelectTabId: setSelectedTabId,
  onUpload,
  onChangeVersion,
}: Props) {
  const { user } = useSession()

  const isLoanSharee = version
    ? version.document?.clientId !== user?.client?.id
    : document.clientId !== user?.client?.id

  const tabs: Tab[] = [
    { id: 'checklist', icon: IconName.checklist, title: 'Checklist' },
    { id: 'comments', icon: IconName.comments, title: 'Comments' },
    {
      id: 'versionHistory',
      icon: IconName.versionHistory,
      title: 'Version History',
    },
    {
      id: 'documentInfo',
      icon: IconName.documentInfo,
      title: 'Document Info',
    },
  ]
  const tab = tabs.find(({ id }) => id === selectedTabId) as Tab

  return (
    <div className={styles.sideMenu}>
      <div
        className={clsx(
          styles.tabContent,
          !!selectedTabId && styles.open,
          selectedTabId === 'comments' && 'bg-white-100'
        )}
      >
        {selectedTabId !== 'comments' && (
          <Header variant="h3" className={styles.tabContentHeader}>
            {tab?.title}
          </Header>
        )}
        {selectedTabId === 'checklist' && (
          <PanelChecklist document={document} />
        )}
        {selectedTabId === 'comments' && !isLoanSharee && (
          <PanelComments
            documentId={document.id}
            loanId={loanId}
            isLocked={isLocked}
          />
        )}
        {selectedTabId === 'comments' && isLoanSharee && (
          <div className={styles.commentsPanel}>
            <div className={styles.comments}>
              <div className={styles.noComments}>
                You cannot comment on documents while you are sharing a loan.
              </div>
            </div>
          </div>
        )}
        {selectedTabId === 'versionHistory' && (
          <PanelVersionHistory
            isLocked={isLocked}
            document={document}
            version={version}
            onUpload={onUpload}
            onChangeVersion={onChangeVersion}
          />
        )}
        {selectedTabId === 'documentInfo' && (
          <PanelDocumentInfo
            document={document}
            version={version}
            isLocked={isLocked}
          />
        )}
      </div>
      <div className={styles.tabs}>
        {tabs.map(({ id, icon }) => (
          <Button
            key={id}
            variant="ghost"
            icon
            className={clsx({
              [styles.selected]: id === selectedTabId,
            })}
            onClick={() => {
              setSelectedTabId(selectedTabId === id ? undefined : id)
            }}
          >
            <Icon name={icon} />
          </Button>
        ))}
      </div>
    </div>
  )
}
