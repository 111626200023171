import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Actions, Conditions, Trigger } from 'admin/components/Conditions'
import {
  useAutomationConfig,
  useAddAutomation,
  useEditAutomation,
} from 'admin/hooks/use-automation'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { Toggle } from 'components/Toggle'
import {
  IAutomation,
  ITriggerConfig,
  IConditionConfig,
  IActionConfig,
} from 'types'

interface Props {
  automation?: IAutomation
  onClose: () => void
}

const ModalAutomation = ({ automation, onClose }: Props) => {
  const isEditing = !!automation
  const [name, setName] = useState(automation?.name || '')
  const [isActive, setIsActive] = useState(automation?.isActive ?? true)
  const [trigger, setTrigger] = useState(automation?.trigger)
  const [conditions, setConditions] = useState(automation?.conditions || [])
  const [actions, setActions] = useState(automation?.actions || [])
  const { triggerConfigs, conditionConfigs, actionConfigs, pending } =
    useAutomationConfig()
  const { mutate: addAutomation, isPending: isAdding } = useAddAutomation()
  const { mutate: editAutomation, isPending: isSaving } = useEditAutomation()

  const handleSave = useCallback(() => {
    const nextAutomation = {
      name: name || 'Untitled Automation',
      isActive,
      trigger,
      conditions,
      actions,
    }
    if (isEditing) {
      editAutomation(
        {
          ...automation,
          ...nextAutomation,
        },
        { onSuccess: onClose }
      )
    } else {
      addAutomation(nextAutomation, { onSuccess: onClose })
    }
  }, [isEditing, name, isActive, trigger, conditions, actions, onClose])

  return (
    <Modal
      title={isEditing ? 'Edit Automation' : 'New Automation'}
      loading={pending}
      crossClose
      onClose={onClose}
    >
      <Flex stack gap={24}>
        <Flex stack gap={8}>
          <div>Automation Name</div>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Untitled Automation"
          />
        </Flex>
        <Flex stack gap={0}>
          <Trigger
            triggersConfig={triggerConfigs as ITriggerConfig[]}
            rule={trigger}
            hasConditions={!!conditions.length}
            onChange={(nextTrigger) => {
              setTrigger(nextTrigger)
              if (!nextTrigger) {
                setConditions([])
              }
            }}
          />
          {!!trigger && (
            <Conditions
              conditionsConfig={conditionConfigs as IConditionConfig[]}
              rules={conditions}
              onChange={setConditions}
            />
          )}
          <div
            className={clsx(
              !!conditions.length && 'mt-2',
              'border-0 border-l border-solid border-grey-200 h-6 ml-[19px] mb-2'
            )}
          />
          <Actions
            actionConfigs={actionConfigs as IActionConfig[]}
            rules={actions}
            onChange={setActions}
          />
        </Flex>

        <Toggle
          label="Active"
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
        />
        <Flex justifyContent="flex-end" alignItems="center" gap={8}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={isAdding || isSaving}
            disabled={!trigger}
            onClick={handleSave}
          >
            {isEditing ? 'Save' : 'Create'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export { ModalAutomation }
