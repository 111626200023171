import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addLoanSubstatus,
  deleteLoanSubstatus,
  getLoanSubstatus,
  updateLoanSubstatus,
} from 'admin/services/api/loan-substatus'
import { KEY_LOAN_SUBSTATUS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { LoanSubstatus, LoanSubstatusPayload } from 'types'

const useLoanSubstatuses = (options = {}) => {
  return useQuery({
    queryKey: [KEY_LOAN_SUBSTATUS],
    queryFn: getLoanSubstatus,
    ...options,
  })
}

const useAddLoanSubstatus = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addLoanSubstatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_SUBSTATUS] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanSubstatus = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      silent,
      ...payload
    }: LoanSubstatusPayload & {
      silent?: boolean
    }) => updateLoanSubstatus(payload),
    onSuccess: (
      substatus: LoanSubstatus,
      {
        silent,
      }: LoanSubstatusPayload & {
        silent?: boolean
      }
    ) => {
      const substatuses = queryClient.getQueryData([
        KEY_LOAN_SUBSTATUS,
      ]) as LoanSubstatus[]
      if (substatuses && !silent) {
        queryClient.setQueryData(
          [KEY_LOAN_SUBSTATUS],
          substatuses?.map((s) => (s.id === substatus.id ? substatus : s))
        )
      }
      queryClient.setQueryData([KEY_LOAN_SUBSTATUS, substatus.id], substatus)
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanSubstatus = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteLoanSubstatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_SUBSTATUS] })
    },
    onError: handleErrorResponse,
  })
}

const useInvalidateLoanSubstatuses = () => {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries({ queryKey: [KEY_LOAN_SUBSTATUS] })
}

export {
  useLoanSubstatuses,
  useAddLoanSubstatus,
  useUpdateLoanSubstatus,
  useDeleteLoanSubstatus,
  useInvalidateLoanSubstatuses,
}
