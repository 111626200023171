import { MongoQuery, RawRule } from '@casl/ability'
import { IAction, ISubject } from './AbilitiesContext'

const getAbilityCondition = (
  permissions: RawRule<[IAction, ISubject], MongoQuery>[] | undefined,
  action: IAction,
  subject: ISubject
) => {
  return permissions?.find((p) => {
    return p.action === action && p.subject === subject
  })?.conditions
}

export { getAbilityCondition }
