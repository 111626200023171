import clsx from 'clsx'
import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  className?: string
}

function TableStickyFooter({ children, className }: Props) {
  return (
    <div
      className={clsx(
        'sticky bottom-0 -mx-5 flex justify-center border-0 border-t border-solid border-grey-200 bg-white-100 p-4',
        className
      )}
    >
      {children}
    </div>
  )
}

export { TableStickyFooter }
