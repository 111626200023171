import { get } from 'lodash'
import { useState } from 'react'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useUpdateCurrentClient } from 'admin/hooks/use-users'
import { pathTo, prefix } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { ModalProfileDomainEdit } from 'components/Modal/ProfileDomainEdit'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'
import { message } from 'utils/message'
import { BusinessProfileDomain } from './BusinessProfileDomain'
import { ClientImage } from './ClientImage'

function Workspace() {
  const { user, signOut, refreshUser } = useSession()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const name = get(user, 'client.name', '')
  const subdomain = get(user, 'client.subdomain', '')
  const clientId = get(user, 'client.id', '')
  const paymentsEnabled = get(user, 'client.paymentsEnabled', false)
  const { mutate: update } = useUpdateCurrentClient()

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Workspace"
        />

        <Panel
          title="Workspace"
          className="max-w-panel"
          onEdit={() => setIsModalVisible(true)}
        >
          <BusinessProfileDomain
            name={name}
            subdomain={subdomain}
            paymentsEnabled={paymentsEnabled}
          />
        </Panel>
        <Panel title="Company Icon" className="max-w-panel">
          <Flex stack gap={20}>
            <div className="text-grey-600">
              Your icon should be at least 400x400px and will be visible in the
              side navigation of your admin portal and favicons
            </div>
            <ClientImage
              readPropertyKey="logoUrl"
              uploadPropertyKey="uploadIconUrl"
              entity="Icon"
            />
          </Flex>
        </Panel>
        <Panel title="Company Logo" className="max-w-panel">
          <Flex stack gap={20}>
            <div className="text-grey-600">
              Your logo should be at least 400 x 800px and will be visible to
              your borrowers and investors on the login pages and in their
              respective portals on statements, receipts and quotes. It will be
              used on a light background.
            </div>
            <ClientImage
              readPropertyKey="statementUrl"
              uploadPropertyKey="uploadStatementUrl"
              entity="Logo"
            />
          </Flex>
        </Panel>
        <Panel
          title="Borrower & Investor Portal Header Logo"
          className="max-w-panel"
        >
          <Flex stack gap={20}>
            <div className="text-grey-600">
              Your logo should be at least 400 x 800px and will be visible to
              your borrowers and investors in their respective portals in the
              top header. It will be used on a dark background.
            </div>
            <ClientImage
              readPropertyKey="url"
              uploadPropertyKey="uploadUrl"
              entity="Logo"
            />
          </Flex>
        </Panel>
      </Flex>
      {isModalVisible ? (
        <ModalProfileDomainEdit
          onCancel={() => setIsModalVisible(false)}
          onSubmit={(formValues) =>
            update(
              {
                name: formValues.name,
                subdomain: formValues.subdomain,
                clientId,
              },
              {
                onSuccess: () => {
                  setIsModalVisible(false)
                  message.success('Business information updated')
                  if (subdomain !== formValues.subdomain) {
                    signOut()
                    window.location.href = `https://${formValues.subdomain}${prefix}`
                  } else {
                    refreshUser()
                  }
                },
              }
            )
          }
          saving={false}
          name={name}
          subdomain={subdomain}
        />
      ) : null}
    </MainContent>
  )
}

export { Workspace }
