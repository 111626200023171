import { cloneDeep } from 'lodash'
import { useState, ReactNode, useMemo } from 'react'
import { InlineEditGridItem } from 'admin/components/InlineEdit'
import {
  useUpdateLoanAddressField,
  useUpdateLoanAddress,
} from 'admin/hooks/use-loan-address'
import { useUpdateLoanField } from 'admin/hooks/use-loan-fields'
import { useUpdateLoan } from 'admin/hooks/use-loans'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { LoanFields } from 'components/Modal/LoanFields'
import { Panel } from 'components/Panel'
import { Field, Loan, Address } from 'types'
import { formatField, getFieldsBySection, mergeFields } from 'utils/fields'
import styles from '../../admin/pages/Loan/styles.module.scss'
import { Summary } from '../Summary'

interface Props {
  loan: Loan
  address?: Address
  title?: string
  sections: string[]
  className?: string
  context?: 'origination' | 'servicing'
  includeAtTop?: ReactNode
  readonly?: boolean
}

function PanelLoanFields({
  loan,
  title,
  sections,
  context = 'origination',
  address,
  includeAtTop,
  className,
  readonly = false,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mutate: updateLoan, isPending: isUpdatingLoan } = address
    ? useUpdateLoanAddress({
        id: loan.id,
        silent: true,
      })
    : useUpdateLoan({
        id: loan.id,
        silent: true,
      })

  const { mutateAsync: updateField } = address
    ? useUpdateLoanAddressField({
        id: loan.id,
        addressId: address.id,
      })
    : useUpdateLoanField({ id: loan.id })

  const fieldGroups: Array<Field[]> = useMemo(
    () =>
      sections.map((section) =>
        getFieldsBySection(
          address ? address.fields : loan.fields,
          section,
          context
        )
      ),
    [sections, address, loan, context]
  )
  const hasFields = useMemo(() => fieldGroups.flat().length > 0, [fieldGroups])
  const editableFields: Field[] = useMemo(
    () => cloneDeep(fieldGroups.flat().filter(({ viewOnly }) => !viewOnly)),
    [fieldGroups]
  )

  return hasFields ? (
    <Panel
      title={title || sections[0]}
      onEdit={
        !readonly && editableFields.length
          ? () => setIsModalVisible(true)
          : undefined
      }
      className={className}
      alwaysShowEdit
    >
      {fieldGroups.map((fields, index) =>
        fields.length ? (
          <Grid gap={24} key={`section-${fields[0].id}`}>
            {index > 0 && (
              <Grid.Item xs={12}>
                <div className={styles.divider} />
              </Grid.Item>
            )}
            {fieldGroups.length > 1 && sections[index] !== title && (
              <Grid.Item xs={12}>
                <Header variant="h4">{sections[index]}</Header>
              </Grid.Item>
            )}
            {index === 0 && includeAtTop}
            {fields.map((field) =>
              readonly ? (
                <Grid.Item key={field.id} xs={6}>
                  <Summary name={field.name}>{formatField(field)}</Summary>
                </Grid.Item>
              ) : (
                <InlineEditGridItem
                  key={field.id}
                  field={field}
                  save={(value) =>
                    updateField({
                      ...field,
                      property: {
                        ...field.property,
                        value: [value],
                      },
                    })
                  }
                />
              )
            )}
          </Grid>
        ) : (
          includeAtTop
        )
      )}
      {isModalVisible && (
        <LoanFields
          title={title || sections[0]}
          fields={editableFields}
          saving={isUpdatingLoan}
          onSave={(updatedFields) =>
            (updateLoan as any)(
              {
                fields: mergeFields(
                  address ? address.fields : loan.fields,
                  updatedFields
                ) as unknown as Field[],
                ...(address ? { id: address.id } : {}),
              },
              {
                onSuccess: () => {
                  setIsModalVisible(false)
                },
              }
            )
          }
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  ) : null
}

export { PanelLoanFields }
