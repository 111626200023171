import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import { updateOwners } from 'admin/services/api/loans'
import { KEY_LOANS } from 'constants/query-keys'
import {
  getLoan,
  getLoans,
  getLoanTrusts,
  getLoansAdmins,
} from 'services/api/loans'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types'

interface Props {
  sort?: Sort | string[]
  search?: string
  filter?: Filter
  pagination?: Pagination
}

const useLoans = (
  { search, filter, pagination, sort }: Props = {},
  options?: {
    refetchInterval?: number
    keepPreviousData?: boolean
    enabled?: boolean
  }
) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_LOANS, search, nextFilter, pagination, sort],
    queryFn: () =>
      getLoans({ search, sort, filter: nextFilter, page: pagination }),
    ...options,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  })
}

const useLoansAdmins = ({ search, filter }: Props = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_LOANS, 'admins', search, nextFilter],
    queryFn: () => getLoansAdmins(),
  })
}

const useLoan = ({ id }: { id?: string }) => {
  return useQuery({
    queryKey: [KEY_LOANS, id],
    queryFn: () => getLoan(id as string),
    enabled: !!id,
  })
}

const useLoanTrusts = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_LOANS, id, 'trusts'],
    queryFn: () => getLoanTrusts(id as string),
  })
}

const useUpdateLoanOwners = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, owners }: { id: string; owners: string[] }) =>
      updateOwners({ id, owners }),
    onError: handleErrorResponse,
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loan?.id], loan)
    },
  })
}

export { useLoans, useLoan, useLoanTrusts, useLoansAdmins, useUpdateLoanOwners }
