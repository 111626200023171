import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { MailConnect } from './MailConnect'
import { MailForwardingAddress } from './MailForwardingAddress'
import { MailSignatures } from './MailSignatures/MailSignatures'

const MailSettings = () => {
  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'My Account', link: pathTo('account') }}
          title="Mail"
        />
        <MailConnect />
        <MailForwardingAddress />
        <MailSignatures />
      </Flex>
    </MainContent>
  )
}

export { MailSettings }
