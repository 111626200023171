import { toArray } from 'lodash'
import qs from 'qs'
import { ISortItem } from './config'

const sortValueToUrl = (value: ISortItem[]): string => {
  return qs.stringify(
    value.map(({ id, dir }) => ({ id, dir })),
    { strictNullHandling: true }
  )
}

const urlToSortValue = (value: string | undefined | null): ISortItem[] => {
  if (value) {
    return toArray(
      qs.parse(value, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
        arrayLimit: 500,
      }) as any
    ).map(({ id, dir }) => ({ id, dir }))
  }
  return []
}

const urlToSortTable = (value: string | undefined | null): string[] => {
  if (value) {
    return toArray(
      qs.parse(value, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
        arrayLimit: 500,
      }) as any
    ).map(({ id, dir }) => `${id} ${dir}`)
  }
  return []
}

export { sortValueToUrl, urlToSortValue, urlToSortTable }
