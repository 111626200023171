import clsx from 'clsx'
import { differenceInDays } from 'date-fns'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { Text } from 'components/Text'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { formatField, getFieldsBySection } from 'utils/fields'

interface Props {
  loan: Loan
}

const excludeFieldIds = [
  'to-trust',
  'principal-and-interest',
  'regular-payment',
]

const PanelLoanPayment = ({ loan }: Props) => {
  const paymentFields = useMemo(
    () =>
      getFieldsBySection(loan.fields, 'Payment', 'servicing').filter(
        ({ id }) => !excludeFieldIds.includes(id)
      ),
    [loan.fields]
  )
  const isFirstPayment = !loan.dateLastpayment
  const isLiquidated = loan.status === 'liquidated'
  const isLate = loan.daysLate > 0 && !isLiquidated
  const isDue = loan.daysPastDue > 0 && !isLate
  const daysBetweenPayments = useMemo(
    () => Math.abs(differenceInDays(loan.datePaidto, loan.dateNextpayment)),
    [loan.datePaidto, loan.dateNextpayment]
  )
  const daysToNextPayment = useMemo(
    () => differenceInDays(loan.dateNextpayment, new Date()),
    [loan.dateNextpayment]
  )
  const progress = useMemo(() => {
    return Math.min(
      ((daysBetweenPayments - daysToNextPayment) / daysBetweenPayments) * 100,
      100
    )
  }, [])

  return (
    <Panel className="p-0">
      {!isLiquidated && loan.dateNextpayment && (
        <div className="p-5 border-solid border-0 border-b border-grey-100">
          <Text variant="s" className="leading-[18px] text-grey-700">
            {isFirstPayment ? 'First' : 'Next'} Payment
          </Text>
          <Flex alignItems="center" justifyContent="space-between">
            <Header variant="h4">
              {friendlyDate(loan.dateNextpayment) || '-'}
            </Header>
            <Header variant="h4">{formatUsd(loan.nextPaymentAmount)}</Header>
          </Flex>
          <Flex stack gap={5} className="mt-2.5">
            <Text variant="s" className="leading-[18px] text-grey-700">
              {isLate && `${loan.daysLate} days late`}
              {isDue && 'Due'}
              {!isLate && !isDue && `${daysToNextPayment || '-'} days`}
            </Text>
            <Flex className="w-full h-[5px] bg-grey-100 rounded-[3px]">
              <div
                style={{ width: `${progress}%` }}
                className={clsx(
                  'h-full inline-block rounded-[3px]',
                  !isLate && !isDue && 'bg-green-50',
                  isLate && 'bg-red-50',
                  isDue && 'bg-yellow-100'
                )}
              />
            </Flex>
          </Flex>
        </div>
      )}
      <div className="py-6 px-5">
        <Grid gap={24}>
          {paymentFields.map((field) => (
            <Grid.Item key={field.id} xs={6}>
              <Summary name={field.name}>{formatField(field)}</Summary>
            </Grid.Item>
          ))}
          <Grid.Item xs={6}>
            <Summary name="Unpaid Charges">
              {formatUsd(loan.unpaidCharges)}
            </Summary>
          </Grid.Item>
          <Grid.Item xs={6}>
            <Summary name="Unpaid Interest">
              {formatUsd(loan.unpaidInterest)}
            </Summary>
          </Grid.Item>
        </Grid>
      </div>
    </Panel>
  )
}

export { PanelLoanPayment }
