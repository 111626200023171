import CurrencyIcon from 'images/icon-field-currency.svg'
import DateIcon from 'images/icon-field-date.svg'
import DecimalIcon from 'images/icon-field-decimal.svg'
import DropdownIcon from 'images/icon-field-dropdown.svg'
import EmailIcon from 'images/icon-field-email.svg'
import NumberIcon from 'images/icon-field-number.svg'
import PercentageIcon from 'images/icon-field-percentage.svg'
import PhoneIcon from 'images/icon-field-phone.svg'
import TextIcon from 'images/icon-field-text.svg'
import YesNoIcon from 'images/icon-field-yes-no.svg'
import { ICustomApplicationField } from 'types'

const fields: ICustomApplicationField[] = [
  {
    type: 'currency',
    name: 'Currency',
    icon: CurrencyIcon,
  },
  {
    type: 'date',
    name: 'Date',
    icon: DateIcon,
  },
  {
    type: 'decimal',
    name: 'Decimal',
    icon: DecimalIcon,
  },
  {
    type: 'option',
    name: 'Dropdown',
    icon: DropdownIcon,
  },
  {
    type: 'email',
    name: 'Email',
    icon: EmailIcon,
  },
  {
    type: 'number',
    name: 'Number',
    icon: NumberIcon,
  },
  {
    type: 'percentage',
    name: 'Percentage',
    icon: PercentageIcon,
  },
  {
    type: 'phone',
    name: 'Phone',
    icon: PhoneIcon,
  },
  {
    type: 'text',
    name: 'Text',
    icon: TextIcon,
  },
  {
    type: 'yes-no',
    name: 'Yes or No',
    icon: YesNoIcon,
  },
]

export { fields }
