import { useCallback, useState } from 'react'
import {
  CommentEditor,
  isEmpty,
} from 'admin/components/InlineWysiwyg/CommentEditor'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { IComment } from 'types'

interface Props {
  comment: IComment
  isSaving?: boolean
  users?: { value: string; id: string; email: string }[]
  onSave: (comment: IComment) => void
  onCancel: () => void
}

const EditComment = ({
  comment: initialComment,
  users = [],
  onSave,
  onCancel,
}: Props) => {
  const [comment, setComment] = useState(initialComment)
  const [isSaving, setIsSaving] = useState(false)
  const handleSave = useCallback(() => {
    setIsSaving(true)
    onSave(comment)
  }, [onSave, comment])

  return (
    <div className="sticky top-0 bg-white-100">
      <Flex
        stack
        gap={12}
        className="p-3 border border-solid border-grey-200 rounded-xl"
      >
        <Flex gap={4}>
          <Icon name={IconName.edit} className="text-grey-600" />
          <Text className="font-bold">Edit Comment</Text>
        </Flex>
        <CommentEditor
          namespace="body"
          users={users}
          placeholder="Write a comment and @mention teammates. This is only visible to your internal team."
          value={comment.text}
          dropdownPosition="bottom"
          onChange={(text) => setComment({ ...comment, text })}
          onEnter={handleSave}
        />
        <Flex gap={10} justifyContent="flex-end">
          <Button onClick={onCancel} variant="ghost">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="primary"
            loading={isSaving}
            disabled={isEmpty(comment.text)}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </div>
  )
}

export { EditComment }
