import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { IBudgetWorksheetAttribute } from 'types'
import { WorkItemAttributesTableRow } from './WorkItemAttributesTableRow'

interface Props {
  attributes: IBudgetWorksheetAttribute[]
  onEdit: (attribute: IBudgetWorksheetAttribute) => void
  onDelete: (attribute: IBudgetWorksheetAttribute) => void
  onReorder: (attributes: IBudgetWorksheetAttribute[]) => void
}

const WorkItemAttributesTable = ({
  attributes: initialAttributes,
  onEdit,
  onDelete,
  onReorder,
}: Props) => {
  const [attributes, setAttributes] =
    useState<IBudgetWorksheetAttribute[]>(initialAttributes)

  const handleDrop = useCallback(
    (dragIndex: number, dropIndex: number) => {
      if (!isNil(dragIndex) && !isNil(dropIndex)) {
        setAttributes((currentAttributes) => {
          const nextAttributes = [...currentAttributes]
          const dragAttribute = currentAttributes[dragIndex]
          nextAttributes.splice(dragIndex, 1)
          nextAttributes.splice(
            dragIndex < dropIndex ? dropIndex : dropIndex + 1,
            0,
            dragAttribute
          )
          onReorder(nextAttributes)
          return nextAttributes
        })
      }
    },
    [onReorder]
  )

  useEffect(() => {
    setAttributes(initialAttributes)
  }, [initialAttributes])

  return (
    <div>
      <div className="py-3 font-bold pl-11 border-0 border-b border-solid border-grey-200">
        Attribute Name
      </div>
      <DndProvider backend={HTML5Backend}>
        <div>
          {attributes.map((attribute, index) => (
            <WorkItemAttributesTableRow
              key={attribute.name + index}
              index={index}
              attribute={attribute}
              onEdit={onEdit}
              onDelete={onDelete}
              onDrop={handleDrop}
            />
          ))}
        </div>
      </DndProvider>
    </div>
  )
}

export { WorkItemAttributesTable }
