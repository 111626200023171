import { ColumnDef } from '@tanstack/react-table'
import { clsx } from 'clsx'
import { isSameDay } from 'date-fns'
import { useMemo } from 'react'
import { Badge } from 'components/Badge'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import { IThreadCheckboxes, IThreadListItem } from 'types/thread'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  data: IThreadListItem[]
  loading: boolean
  checkboxes: IThreadCheckboxes
  onCheck: (id: string, checked: boolean) => void
  onRowClick: (row?: IThreadListItem) => void
}
function ThreadsTable({
  data = [],
  loading = false,
  checkboxes,
  onCheck,
  onRowClick,
}: Props) {
  const rows = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        rowClassName: item.isRead ? '!bg-grey-50' : '',
      })),
    [data]
  )

  const columns: ColumnDef<IThreadListItem>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        size: 40,
        cell: ({ row }) => {
          const { id, isRead } = row.original

          return (
            <Flex
              className="absolute w-10 h-[42px] cursor-default -translate-x-2 -translate-y-1/2"
              alignItems="center"
              justifyContent="center"
              onClick={(e) => e.stopPropagation()}
            >
              <Checkbox
                className="m-0"
                checked={
                  // if all items selected and this item is not excluded
                  (checkboxes.mode === 'all' &&
                    !checkboxes.excludeIds.includes(id)) ||
                  // if read items selected and this item is read and not excluded or included manually
                  (checkboxes.mode === 'read' &&
                    ((isRead && !checkboxes.excludeIds.includes(id)) ||
                      checkboxes.includeIds.includes(id))) ||
                  // if unread items selected and this item is unread and not excluded or included manually
                  (checkboxes.mode === 'unread' &&
                    ((!isRead && !checkboxes.excludeIds.includes(id)) ||
                      checkboxes.includeIds.includes(id))) ||
                  // if this item is included manually
                  (checkboxes.mode === null &&
                    checkboxes.includeIds.includes(id))
                }
                onChange={(e) => onCheck(id, e.target.checked)}
                onClick={(e) => e.stopPropagation()}
              />
            </Flex>
          )
        },
      },
      {
        accessorKey: 'name',
        size: 260,
        cell: ({ row }) => {
          const mail = row.original
          return (
            <div
              className={clsx(
                'overflow-hidden text-ellipsis whitespace-nowrap',
                mail.isRead && 'text-grey-700',
                !mail.isRead && 'text-grey-900 font-bold'
              )}
            >
              {mail.recipients.map((recipient) => recipient).join(', ')}
            </div>
          )
        },
      },
      {
        accessorKey: 'text',
        cell: ({ row }) => {
          const { subject, body, isRead, isDraft } = row.original

          return (
            <Flex>
              {isDraft && (
                <Badge
                  color="red"
                  className="!bg-white-100 !text-red-100 !border-red-100 border-solid border-t border-b border-r border-l"
                >
                  Draft
                </Badge>
              )}
              <div
                className={clsx(
                  'truncate max-w-[35%]',
                  isRead && 'text-grey-700',
                  !isRead && 'text-grey-900 font-bold'
                )}
              >
                {subject || '(no subject)'}
              </div>
              <div className="text-grey-700 truncate">{body}</div>
            </Flex>
          )
        },
      },
      {
        accessorKey: 'date',
        size: 100,
        meta: {
          align: 'right',
        },
        cell: ({ row }) => {
          const { date, isRead } = row.original

          return (
            <div className={clsx('text-grey-900', !isRead && 'font-bold')}>
              {isSameDay(date, Date.now())
                ? friendlyDateTime(date, 'h:mm a')
                : friendlyDateTime(date, 'MMM d')}
            </div>
          )
        },
      },
    ],
    [checkboxes]
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={rows}
      loading={loading}
      onClick={onRowClick}
    />
  )
}
export { ThreadsTable }
