import { request } from 'services/request'
import {
  Person,
  PersonDetails,
  PersonRole,
  PersonTitle,
  Sort,
  Address,
} from 'types'
import { Search, Filter, Meta, Pagination } from 'types/table'
import { updateRequestTable } from 'utils/api/table-parser'

const roles = [
  'Accountant',
  'Appraisal POC',
  'Attorney',
  'Borrower Attorney',
  'Broker Office',
  'Broker Processor',
  'Closing Assistant',
  'Escrow Office',
  'General Contractor',
  'Inspector',
  'Insurance Agent',
  'Investor',
  'Point of Contact',
  'Property Inspector',
  'Property Manager',
  'Realtor',
  'Referrer',
  'Servicer',
  'Surveyor',
  'Title Agent',
  'Other',
]
type Role = (typeof roles)[number]

type Vendor = Person & {
  role: Role | undefined
}
type VendorDetails = PersonDetails

type NewVendor = Omit<Vendor, 'id'>

const getVendors = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ vendors: Vendor[]; meta: Meta }> => {
  const {
    data: { vendors, pagination },
  } = await request.get('/vendor', {
    params: updateRequestTable(params),
  })
  return { vendors, meta: pagination }
}

const getVendor = async (id: string): Promise<Vendor> => {
  const {
    data: { vendor },
  } = await request.get(`/vendor/${id}`)
  return vendor
}

const getVendorFilters = async (
  columns: string[]
): Promise<Record<string, any>> => {
  const { data } = await request.get(
    `/vendor/filters?columns=${columns.join(',')}`
  )
  return data
}

const addVendor = async (payload: Omit<Vendor, 'id'>): Promise<Vendor> => {
  const {
    data: { vendor },
  } = await request.post('/vendor', payload)
  return vendor
}

const editVendor = async (vendor: Partial<Vendor>) => {
  return request.patch(`/vendor/${vendor.id}`, vendor)
}

const removeVendor = async (id: string) => {
  return request.delete(`/vendor/${id}`)
}

const linkAccount = async ({
  individualId,
  entityId,
  role,
  title,
}: {
  individualId: string
  entityId: string
  role: PersonRole
  title: PersonTitle
}): Promise<Vendor> => {
  const {
    data: { vendor },
  } = await request.put(`/vendor/${entityId}/manager`, {
    id: individualId,
    role,
    title,
  })
  return vendor
}

const updateLinkAccount = async ({
  individualId,
  entityId,
  role,
  title,
}: {
  individualId: string
  entityId: string
  role?: PersonRole
  title?: PersonTitle
}): Promise<Vendor> => {
  const {
    data: { vendor },
  } = await request.patch(`/vendor/${entityId}/manager/${individualId}`, {
    id: individualId,
    role,
    title,
  })
  return vendor
}

const unlinkAccount = async ({
  individualId,
  entityId,
}: {
  individualId: string
  entityId: string
}): Promise<Vendor> => {
  const {
    data: { vendor },
  } = await request.delete(`/vendor/${entityId}/manager/${individualId}`)
  return vendor
}

const addAddress = ({ id, address }: { id: string; address: Address }) => {
  return request.post(`/vendor/${id}/address`, address)
}

const updateAddress = ({
  id,
  addressId,
  address,
}: {
  id: string
  addressId: string
  address: Address
}) => {
  return request.patch(`/vendor/${id}/address/${addressId}`, address)
}

const updateOwners = async ({
  id,
  owners,
}: {
  id: string
  owners: string[]
}): Promise<Person> => {
  const {
    data: { vendor },
  } = await request.put(`/vendor/${id}/owner`, { owners })

  return vendor
}

export type { Vendor, NewVendor, Role, VendorDetails }
export {
  getVendors,
  getVendor,
  addVendor,
  editVendor,
  removeVendor,
  getVendorFilters,
  roles,
  linkAccount,
  updateLinkAccount,
  unlinkAccount,
  addAddress,
  updateAddress,
  updateOwners,
}
