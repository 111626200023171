import { uniq } from 'lodash'
import { useCallback, useState, useMemo, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ColumnDropdown } from './ColumnDropdown'
import { DropdownItem } from './DropdownItem'
import { ISortItem, sortConfig } from './config'
import { sortValueToUrl, urlToSortValue } from './helpers'

function SortDropdown() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<ISortItem[]>(
    urlToSortValue(searchParams.get('sort'))
  )

  const handleAddSortItem = useCallback(
    (item: ISortItem) => {
      setSelectedItems((prev) => [...prev, item])
    },
    [selectedItems]
  )

  const handleDeleteSort = useCallback(
    (item: ISortItem) => {
      setSelectedItems((prev) => prev.filter((option) => option.id !== item.id))
    },
    [selectedItems]
  )

  const handleChangeItem = useCallback(
    (currentItem: ISortItem, nextItem: ISortItem) => {
      setSelectedItems((selectedItems) =>
        selectedItems.map((item) =>
          item.id === currentItem.id ? nextItem : item
        )
      )
    },
    []
  )

  const handleDrop = useCallback(
    async (dragItem: ISortItem, targetItem: ISortItem) => {
      if (dragItem && targetItem) {
        const reorderedItems = [...selectedItems]
        const itemIndex = reorderedItems.findIndex(
          ({ id }) => id === dragItem.id
        )
        reorderedItems.splice(itemIndex, 1)
        const targetIndex = reorderedItems.findIndex(
          ({ id }) => id === targetItem.id
        )
        reorderedItems.splice(targetIndex + 1, 0, dragItem)

        setSelectedItems(reorderedItems)
      }
    },
    [selectedItems]
  )

  const handleApply = useCallback(() => {
    searchParams.set('sort', sortValueToUrl(selectedItems))
    setSearchParams(searchParams, { replace: true })
    setIsOpen(false)
  }, [selectedItems, searchParams, setSearchParams])

  const handleReset = useCallback(() => {
    setSelectedItems([])
    searchParams.delete('sort')
    setSearchParams(searchParams, { replace: true })
    setIsOpen(false)
  }, [searchParams])

  const sortIcon = useMemo(() => {
    const directions = uniq(selectedItems.map(({ dir }) => dir))
    if (directions.length === 0 || directions.length > 1) {
      return IconName.sort
    } else if (directions[0] === 'asc') {
      return IconName.sortAsc
    } else {
      return IconName.sortDesc
    }
  }, [selectedItems])

  useEffect(() => {
    if (!isOpen) {
      setSelectedItems(urlToSortValue(searchParams.get('sort')))
    }
  }, [isOpen, searchParams])

  return (
    <Dropdown
      open={isOpen}
      onOpenChange={setIsOpen}
      trigger={
        <Button variant="ghost">
          <Icon name={sortIcon} />
          {(selectedItems.length === 1 &&
            sortConfig.find((config) => config.id === selectedItems[0].id)
              ?.label) ||
            'Sort'}
          {selectedItems.length > 1 && (
            <Flex
              alignItems="center"
              justifyContent="center"
              className="bg-purple-50 text-purple-300 rounded-sm px-1 h-[22px]"
            >
              {selectedItems.length}
            </Flex>
          )}
        </Button>
      }
    >
      <Flex gap={0} className="p-3 min-w-[400px]" stack>
        {selectedItems.length > 0 && (
          <DndProvider backend={HTML5Backend}>
            <Flex stack gap={0} className="py-1 mb-4">
              {selectedItems.map((item) => (
                <DropdownItem
                  key={item.id}
                  item={item}
                  selectedItems={selectedItems}
                  onDelete={handleDeleteSort}
                  onChange={(nextItem) => handleChangeItem(item, nextItem)}
                  onDrop={handleDrop}
                />
              ))}
            </Flex>
          </DndProvider>
        )}
        <Flex justifyContent="space-between">
          <ColumnDropdown
            onSelect={handleAddSortItem}
            selectedOptions={selectedItems}
          />
          {selectedItems.length > 0 && (
            <Flex gap={16}>
              <Button variant="ghost" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="primary" onClick={handleApply}>
                Apply
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Dropdown>
  )
}

export { SortDropdown }
