import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useDismiss,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react'
import clsx from 'clsx'
import { isNull } from 'lodash'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import styles from './styles.module.scss'

interface Props {
  className: string
  placeholder?: string
  name?: string
  disabled?: boolean
  value: string
  onChange: (value: string) => void
  onSelect?: (value: google.maps.places.AutocompletePrediction) => void
  autoFocus?: boolean
  'data-testid'?: string
}

const PlacesAutocomplete = ({
  className,
  name,
  value,
  disabled,
  placeholder,
  onChange,
  onSelect,
  autoFocus,
  'data-testid': dataTestId,
}: Props) => {
  const {
    ready,
    suggestions: { status, data },
    clearSuggestions,
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us', 'ca', 'pr'] },
      language: 'en',
    },
    debounce: 300,
  })
  const [open, setOpen] = useState(false)
  const listRef = useRef<(HTMLElement | null)[]>([])
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift(), flip(), offset(4)],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useRole(context, { role: 'listbox' }),
      useListNavigation(context, {
        listRef: listRef,
        activeIndex: activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
      }),
      useDismiss(context),
    ]
  )

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onChange(e.target.value)
    setOpen(true)
  }

  const handleSelect = (data: google.maps.places.AutocompletePrediction) => {
    setValue(data.structured_formatting.main_text, false)
    if (onSelect) {
      onSelect(data)
    } else {
      onChange(data.structured_formatting.main_text)
    }
    clearSuggestions()
    setOpen(false)
  }

  useEffect(() => {
    if (autoFocus) {
      setTimeout(
        () => (context.refs.reference.current as HTMLInputElement)?.focus(),
        1
      )
    }
  }, [])

  return (
    <>
      <input
        className={className}
        name={name}
        value={value}
        onChange={handleInput}
        disabled={!ready || disabled}
        placeholder={placeholder}
        autoComplete="off"
        data-testid={dataTestId}
        {...getReferenceProps({
          ref: refs.setReference,
          onKeyDown(event) {
            if (
              event.key === 'Enter' &&
              !isNull(activeIndex) &&
              data[activeIndex]
            ) {
              event.preventDefault()
              handleSelect(data[activeIndex])
              setActiveIndex(null)
              setOpen(false)
            }
          },
        })}
      />
      {status === 'OK' && open && (
        <FloatingPortal>
          <FloatingFocusManager context={context} initialFocus={-1}>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 2,
                  width: (context.refs.reference.current as HTMLInputElement)
                    ?.offsetWidth,
                },
              })}
              className={styles.dropdown}
            >
              {data.map((suggestion, index) => {
                return (
                  <div
                    key={suggestion.place_id}
                    className={clsx(styles.item, {
                      [styles.itemActive]: activeIndex === index,
                    })}
                    {...getItemProps({
                      ref(node) {
                        listRef.current[index] = node
                      },
                      onClick() {
                        handleSelect(suggestion)
                        setOpen(false)
                      },
                    })}
                  >
                    {suggestion.description}
                  </div>
                )
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

export default PlacesAutocomplete
