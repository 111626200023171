import clsx from 'clsx'
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  startOfDay,
  isAfter,
  parseISO,
} from 'date-fns'
import { capitalize } from 'lodash'
import { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Text } from 'components/Text'
import { useUpdateLoanOwners } from 'hooks/use-loans'
import { useSession } from 'hooks/use-session'
import { IPipelineSettingsTime } from 'services/api/session'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { formatRelativeDate, friendlyDate } from 'utils/date'
import { SubstatusBadge } from './SubstatusBadge'

interface Props {
  loan: Loan
}

const checkTimeAlert = (time: string | null) => {
  return time ? isAfter(new Date(), time) : false
}

function LoansBoardItem({ loan: propLoan }: Props) {
  const navigate = useNavigate()
  const { isAdminManager, user } = useSession()
  const { mutate: updateOwners } = useUpdateLoanOwners()
  const [loan, setLoan] = useState<Loan>(propLoan)
  const timerType = user?.client?.pipelineSettings
    ?.time as IPipelineSettingsTime

  const [timer, setTimer] = useState<string>()
  const [timeInterval, setTimeInterval] = useState(24 * 60 * 60 * 1000) // 1 day in milliseconds
  const borrower = loan.borrowers.find(({ borrow }) => borrow.primary)
  const { isVisible, options, selectedOwnerIds } = useOwnerSelector({
    selectedOwners: loan?.owners || [],
  })
  const [isAttention, setIsAttention] = useState(
    checkTimeAlert(loan.times.attention)
  )
  const [isCritical, setIsCritical] = useState(
    checkTimeAlert(loan.times.critical)
  )
  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id: loan.id, owners }, { onSuccess: setLoan })
    },
    [loan.id, updateOwners]
  )
  const isReadOnly = loan.lock || !isAdminManager
  const updateTimer = useCallback(() => {
    switch (timerType) {
      case 'since_substatus':
        setTimer(
          formatRelativeDate(loan.times.statusChanged, {
            short: true,
            absolute: true,
          })
        )
        break
      case 'date_closing':
        if (loan.dateClosing) {
          setTimer(
            formatRelativeDate(startOfDay(parseISO(loan.dateClosing)), {
              short: true,
            })
          )
        } else {
          setTimer(undefined)
        }
        break
      default: {
        setTimer(undefined)
        break
      }
    }
  }, [loan, timerType])

  const checkInterval = useCallback(() => {
    const time =
      timerType === 'since_substatus'
        ? loan.times.statusChanged
        : loan.dateClosing
    if (Math.abs(differenceInSeconds(new Date(), time)) < 60) {
      setTimeInterval(1000) // 1 sec in milliseconds
    } else if (Math.abs(differenceInMinutes(new Date(), time)) < 60) {
      setTimeInterval(60 * 1000) // 1 min in milliseconds
    } else if (Math.abs(differenceInHours(new Date(), time)) < 24) {
      setTimeInterval(60 * 60 * 1000) // 1 hour in milliseconds
    }
  }, [loan, timerType])

  useEffect(() => {
    setLoan(propLoan)
  }, [propLoan])

  useEffect(() => {
    checkInterval()
    updateTimer()
  }, [checkInterval, updateTimer, loan])

  useEffect(() => {
    const interval = setInterval(() => {
      checkInterval()
      updateTimer()
      setIsAttention(checkTimeAlert(loan.times.attention))
      setIsCritical(checkTimeAlert(loan.times.critical))
    }, timeInterval)
    return () => clearInterval(interval)
  }, [checkInterval, loan, timeInterval, timerType, updateTimer])

  return (
    <div
      className={clsx(
        'px-2.5 py-2 overflow-hidden min-w-[250px]',
        timer &&
          isAttention &&
          !isCritical &&
          'bg-gradient-to-b from-[#FFEBD3] to-white-100 rounded [box-shadow:0px_0px_4px_0px_#EB966E_inset,0px_2px_1px_0px_rgba(33,39,42,0.08)]',
        timer &&
          isCritical &&
          '!bg-gradient-to-b from-[#FFE5E5] to-white-100 rounded [box-shadow:0px_0px_4px_0px_#C6393C_inset,0px_2px_1px_0px_rgba(33,39,42,0.08)]'
      )}
      onClick={() => navigate(pathTo('loan', loan.id))}
    >
      <Flex alignItems="center" justifyContent="space-between" className="mb-2">
        <Text className="font-bold truncate max-w-[180px]">{loan.name}</Text>
        {timer && (
          <Flex gap={4} alignItems="center">
            <Icon
              name={isCritical || isAttention ? IconName.flag : IconName.watch}
              className={clsx(
                'text-grey-600',
                isAttention && 'text-peach-100',
                isCritical && 'text-red-50'
              )}
            />
            <span
              className={clsx(
                'text-grey-600',
                isAttention && 'text-peach-100',
                isCritical && 'text-red-50'
              )}
            >
              {timer}
            </span>
          </Flex>
        )}
      </Flex>

      {loan.substatus && (
        <SubstatusBadge
          substatus={loan.substatus}
          className="inline-flex bg-white-100 max-w-[160px]"
        />
      )}
      <Flex
        stack
        gap={6}
        className={`text-grey-700 ${borrower?.name || loan.dateClosing || loan.product?.name ? 'mt-3' : ''}`}
      >
        {borrower?.name && (
          <Flex alignItems="center" gap={6}>
            <Icon name={IconName.borrower} className="w-[13px] h-[13px]" />
            <Text variant="s">{borrower.name}</Text>
          </Flex>
        )}
        {loan.dateClosing && (
          <Flex alignItems="center" gap={6}>
            <Icon name={IconName.calendar} className="w-[13px] h-[13px]" />
            <Text variant="s">{friendlyDate(loan.dateClosing)}</Text>
          </Flex>
        )}
        {loan.product?.name && (
          <Flex alignItems="center" gap={6}>
            <Icon
              name={IconName.productNavigation}
              className="w-[13px] h-[13px]"
            />
            <Text variant="s">{loan.product.name}</Text>
          </Flex>
        )}
      </Flex>

      <Flex className={loan.amount || loan.useOfFunds ? 'mt-3' : ''}>
        {loan.amount || loan.useOfFunds ? (
          <Badge color="gray" className="flex font-bold whitespace-pre-wrap">
            {loan.amount && (
              <Text variant="s" className="text-grey-900">
                {formatUsd(loan.amount)}
              </Text>
            )}
            {loan.amount && loan.useOfFunds && ' - '}
            {loan.useOfFunds && (
              <Text variant="s" className="truncate text-grey-700">
                {capitalize(loan.useOfFunds)}
              </Text>
            )}
          </Badge>
        ) : (
          <div />
        )}
      </Flex>
      {isVisible &&
        (!isReadOnly || (!!selectedOwnerIds.length && isReadOnly)) && (
          <div className="mt-1.5">
            <OwnerSelector
              userOptions={options}
              selectedUsers={selectedOwnerIds}
              onChange={handleOwnersChange}
              readOnly={isReadOnly}
            />
          </div>
        )}
    </div>
  )
}

export default LoansBoardItem
