export const KEY_APPLICATIONS = 'applications'
export const KEY_APPLICATION_TEMPLATES = 'application-templates'
export const KEY_APPLICATION_FIELDS = 'application-fields'
export const KEY_DRAFT_APPLICATIONS = 'draft-applications'
export const KEY_CUSTOM_APPLICATION = 'custom-application'
export const KEY_PERSONS = 'persons'
export const KEY_VENDORS = 'vendors'
export const KEY_BORROWERS = 'borrowers'
export const KEY_DOCUMENTS = 'documents'
export const KEY_ACTIVITIES = 'activities'
export const KEY_DOCUMENT_COMMENTS = 'document-comments'
export const KEY_PRODUCTS = 'products'
export const KEY_LOANS = 'loans'
export const KEY_LOAN_FUNDING_SOURCES = 'loan-founding-sources'
export const KEY_LOAN_TRANSACTIONS = 'loan-transactions'
export const KEY_LOAN_PAYMENT_CALCULATE = 'loan-payment-calculate'
export const KEY_LOAN_PAYMENTS = 'loan-payments'
export const KEY_LOAN_STATEMENTS = 'loan-statements'
export const KEY_LOAN_SUBSTATUS = 'loan-substatus'
export const KEY_LOAN_BUDGET = 'loan-budget'
export const KEY_LOAN_MODIFICATION = 'loan-modification'
export const KEY_PAYMENTS = 'payments'
export const KEY_LOAN_CHARGES = 'loan-charges'
export const KEY_LOAN_COMMENTS = 'loan-comments'
export const KEY_LOAN_INVESTORS = 'loan-investors'
export const KEY_MEMBERS = 'members'
export const KEY_USERS = 'users'
export const KEY_INVESTORS = 'investors'
export const KEY_INVESTOR_STATEMENTS = 'investor-statements'
export const KEY_PAYOUTS = 'payouts'
export const KEY_PAYMENTS_DUE = 'payments-due'
export const KEY_TRUSTS = 'trusts'
export const KEY_BANKING = 'banking'
export const KEY_TRANSACTIONS = 'transactions'
export const KEY_REPORT = 'report'
export const KEY_CUSTOM_REPORT = 'custom-report'
export const KEY_OFFERINGS = 'offerings'
export const KEY_QUOTES = 'quotes'
export const KEY_CLIENT = 'KEY_CLIENT'
export const KEY_NOTIFICATIONS = 'notifications'
export const KEY_NOTIFICATION_SETTINGS = 'notification-settings'
export const KEY_LENDER_OF_RECORD = 'lender-of-record'
export const KEY_TEMPLATE = 'template'
export const KEY_FUNDS = 'funds'
export const KEY_TASKS = 'tasks'
export const KEY_TASK_TEMPLATES = 'task-templates'
export const KEY_CONNECTIONS = 'connections'
export const KEY_CUSTOM_FIELDS = 'custom-fields'
export const KEY_AUTOMATIONS = 'automations'
export const KEY_AUTOMATIONS_LOG = 'automations-log'
export const KEY_ROLES = 'roles'
export const KEY_THREADS = 'threads'
export const KEY_THREAD_SIGNATURES = 'thread-signatures'
export const KEY_BUDGET_WORKSHEET = 'budget-worksheet'
