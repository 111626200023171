import { request } from 'services/request'
import { IMailSignature } from 'types'

const getMailSignatures = async (): Promise<IMailSignature[]> => {
  const {
    data: { signatures },
  } = await request.get('/mailsignature')
  return signatures
}

const addMailSignature = async (payload: {
  name: string
  body: string
}): Promise<IMailSignature> => {
  const {
    data: { signature },
  } = await request.post('/mailsignature', payload)
  return signature
}

const updateMailSignature = async ({
  id,
  ...payload
}: {
  id: string
  name?: string
  body?: string
}): Promise<IMailSignature> => {
  const {
    data: { signature },
  } = await request.patch(`/mailsignature/${id}`, payload)
  return signature
}

const removeMailSignature = async (id: string) => {
  return request.delete(`/mailsignature/${id}`)
}

export {
  getMailSignatures,
  addMailSignature,
  updateMailSignature,
  removeMailSignature,
}
