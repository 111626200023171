import { useState } from 'react'
import {
  useAddMailSignature,
  useMailSignatures,
  useUpdateMailSignature,
} from 'admin/hooks/use-thread-signature'
import { EmptySignatures } from 'admin/pages/Settings/MailSettings/MailSignatures/EmptySignatures'
import { MailSignatureItem } from 'admin/pages/Settings/MailSettings/MailSignatures/MailSignatureItem'
import { ModalSignature } from 'admin/pages/Settings/MailSettings/MailSignatures/ModalSignature'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'

function MailSignatures() {
  const { data: signatures, isPending } = useMailSignatures()
  const [editSignatureId, setEditSignatureId] = useState<string | null>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { mutate: add, isPending: isAdding } = useAddMailSignature()
  const { mutate: update, isPending: isUpdating } = useUpdateMailSignature()

  return (
    <>
      <Panel
        title="Email Signatures"
        className="max-w-panel"
        loading={isPending}
        actionChildren={
          <Button onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.addCircle} />
            Add Signature
          </Button>
        }
      >
        {!isPending && !signatures?.length && (
          <EmptySignatures onClick={() => setIsModalVisible(true)} />
        )}
        {!isPending && !!signatures?.length && (
          <>
            <Flex stack gap={16}>
              {signatures.map((signature) => (
                <MailSignatureItem
                  key={signature.id}
                  signature={signature}
                  onEdit={(id) => {
                    setEditSignatureId(id)
                    setIsModalVisible(true)
                  }}
                />
              ))}
            </Flex>
            <Button
              variant="ghost"
              className="mt-4"
              onClick={() => setIsModalVisible(true)}
            >
              <Icon name={IconName.addCircle} className="text-grey-600" />
              New Signature
            </Button>
          </>
        )}
      </Panel>
      {isModalVisible && (
        <ModalSignature
          signature={signatures?.find(({ id }) => id === editSignatureId)}
          onSave={(signature) => {
            if (editSignatureId) {
              update(
                { id: editSignatureId, ...signature },
                {
                  onSuccess: () => {
                    setEditSignatureId(null)
                    setIsModalVisible(false)
                  },
                }
              )
            } else {
              add(signature, {
                onSuccess: () => {
                  setIsModalVisible(false)
                },
              })
            }
            setIsModalVisible(false)
          }}
          saving={isAdding || isUpdating}
          onClose={() => {
            setEditSignatureId(null)
            setIsModalVisible(false)
          }}
        />
      )}
    </>
  )
}

export { MailSignatures }
