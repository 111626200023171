import { useFormikContext } from 'formik'
import { get, compact, isString } from 'lodash'
import { getCountries, getCountryByNameOrShortName } from 'node-countries'
import { ReactNode, useMemo } from 'react'
import { getGeocode } from 'use-places-autocomplete'
import { Flex } from 'components/Flex'
import { Field, Address as AddressField, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Text } from 'components/Text'

interface Props {
  prefix?: string
  className?: string
  autoFocus?: boolean
  disabled?: boolean
  heading?: string
  description?: string
  children?: ({
    getPath,
    handleAddressSelect,
    handleCountryChange,
    countryOptions,
    stateOptions,
  }: any) => ReactNode
}

function FormAddress({
  children,
  className,
  autoFocus,
  disabled,
  prefix = '',
  heading,
  description,
}: Props) {
  const { setFieldTouched, values, setFieldValue } = useFormikContext<any>()

  const getPath = (key: string) =>
    compact([...prefix.split('.'), key]).join('.')
  const countryOptions = useMemo(
    () =>
      getCountries()
        .filter(({ status }) => status === 'assigned')
        .map((country) => ({
          value: country.name,
          label: country.name,
        })),
    []
  )
  const stateOptions = useMemo(
    () => [
      { label: 'Select...', value: '' },
      ...(
        getCountryByNameOrShortName(get(values, getPath('country')))
          ?.provinces || []
      ).map((province) => ({
        value: province.name,
        label: province.name,
      })),
    ],
    [values]
  )

  const handleAddressSelect = async ({
    structured_formatting,
    place_id,
  }: google.maps.places.AutocompletePrediction) => {
    const [geocoderResult] = await getGeocode({ placeId: place_id })
    await setFieldValue(getPath('street1'), structured_formatting.main_text)
    await setFieldTouched(getPath('street1'), false, false)
    geocoderResult.address_components.forEach((addressComponent) => {
      if (
        addressComponent.types.includes('locality') ||
        addressComponent.types.includes('sublocality')
      ) {
        setFieldValue(getPath('city'), addressComponent.long_name)
        setFieldTouched(getPath('city'), false, false)
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        setFieldValue(getPath('state'), addressComponent.long_name)
        setFieldTouched(getPath('state'), false, false)
      }
      if (addressComponent.types.includes('postal_code')) {
        setFieldValue(getPath('zipcode'), addressComponent.long_name)
        setFieldTouched(getPath('zipcode'), false, false)
      }
      if (addressComponent.types.includes('country')) {
        setFieldValue(getPath('country'), addressComponent.long_name)
        setFieldTouched(getPath('country'), false, false)
      }
    })
  }

  const handleCountryChange = (country: string) => {
    setFieldValue(getPath('country'), country)
    setFieldValue(getPath('state'), '')
  }

  return children ? (
    <>
      {children({
        getPath,
        handleAddressSelect,
        handleCountryChange,
        countryOptions,
        stateOptions,
      })}
    </>
  ) : (
    <Grid columnGap={24} className={className}>
      {(heading || description) && (
        <Grid.Item xs={12}>
          <Flex stack gap={4} className="mb-6">
            {heading && (
              <div className="text-xl font-bold text-grey-900">{heading}</div>
            )}
            {description && (
              <Text variant="l" className="text-grey-700">
                {description}
              </Text>
            )}
          </Flex>
        </Grid.Item>
      )}
      <Grid.Item xs={12}>
        <AddressField
          name={getPath('street1')}
          label="Street Address 1"
          placeholder="Enter an address"
          types={['geocode']}
          onSelect={handleAddressSelect}
          autoFocus={autoFocus}
          disabled={disabled}
          data-testid="address-street1"
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Field
          name={getPath('street2')}
          disabled={disabled}
          label="Street Address 2"
          placeholder="Additional street name"
          data-testid="address-street2"
        />
      </Grid.Item>
      <Grid.Item xs={12} sm={6}>
        <Field
          name={getPath('city')}
          disabled={disabled}
          label="City"
          placeholder="City"
          data-testid="address-city"
        />
      </Grid.Item>
      <Grid.Item xs={12} sm={6}>
        <Select
          name={getPath('state')}
          disabled={disabled}
          label="Province/State"
          placeholder="Province/State"
          portal
          options={stateOptions}
          data-testid="address-state"
        />
      </Grid.Item>
      <Grid.Item xs={12} sm={6}>
        <Field
          name={getPath('zipcode')}
          disabled={disabled}
          label="Postal Code/Zip"
          placeholder="Postal Code/Zip"
          data-testid="address-zipcode"
        />
      </Grid.Item>
      <Grid.Item xs={12} sm={6}>
        <Select
          name={getPath('country')}
          disabled={disabled}
          label="Country"
          placeholder="Country"
          portal
          onChange={(option) => {
            handleCountryChange(isString(option?.value) ? option.value : '')
          }}
          options={countryOptions}
          data-testid="address-country"
        />
      </Grid.Item>
    </Grid>
  )
}

export default FormAddress
