import { Toggle as InputToggle } from '../Toggle'
import { CustomFieldProps } from './Field'
import styles from './styles.module.scss'
import { Field } from './index'

function Toggle(toggleProps: CustomFieldProps) {
  return (
    <Field className={styles.checkbox} {...toggleProps}>
      {({ field }: any) => (
        <InputToggle {...field} disabled={toggleProps.disabled} />
      )}
    </Field>
  )
}

export default Toggle
