import { request, requestWithoutToken } from 'services/request'
import { LoanDocument, Application, IApplicationScheme } from 'types'
import {
  updateResponseApplication,
  updateRequestApplication,
  updateRequestApplicationScheme,
  updateResponseApplicationScheme,
} from 'utils/api/application-parser'

const addGuestApplication = async (
  payload: Partial<Application> & { clientId: string }
): Promise<{ application: Application; authToken: string }> => {
  const {
    data: { application, authToken },
  } = await requestWithoutToken.post('/application', payload)
  return { application: updateResponseApplication(application), authToken }
}

const getApplication = async (id: string): Promise<Application> => {
  const {
    data: { application },
  } = await request.get(`/application/${id}`)
  return updateResponseApplication(application)
}

const getApplicationTemplate = async (
  clientId: string
): Promise<Application> => {
  const {
    data: { application },
  } = await requestWithoutToken.post('/application/template', { clientId })
  return updateResponseApplication(application)
}

const deleteApplicationTemplate = async (): Promise<Application> => {
  const {
    data: { application },
  } = await request.delete('/application/template')
  return updateResponseApplication(application)
}

const getDraftApplications = async (): Promise<Application[]> => {
  const {
    data: { applications },
  } = await request.get('/application/draft')
  return applications
}

const getApplications = async (): Promise<Application[]> => {
  const {
    data: { applications },
  } = await request.get('/application')
  return applications.filter(({ status }) => status !== 'draft')
}

const updateApplication = async ({
  id,
  ...data
}: Partial<Application>): Promise<Application> => {
  const {
    data: { application },
  } = await request.patch(`/application/${id}`, updateRequestApplication(data))
  return updateResponseApplication(application)
}

const deleteApplication = (id: string) => {
  return request.delete(`/application/${id}`)
}

const deleteDraftApplication = (id: string) => {
  return request.delete(`/application/draft/${id}`)
}

const signApplication = async ({
  id,
}: {
  id: string
}): Promise<Application> => {
  const {
    data: { application },
  } = await request.post(`/application/draft/${id}/sign`, {})
  return updateResponseApplication(application)
}

const signGuestApplication = async ({
  id,
  clientId,
}: {
  id: string
  clientId: string
}): Promise<Application> => {
  const {
    data: { application },
  } = await requestWithoutToken.post(`/application/draft/${id}/sign`, {
    clientId,
  })
  return updateResponseApplication(application)
}

const getApplicationDocuments = async ({
  id,
}: {
  id: string
}): Promise<LoanDocument[]> => {
  const {
    data: { documents },
  } = await request.get(`/application/${id}/document`)
  return documents
}

const getDraftApplication = async (
  applicationId: string
): Promise<{ data: IApplicationScheme; id: string; dateSubmitted: string }> => {
  const {
    data: { application },
  } = await request.get(`/application/draft/${applicationId}`)
  return {
    ...application,
    data: updateResponseApplicationScheme(application.data),
  }
}

const addDraftApplication = async (
  clientId: string,
  data: IApplicationScheme,
  isGuest: boolean = false
): Promise<{ data: IApplicationScheme; id: string; dateSubmitted: string }> => {
  const requestMethod = isGuest ? requestWithoutToken : request
  const {
    data: { application },
  } = await requestMethod.post('/application/draft', {
    clientId,
    data: updateRequestApplicationScheme(data),
  })
  return {
    ...application,
    data: updateResponseApplicationScheme(application.data),
  }
}

const updateDraftApplication = async (
  clientId: string,
  applicationId: string,
  data: IApplicationScheme
): Promise<{ data: IApplicationScheme; id: string; dateSubmitted: string }> => {
  const {
    data: { application },
  } = await request.patch(`/application/draft/${applicationId}`, {
    clientId,
    data: updateRequestApplicationScheme(data),
  })
  return {
    ...application,
    data: updateResponseApplicationScheme(application.data),
  }
}

export {
  addGuestApplication,
  getApplication,
  getDraftApplications,
  getApplicationTemplate,
  updateApplication,
  deleteApplication,
  deleteDraftApplication,
  getApplications,
  signApplication,
  signGuestApplication,
  getApplicationDocuments,
  getDraftApplication,
  addDraftApplication,
  updateDraftApplication,
  deleteApplicationTemplate,
}
