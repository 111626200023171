import { orderBy } from 'lodash'
import { request } from 'services/request'
import { LoanModification, Field } from 'types'
import {
  updateRequestFields,
  updateResponseFields,
} from 'utils/api/loan-parser'
import { updateResponseFields as updateResponseProductFields } from 'utils/api/product-parser'

const getLoanModifications = async (
  loanId: string
): Promise<{ modifications: LoanModification[] }> => {
  const {
    data: { modifications },
  } = await request.get(`/loan/${loanId}/modification`)
  return {
    modifications: modifications.map((modification) => ({
      ...modification,
      fields: updateResponseFields(modification.fields),
      ...(modification.document
        ? {
            document: {
              ...modification.document,
              versions: orderBy(
                modification.document.versions,
                'createdAt',
                'desc'
              ),
            },
          }
        : {}),
    })),
  }
}

const getModificationFields = async (): Promise<Field[]> => {
  const {
    data: { fields },
  } = await request.get('/modification/fields')
  return updateResponseProductFields(fields)
}

const getLoanModification = async (
  loanId: string,
  modificationId: string
): Promise<LoanModification> => {
  const {
    data: { modification },
  } = await request.get(`/loan/${loanId}/modification/${modificationId}`)
  return {
    ...modification,
    fields: updateResponseFields(modification.fields),
  }
}

const addLoanModification = async (
  loanId: string,
  data: Partial<LoanModification>
): Promise<LoanModification> => {
  const {
    data: { modification },
  } = await request.post(`/loan/${loanId}/modification`, data)
  return {
    ...modification,
    fields: updateResponseFields(modification.fields),
  }
}

const updateLoanModification = async (
  loanId: string,
  data: LoanModification
): Promise<LoanModification> => {
  const {
    data: { modification },
  } = await request.patch(`/loan/${loanId}/modification/${data.id}`, {
    ...data,
    ...(data.fields ? { fields: updateRequestFields(data.fields) } : {}),
  })
  return {
    ...modification,
    fields: updateResponseFields(modification.fields),
  }
}

const deleteLoanModification = async (
  loanId: string,
  modificationId: string
): Promise<{ success: boolean }> => {
  const { data } = await request.delete(
    `/loan/${loanId}/modification/${modificationId}`
  )
  return data
}

const addLoanModificationDocument = async (
  loanId: string,
  modificationId: string
): Promise<{ modification: Partial<LoanModification> }> => {
  const {
    data: { modification },
  } = await request.put(
    `/loan/${loanId}/modification/${modificationId}/document`
  )
  return {
    modification,
  }
}

const deleteLoanModificationDocument = async (
  loanId: string,
  modificationId: string
): Promise<{ success: boolean }> => {
  const { data } = await request.delete(
    `/loan/${loanId}/modification/${modificationId}/document`
  )
  return data
}

export {
  getLoanModifications,
  getLoanModification,
  addLoanModification,
  updateLoanModification,
  deleteLoanModification,
  addLoanModificationDocument,
  deleteLoanModificationDocument,
  getModificationFields,
}
