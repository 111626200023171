import clsx from 'clsx'
import { Form as FormFormik, FormikFormProps, useFormikContext } from 'formik'
import { createElement, useEffect } from 'react'
import styles from './styles.module.scss'

interface FormProps extends FormikFormProps {
  onChange?: (values: any) => void
  modal?: boolean
}

type HTMLFormProps = React.FormHTMLAttributes<HTMLFormElement>

interface FormProps
  extends FormikFormProps,
    Omit<HTMLFormProps, keyof FormikFormProps> {
  onChange?: (values: any) => void
  modal?: boolean
}

function Form({ modal, onChange, ...props }: FormProps) {
  const { values } = useFormikContext()

  useEffect(() => {
    onChange && onChange(values)
  }, [values])

  return createElement(FormFormik, {
    ...props,
    className: clsx(props.className, { [styles.formModal]: modal }),
  })
}

export default Form
