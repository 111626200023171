import clsx from 'clsx'
import { ReactNode, useEffect, useState } from 'react'
import { Option } from 'components/Form'
import { Field, FieldType } from 'types'
import { formatField } from 'utils/fields'
import styles from './styles.module.scss'
import { InlineField, SavingIndicator, Undo } from './index'

type Value = string | number | null | undefined
type Status = 'untouched' | 'saving' | 'saved'

interface Props {
  type?: FieldType
  className?: string
  inputClassName?: string
  value: Value
  options?: Option[] | null
  hideEmptyOption?: boolean
  hideUndo?: boolean
  save: (value: Value) => Promise<unknown>
  format?: (
    value,
    { focus, hover, status }: { focus: boolean; hover: boolean; status: Status }
  ) => ReactNode
  loading?: boolean
}

function InlineEditField({
  type = 'text',
  value: initialValue,
  className,
  inputClassName,
  options,
  hideEmptyOption,
  hideUndo,
  save,
  format,
  loading = false,
}: Props) {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [status, setStatus] = useState<Status>('untouched')
  const [value, setValue] = useState<Value>(initialValue)
  const [undoValue, setUndoValue] = useState<Value>(initialValue)

  // update current value to the latest if it's not saving now
  useEffect(() => {
    if (status !== 'saving') {
      setValue(initialValue)
    }
  }, [initialValue])

  // save current value for undo purpose
  useEffect(() => {
    if (focus) {
      setUndoValue(value)
    }
  }, [focus])

  const handleUndo = () => {
    const currentUndoValue = undoValue
    setUndoValue(value)
    setValue(currentUndoValue)
    handleSave(currentUndoValue)
  }

  const handleSave = async (nextValue: Value) => {
    setFocus(false)
    setHover(false)
    if (nextValue !== initialValue) {
      setStatus('saving')
      await save(nextValue)
      setStatus('saved')
    }
  }

  const formattedValue = format
    ? format(value, { hover, focus, status })
    : formatField({
        type: [type],
        property: { value: [value] },
      } as unknown as Field)

  return (
    <div
      className={clsx(styles.inlineEditField, className)}
      onClick={() => {
        setFocus(true)
        setHover(false)
      }}
      onMouseEnter={() => !focus && setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div>
        {formattedValue}
        <div
          contentEditable={!focus}
          spellCheck={false}
          suppressContentEditableWarning={true}
          onFocus={() => setFocus(true)}
          className={styles.contentEditable}
        />
      </div>
      {!focus && hover && (
        <div className={styles.field}>
          <div className={styles.overlay}></div>
          <InlineField
            key={value}
            className={clsx(styles.hover, inputClassName)}
            type={type}
            options={options}
            value={value}
            mode="hover"
            loading={loading}
          />
        </div>
      )}
      {focus && (
        <div className={styles.field}>
          <InlineField
            value={value}
            type={type}
            options={options}
            hideEmptyOption={hideEmptyOption}
            autoFocus
            className={inputClassName}
            onChange={(value) => setValue(value)}
            onBlur={(nextValue) => {
              handleSave(nextValue || value)
            }}
            onEscPress={() => {
              setValue(initialValue)
              setFocus(false)
            }}
            onEnterPress={() => {
              handleSave(value)
            }}
            loading={loading}
          />
        </div>
      )}
      {status !== 'untouched' && <SavingIndicator status={status} />}
      {status !== 'untouched' && !hideUndo && <Undo onClick={handleUndo} />}
    </div>
  )
}

export default InlineEditField
