import clsx from 'clsx'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoanDocument } from 'types'
import { friendlyMime, friendlySize } from 'utils/file'

interface Props {
  document: LoanDocument
  selected: boolean
  onSelect: () => void
}

const ListDocument = ({ document, selected, onSelect }: Props) => {
  const isUploaded = useMemo(() => !!document.size, [document.size])

  return (
    <Flex
      key={document.id}
      gap={8}
      alignItems="center"
      justifyContent="space-between"
      onClick={onSelect}
      className={clsx(
        'ml-8 py-2.5 pr-2 pl-3 text-grey-700 leading-5 cursor-pointer border-0 border-solid border-grey-200 border-b hover:bg-grey-50 hover:text-grey-800',
        selected && 'cursor-pointer bg-purple-50 hover:text-grey-800'
      )}
    >
      <Flex justifyContent="start">
        <div>{document.name}</div>
        <div className="text-sm text-grey-700">
          {isUploaded &&
            `${friendlyMime(document.type || '')} - ${friendlySize(document.size)}`}
        </div>
      </Flex>
      {selected && (
        <Icon name={IconName.circleCheck} className="text-purple-400" />
      )}
    </Flex>
  )
}

export { ListDocument }
