import { getVendors } from 'admin/services/api/vendors'
import { Row, Sort, Filter } from 'types'

const downloadVendors = async (
  sort?: Sort,
  search?: string,
  filter?: Filter
): Promise<Row[]> => {
  const { vendors } = await getVendors({
    sort,
    search,
    filter: {
      isAccount: [true],
      ...(filter || {}),
    },
  })
  return vendors.map((vendor) => ({
    Company: vendor.companyName,
    Name: vendor.name,
    Email: vendor.email,
    Phone: vendor.phone,
    Role: vendor.role,
  }))
}

export { downloadVendors }
