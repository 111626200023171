import { IApplicationScheme } from 'types'
import { ApplicationField, Field } from 'types/field'
import { formatPercent, parsePercent } from 'utils/math'
import { updateResponseValue, updateRequestValue } from './field-parser'

const updateResponseFields = (fields: ApplicationField[]): Field[] => {
  return fields.map(
    ({ application, ...rest }) =>
      ({
        ...rest,
        property: {
          ...application,
          value: updateResponseValue(application?.value, rest.type),
        },
      }) as Field
  )
}

const updateResponseApplication = (application) => {
  return {
    ...application,
    applicationFields: updateResponseFields(
      application.applicationFields ?? []
    ),
    addresses: application.addresses?.map((address) => ({
      ...address,
      fields: updateResponseFields(address.fields),
    })),
  }
}

const updateRequestFields = (fields: Field[]): ApplicationField[] => {
  return fields.map(({ property, ...field }) => ({
    ...field,
    application: {
      ...property,
      value: updateRequestValue(property.value, field.type),
    },
  }))
}

const updateRequestApplication = (application) => {
  return {
    ...application,
    applicationFields: application.applicationFields
      ? updateRequestFields(application.applicationFields)
      : undefined,
  }
}

const updateRequestApplicationScheme = (
  applicationScheme: IApplicationScheme
) => ({
  ...applicationScheme,
  pages: applicationScheme.pages.map((page) => ({
    ...page,
    fields: page.fields.map((field) => {
      if (['guarantor', 'borrower'].includes(field.type)) {
        return {
          ...field,
          value: (field.value as any[])?.map((value) =>
            Object.entries(value).map(([key, value]) => ({ id: key, value }))
          ),
        }
      }
      if (field.type === 'percentage') {
        return {
          ...field,
          value: parsePercent(field.value as string),
        }
      }
      return field
    }),
  })),
})

const updateResponseApplicationScheme = (
  applicationScheme: IApplicationScheme
) => ({
  ...applicationScheme,
  pages: applicationScheme.pages.map((page) => ({
    ...page,
    fields: page.fields.map((field) => {
      if (['guarantor', 'borrower'].includes(field.type)) {
        try {
          return {
            ...field,
            value: (field.value as any[])?.map((value) =>
              Object.fromEntries(value.map(({ id, value }) => [id, value]))
            ),
          }
        } catch {
          return { ...field, value: [] }
        }
      }
      if (field.type === 'percentage') {
        return {
          ...field,
          value: formatPercent(field.value as string),
        }
      }
      return field
    }),
  })),
})

export {
  updateResponseValue,
  updateResponseFields,
  updateResponseApplication,
  updateRequestValue,
  updateRequestFields,
  updateRequestApplication,
  updateRequestApplicationScheme,
  updateResponseApplicationScheme,
}
