import { Button } from 'components/Button'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { Panel } from 'components/Panel'
import { rootDomain } from 'constants/domain'
import { useSession } from 'hooks/use-session'
import { message } from 'utils/message'

const MailForwardingAddress = () => {
  const { user } = useSession()

  return (
    <Panel title="Mail Forwarding Address" className="max-w-panel">
      <Flex stack gap={12}>
        <div className="text-grey-700">
          Baseline will use the following mail forwarding address. Connect to
          Gmail or Outlook to use an existing address.
        </div>
        <Flex alignItems="center" gap={10}>
          <Input
            value={`admin+${user?.admin?.id}@reply${rootDomain}`}
            disabled
          />
          <CopyToClipboard
            text={`admin+${user?.admin?.id}@reply${rootDomain}`}
            onCopy={() => message.success('Link copied')}
          >
            <Button variant="secondary" size="large">
              <Icon name={IconName.copyDocument} className="text-grey-600" />
              Copy
            </Button>
          </CopyToClipboard>
        </Flex>
      </Flex>
    </Panel>
  )
}

export { MailForwardingAddress }
