import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getOfferings,
  getOffering,
  addOffering,
  updateOffering,
  deleteOffering,
  duplicateOffering,
} from 'admin/services/api/offerings'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Offering } from 'types'
import { Filter, Pagination, Sort } from 'types/table'
import { message } from 'utils/message'

interface Props {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
}

const useOfferings = ({ search, filter, pagination, sort }: Props = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_OFFERINGS, search, nextFilter, pagination, sort],
    queryFn: () =>
      getOfferings({ search, filter: nextFilter, page: pagination, sort }),
    placeholderData: keepPreviousData,
  })
}

const useOffering = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id],
    queryFn: () => getOffering(id),
  })
}

const useAddOffering = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addOffering,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS] })
      message.success('Offering saved')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateOffering = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (offering: Partial<Offering>) =>
      updateOffering({ id, ...offering }),
    onSuccess: ({ offering }) => {
      queryClient.setQueryData([KEY_OFFERINGS, offering.id], { offering })
      message.success('Offering saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteOffering = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteOffering(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS] })
      }, 50)
      message.success('Offering deleted')
    },
    onError: handleErrorResponse,
  })
}

const useDuplicateOffering = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => duplicateOffering(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS] })
      message.success('Offering duplicated')
    },
    onError: handleErrorResponse,
  })
}

export {
  useOfferings,
  useOffering,
  useAddOffering,
  useUpdateOffering,
  useDeleteOffering,
  useDuplicateOffering,
}
