import { Formik } from 'formik'
import { isNil, toString } from 'lodash'
import { Button } from 'components/Button'
import { Date, FieldIcon, Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { VendorPayment } from 'services/api/vendor-payment'
import { Transaction } from 'types'
import { createScheme, required } from 'utils/schemas'

interface Props {
  saving: boolean
  transaction?: Transaction
  onSave: (payment: VendorPayment) => void
  onCancel: () => void
}

type FormValues = Omit<VendorPayment, 'amount'> & {
  amount: string
}

const Schema = createScheme({
  date: required,
  amount: required,
})

function ModalVendorPayment({ saving, transaction, onSave, onCancel }: Props) {
  const initialValues: FormValues = {
    date: transaction?.date || '',
    amount: isNil(transaction?.amount)
      ? ''
      : toString(Math.abs(transaction.amount)),
    type: transaction?.type || 'Deposit',
    description: transaction?.payment?.description || '',
  }

  const onSubmit = (values: FormValues) => {
    onSave({
      ...values,
      amount: parseFloat(values.amount),
    })
  }

  return (
    <Modal
      title={transaction ? 'Edit Transaction' : 'Add Transaction'}
      onClose={onCancel}
      className="w-[564px]"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Schema}
      >
        <Form>
          <Grid className="mt-4" columnGap={16}>
            <Grid.Item xs={6}>
              <Date name="date" label="Date" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="currency" label="Amount" name="amount" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                label="Type"
                name="type"
                disabled={!!transaction}
                options={[
                  { value: 'Deposit', label: 'Deposit' },
                  { value: 'Withdrawal', label: 'Withdrawal' },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="text" label="Memo" name="description" />
            </Grid.Item>
          </Grid>
          <Grid.Item xs={12} className="flex gap-4 justify-end">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              Save
            </Button>
          </Grid.Item>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalVendorPayment }
