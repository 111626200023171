import styles from 'pages/Session/styles.module.scss'

interface Props {
  errors?: string[]
}

const Errors = ({ errors }: Props) => {
  return errors && errors.length > 0 ? (
    <div className={styles.errors}>
      {errors.map((message, index) => (
        <div key={index} className={styles.error}>
          {message}
        </div>
      ))}
    </div>
  ) : null
}

export { Errors }
