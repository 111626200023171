import clsx from 'clsx'
import { parseISO } from 'date-fns'
import { DatePicker } from 'components/DatePicker'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

function DateField({
  disabled,
  'data-testid': dataTestId,
  ...props
}: CustomFieldProps & { minDate?: string; maxDate?: string }) {
  return (
    <Field {...props}>
      {({ form, meta: { touched, error }, field }: FieldChildProps) => {
        return (
          <DatePicker
            name={field.name}
            value={field.value}
            disabled={disabled}
            data-testid={dataTestId}
            className={clsx({
              [styles.errorField]: touched && error,
            })}
            onChange={(date) => {
              props.onChange &&
                props.onChange({ target: { id: field.name, value: date } })
              form.setFieldValue(field.name, date)
            }}
            maxDate={props.maxDate ? parseISO(props.maxDate) : undefined}
            minDate={props.minDate ? parseISO(props.minDate) : undefined}
          />
        )
      }}
    </Field>
  )
}

export default DateField
