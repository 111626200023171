import { request } from 'services/request'
import {
  Filter,
  Meta,
  Pagination,
  Search,
  Sort,
  TaskTemplate,
  TaskTemplateTask,
} from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

const getTaskTemplates = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ taskTemplates: TaskTemplate[]; meta: Meta }> => {
  const {
    data: { taskTemplates, pagination },
  } = await request.get('/task/template', {
    params: updateRequestTable(params),
  })
  return {
    taskTemplates: taskTemplates,
    meta: pagination,
  }
}

const getTaskTemplate = async (id: string): Promise<TaskTemplate> => {
  const {
    data: { taskTemplate },
  } = await request.get(`/task/template/${id}`)
  return taskTemplate
}

const addTaskTemplate = async (
  payload: Partial<TaskTemplate>
): Promise<TaskTemplate> => {
  const {
    data: { taskTemplate },
  } = await request.post('/task/template', payload)
  return taskTemplate
}

const editTaskTemplate = async (template: Partial<TaskTemplate>) => {
  return request.patch(`/task/template/${template.id}`, template)
}

const removeTaskTemplate = async (id: string) => {
  return request.delete(`/task/template/${id}`)
}

const addTaskTemplateTask = async (
  payload: Partial<TaskTemplateTask>
): Promise<TaskTemplateTask> => {
  const {
    data: { task },
  } = await request.post('/task', payload)
  return task
}

const editTaskTemplateTask = async (
  payload: Partial<TaskTemplateTask>
): Promise<TaskTemplateTask> => {
  const {
    data: { task },
  } = await request.patch(`/task/${payload.id}`, payload)
  return task
}

const removeTaskTemplateTask = (
  taskId: string
): Promise<{ success: boolean }> => {
  return request.delete(`/task/${taskId}`)
}

const createLoanTasksFromTemplate = (loanId: string, templateId: string) => {
  return request.put(`/loan/${loanId}/task/template/${templateId}`)
}

export {
  getTaskTemplates,
  getTaskTemplate,
  addTaskTemplate,
  removeTaskTemplate,
  editTaskTemplate,
  addTaskTemplateTask,
  editTaskTemplateTask,
  removeTaskTemplateTask,
  createLoanTasksFromTemplate,
}
