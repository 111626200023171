import { createContext } from 'react'
import { useSessionManager } from 'hooks/use-session-manager'

export const SessionContext = createContext<
  ReturnType<typeof useSessionManager>
>({
  signIn: () => {},
  start: () => {},
  signOut: () => {},
  refreshUser: () => {},
  clearErrors: () => {},
  invalidate: () => {},
  isSigningIn: false,
  isLoading: true,
  isSignedIn: true,
  isSignedOut: false,
  isIndividual: false,
  isAdmin: false,
  isAdminManager: false,
  isManager: false,
  user: null,
  errors: [],
  borrowerAccounts: [],
})
