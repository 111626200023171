import clsx from 'clsx'
import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import styles from './styles.module.scss'

interface Props extends HTMLProps<HTMLDivElement> {
  className?: string
}

const Footer = forwardRef(
  (
    { children, className, ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    return (
      <div ref={ref} className={clsx(styles.footer, className)} {...rest}>
        {children}
      </div>
    )
  }
)

Footer.displayName = 'Drawer.Footer'

export { Footer }
