import { uniqueId } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { IComment } from 'types'
import { Comment } from './Comment'
import { EditComment } from './EditComment'

interface Props {
  comments: IComment[]
  users?: { value: string; id: string; email: string }[]
  hidePinIcon?: boolean
  onPin?: (comment: IComment) => void
  onUnpin?: (comment: IComment) => void
  onView?: (comment: IComment) => void
  onDelete?: (comment: IComment) => void
  onEdit?: (comment: IComment) => Promise<IComment>
  showTypingIndicator?: boolean
}

const Comments = ({
  comments,
  users,
  hidePinIcon,
  onPin,
  onUnpin,
  onView,
  onEdit,
  onDelete,
  showTypingIndicator,
}: Props) => {
  const { hash } = useLocation()
  const uniqInstanceId = useMemo(() => uniqueId(), [])
  const [editingComment, setEditingComment] = useState<IComment | null>(null)

  const handleEditComment = useCallback(
    async (comment: IComment) => {
      await onEdit?.(comment)
      setTimeout(() => setEditingComment(null), 1000)
    },
    [onEdit]
  )

  useEffect(() => {
    if (hash.startsWith('#comments:')) {
      const commentId = hash.split(':')[1]
      const commentElement = document.getElementById(
        `${uniqInstanceId}_${commentId}`
      )
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'center' })
      }
    }
  }, [uniqInstanceId, hash])

  return (
    <Flex stack gap={0}>
      {showTypingIndicator && (
        <Flex alignItems="center" gap={8} className="px-3 pb-2 rounded-md">
          <Icon name={IconName.typing} className="text-grey-20 mr-3" />
          <Text variant="s" className="text-grey-600">
            Baseline is thinking...
          </Text>
        </Flex>
      )}
      {comments.map((comment) =>
        comment.id === editingComment?.id ? (
          <EditComment
            key={comment.id}
            users={users}
            comment={comment}
            onSave={handleEditComment}
            onCancel={() => setEditingComment(null)}
          />
        ) : (
          <Comment
            key={comment.id}
            comment={comment}
            namespace={uniqInstanceId}
            hidePinIcon={hidePinIcon}
            onPin={onPin}
            onUnpin={onUnpin}
            onView={onView}
            onEdit={onEdit ? setEditingComment : undefined}
            onDelete={onDelete}
          />
        )
      )}
    </Flex>
  )
}

export { Comments }
