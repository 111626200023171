import { ColumnDef } from '@tanstack/react-table'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { ChargeStatusBadge } from 'components/Badge/ChargeStatusBadge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Redact } from 'components/Redact'
import { Table } from 'components/Table'
import { Charge } from 'types/charge'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { sumDecimal } from 'utils/math'

type Props = {
  charges?: Charge[]
  loading: boolean
  onRowClick?: (charge?: Charge) => void
  onEdit?: (charge: Charge) => void
  onHistory: (charge: Charge) => void
  onDelete?: (id: string) => void
}

function TableCharges({
  charges = [],
  loading,
  onRowClick,
  onHistory,
  onEdit,
  onDelete,
}: Props) {
  const ability = useAbilitiesContext()
  const columns: ColumnDef<Charge>[] = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'dateCharged',
        size: 180,
        cell: ({ getValue }) => friendlyDate(getValue() as string),
        meta: {
          getTopCellValue: () => <div className="font-bold">Total</div>,
        },
      },
      {
        header: 'Description',
        accessorKey: 'name',
        meta: {
          getTopCellValue: () => <div />,
        },
      },
      {
        header: 'Payable To',
        id: 'person.name',
        cell: ({ row }) => {
          const { person } = row.original
          return (
            <Redact
              value={compact([person?.companyName, person?.name]).join(' - ')}
              hide={person?.isInvestor && ability.cannot('read', 'investors')}
            />
          )
        },
        meta: {
          getTopCellValue: () => <div />,
        },
      },
      {
        header: 'Due Date',
        accessorKey: 'dateDue',
        size: 180,
        cell: ({ getValue }) => friendlyDate(getValue() as string),
        meta: {
          getTopCellValue: () => <div />,
        },
      },
      {
        header: 'Original Amount',
        accessorKey: 'amount',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          getTopCellValue: ({ rows }) => {
            const total = sumDecimal(rows.map((row) => row.original.amount))
            return <div className="font-bold">{formatUsd(total)}</div>
          },
        },
      },
      {
        header: 'Amount Due',
        accessorKey: 'amountDue',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          getTopCellValue: ({ rows }) => {
            const total = sumDecimal(rows.map((row) => row.original.amountDue))
            return <div className="font-bold">{formatUsd(total)}</div>
          },
        },
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          const { amount, amountDue } = row.original
          return <ChargeStatusBadge amount={amount} amountDue={amountDue} />
        },
        meta: {
          getTopCellValue: () => <div />,
        },
      },
      {
        accessorKey: 'id',
        header: '',
        size: 46,
        cell: ({ getValue, row }) => {
          const id = getValue() as string
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onHistory(row.original)}
              >
                <Icon name={IconName.versionHistory} />
                History
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                disabled={!onEdit}
                onSelect={() => onEdit && onEdit(row.original)}
              >
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                disabled={!onDelete}
                onSelect={() => onDelete && onDelete(id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
        meta: {
          getTopCellValue: () => <div />,
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={charges}
      loading={loading}
      onClick={onRowClick}
    />
  )
}

export default TableCharges
