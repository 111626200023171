import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isString } from 'lodash'
import {
  getUsers,
  addUser,
  updateCurrentUser,
  updateUser,
  deleteUser,
  User,
  updateCurrentClient,
} from 'admin/services/api/users'
import { KEY_USERS } from 'constants/query-keys'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useUsers = ({
  clientId,
  user,
}: {
  clientId?: string
  user: CurrentUser | null
}) => {
  return useQuery({
    queryKey: [KEY_USERS, clientId],
    queryFn: async () => {
      const users = await getUsers(clientId as string)

      return user?.admin?.email &&
        user?.admin?.email.match(/@(baselinesoftware|baselinelending)\.com/i)
        ? users // Baseline accounts can see all members
        : users.filter(
            (member) =>
              !member.login.email ||
              !member.login.email.match(
                /@(baselinesoftware|baselinelending)\.com/i
              )
          ) // Otherwise you can only see non-Baseline members
    },
    enabled: !!clientId,
  })
}

const useUpdateCurrentUser = () => {
  const session = useSession()
  return useMutation({
    mutationFn: (data: any) => updateCurrentUser(data),
    onSuccess: () => {
      session.refreshUser()
      message.success('User updated')
    },
    onError: (error: AxiosError) => {
      if (
        [405].includes(error?.response?.status as number) &&
        isString(error?.response?.data) &&
        error.response?.data === 'Invalid password'
      ) {
        message.error("Sorry, your old password didn't match. Please try again")
      } else {
        handleErrorResponse(error)
      }
    },
  })
}

const useUpdateCurrentClient = () => {
  return useMutation({
    mutationFn: ({
      clientId,
      name,
      subdomain,
      pipelineSettings,
    }: {
      clientId: string
      name?: string
      subdomain?: string
      pipelineSettings?: any
    }) => updateCurrentClient({ name, subdomain, pipelineSettings, clientId }),
    onError: (error: AxiosError) => {
      if ([406].includes(error?.response?.status as number)) {
        message.error('That subdomain is in use. Please try another.')
      } else {
        handleErrorResponse(error)
      }
    },
  })
}

const useUpdateCurrentClientSettings = (showToast: boolean = true) => {
  return useMutation({
    mutationFn: ({ settings, clientId }: { settings: any; clientId: string }) =>
      updateCurrentClient({ settings, clientId }),
    onSuccess: () => {
      showToast && message.success('Setting updated')
    },
    onError: (error: AxiosError) => {
      if ([406].includes(error?.response?.status as number)) {
        message.error('That subdomain is in use. Please try another.')
      } else {
        handleErrorResponse(error)
      }
    },
  })
}

const useAddUser = ({ clientId }: { clientId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      name,
      email,
      permission,
      roles,
    }: {
      name: string
      email: string
      permission: User['permission']
      roles: string[]
    }) => addUser({ clientId, name, email, permission, roles }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_USERS, clientId] })
      message.success('Member added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateUser = ({ clientId }: { clientId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      userId,
      name,
      email,
      permission,
      roles,
    }: {
      userId: string
      name?: string
      email?: string
      permission: User['permission']
      roles?: string[]
    }) => updateUser({ clientId, userId, name, email, permission, roles }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_USERS, clientId] })
      message.success('Member updated')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveUser = ({ clientId }: { clientId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ userId }: { userId: string }) =>
      deleteUser({ clientId, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_USERS, clientId] })
      message.success('Member removed')
    },
    onError: handleErrorResponse,
  })
}

export {
  useUsers,
  useAddUser,
  useUpdateUser,
  useRemoveUser,
  useUpdateCurrentUser,
  useUpdateCurrentClient,
  useUpdateCurrentClientSettings,
}
