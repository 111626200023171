import { request } from '../request'

const generatePdf = async (printUrl: string): Promise<string> => {
  const {
    data: { url },
  } = await request.post('/pdf', { url: printUrl })
  return url
}

export { generatePdf }
