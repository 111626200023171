import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KEY_BUDGET_WORKSHEET } from 'constants/query-keys'
import {
  getBudgetWorksheets,
  getBudgetWorksheet,
  addBudgetWorksheet,
  updateBudgetWorksheet,
  duplicateBudgetWorksheet,
  deleteBudgetWorksheet,
} from 'services/api/budget-worksheet'
import { handleErrorResponse } from '../services/request'

const useBudgetWorksheets = () => {
  return useQuery({
    queryKey: [KEY_BUDGET_WORKSHEET],
    queryFn: getBudgetWorksheets,
  })
}

const useBudgetWorksheet = (worksheetId: string) => {
  return useQuery({
    queryKey: [KEY_BUDGET_WORKSHEET, worksheetId],
    queryFn: () => getBudgetWorksheet(worksheetId),
  })
}

const useAddBudgetWorksheet = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addBudgetWorksheet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_BUDGET_WORKSHEET] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateBudgetWorksheet = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateBudgetWorksheet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_BUDGET_WORKSHEET] })
    },
    onError: handleErrorResponse,
  })
}

const useDuplicateBudgetWorksheet = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: duplicateBudgetWorksheet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_BUDGET_WORKSHEET] })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteBudgetWorksheet = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteBudgetWorksheet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_BUDGET_WORKSHEET] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useBudgetWorksheets,
  useBudgetWorksheet,
  useAddBudgetWorksheet,
  useUpdateBudgetWorksheet,
  useDuplicateBudgetWorksheet,
  useDeleteBudgetWorksheet,
}
