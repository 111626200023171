import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import styles from 'pages/Session/styles.module.scss'

interface Props {
  openIDRedirectUrl: URL
  errors: string[]
}

const OpenIdError = ({ openIDRedirectUrl, errors }: Props) => {
  const errorRedirectUrl = useMemo(() => {
    const url = new URL(openIDRedirectUrl.href)
    url.searchParams.set('error', 'invalid_request')
    url.searchParams.set('error_description', errors[0])
    return url
  }, [openIDRedirectUrl, errors])

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo type="statement" to={errorRedirectUrl.href} />
        <div className={styles.form}>
          <Header variant="h1" className={styles.title}>
            {errors[0]}
          </Header>
          <Text className={styles.description}>
            Please try logging in again or reach out to us for help.
          </Text>
        </div>
        <Link to={errorRedirectUrl.href} className={styles.back}>
          <Icon name={IconName.arrowLeft} />
          Back to login
        </Link>
      </div>
    </div>
  )
}

export { OpenIdError }
