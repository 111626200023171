import { Formik } from 'formik'
import { SelectBorrower } from 'admin/components/SelectBorrower'
import { Borrower } from 'admin/services/api/borrowers'
import { Product } from 'admin/services/api/products'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import styles from 'components/Modal/AddLoan/styles.module.scss'
import { TextLink } from 'components/TextLink'
import { useOfFundsOptions } from 'constants/use-of-funds'
import { createScheme, required } from 'utils/schemas'

type LoanFormValues = {
  name: string
  useOfFunds?: string
  productId?: string
  borrowers: string[]
  guarantors: string[]
}

const initialFormValues: LoanFormValues = {
  name: '',
  borrowers: [],
  guarantors: [],
}

interface Props {
  initialValues: LoanFormValues
  products: Product[]
  borrowers: Borrower[]
  include?: ('useOfFunds' | 'product')[]
  onCancel: () => void
  onAddPerson: (
    values: LoanFormValues,
    type: 'borrower' | 'guarantor',
    personName?: string
  ) => void
  onSubmit: (values: LoanFormValues) => void
}

const LoanForm = ({
  initialValues,
  include,
  products,
  borrowers,
  onCancel,
  onAddPerson,
  onSubmit,
}: Props) => {
  const FormSchema = createScheme({
    name: required,
    ...(include?.includes('useOfFunds') ? { useOfFunds: required } : undefined),
    ...(include?.includes('product') ? { productId: required } : undefined),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        useOfFunds:
          initialValues.useOfFunds ||
          (include?.includes('useOfFunds')
            ? useOfFundsOptions[0].value
            : undefined),
        productId:
          initialValues.productId ||
          (include?.includes('product') && products.length
            ? products[0].id
            : undefined),
      }}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form modal>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field name="name" label="Loan Name" />
            </Grid.Item>
            {include?.includes('useOfFunds') && (
              <Grid.Item xs={6}>
                <Select
                  name="useOfFunds"
                  label="Use of Funds"
                  portal
                  options={useOfFundsOptions}
                />
              </Grid.Item>
            )}
            {include?.includes('product') && (
              <Grid.Item xs={include?.includes('useOfFunds') ? 6 : 12}>
                <Select
                  name="productId"
                  label="Product"
                  portal
                  options={products.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12}>
              <Flex gap={9} stack>
                <div>Borrower(s)</div>
                <SelectBorrower
                  value={values.borrowers}
                  onSelect={(id) => setFieldValue('borrowers', id)}
                  isMulti
                  entity="borrower"
                  defaultOptions={borrowers}
                  filter={{ isAccount: [true] }}
                />
                <TextLink onClick={() => onAddPerson(values, 'borrower')}>
                  <Icon name={IconName.plus} size="sm" />
                  Create a new borrower
                </TextLink>
              </Flex>
            </Grid.Item>
            <Grid.Item xs={12} className="mt-6">
              <Flex gap={9} stack>
                <div>Guarantor(s)</div>
                <SelectBorrower
                  value={values.guarantors}
                  onSelect={(id) => setFieldValue('guarantors', id)}
                  isMulti
                  entity="guarantor"
                  defaultOptions={borrowers}
                  filter={{ isAccount: [true] }}
                />
                <TextLink onClick={() => onAddPerson(values, 'guarantor')}>
                  <Icon name={IconName.plus} size="sm" />
                  Create a new guarantor
                </TextLink>
              </Flex>
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </Grid.Item>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export type { LoanFormValues }
export { initialFormValues }
export default LoanForm
