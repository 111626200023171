import { request } from 'services/request'
import {
  Offering,
  Filter,
  Meta,
  Pagination,
  Sort,
  LoanFundingSource,
} from 'types'
import { updateResponseOffering } from 'utils/api/offering-parse'
import { updateRequestTable } from 'utils/api/table-parser'
import { formatPercent } from 'utils/math'

const getOfferings = async (params: {
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
}): Promise<{ offerings: Offering[]; meta: Meta }> => {
  const {
    data: { offerings, pagination },
  } = await request.get('/offering', { params: updateRequestTable(params) })
  return {
    offerings: offerings.map((offering) => updateResponseOffering(offering)),
    meta: pagination,
  }
}

const getOffering = async (
  id: string
): Promise<{ offering: Offering; fundingSources?: LoanFundingSource[] }> => {
  const {
    data: { offering, fundingSources },
  } = await request.get(`/offering/${id}`)
  return {
    offering: updateResponseOffering(offering),
    fundingSources: fundingSources?.map((fundingSource: LoanFundingSource) => ({
      ...fundingSource,
      rate: formatPercent(fundingSource.rate),
    })),
  }
}

const addOffering = async (payload: { name: string }): Promise<Offering> => {
  const {
    data: { offering },
  } = await request.post('/offering', payload)
  return updateResponseOffering(offering)
}

const updateOffering = async ({
  id,
  ...payload
}: Partial<Offering>): Promise<{ offering: Offering }> => {
  const {
    data: { offering },
  } = await request.patch(`/offering/${id}`, payload)
  return { offering: updateResponseOffering(offering) }
}

const deleteOffering = async (offeringId: string): Promise<unknown> => {
  return request.delete(`/offering/${offeringId}`)
}

const duplicateOffering = async (id: string) => {
  const {
    data: { offering },
  } = await request.put(`/offering/${id}`)
  return updateResponseOffering(offering)
}

export {
  getOfferings,
  getOffering,
  addOffering,
  updateOffering,
  deleteOffering,
  duplicateOffering,
}
