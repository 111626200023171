import {
  createContext,
  useState,
  PropsWithChildren,
  memo,
  useMemo,
} from 'react'

interface SwitcherContextType {
  open: boolean
  setOpen: (open: boolean) => void
}

const SwitcherContext = createContext<SwitcherContextType>(
  {} as SwitcherContextType
)
SwitcherContext.displayName = 'SwitcherContext'

interface Props extends PropsWithChildren {}

const SwitcherProvider = memo(({ children }: Props) => {
  const [open, setOpen] = useState(false)
  const value = useMemo(() => ({ open, setOpen }), [open])

  return (
    <SwitcherContext.Provider value={value}>
      {children}
    </SwitcherContext.Provider>
  )
})

SwitcherProvider.displayName = 'SwitcherProvider'

export { SwitcherProvider, SwitcherContext }
