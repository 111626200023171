import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'

interface Props {
  icon: ReactNode
  title: string
  description: string
  onClick: () => void
}

export const Link = ({ icon, title, description, onClick }: Props) => {
  return (
    <Flex
      onClick={onClick}
      className="cursor-pointer border-0 border-b border-solid border-b-grey-200 px-2 py-5 hover:bg-grey-50"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Flex
          className="h-8 min-w-8 rounded bg-grey-100"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </Flex>
        <Flex gap={2} flexDirection="column">
          <Text className="text-black-100">{title}</Text>
          <Text className="text-grey-700">{description}</Text>
        </Flex>
      </Flex>
      <Flex className="h-8 min-w-8" alignItems="center" justifyContent="center">
        <Icon className="text-grey-500" name={IconName.arrowRight} />
      </Flex>
    </Flex>
  )
}
