import { clsx } from 'clsx'
import { last } from 'lodash'
import { useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useThread } from 'admin/hooks/use-threads'
import { LinkedContacts } from 'admin/pages/Thread/PanelsForLinking/LinkedContacts'
import { LinkedLoans } from 'admin/pages/Thread/PanelsForLinking/LinkedLoans'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { Header } from './Header/Header'
import { Mail } from './Mail/Mail'
import { ThreadProvider } from './ThreadProvider'

const Thread = () => {
  const navigate = useNavigate()
  const { loanId, threadId } = useParams() as {
    loanId?: string
    threadId: string
  }
  const { data: thread } = useThread({ id: threadId })

  const title = useMemo(
    () => thread?.mails?.[0]?.subject || '(no subject)',
    [thread]
  )

  const handleClose = useCallback(
    (replace = false) => {
      if (window.history?.length && window.history.length > 1) {
        navigate(-1)
      } else {
        if (loanId) {
          const isServicing = window.location.pathname.includes('/servicing')
          navigate(
            pathTo(
              isServicing ? 'servicingLoanTab' : 'loanTab',
              loanId,
              'mail'
            ),
            { replace }
          )
        } else {
          navigate(pathTo('threads'), { replace })
        }
      }
    },
    [navigate, loanId]
  )
  const handleDelete = useCallback(() => {
    if (thread?.mails.length === 1) {
      handleClose(true)
    }
  }, [thread, handleClose])

  const lastMailId = last(thread?.mails?.filter(({ isDraft }) => !isDraft))?.id
  const isNewThread = thread?.mails.length === 1 && thread?.mails[0].isDraft

  return (
    <MainContent>
      {thread ? (
        <ThreadProvider thread={thread}>
          <Flex stack gap={32}>
            <Header onClose={handleClose} />
            <Flex gap={40}>
              <div
                className={clsx('w-full', !isNewThread && 'overflow-hidden')}
              >
                {!isNewThread && (
                  <>
                    <Helmet>
                      <title>{title}</title>
                    </Helmet>
                    <div className="mb-8 ml-[68px] text-5xl text-grey-900 font-bold truncate">
                      {title}
                    </div>
                  </>
                )}
                {thread.mails.map((mail, index) => (
                  <Mail
                    key={`${mail.id}_${mail.isDraft}`}
                    mailId={mail.id}
                    showDraftInEditor
                    isFirstMail={index === 0}
                    isLastMail={mail.id === lastMailId}
                    showActions={
                      index === thread.mails.length - 1 &&
                      mail.id === lastMailId
                    }
                    className={isNewThread ? '!border-b-0 !pl-0' : ''}
                    onDelete={handleDelete}
                  />
                ))}
              </div>
              <div className="max-w-xs w-full ">
                <Flex stack gap={32} className="sticky top-20">
                  <LinkedLoans />
                  <LinkedContacts />
                </Flex>
              </div>
            </Flex>
          </Flex>
        </ThreadProvider>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Thread }
