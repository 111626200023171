import clsx from 'clsx'
import { Formik } from 'formik'
import { compact, findIndex, isEqual, isUndefined } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  schemeToValues,
  isLastPage,
  isFirstPage,
  isFieldVisible,
  isPageVisible,
} from 'borrower/components/DraftApplication/helpers'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import {
  savePreviewApplication,
  deletePreviewApplication,
  readPreviewApplication,
} from 'services/storage'
import { IApplicationScheme } from 'types'
import { ApplicationField } from '../GuestApplication/ApplicationField'
import styles from './styles.module.scss'

interface Props {
  scheme: IApplicationScheme
}

function PreviewApplication({ scheme: initialScheme }: Props) {
  const [scheme, setScheme] = useState<IApplicationScheme>(initialScheme)
  const pages = useMemo(
    () => scheme.pages.filter((page) => isPageVisible(scheme, page)),
    [scheme]
  )
  const pageIds = useMemo(() => pages.map(({ id }) => id), [pages])
  const defaultPageId = useMemo(() => {
    const defaultPageIndex = Math.max(
      0,
      ...pages
        .map(({ complete }, index) => ({ index, complete }))
        .filter(({ complete }) => complete)
        .map(({ index }) => index)
    )
    return pages[defaultPageIndex].id
  }, [pages])
  const [currentPageId, setCurrentPageId] = useState<string>(defaultPageId)
  const currentPageIndex = useMemo(
    () => pageIds.indexOf(currentPageId),
    [pageIds, currentPageId]
  )
  const page = useMemo(
    () => pages[currentPageIndex],
    [scheme, currentPageIndex]
  )
  const goToPage = useCallback(
    (pageId: string) => {
      setCurrentPageId(pageId)
      document.getElementById(pageId)?.scrollIntoView()
      window.scrollTo({ top: window.scrollY - 80 })
    },
    [page]
  )

  const goToNextPage = useCallback(() => {
    if (pageIds[currentPageIndex + 1]) {
      goToPage(pageIds[currentPageIndex + 1])
    }
  }, [goToPage, currentPageIndex, pageIds])

  const handleBack = useCallback(() => {
    if (pageIds[currentPageIndex - 1]) {
      goToPage(pageIds[currentPageIndex - 1])
    }
  }, [goToPage, currentPageIndex, pageIds])

  const initialValues = useMemo(
    () => schemeToValues(pages.map(({ fields }) => fields).flat()),
    [pages]
  )

  useEffect(() => {
    const onStorageChange = () => setScheme(readPreviewApplication())
    window.addEventListener('storage', onStorageChange)
    return () => window.removeEventListener('storage', onStorageChange)
  }, [])

  const updateScheme = useCallback(
    (values, complete = false) => {
      const nextScheme = {
        ...scheme,
        pages: scheme.pages.map((page) => ({
          ...page,
          complete: page.id === currentPageId ? complete : page.complete,
          fields: page.fields.map((field) => ({
            ...field,
            value: isUndefined(values[field.id])
              ? field.value
              : values[field.id],
          })),
        })),
      }

      if (!isEqual(nextScheme, scheme)) {
        setScheme(nextScheme)
        savePreviewApplication(nextScheme)
      }
    },
    [scheme, currentPageId, savePreviewApplication]
  )

  useEffect(() => {
    const pageElements = compact(
      scheme.pages.map((page) => document.getElementById(page.id))
    )
    let direction = 'up'
    let prevYPosition = 0

    const setScrollDirection = () => {
      if (window.scrollY > prevYPosition) {
        direction = 'down'
      } else {
        direction = 'up'
      }

      prevYPosition = window.scrollY
    }
    const onScroll = () => {
      setScrollDirection()

      if (direction === 'down') {
        const pageIndex = findIndex(
          pageElements.slice().reverse(),
          (el) => el.getBoundingClientRect().top < window.innerHeight / 2
        )
        setCurrentPageId(pageIds[pageElements.length - pageIndex - 1])
      }
      if (direction === 'up') {
        const pageIndex = findIndex(
          pageElements,
          (el) => el.getBoundingClientRect().bottom > window.innerHeight / 2
        )
        if (pageIndex !== -1) {
          setCurrentPageId(pageIds[pageIndex])
        }
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scheme])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        updateScheme(values, true)
        goToNextPage()
      }}
    >
      <Form onChange={updateScheme}>
        {pages.map((page, index) => (
          <div key={page.id} id={page.id}>
            <Flex stack gap={32} className={clsx(styles.panel, 'relative')}>
              {currentPageId !== page.id && (
                <div className="absolute inset-0 opacity-60 z-1 rounded-[24px] bg-white-100" />
              )}
              <Flex stack gap={16}>
                <Flex>
                  <div className={styles.steps}>
                    Step {index + 1}/{pageIds.length}
                  </div>
                  <EllipsesActions
                    placement="bottom-start"
                    trigger={
                      <Flex className={styles.step} alignItems="center" gap={4}>
                        {page.tag}
                        <Icon name={IconName.arrowDown} />
                      </Flex>
                    }
                  >
                    {pages.map(({ id, tag }) => (
                      <EllipsesActions.Item
                        key={id}
                        onSelect={() => goToPage(id)}
                      >
                        {tag}
                      </EllipsesActions.Item>
                    ))}
                  </EllipsesActions>
                </Flex>
                <Header>{page.header}</Header>
                {page.description && (
                  <Text className="text-grey-600">{page.description}</Text>
                )}
              </Flex>
              <div className={styles.labels}>
                {page.fields
                  .filter((field) => isFieldVisible(scheme, field))
                  .map((field) => (
                    <ApplicationField
                      key={field.id}
                      scheme={scheme}
                      field={field}
                    />
                  ))}
              </div>
              <div className={styles.controls}>
                {isFirstPage(scheme, page.id) && (
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      deletePreviewApplication()
                      window.close()
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <div />
                <div className={styles.buttons}>
                  {!isFirstPage(scheme, page.id) && (
                    <Button variant="tertiary" onClick={handleBack}>
                      ←
                    </Button>
                  )}
                  <Button type="submit" disabled={isLastPage(scheme, page.id)}>
                    {isLastPage(scheme, page.id)
                      ? 'Submit Application'
                      : 'Continue →'}
                  </Button>
                </div>
              </div>
            </Flex>
            {!isLastPage(scheme, page.id) && <div className={styles.divider} />}
          </div>
        ))}
      </Form>
    </Formik>
  )
}
export { PreviewApplication }
