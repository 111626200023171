import { PropsWithChildren, useMemo, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import { changeFavicon } from 'utils/page'

interface Props extends PropsWithChildren {
  allowNotAuthorized?: boolean
}

const AppCustomization = ({ children, allowNotAuthorized }: Props) => {
  const { user } = useSession()
  const { data } = useClient({
    enabled: allowNotAuthorized || !user,
  })
  const client = useMemo(() => user?.client || data, [user, data])

  useEffect(() => {
    if (client?.image?.logoUrl) {
      changeFavicon(client.image.logoUrl)
    }
  }, [client])

  return (
    <>
      <Helmet>
        <title>{client?.name || 'Baseline'}</title>
      </Helmet>
      {children}
    </>
  )
}

export { AppCustomization }
