import { omitBy, size } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableVendors } from 'admin/components/table/TableVendors'
import {
  useLoanVendors,
  useAddLoanVendor,
  useRemoveLoanVendor,
} from 'admin/hooks/use-loan-vendors'
import { useVendorFilters } from 'admin/hooks/use-vendors'
import { pathTo } from 'admin/path-to'
import { Vendor } from 'admin/services/api/vendors'
import { LoadMore } from 'components/LoadMore'
import { ChooseVendor } from 'components/Modal/ChooseVendor'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Loan } from 'types'

interface Props {
  adding: boolean
  setAdding: (adding: boolean) => void
  loan: Loan
  showCash?: boolean
}

function PanelVendors({ loan, adding, setAdding, showCash }: Props) {
  const {
    visibleItems,
    result,
    filter,
    setFilter,
    setPagination,
    resetPagination,
    sort,
    setSort,
    updateItem,
  } = usePagination<Vendor>({
    property: 'vendors',
    useData: (params) =>
      useLoanVendors({ ...params, loanId: loan.id, details: true }),
  })
  const [removingId, setRemovingId] = useState<string | null>(null)
  const vendorFilters = useVendorFilters(['role'])
  const add = useAddLoanVendor()
  const navigate = useNavigate()
  const remove = useRemoveLoanVendor()

  const handleRowClick = useCallback((vendor?: Vendor) => {
    if (vendor?.id) {
      navigate(pathTo('vendor', vendor.id))
    }
  }, [])

  return (
    <Panel title="Contacts">
      <TableVendors
        onDelete={setRemovingId}
        data={visibleItems}
        loading={result.isPending}
        filter={filter}
        filters={vendorFilters.data}
        onFilter={(newFilter) => {
          setFilter(
            omitBy({ ...filter, ...newFilter }, (value) => !size(value))
          )
        }}
        disabled={loan.lock}
        deleteCopy="Remove"
        onClick={handleRowClick}
        sort={sort}
        onSort={setSort}
        showCash={showCash}
        onUpdateItem={updateItem}
      />
      <LoadMore
        loading={result.isFetching}
        count={visibleItems.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
      {adding ? (
        <ChooseVendor
          onSave={(vendor) =>
            add.mutate(
              { loanId: loan.id, vendor: vendor as Vendor },
              {
                onSuccess: () => {
                  resetPagination()
                  setAdding(false)
                },
              }
            )
          }
          onClose={() => {
            setAdding(false)
          }}
        />
      ) : null}
      {removingId && (
        <ModalDelete
          title="Remove Vendor"
          action="Remove"
          resource="vendor"
          name={
            visibleItems?.find((vendor) => vendor.id === removingId)?.name ||
            visibleItems?.find((vendor) => vendor.id === removingId)
              ?.companyName
          }
          loading={remove.isPending}
          onDelete={() =>
            remove.mutate(
              { loanId: loan.id, vendorId: removingId },
              {
                onSettled: () => {
                  resetPagination()
                  setRemovingId(null)
                },
              }
            )
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </Panel>
  )
}

export default PanelVendors
