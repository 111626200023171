import { isNil } from 'lodash'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  amount: number
  amountDue: number
}

type Status = 'Unpaid' | 'Partially Paid' | 'Paid'

const statusToColor: Record<Status, ValueOf<Pick<BadgeProps, 'color'>>> = {
  Unpaid: 'gray',
  Paid: 'green',
  'Partially Paid': 'yellow',
}

const getStatus = (amount, amountDue): Status => {
  if (!isNil(amount) && amountDue <= 0) {
    return 'Paid'
  }
  if (amountDue === amount) {
    return 'Unpaid'
  }
  return 'Partially Paid'
}

function ChargeStatusBadge({ amount, amountDue }: Props) {
  const status = getStatus(amount, amountDue)
  return <Badge color={statusToColor[status]}>{status}</Badge>
}

export { ChargeStatusBadge, getStatus }
