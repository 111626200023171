import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { IThread } from 'types'
import { Item } from './ModalSaveDocs'
import { SectionHeader } from './SectionHeader'

interface Props {
  thread: IThread
  onSelect: (item: Item) => void
}

const Initial = ({ thread, onSelect }: Props) => {
  const loans: Item[] = useMemo(
    () =>
      thread.loans.map(({ id, name }) => ({
        id: id,
        name: name,
        type: 'loan',
      })),
    [thread.loans]
  )
  const contacts: Item[] = useMemo(
    () =>
      thread.people
        .filter(({ isVendor }) => !isVendor)
        .map(({ id, name, isBorrower }) => ({
          id: id,
          name: name,
          type: isBorrower ? 'borrower' : 'investor',
        })),
    [thread.people]
  )

  return (
    <Flex stack gap={12}>
      <div className="text-sm text-grey-600 leading-[18px]">
        {loans.length ? 'Linked Loans' : 'No Loans'}
      </div>
      <div>
        {loans.map((loan) => (
          <SectionHeader
            key={loan.id}
            name={loan.name}
            onClickItem={() => {
              onSelect(loan)
            }}
          />
        ))}
      </div>
      <div className="text-sm text-grey-600 leading-[18px]">
        {contacts.length ? 'Contacts' : 'No Contacts'}
      </div>
      <div>
        {contacts.map((contact) => (
          <SectionHeader
            key={contact.id}
            name={contact.name}
            onClickItem={() => onSelect(contact)}
          />
        ))}
      </div>
    </Flex>
  )
}

export { Initial }
