import clsx from 'clsx'
import { LexicalEditor } from 'lexical'
import { useCallback, useMemo, useState } from 'react'
import { Flex } from 'components/Flex'
import { Panel } from 'components/Panel'
import { IMail } from 'types'
import { IThread } from 'types/thread'
import { useThreadContext } from '../ThreadContext'
import { Addressees } from './Addressees'
import { Attachments } from './Attachments'
import { Body } from './Body'
import { Controls } from './Controls'
import { ModalAttachDocs } from './ModalAttachDocs/ModalAttachDocs'

interface Props {
  thread: IThread
  mail: IMail
  className?: string
  dockMode?: boolean
  onDelete?: (mail: IMail) => void
}

const ThreadForm = ({ thread, mail, className, dockMode, onDelete }: Props) => {
  const { deleteMail, sendMail, isSending, updateMail } = useThreadContext()
  const [isAttachDocs, setIsAttachDocs] = useState(false)
  const [editor, setEditor] = useState<LexicalEditor>()
  const isNewMail = thread.mails.length === 1 && mail.isDraft
  const handleDelete = useCallback(() => {
    deleteMail({ threadId: thread.id, mailId: mail.id })
    onDelete?.(mail)
  }, [deleteMail, thread.id, mail.id, onDelete])
  const focusToField = useMemo(
    () => (isNewMail && mail.to.length === 0) || !!mail.forwardTo,
    [isNewMail, mail.to, mail.forwardTo]
  )

  return (
    <Panel
      className={clsx('p-0', isNewMail ? 'shadow-300' : 'shadow-0', className)}
    >
      <Flex stack className="pb-3 flex-grow w-full overflow-hidden">
        <Addressees
          autoFocus={focusToField}
          isNewMail={isNewMail}
          dockMode={dockMode}
          thread={thread}
          mail={mail}
          onChange={updateMail}
        />
        <Body
          autoFocus={!focusToField}
          isNewMail={isNewMail}
          mail={mail}
          onChange={updateMail}
          onInit={setEditor}
        />
        <Attachments mail={mail} />
      </Flex>
      {editor && (
        <Controls
          editor={editor}
          isSending={isSending}
          onSend={() => sendMail({ mailId: mail.id })}
          onAttachingDocs={() => setIsAttachDocs(true)}
          onDelete={handleDelete}
        />
      )}
      {isAttachDocs && (
        <ModalAttachDocs
          thread={thread}
          mail={mail}
          onClose={() => setIsAttachDocs(false)}
        />
      )}
    </Panel>
  )
}

export { ThreadForm }
