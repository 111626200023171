import { useCallback } from 'react'
import { Button } from 'components/Button'
import { Header } from 'components/Header'
import styles from 'pages/Session/styles.module.scss'

const NoClientError = () => {
  const handleBack = useCallback(() => {
    window.location.href = 'https://www.baselinesoftware.com'
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <div className="content-center">
          <Header variant="h4" className={styles.description}>
            No site is configured at this address.
          </Header>
          <Button onClick={handleBack}>Back to Baseline</Button>
        </div>
      </div>
    </div>
  )
}

export { NoClientError }
