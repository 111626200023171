import { set } from 'lodash'
import { useCallback, useState, ChangeEvent } from 'react'
import { useUpdateCurrentClient } from 'admin/hooks/use-users'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Select, Option } from 'components/Select'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { useSession } from 'hooks/use-session'
import { IPipelineSettingsTime } from 'services/api/session'

const PipelineTime = () => {
  const { user } = useSession()
  const [pipelineTime, setPipelineTime] = useState<IPipelineSettingsTime>(
    user?.client.pipelineSettings?.time || 'since_substatus'
  )
  const [isTimeEnabled, setIsTimeEnabled] = useState<boolean>(
    !!user?.client.pipelineSettings?.time
  )
  const { mutate: update } = useUpdateCurrentClient()

  const handleSelectChange = useCallback(
    ({ value }: Option) => {
      const pipelineTime = value as 'since_substatus' | 'date_closing'
      setPipelineTime(pipelineTime)
      updatePipelineTime(pipelineTime)
    },
    [user]
  )

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isPipelineTimeEnabled = e.target.checked
      setIsTimeEnabled(isPipelineTimeEnabled)
      updatePipelineTime(isPipelineTimeEnabled ? pipelineTime : null)
    },
    [user, pipelineTime]
  )

  const updatePipelineTime = useCallback(
    (value: IPipelineSettingsTime) => {
      set(user as any, 'client.pipelineSettings.time', value)
      update({
        clientId: user?.client.id as string,
        pipelineSettings: { ...user?.client.pipelineSettings, time: value },
      })
    },
    [user]
  )

  return (
    <Flex stack className="mt-1 mx-2 mb-6">
      <Flex alignItems="center" gap={8}>
        <label className="flex gap-1 text-grey-900 cursor-pointer">
          <Checkbox checked={isTimeEnabled} onChange={handleCheckboxChange} />
          Enable time display and time-based alerts{' '}
          <Tooltip content="Enabling this option will display the time elapsed since the loan was last updated, or the time remaining until closing.">
            <Icon name={IconName.info} size="md" className="text-grey-600" />
          </Tooltip>
        </label>
      </Flex>
      <Flex gap={6} stack className="ml-8">
        <Text className="text-grey-800">Method</Text>
        <Select
          className="sm:w-72"
          value={pipelineTime}
          isDisabled={!isTimeEnabled}
          options={[
            {
              label: 'Time since last progression',
              value: 'since_substatus',
            },
            {
              label: 'Time remaining until closing',
              value: 'date_closing',
            },
          ]}
          onChange={handleSelectChange}
        />
      </Flex>
    </Flex>
  )
}

export { PipelineTime }
