import clsx from 'clsx'
import { Formik } from 'formik'
import { pick } from 'lodash'
import { useCallback } from 'react'
import { Borrower } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import {
  Checkbox,
  Date,
  Field,
  Form,
  Phone,
  Select,
  SocialSecurityNumber,
  FieldIcon,
} from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { useSession } from 'hooks/use-session'
import { parseInitValues } from 'utils/form'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  borrower?: Borrower
  saveText?: string
  cancelText?: string
  saving?: boolean
  include?: string[]
  exclude?: string[]
  onBack?: () => void
  onSave: (borrower: Borrower) => void
  onCancel: () => void
  personName?: string
}

function FormBorrowerIndividual({
  borrower = {} as Borrower,
  saveText = 'Save',
  cancelText = 'Cancel',
  include = [],
  exclude = [],
  saving,
  onBack,
  onSave,
  onCancel,
  personName,
}: Props) {
  const { user } = useSession()
  const isOpenId = user?.client?.settings?.openid
  const isNameIncluded = !exclude.includes('name')
  const isEmailIncluded = include.includes('email')
  const isInvitationIncluded = !isOpenId && include.includes('sendInvitation')
  const isAccountTypeIncluded =
    include.includes('isAccount') && !exclude.includes('isAccount')
  const isCreditScoreIncluded = include.includes('creditScore')
  const isNumFlippedIncluded = borrower.isBorrower
  const isNumConstructionsIncluded = borrower.isBorrower

  const BorrowerSchema = useCallback(
    () =>
      createScheme({
        ...(isNameIncluded ? { name: required } : {}),
      }),
    [isNameIncluded]
  )
  const data = pick(borrower, [
    'gender',
    'dateBirth',
    'socialSecurityNumber',
    'citizenship',
    'maritalStatus',
    'phone',
    'phoneSecondary',
    isNameIncluded ? 'name' : '',
    isCreditScoreIncluded ? 'creditScore' : '',
    isEmailIncluded ? 'email' : '',
    isInvitationIncluded ? 'sendInvitation' : '',
    isNumFlippedIncluded ? 'numFlipped' : '',
    isNumConstructionsIncluded ? 'numConstructions' : '',
    isAccountTypeIncluded ? 'isAccount' : '',
  ])

  const initialValues: Borrower = {
    gender: undefined,
    maritalStatus: undefined,
    citizenship: undefined,
    dateBirth: undefined,
    socialSecurityNumber: undefined,
    phone: undefined,
    phoneSecondary: undefined,
    ...(isNameIncluded ? { name: personName ?? undefined } : {}),
    ...(isCreditScoreIncluded ? { creditScore: undefined } : {}),
    ...(isEmailIncluded ? { email: undefined } : {}),
    ...(isInvitationIncluded ? { sendInvitation: false } : {}),
    ...(isNumFlippedIncluded ? { numFlipped: undefined } : {}),
    ...(isNumConstructionsIncluded ? { numConstructions: undefined } : {}),
    ...parseInitValues(data),
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={BorrowerSchema}
      onSubmit={onSave}
      validateOnChange={false}
    >
      <Form modal>
        <div>
          <Grid columnGap={24}>
            {isNameIncluded && (
              <Grid.Item xs={isCreditScoreIncluded ? 6 : 12}>
                <Field
                  name="name"
                  label="Name"
                  placeholder="Full Name"
                  required
                  autoFocus
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12} sm={6}>
              <Select
                name="gender"
                label="Gender"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Other', value: 'other' },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Date name="dateBirth" label="Date of Birth" />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <SocialSecurityNumber name="socialSecurityNumber" />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="citizenship"
                label="Citizenship"
                options={[
                  { label: 'U.S. Citizen', value: 'U.S. Citizen' },
                  { label: 'Permanent Resident', value: 'Permanent Resident' },
                  { label: 'Foreign National', value: 'Foreign National' },
                ]}
              />
            </Grid.Item>
            {isCreditScoreIncluded && (
              <Grid.Item xs={12} sm={6}>
                <Field name="creditScore" label="Credit Score" type="number" />
              </Grid.Item>
            )}
            <Grid.Item xs={12} sm={6}>
              <Select
                name="maritalStatus"
                label="Marital Status"
                options={[
                  { label: 'Married', value: 'married' },
                  { label: 'Unmarried', value: 'unmarried' },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Phone name="phone" label="Phone" />
            </Grid.Item>
            {isNumFlippedIncluded && (
              <Grid.Item xs={12} sm={6}>
                <FieldIcon
                  type="number"
                  label="Number of Flips"
                  name="numFlipped"
                  mask="#"
                />
              </Grid.Item>
            )}
            {isNumConstructionsIncluded && (
              <Grid.Item xs={12} sm={6}>
                <FieldIcon
                  type="number"
                  name="numConstructions"
                  label="Number of Projects"
                  mask="#"
                />
              </Grid.Item>
            )}

            {isAccountTypeIncluded && (
              <Grid.Item xs={12} sm={6}>
                <Select
                  name="isAccount"
                  label="Account Type"
                  portal
                  options={[
                    { label: 'Full Account', value: true },
                    { label: 'Profile', value: false },
                  ]}
                />
              </Grid.Item>
            )}
            {isEmailIncluded && (
              <Grid.Item xs={12} sm={isInvitationIncluded ? 12 : 6}>
                <Field
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Type your email"
                  fieldClassName={clsx({
                    [styles.emailWithInvitation]: isInvitationIncluded,
                  })}
                />
              </Grid.Item>
            )}
            {isInvitationIncluded && (
              <Grid.Item xs={12} className="-ml-[5px]">
                <Checkbox name="sendInvitation" label="Send Invitation" />
              </Grid.Item>
            )}
          </Grid>
        </div>
        <div className={styles.buttonsWithBack}>
          {onBack && (
            <TextLink variant="invert" onClick={onBack}>
              <Icon name={IconName.arrowLeft} size="sm" />
              Back
            </TextLink>
          )}
          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button loading={saving} type="submit">
              {saveText}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

export default FormBorrowerIndividual
