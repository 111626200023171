import { useQueryClient, InvalidateQueryFilters } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useHandleAutomationLogAction } from 'admin/hooks/use-automation'
import { useRemoveTask } from 'admin/hooks/use-tasks'
import { TaskDrawer } from 'admin/pages/Tasks/TaskDrawer'
import { pathTo } from 'admin/path-to'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { ModalDelete } from 'components/Modal/Delete'
import { Tooltip } from 'components/Tooltip'
import { useWebSocket } from 'hooks/use-websocket'
import { Task } from 'types'
import { message, toast } from 'utils/message'
import { TasksContext } from './TasksContext'

const TasksProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isTaskOpen, setIsTaskOpen] = useState(false)
  const [taskId, setTaskId] = useState<string>()
  const [backButtonText, setBackButtonText] = useState<string>()
  const [taskDeleteCallback, setTaskDeleteCallback] =
    useState<(id: string) => void>()
  const [taskSaveCallback, setTaskSaveCallback] =
    useState<(task: Task) => void>()
  const [taskBackCallback, setTaskBackCallback] = useState<() => void>()
  const [loanId, setLoanId] = useState<string>()
  const [removingId, setRemovingId] = useState<string>()
  const { mutate: removeTask, isPending: isRemoving } = useRemoveTask()
  const { mutate: handleAutomationLogAction } = useHandleAutomationLogAction()

  const { lastMessage } = useWebSocket()

  useEffect(() => {
    if (lastMessage?.type === 'automation') {
      const {
        data: { message: text, action, success, event_id },
      } = lastMessage

      if (success) {
        message.success(
          <Flex
            alignItems="center"
            justifyContent="space-between"
            className="flex-grow overflow-hidden"
            gap={16}
            onClick={() => {
              toast.dismiss(event_id)
            }}
          >
            <div className="truncate">{text}</div>
            {action && (
              <Button
                variant="secondary"
                onClick={() => {
                  handleAutomationLogAction(event_id, {
                    onSuccess: () => {
                      message.success(
                        action === 'cancel'
                          ? 'Cancelled successfully'
                          : 'Undone successfully'
                      )
                    },
                  })
                  toast.dismiss(event_id)
                }}
              >
                {action}
              </Button>
            )}
          </Flex>,
          {
            toastId: event_id,
            closeButton: false,
          }
        )
      } else {
        message.error(
          <Flex
            className="flex-grow overflow-hidden"
            alignItems="center"
            justifyContent="space-between"
            gap={16}
          >
            <div className="truncate">{text}</div>
            <Button
              variant="secondary"
              onClick={() => {
                toast.dismiss(event_id)
                navigate(pathTo('settingsAutomationsLog'))
              }}
            >
              View log
            </Button>
          </Flex>,
          {
            toastId: event_id,
            autoClose: 10000,
            closeButton: false,
          }
        )
      }
    } else if (lastMessage?.type === 'notification') {
      const {
        data: { id: event_id, html: text, path, by },
      } = lastMessage

      message.default(
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="flex-grow overflow-hidden"
          gap={16}
          onClick={() => {
            toast.dismiss(event_id)
          }}
        >
          <Flex
            alignItems="center"
            gap={8}
            className="flex-grow overflow-hidden"
          >
            {by && by.name && (
              <Tooltip content={by && by.name} className="align-middle">
                <Avatar id={(by && by.id) || 'unknown'} name={by && by.name} />
              </Tooltip>
            )}
            <div
              className="truncate align-middle h-full"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  text?.replace(new RegExp(`^${by?.name || ''}:`), '') || ''
                ),
              }}
            />
          </Flex>

          {path && (
            <Button
              variant="secondary"
              onClick={() => {
                navigate(path)
                toast.dismiss(event_id)
              }}
            >
              View
            </Button>
          )}
        </Flex>,
        {
          toastId: event_id,
          closeButton: false,
        }
      )
    } else if (lastMessage?.type === 'invalidate') {
      const { data: keys } = lastMessage || {}
      if (Array.isArray(keys || [])) {
        keys.forEach((key: string[]) => {
          queryClient.invalidateQueries(key as InvalidateQueryFilters)
        })
      }
    }
  }, [lastMessage])

  const handleOpenTask = useCallback(
    (
      taskId?: string,
      options: {
        loanId?: string
        backButtonText?: string
        onBack?: () => void
        onSave?: (task: Task) => void
        onDelete?: (id: string) => void
      } = {}
    ) => {
      setIsTaskOpen(true)
      setTaskId(taskId)
      setLoanId(options?.loanId)
      setBackButtonText(options?.backButtonText)
      setTaskDeleteCallback(() => options?.onDelete)
      setTaskSaveCallback(() => options?.onSave)
      setTaskBackCallback(() => options?.onBack)
    },
    []
  )
  const handleBackTask = useCallback(() => {
    taskBackCallback?.()
    setIsTaskOpen(false)
    setTaskId(undefined)
    setLoanId(undefined)
    setBackButtonText(undefined)
    setTaskDeleteCallback(undefined)
    setTaskSaveCallback(undefined)
    setTaskBackCallback(undefined)
  }, [taskBackCallback])
  const handleCloseTask = useCallback(() => {
    setIsTaskOpen(false)
    setTaskId(undefined)
    setLoanId(undefined)
    setBackButtonText(undefined)
    setTaskDeleteCallback(undefined)
    setTaskSaveCallback(undefined)
    setTaskBackCallback(undefined)
  }, [])
  const handleRemoveTask = useCallback(() => {
    removeTask(removingId as string, {
      onSuccess: () => {
        setIsTaskOpen(false)
        setTaskId(undefined)
        setLoanId(undefined)
        setBackButtonText(undefined)
        setRemovingId(undefined)
        setTaskSaveCallback(undefined)
        taskDeleteCallback?.(removingId as string)
      },
    })
  }, [removeTask, removingId, taskDeleteCallback])

  const value = useMemo(
    () => ({
      openTask: handleOpenTask,
      isTaskOpen,
      taskId,
      loanId,
      closeTask: handleCloseTask,
    }),
    [handleOpenTask, isTaskOpen, taskId, loanId, handleCloseTask]
  )

  return (
    <TasksContext.Provider value={value}>
      {children}
      {isTaskOpen && (
        <TaskDrawer
          loanId={loanId}
          taskId={taskId}
          backButtonText={backButtonText}
          onBack={handleBackTask}
          onClose={handleCloseTask}
          onSave={taskSaveCallback}
          onDelete={setRemovingId}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="task"
          loading={isRemoving}
          onDelete={handleRemoveTask}
          onCancel={() => setRemovingId(undefined)}
        />
      )}
    </TasksContext.Provider>
  )
}

export { TasksProvider }
