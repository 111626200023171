import { usePersons } from 'admin/hooks/use-persons'
import { Flex } from 'components/Flex'
import { Select } from 'components/Select'
import { getNodeText } from 'utils/react-text'

interface Props {
  personId: string | undefined
  placeholder: string
  onChange: ({ personId }: { personId: string }) => void
}
function SelectPerson({ personId, placeholder, onChange }: Props) {
  const { data: persons, isPending } = usePersons({
    sort: ['name', 'ASC'],
  })

  return (
    <Select
      portal
      value={personId}
      options={
        persons?.people?.map(({ id, name, email }) => ({
          value: id,
          label: (
            <Flex stack gap={4}>
              <div className="font-bold text-grey-900">{name}</div>
              <div className="text-grey-700">{email}</div>
            </Flex>
          ),
        })) || []
      }
      filterOption={(option, filter) =>
        getNodeText(option.label).toLowerCase().includes(filter.toLowerCase())
      }
      onChange={({ value }) => onChange({ personId: value as string })}
      classNames={{
        loadingIndicator: () => 'absolute left-1.5',
      }}
      isLoading={isPending}
      placeholder={isPending ? '' : placeholder}
    />
  )
}

export { SelectPerson }
