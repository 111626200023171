import { compact } from 'lodash'
import { getLoanCharges } from 'admin/services/api/loan-charges'
import { getStatus } from 'components/Badge/ChargeStatusBadge'
import { Row } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

interface Props {
  loanId: string
  ability: any
}

const downloadLoanCharges = async ({
  loanId,
  ability,
}: Props): Promise<Row[]> => {
  const charges = await getLoanCharges(loanId)
  return charges.map((charge) => ({
    'Date Charged': friendlyDate(charge.dateCharged),
    Description: charge.name,
    'Payable To':
      charge.person?.isInvestor && ability.cannot('read', 'investors')
        ? '•••••••••'
        : compact([charge.person?.companyName, charge.person?.name]).join(
            ' - '
          ),
    'Date Due': friendlyDate(charge.dateDue as string),
    'Original Amount': formatUsd(charge.amount),
    'Amount Due': formatUsd(charge.amountDue),
    Status: getStatus(charge.amount, charge.amountDue),
  }))
}

export { downloadLoanCharges }
