import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ACTION_CONNECT_MAIL_HIDDEN } from 'constants/local-storage-keys'
import { useSession } from 'hooks/use-session'
import connectYourAccountLoansImg from 'images/connect-your-account-loans.png'
import connectYourAccountThreadsImg from 'images/connect-your-account-threads.png'

interface IProps {
  smallVersion?: boolean
}

const BannerConnectMail = ({ smallVersion }: IProps) => {
  const navigate = useNavigate()
  const { user } = useSession()

  const [isVisibleConnectMail, setIsVisibleConnectMail] = useState(
    !user?.admin?.emailConnectionType &&
      localStorage.getItem(ACTION_CONNECT_MAIL_HIDDEN) !== '1'
  )
  const closePanel = useCallback(() => {
    localStorage.setItem(ACTION_CONNECT_MAIL_HIDDEN, '1')
    setIsVisibleConnectMail(false)
  }, [])
  const handleConnectClick = useCallback(() => {
    navigate(pathTo('accountMail'))
  }, [navigate])

  return (
    isVisibleConnectMail && (
      <>
        {smallVersion ? (
          <Flex
            gap={0}
            className="w-full my-4 rounded-xl bg-grey-50 text-grey-800"
          >
            <div className="xs:w-full lg:w-1/2 pt-10 px-12 pb-12">
              <div className="text-4xl font-bold">
                Connect to Gmail or Outlook
              </div>
              <div className="mt-4 text-lg leading-5">
                Connect to send and receive email from your Gmail or Outlook
                accounts, right within Baseline
              </div>
              <Flex stack gap={8} className="mt-6">
                <div className="font-bold leading-5">Security built-in</div>
                <Flex gap={10} alignItems="center" className="leading-[19px]">
                  <Icon name={IconName.lock} />
                  <div>Private unless linked to a loan or contact</div>
                </Flex>
                <Flex gap={10} alignItems="center" className="leading-[19px]">
                  <Icon name={IconName.info} />
                  <div>Baseline will never email contacts on your behalf</div>
                </Flex>
              </Flex>
              <Flex alignItems="center" className="mt-14">
                <Button
                  size="large"
                  variant="primary"
                  onClick={handleConnectClick}
                >
                  Connect to Gmail or Outlook
                  <Icon name={IconName.arrowRightLong} className="ml-0.5" />
                </Button>
                <Button size="large" variant="ghost" onClick={closePanel}>
                  Maybe Later
                </Button>
              </Flex>
            </div>
            <Flex
              justifyContent="center"
              className="w-1/2 max-w-[600px] ml-auto xs:hidden lg:flex"
            >
              <div
                className={
                  'center w-full h-full rounded-r-xl !bg-cover !bg-center !bg-no-repeat'
                }
                style={{ background: `url(${connectYourAccountThreadsImg})` }}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex
            gap={20}
            justifyContent="space-between"
            className="w-full my-4 py-6 px-8 rounded-xl bg-grey-50 text-grey-800 xs:!flex-col lg:!flex-row"
          >
            <Flex gap={20} alignItems="center">
              <Flex
                justifyContent="center"
                className="min-w-[65px] h-[60px] xs:hidden lg:flex"
              >
                <div
                  className={
                    'center w-full h-full !bg-cover !bg-center !bg-no-repeat'
                  }
                  style={{ background: `url(${connectYourAccountLoansImg})` }}
                />
              </Flex>
              <div>
                <div className="text-2xl font-bold">
                  Connect your Gmail or Outlook account
                </div>
                <div className="mt-2 text-lg leading-5">
                  Connect to send and receive email from your Gmail or Outlook
                  accounts, right within Baseline
                </div>
              </div>
            </Flex>
            <Flex alignItems="center" className="xs:hidden lg:flex">
              <Button variant="ghost" onClick={closePanel}>
                Maybe Later
              </Button>
              <Button variant="primary" onClick={handleConnectClick}>
                Connect to Gmail or Outlook
                <Icon name={IconName.arrowRightLong} className="ml-0.5" />
              </Button>
            </Flex>
            <Flex alignItems="center" className="xs:flex lg:hidden">
              <Button variant="primary" onClick={handleConnectClick}>
                Connect
                <Icon name={IconName.arrowRightLong} className="ml-0.5" />
              </Button>
              <Button variant="ghost" onClick={closePanel}>
                Maybe Later
              </Button>
            </Flex>
          </Flex>
        )}
      </>
    )
  )
}

export { BannerConnectMail }
