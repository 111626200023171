import { Grid } from 'components/Grid'
import { Summary } from 'components/Summary'
import { Field, FieldValue } from 'types'
import { formatField } from 'utils/fields'
import styles from './styles.module.scss'
import { InlineSummary } from './index'

interface Props {
  field: Field
  save: (value: FieldValue) => Promise<unknown>
  size?: number
}

function InlineEditGridItem({ field, save, size = 6 }: Props) {
  return (
    <Grid.Item xs={size} key={field.id}>
      {field.viewOnly ? (
        <Summary name={field.name} className={styles.readOnly}>
          {formatField(field)}
        </Summary>
      ) : (
        <InlineSummary
          name={field.name}
          type={field.type[0]}
          value={field.property.value[0]}
          options={field.options}
          mask={field.mask}
          fullAccuracy={field.fullAccuracy}
          multiline
          save={save}
        />
      )}
    </Grid.Item>
  )
}

export default InlineEditGridItem
