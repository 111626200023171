import { Children, ReactNode, useEffect } from 'react'
import { DndProvider, useDragDropManager } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Icon, IconName } from '../Icon'
import Column from './Column'
import Item from './Item'
import styles from './styles.module.scss'

interface BoardProps {
  loading?: boolean
  children: ReactNode
  onStateChange?: (state: { isDragging: boolean }) => void
}

interface DnDBoardProps extends BoardProps {
  isEmpty: boolean
  empty: ReactNode
}

function Board({ loading, children, onStateChange }: BoardProps) {
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  useEffect(
    () =>
      monitor.subscribeToStateChange(() => {
        monitor.getItemType() === 'item' &&
          onStateChange?.({
            isDragging: monitor.isDragging(),
          })
      }),
    [monitor, onStateChange]
  )

  return (
    <div
      className={styles.board}
      style={{
        gridTemplateColumns: `repeat(${Children.count(children)}, 1fr)`,
      }}
    >
      {children}
      {loading && (
        <div
          className={styles.loaderWrapper}
          style={{ gridColumn: `span ${Children.count(children)}` }}
        >
          <Icon name={IconName.loaderSpinner} className="spinner" />
          Loading...
        </div>
      )}
    </div>
  )
}

function DndBoard({
  loading,
  onStateChange,
  children,
  isEmpty,
  empty,
}: DnDBoardProps) {
  return isEmpty ? (
    <>{empty}</>
  ) : (
    <DndProvider backend={HTML5Backend}>
      <Board loading={loading} onStateChange={onStateChange}>
        {children}
      </Board>
    </DndProvider>
  )
}

DndBoard.Column = Column
DndBoard.Item = Item

export type { BoardProps }
export default DndBoard
