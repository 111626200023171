import { useCallback, useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Text } from 'components/Text'
import { APPLICATION_SOW_HIDDEN } from 'constants/local-storage-keys'
import promoSowImg from 'images/promo-sow.png'

const supportLink = 'https://help.baselinesoftware.com/en/'

function Banner() {
  const [isVisiblePanel, setIsVisible] = useState(
    localStorage.getItem(APPLICATION_SOW_HIDDEN) !== '1'
  )
  const closePanel = useCallback(() => {
    localStorage.setItem(APPLICATION_SOW_HIDDEN, '1')
    setIsVisible(false)
  }, [])
  return (
    <>
      {isVisiblePanel && (
        <Flex
          justifyContent="space-between"
          className="my-6 rounded overflow-hidden bg-grey-75 !flex-col lg:!flex-row mb-0"
          gap={0}
        >
          <Flex
            stack
            justifyContent="center"
            className="px-10 pt-10 lg:pt-0"
            gap={16}
          >
            <Header variant="h3">Customize Your Worksheets</Header>
            <Text className="text-lg text-grey-800 max-w-80">
              Create custom worksheets for your borrowers to complete. Create
              and configure your worksheets below for all your needs, including
              rehab and new construction.
            </Text>
            <Flex gap={10} className="mt-4">
              <Button
                variant="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(supportLink, '_blank')
                }}
              >
                Learn more
              </Button>
              <Button variant="ghost" onClick={closePanel}>
                Dismiss
              </Button>
            </Flex>
          </Flex>
          <Flex className="mt-10 lg:mt-0" justifyContent="center">
            <div
              className="h-[301px] w-[567px]  rounded !bg-cover !bg-center !bg-no-repeat"
              style={{ background: `url(${promoSowImg})` }}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}

export { Banner }
