export const changeFavicon = (faviconURL: string) => {
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.head.appendChild(link)
  }
  link.href = faviconURL
  console.log('Favicon changed to:', faviconURL)
}
