import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { uniqueId } from 'lodash'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SelectRecipient } from 'admin/components/SelectRecipient'
import { FormValues as FieldFormValues } from 'admin/pages/Product/ModalField'
import { useProductContext } from 'admin/pages/Product/ProductContext'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import TableHead from 'components/Table/Head'
import tableStyles from 'components/Table/styles.module.scss'
import { Toggle } from 'components/Toggle'
import { Field } from 'types'
import { InputField } from './InputField'
import { Row } from './Row'
import styles from './styles.module.scss'

const dataToRows = (data: Section[]) =>
  data.reduce(
    (rows: (Field | SectionRow | AddFieldRow)[], section: Section) => {
      rows.push(
        {
          id: `section-name-${section.section}`,
          sectionName:
            section.section === 'Tranches' ? 'Classes' : section.section,
        },
        ...(section.fields as Field[]),
        {
          id: `add-field-${section.section}`,
          action: 'add-field',
          section: section.section,
        }
      )
      return rows
    },
    []
  )

type Section = { section: string; fields: Field[] }
type SectionRow = { id: string; sectionName: string }
type AddFieldRow = { id: string; action: string; section: string }
type Row = Field | SectionRow | AddFieldRow

type Props = {
  data?: Section[]
  variables?: string[]
  onReorder: (fields: Field[]) => void
  onChange: (
    field: Field,
    fieldValues: Partial<FieldFormValues> & { order?: number }
  ) => void
  onDefaultChange: (
    field: Field,
    data: { value?: string; formula?: string; personId?: string }
  ) => void
  onAddField: (section: string) => void
  onEditField: (field: Field) => void
  onDeleteField: (field: Field) => void
  loading?: boolean
}

function TableProductChargesFields({
  data = [],
  variables,
  onReorder,
  onChange,
  onDefaultChange,
  onAddField,
  onEditField,
  onDeleteField,
  loading,
}: Props) {
  const { loan, productId } = useProductContext()
  const columns: ColumnDef<Row>[] = useMemo(
    () => [
      {
        header: '',
        size: 65,
        accessorKey: 'enabled',
        cell: ({ row }) => (
          <Toggle
            key={uniqueId()}
            checked={['on', 'always-on'].includes(
              (row.original as Field).property.enabled as string
            )}
            disabled={(row.original as Field).property.enabled === 'always-on'}
            onChange={(e) => {
              onChange(row.original as Field, { enabled: e.target.checked })
            }}
          />
        ),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        size: 270,
      },
      {
        header: 'Description',
        accessorKey: 'description',
        size: 'auto' as unknown as number,
        cell: ({ getValue }) => (
          <div className={styles.textWrap}>{getValue() as string}</div>
        ),
      },
      {
        header: 'Payable to',
        accessorKey: 'property.payableTo',
        size: 200,
        cell: ({ row }) => {
          const field = row.original as Field
          return (
            <SelectRecipient
              value={field.property.personId}
              allowEmpty
              onSelect={({ id }) =>
                onDefaultChange(field, {
                  value: field.property.value?.[0] as string,
                  formula: field.property.formula as string,
                  personId: id,
                })
              }
            />
          )
        },
      },
      {
        header: 'Default',
        size: 200,
        accessorKey: 'type',
        cell: ({ row }) =>
          !(row.original as Field).viewOnly && (
            <InputField
              loanId={loan?.id}
              productId={productId}
              field={
                {
                  ...row.original,
                  id: row.original.id.startsWith('new-field-')
                    ? 'formula'
                    : row.original.id,
                } as Field
              }
              variables={variables}
              onChange={(data: { value?: string; formula?: string }) =>
                onDefaultChange(row.original as Field, data)
              }
              singleLine
            />
          ),
      },
      {
        size: 40,
        id: 'actions',
        cell: ({ cell }) =>
          (cell.row.original as Field).editable && (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onEditField(cell.row.original as Field)}
              >
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDeleteField(cell.row.original as Field)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          ),
      },
    ],
    [variables, onChange, onDefaultChange, onEditField, onDeleteField]
  )
  const [rows, setRows] = useState(dataToRows(data))
  useEffect(() => {
    setRows(dataToRows(data))
  }, [data])

  const moveRow = useCallback(
    (section: string, dragId: string, hoverId: string) => {
      const sectionRows = (rows as Field[]).filter((row) =>
        [row.section, row.sectionServicing].includes(section)
      )
      const dragRow = sectionRows.find((row) => row.id === dragId) as Field
      const hoverRow = sectionRows.find((row) => row.id === hoverId) as Field

      rows.splice(
        rows.findIndex((row) => row === dragRow),
        1,
        rows.splice(
          rows.findIndex((row) => row === hoverRow),
          1,
          dragRow
        )[0]
      )
      setRows([...rows].map((row, index) => ({ ...row, order: index })))
    },
    [rows]
  )

  const handleReorder = useCallback(() => {
    onReorder(
      rows.filter(
        (row) =>
          !(row as AddFieldRow).action && !(row as SectionRow).sectionName
      ) as Field[]
    )
  }, [rows, onReorder])

  const table = useReactTable<Row>({
    data: rows,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <DndProvider backend={HTML5Backend}>
      <table className={clsx(tableStyles.table, styles.table)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th className={tableStyles.th} />
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={tableStyles.th}
                  style={header.getSize() ? { width: header.getSize() } : {}}
                >
                  <TableHead {...header.getContext()}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!loading && table.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={columns.length + 1}>
                <div className={tableStyles.tableBodyContent}>No data</div>
              </td>
            </tr>
          )}
          {loading && table.getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1}>
                <div className={tableStyles.tableBodyContent}>
                  <Icon name={IconName.loaderSpinner} className="spinner" />
                  Loading...
                </div>
              </td>
            </tr>
          ) : (
            <>
              {table.getRowModel().rows.map((row) => (
                <Row
                  key={row.original.id}
                  page="charges"
                  row={row}
                  onReorder={handleReorder}
                  onMoveRow={moveRow}
                  onAddField={onAddField}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </DndProvider>
  )
}

export { TableProductChargesFields }
