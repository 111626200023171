import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import {
  useLoanComments,
  useUpdateLoanComment,
} from 'admin/hooks/use-loan-comments'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { Button } from 'components/Button'
import { Comments } from 'components/Comments'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Loan } from 'types'

interface Props {
  loan: Loan
}

const PinnedComments = ({ loan }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const { comments, openComments } = useLoanContext()
  const { mutate: updateComment } = useUpdateLoanComment(loan.id)
  const { data: pinnedComments } = useLoanComments({
    loanId: loan.id,
    filter: { isPinned: [true] },
    params: { page: 0, size: 100 },
  })
  const isButtonVisible = useMemo(
    () =>
      isExpanded &&
      comments &&
      pinnedComments &&
      (comments?.total || 0) > (pinnedComments?.total || 0),
    [isExpanded, comments, pinnedComments]
  )
  const unpinComment = useCallback(
    (commentId: string) => {
      updateComment({ id: commentId, isPinned: false })
    },
    [updateComment]
  )

  const isVisible = useMemo(
    () => !!comments?.total && !!pinnedComments?.total,
    [comments, pinnedComments]
  )

  return (
    isVisible && (
      <div className="bg-grey-75 p-3 rounded">
        <Flex stack gap={12}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className="cursor-pointer"
            onClick={() => setIsExpanded((currentValue) => !currentValue)}
          >
            <Flex gap={8} alignItems="center" className="h-[22px] pl-2">
              <Icon name={IconName.pin} className="text-grey-500" />
              <div className="font-bold">Pinned Comments</div>
              {!isExpanded && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="bg-purple-50 text-purple-300 rounded-sm px-2 h-[22px]"
                >
                  {pinnedComments!.total}
                </Flex>
              )}
            </Flex>
            <Button icon variant="ghost">
              <Icon
                name={IconName.arrowDown}
                className={clsx(isExpanded && 'rotate-180')}
              />
            </Button>
          </Flex>

          {isExpanded && (
            <Comments
              comments={pinnedComments!.comments}
              hidePinIcon
              onUnpin={({ id }) => unpinComment(id as string)}
              onView={(comment) => openComments({ commentId: comment.id })}
            />
          )}
        </Flex>

        {isButtonVisible && (
          <Button
            className="w-full"
            variant="ghost"
            onClick={() => openComments()}
          >
            View All Comments
            <Icon name={IconName.arrowRightLong} className="text-grey-600" />
          </Button>
        )}
      </div>
    )
  )
}

export { PinnedComments }
