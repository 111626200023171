import { addDays, format, parseISO } from 'date-fns'
import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { useAddLoanModification } from 'admin/hooks/use-loan-modification'
import { useAddQuote } from 'admin/hooks/use-quotes'
import { TabGeneralActions } from 'admin/pages/Loan/TabGeneralActions'
import { pathTo } from 'admin/path-to'
import { TabLoanApplication } from 'components/Application/TabLoanApplication'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { message } from 'utils/message'
import { DrawerModifications } from './DrawerModifications'
import { useLoanContext } from './LoanContext'
import { ModificationModal } from './ModificationModal'
import TabActivities from './TabActivities'
import TabAmortization from './TabAmortization'
import TabBorrower from './TabBorrower'
import TabCharges from './TabCharges'
import { TabCollateral } from './TabCollateral'
import { TabDocuments } from './TabDocuments'
import TabEmailLog from './TabEmailLog'
import TabFunding from './TabFunding'
import TabGeneralServicing from './TabGeneralServicing'
import { TabMail } from './TabMail'
import TabNotifications from './TabNotifications'
import TabPayments from './TabPayments'
import TabScopeOfWork from './TabScopeOfWork'
import TabSettings from './TabSettings'
import TabStatements from './TabStatements'
import TabVendors from './TabVendors'
import styles from './styles.module.scss'

function LoanViewServicing() {
  const { user } = useSession()
  const ability = useAbilitiesContext()
  const { id, tab } = useParams() as { id: string; tab: string }
  const navigate = useNavigate()
  const [action, setAction] = useState<string | null>(null)
  const {
    loan,
    settings,
    isTimelineOpen,
    editModification,
    closeTimeline,
    openEditModification,
    closeEditModification,
    modifications,
    isModificationsLoading,
  } = useLoanContext()
  const { mutate: addModification, isPending: isAdding } =
    useAddLoanModification(loan.id)
  const { mutate: addQuote } = useAddQuote()

  const handleAddModification = useCallback(() => {
    const lastModification = modifications?.[0]
    addModification(
      {
        dateEffective: format(
          addDays(
            lastModification?.dateEffective
              ? parseISO(lastModification.dateEffective)
              : new Date(),
            1
          ),
          'yyyy-MM-dd'
        ),
      },
      {
        onSuccess: (modification) =>
          openEditModification({ ...modification, isNew: true }),
      }
    )
  }, [modifications, addModification])

  const handleEditModification = useCallback(
    (id) =>
      openEditModification(modifications?.find((m) => m.id === id) || null),
    [modifications]
  )

  const showQuotes = ability.can('read', 'quotes')

  return (
    <>
      <Tabs
        key={tab}
        defaultActiveId={tab || 'loanTerms'}
        onTabSelect={(tabId) => navigate(pathTo('servicingLoanTab', id, tabId))}
        className={styles.tabs}
        actions={[
          ...(showQuotes
            ? [
                {
                  label: 'Generate Quote',
                  icon: IconName.quote,
                  onSelect: () => {
                    const hideMessage = message.progress(
                      'Generating Quote…',
                      2000
                    )

                    addQuote(
                      { loanId: loan.id },
                      {
                        onSuccess: (quote) => {
                          hideMessage()
                          navigate(pathTo('quote', 'all', quote.id))
                        },
                      }
                    )
                  },
                },
              ]
            : []),
          {
            label: 'Mark Loan as Sold',
            icon: IconName.sold,
            disabled: loan.status === 'sold',
            onSelect: () => {
              setAction('sold')
            },
          },
          {
            label:
              loan.status === 'servicing'
                ? 'Rollback from Servicing'
                : 'Move to Servicing',
            icon: IconName.moveFile,
            onSelect: () => {
              setAction(loan.status === 'servicing' ? 'rollback' : 'servicing')
            },
          },
          {
            label: 'Share with Funding Partner',
            icon: IconName.send,
            disabled:
              !loan.product || loan.product?.clientId === user?.client.id,
            onSelect: () => {
              setAction('share')
            },
          },
          {
            label: loan.status === 'archived' ? 'Restore' : 'Archive',
            icon: IconName.archive,
            onSelect: () => {
              setAction(loan.status === 'archived' ? 'restore' : 'archive')
            },
          },
          {
            label: 'Duplicate',
            icon: IconName.duplicate,
            onSelect: () => {
              setAction('duplicate')
            },
          },
          {
            label: 'Delete',
            variant: 'danger',
            icon: IconName.delete,
            onSelect: () => {
              setAction('delete')
            },
          },
        ]}
      >
        <Tabs.Pane tab="General" id="loanTerms">
          <TabGeneralServicing loan={loan} />
        </Tabs.Pane>
        {ability.can('list', 'loan-documents') ? (
          <Tabs.Pane tab="Documents" id="documents">
            <TabDocuments loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        {ability.can('read', 'borrowers') ? (
          <Tabs.Pane tab="Borrower" id="borrower">
            <TabBorrower loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        <Tabs.Pane tab="Charges" id="charges">
          <TabCharges loan={loan} />
        </Tabs.Pane>
        {ability.can('read', 'investors') ? (
          <Tabs.Pane tab="Funding" id="funding">
            <TabFunding loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        {ability.can('read', 'payments') ? (
          <Tabs.Pane tab="Payments" id="payments">
            <TabPayments loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        <Tabs.Pane
          tab="Collateral"
          id="collateral"
          className={styles.tabWithNavigation}
          group="More"
        >
          <TabCollateral loan={loan} />
        </Tabs.Pane>
        {settings.showBudget ? (
          <Tabs.Pane
            tab="Scope of Work"
            id="scopeOfWork"
            className={styles.tabWithNavigation}
            disabled={!loan.addresses?.[0]?.id}
            group="More"
          >
            <TabScopeOfWork loan={loan} />
          </Tabs.Pane>
        ) : null}
        <Tabs.Pane tab="Statements" id="statements" group="More">
          <TabStatements loan={loan} />
        </Tabs.Pane>
        {ability.can('read', 'payments') ? (
          <Tabs.Pane tab="Amortization Schedule" id="amortization" group="More">
            <TabAmortization loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        {loan.hasApplication ? (
          <Tabs.Pane tab="Application" id="application" group="More">
            <TabLoanApplication
              loanId={loan.id}
              applicationId={loan.applicationId}
            />
          </Tabs.Pane>
        ) : null}
        {ability.can('read', 'vendors') ? (
          <Tabs.Pane tab="Contacts" id="contacts" group="More">
            <TabVendors loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        {ability.can('read', 'change-log') ? (
          <Tabs.Pane tab="Change Log" id="changeLog" group="More">
            <TabActivities loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        {ability.can('read', 'communications') ? (
          <Tabs.Pane tab="Email Log" id="emailLog" group="More">
            <TabEmailLog loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        <Tabs.Pane tab="Notifications" id="notifications" group="More">
          <TabNotifications loan={loan} />
        </Tabs.Pane>
        {ability.can('read', 'loan-settings') ? (
          <Tabs.Pane tab="Settings" id="settings" group="More">
            <TabSettings loan={loan} />
          </Tabs.Pane>
        ) : (
          <></>
        )}
        {ability.can('read', 'communications') &&
        user?.client.settings?.showCommunications ? (
          <Tabs.Pane
            tab={
              <Flex alignItems="center" gap={4}>
                <Icon name={IconName.envelope} /> Mail
              </Flex>
            }
            tabClassName="relative ml-8 before:-top-0 before:-left-6 before:absolute before:h-[34px] before:content-[''] before:border-0 before:border-l before:border-grey-200 before:border-solid"
            id="mail"
          >
            <TabMail />
          </Tabs.Pane>
        ) : (
          <></>
        )}
      </Tabs>

      {isTimelineOpen && (
        <DrawerModifications
          loan={loan}
          modifications={modifications}
          isLoading={isModificationsLoading}
          isAddingModification={isAdding}
          onEditModification={handleEditModification}
          onAddModification={handleAddModification}
          onClose={closeTimeline}
        />
      )}
      {!!editModification && (
        <ModificationModal
          loanId={loan.id}
          modifications={modifications}
          modification={editModification}
          onClose={closeEditModification}
        />
      )}
      <TabGeneralActions loan={loan} action={action} setAction={setAction} />
    </>
  )
}

export { LoanViewServicing }
