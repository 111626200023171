import clsx from 'clsx'
import { useMemo } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { LoanDocument } from 'types'
import { friendlyMime, friendlySize } from 'utils/file'

interface Props {
  document: LoanDocument
  selected: boolean
  onSelect: () => void
}

const ListDocument = ({ document, selected, onSelect }: Props) => {
  const isUploaded = useMemo(() => !!document.size, [document.size])

  return (
    <Flex
      key={document.id}
      gap={8}
      alignItems="center"
      onClick={isUploaded ? onSelect : undefined}
      className={clsx(
        'py-2.5 pr-2 pl-3 text-grey-700 leading-5',
        isUploaded &&
          !selected &&
          'cursor-pointer hover:bg-grey-50 hover:text-grey-800',
        selected && 'cursor-pointer bg-purple-50 hover:text-grey-800',
        !isUploaded && '!text-grey-500'
      )}
    >
      <Checkbox disabled={!isUploaded} checked={selected} onChange={() => {}} />
      <div>{document.name}</div>
      {isUploaded && (
        <div className="text-sm text-grey-700">{`${friendlyMime(document.type || '')} - ${friendlySize(document.size)}`}</div>
      )}
    </Flex>
  )
}

export { ListDocument }
