import clsx from 'clsx'
import styles from './styles.module.scss'

interface IndicatorProps {
  color?:
    | 'gray'
    | 'grey'
    | 'green'
    | 'peach'
    | 'orange'
    | 'red'
    | 'yellow'
    | 'blue'
    | 'lime'
    | 'purple'
    | 'dark-purple'
    | 'blueberry'
    | 'raspberry'
    | undefined
  size?: 'sm' | 'md'
  className?: string
}

function Indicator({ color = 'grey', size = 'md', className }: IndicatorProps) {
  return (
    <span
      className={clsx(
        styles.indicator,
        color === 'grey' && 'bg-grey-500',
        color === 'gray' && 'bg-grey-500',
        color === 'red' && 'bg-red-50',
        color === 'peach' && 'bg-peach-100',
        color === 'orange' && 'bg-orange-100',
        color === 'yellow' && 'bg-yellow-100',
        color === 'lime' && 'bg-lime-100',
        color === 'green' && 'bg-green-100',
        color === 'blueberry' && 'bg-blueberry-100',
        color === 'blue' && 'bg-blue-100',
        color === 'purple' && 'bg-purple-100',
        color === 'dark-purple' && 'bg-purple-200',
        color === 'raspberry' && 'bg-raspberry-100',
        {
          [styles.small]: size === 'sm',
          [styles.medium]: size === 'md',
        },
        className
      )}
    />
  )
}

export type { IndicatorProps }
export default Indicator
