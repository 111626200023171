import { Formik } from 'formik'
import { set } from 'lodash'
import { useCallback } from 'react'
import { useUpdateCurrentClient } from 'admin/hooks/use-users'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Modal } from 'components/Modal'
import { LoanStatus } from 'constants/loan-status'
import { useSession } from 'hooks/use-session'
import {
  TimeBasedAlertSettings,
  TimeBasedAlertFormScheme,
  TimeBasedAlertFormValues as FormValues,
} from './TimeBasedAlertSettings'

interface Props {
  status: { label: string; value: LoanStatus }
  onCancel: () => void
}

function ModalEditStatus({ status, onCancel }: Props) {
  const { user } = useSession()
  const statusSettings = user?.client.pipelineSettings?.[status.value]
  const initialValue: FormValues = {
    enabled:
      !!statusSettings?.attentionEnabled ||
      !!statusSettings?.criticalEnabled ||
      false,
    attentionEnabled: statusSettings?.attentionEnabled || false,
    attentionTime: statusSettings?.attentionTime || null,
    attentionUnit: statusSettings?.attentionUnit || 'hours',
    criticalEnabled: statusSettings?.criticalEnabled || false,
    criticalTime: statusSettings?.criticalTime || null,
    criticalUnit: statusSettings?.criticalUnit || 'hours',
    attentionNotificationEnabled:
      statusSettings?.attentionNotificationEnabled || false,
    criticalNotificationEnabled:
      statusSettings?.criticalNotificationEnabled || false,
    owners: statusSettings?.owners || [],
    otherOwners: statusSettings?.otherOwners || [],
  }
  const { mutate: update } = useUpdateCurrentClient()

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const updatedValues = {
        ...values,
        owners: values.owners.map(({ id }) => id),
      }
      set(user as any, `client.pipelineSettings.${status.value}`, updatedValues)
      update({
        clientId: user?.client.id as string,
        pipelineSettings: {
          ...user?.client.pipelineSettings,
          [status.value]: updatedValues,
        },
      })
      onCancel()
    },
    [status]
  )

  return (
    <Modal onClose={onCancel} title={`Edit ${status.label} Stage`}>
      <Formik
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validationSchema={TimeBasedAlertFormScheme}
      >
        <Form>
          <TimeBasedAlertSettings />
          <Flex gap={16} justifyContent="flex-end" className="mt-6">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalEditStatus }
