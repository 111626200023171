import { requestWithoutToken } from 'services/request'

type createPersonPayload = {
  name: string
  companyName?: string
  email: string
  subdomain: string
}

const createBorrowerAccount = async (body: createPersonPayload) => {
  const { data } = await requestWithoutToken.put('/borrower', body)
  return data
}

const createInvestorAccount = async (body: createPersonPayload) => {
  const { data } = await requestWithoutToken.put('/investor', body)
  return data
}

export { createBorrowerAccount, createInvestorAccount }
