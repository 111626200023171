import { useMemo } from 'react'
import { useBorrowerDocuments } from 'admin/hooks/use-borrower-documents'
import { ListDocument } from 'admin/pages/Thread/ThreadForm/ModalAttachDocs/ListDocument'
import { PageLoader } from 'components/LoaderOverlay'
import { useInvestorDocuments } from 'hooks/use-investor-documents'
import { LoanDocument } from 'types'
import { Section } from 'utils/loan-document-sections'
import { ListEmpty } from './ListEmpty'
import { Item } from './ModalAttachDocs'

interface Props {
  currentItem: Item
  selectedDocuments: string[]
  onSelectDocument: (section: Section, document: LoanDocument) => void
}

const ListOfContactDocuments = ({
  currentItem,
  selectedDocuments,
  onSelectDocument,
}: Props) => {
  const { data: borrowerDocuments, isFetching: isBorrowerFetching } =
    useBorrowerDocuments(
      {
        id: currentItem.id,
      },
      { enabled: currentItem.type === 'borrower' }
    )
  const { data: investorDocuments, isFetching: isInvestorFetching } =
    useInvestorDocuments(
      {
        id: currentItem.id,
      },
      { enabled: currentItem.type === 'investor' }
    )

  const documents = useMemo(() => {
    if (borrowerDocuments) {
      return borrowerDocuments
    }
    if (investorDocuments) {
      return investorDocuments
    }
    return []
  }, [borrowerDocuments, investorDocuments])

  return (
    <>
      {isBorrowerFetching || isInvestorFetching ? (
        <PageLoader />
      ) : (
        <div>
          {documents.length ? (
            documents.map((document) => (
              <ListDocument
                key={document.id}
                document={document}
                selected={selectedDocuments.includes(document.id)}
                onSelect={() =>
                  onSelectDocument(
                    { name: '', section: '', documents: [document] },
                    document
                  )
                }
              />
            ))
          ) : (
            <ListEmpty />
          )}
        </div>
      )}
    </>
  )
}

export { ListOfContactDocuments }
