import { $applyNodeReplacement, DecoratorNode, DOMExportOutput } from 'lexical'
import { lazy, Suspense } from 'react'
import type {
  DOMConversionMap,
  DOMConversionOutput,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

const VariableComponent = lazy(
  () => import('admin/components/InlineWysiwyg/nodes/VariableComponent')
)

export type SerializedVariableNode = Spread<
  {
    variable: string
  },
  SerializedLexicalNode
>

function convertVariableElement(
  domNode: HTMLElement
): null | DOMConversionOutput {
  const variable = domNode.getAttribute('data-lexical-variable')
  if (variable) {
    const node = $createVariableNode(variable)
    return { node }
  }

  return null
}

export class VariableNode extends DecoratorNode<JSX.Element> {
  __variable: string

  static getType(): string {
    return 'variable'
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variable, node.__key)
  }

  constructor(variable: string, key?: NodeKey) {
    super(key)
    this.__variable = variable
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    return $createVariableNode(serializedNode.variable)
  }

  exportJSON(): SerializedVariableNode {
    return {
      variable: this.getVariable(),
      type: 'variable',
      version: 1,
    }
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span')
    element.className = 'editor-variable'
    return element
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span')
    element.setAttribute('data-lexical-variable', this.__variable)
    element.innerHTML = this.__variable
    return { element }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-variable')) {
          return null
        }
        return {
          conversion: convertVariableElement,
          priority: 1,
        }
      },
    }
  }

  // @todo can be removed?
  updateDOM(): boolean {
    return false
  }

  getTextContent(): string {
    return this.__variable
  }

  getVariable(): string {
    return this.__variable
  }

  setVariable(variable: string): void {
    const writable = this.getWritable()
    writable.__variable = variable
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <VariableComponent variable={this.__variable} nodeKey={this.__key} />
      </Suspense>
    )
  }
}

export function $createVariableNode(variable = ''): VariableNode {
  const variableNode = new VariableNode(variable)
  return $applyNodeReplacement(variableNode)
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode
}
