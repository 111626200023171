import { compact } from 'lodash'
import { Fragment, useMemo } from 'react'
import { displayField } from 'admin/pages/Settings/Application/helpers'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IApplicationCondition, IApplicationFullCondition } from 'types'

interface Props {
  condition: IApplicationFullCondition
  onEdit: (condition: IApplicationFullCondition) => void
  onDelete: (condition: IApplicationFullCondition) => void
}

const operatorToLabel: Record<
  IApplicationCondition['conditions']['0']['operator'],
  string
> = {
  eq: 'is',
  gt: 'is greater than',
  gte: 'is greater than or equal to',
  lt: 'is less than',
  lte: 'is less than or equal to',
}

const actionToLabel: Record<
  IApplicationCondition['actions']['0']['action'],
  string
> = {
  'hide-fields': 'Hide',
  'hide-pages': 'Hide',
  'require-fields': 'Require',
  'not-require-fields': "Don't require",
}

const Condition = ({ condition, onEdit, onDelete }: Props) => {
  const iconName = useMemo(
    () =>
      condition.actions.some((action) => action.action.includes('hide'))
        ? IconName.eyeCrossed
        : IconName.required,
    [condition]
  )

  return (
    <div className="border border-solid border-grey-200 rounded bg-white-100 px-5 py-4">
      <Flex className="border-0 border-b border-solid border-grey-200 pb-3">
        <Flex
          justifyContent="center"
          alignItems="center"
          className="bg-purple-50 text-purple-200 h-8 w-8 flex-shrink-0 rounded"
        >
          <Icon size="lg" name={iconName} />
        </Flex>

        <Flex stack gap={8} className="flex-grow overflow-hidden">
          {condition.conditions.map((condition, index) => (
            <div key={condition.id} className="leading-7">
              {index > 0 && <span>and </span>}
              <span className="font-bold">{condition.field.label}</span>{' '}
              <span>{operatorToLabel[condition.operator]}</span>{' '}
              {compact([condition.value])
                .flat()
                .map((value, index) => (
                  <Fragment key={index}>
                    {index !== 0 && <span> or </span>}
                    <span className="inline-block leading-6 align-middle px-2 bg-grey-100 rounded-sm font-bold max-w-80 truncate">
                      {displayField({ ...condition.field, value })}
                    </span>
                  </Fragment>
                ))}
            </div>
          ))}
        </Flex>

        <div>
          <EllipsesActions>
            <EllipsesActions.Item icon onSelect={() => onEdit(condition)}>
              <Icon name={IconName.edit} />
              Edit
            </EllipsesActions.Item>
            <EllipsesActions.Item
              icon
              onSelect={() => onDelete(condition)}
              variant="danger"
            >
              <Icon name={IconName.delete} />
              Delete
            </EllipsesActions.Item>
          </EllipsesActions>
        </div>
      </Flex>
      <Flex stack gap={8} className="pt-3 ml-12 overflow-hidden">
        {condition.actions.map((action, index) => {
          const titles = compact([
            action.fields?.map(({ label }) => label),
            action.pages?.map(({ header }) => header),
          ]).flat()
          return (
            <div key={action.id + index} className="leading-7">
              <span className="font-bold">{actionToLabel[action.action]} </span>
              {titles.map((title, index) => (
                <Fragment key={`value-${index}`}>
                  {index > 0 && <span> and </span>}
                  <span className="inline-block leading-6 align-middle px-2 bg-grey-100 rounded-sm font-bold max-w-80 truncate">
                    {title}
                  </span>
                </Fragment>
              ))}
            </div>
          )
        })}
      </Flex>
    </div>
  )
}

export { Condition }
