import { MongoQuery, RawRule } from '@casl/ability'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { IAction, ISubject } from 'admin/components/Abilities/AbilitiesContext'
import { request, requestWithoutToken } from 'services/request'
import { Person } from 'types'
import { setAuthTokens } from '../storage'

type TokenContent = {
  admin: string
  client: string
  exp: number
  iat: number
  jti: string
  permission: 'admin' | 'borrower'
  person: null
}

type Credentials = {
  email?: string
  password?: string
  jwt?: string
  url?: string // used for OpenID Connect
  subdomain: string
  isAdmin: boolean
  authentication?: any
}

type ResetPasswordPayload = {
  email: string
  subdomain: string
}

type SuccessResponse = {
  success: boolean
}

type VerifyEmailPayload = {
  id: string
  token: string
}

type ValidateTokenPayload = {
  token: string
}

type ValidateTokenResponse = {
  valid: boolean
}

type ValidateInvitationPayload = {
  subdomain: string
  invitation: string
}

type ValidateInvitationResponse = {
  valid: boolean
  email: string
  name: string
}

type SetPasswordPayload = {
  token: string
  password: string
}

type SignUpPayload = {
  name: string
  password: string
  invitation: string
  subdomain: string
}

type Session = {
  authToken: string
  refreshToken: string
}

type BorrowerAccount = {
  id: string
  client_id: string
  isAccount: boolean
  permission: {
    role: string
  }
  type: string
  name: string
}

type CurrentPerson = {
  id: string
  name: string
  email: string
  type: 'entity' | 'individual'
  gender: string
  dateBirth: string
  citizenship: string
  creditScore: string
  maritalStatus: string
  numFlipped: string
  jurisdiction: string
  entityType: string
  companyName: string
  phone: string
  isAccount: boolean
  isBorrower: boolean
  isInvestor: boolean
  permission: {
    personId: string
    managerId: string
    role: string
  }
}

type IPipelineSettingsTime = 'since_substatus' | 'date_closing' | null
type IPipelineSettings = {
  time: IPipelineSettingsTime
  attentionEnabled: boolean
  attentionTime: number | null
  attentionUnit: 'hours' | 'days'
  criticalEnabled: boolean
  criticalTime: number | null
  criticalUnit: 'hours' | 'days'
}

type ClientSettings = {
  quoteFooter?: string
  duePaymentsDefaultAddDays?: string
  quoteShowProduct?: 'No' | 'Yes'
  borrowerStatementFooter?: string
  autoInvestorAccounting?: 'No' | 'Yes'
  generateInvestorAgreements?: 'No' | 'Yes'
  defaultSpreadRecipient?: string
  budgetOveragePercentage?: number
  investorStatementFooter?: string
  notifyExpiredAdvanceDays?: string
  offeringTermsOfAgreement?: string
  duePaymentsReminderEmailDays?: number
  jspreadsheetLicense?: string
  hideInvestors?: boolean
  hideReports?: boolean
  hideVendors?: boolean
  showCommunications?: boolean
  webhook?: string
  rollupNachaPayoutsTransactions: boolean
  mfa?: boolean
  openid?: boolean
  loanStatementPeriod?: 'monthly' | 'quarterly'
  loanStatementFormat?: 'portal' | 'download'
  investorStatementPeriod?: 'monthly' | 'quarterly'
  investorStatementFormat?: 'portal' | 'download'
}

type CurrentUser = {
  admin?: {
    id: string
    name: string
    email: string
    permission: 'admin'
    phone: null | string
    login: {
      numCredentials?: number
    }
    emailConnectionType: 'googlemail' | 'microsoftoutlook'
  }
  client: {
    id: string
    name: string
    url: string
    origination: boolean
    servicing: boolean
    paymentsEnabled: boolean
    image?: {
      url?: string
      logoUrl?: string
      statementUrl?: string
    }
    pipelineSettings?: IPipelineSettings
    subdomain: string
    settings?: ClientSettings
  }
  borrower?: Person
  actor?: CurrentPerson
  person: Person
  managing?: BorrowerAccount[]
  permissions: RawRule<[IAction, ISubject], MongoQuery>[]
}

const validPermissionsForAdmin = ['admin', 'member', 'individual']

const createSession = async ({ isAdmin, ...body }: Credentials) => {
  const { data } = await requestWithoutToken.post<Session>('/login', body, {
    params: {
      noSnakeCase: true,
    },
  })
  const { permission } = jwtDecode(data.authToken) as TokenContent
  if (isAdmin && !validPermissionsForAdmin.includes(permission)) {
    throw new Error("You don't have permissions")
  }
  setAuthTokens(data.authToken, data.refreshToken)
  return data
}

const switchSession = ({
  authToken,
  refreshToken,
}: {
  refreshToken: string
  authToken: string
}) => {
  setAuthTokens(authToken, refreshToken)
}

const endSession = async () => {
  const { data } = await request.delete('/login')
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  return data
}
const getCurrentUser = async () => {
  const { data } = await request.get<CurrentUser>('/login/current')
  return data
}

const resetPassword = async (body: ResetPasswordPayload) => {
  const { data } = await requestWithoutToken.post<SuccessResponse>(
    '/login/forgot',
    body
  )
  return data
}

const validateToken = async ({ token }: ValidateTokenPayload) => {
  const { data } = await requestWithoutToken.get<ValidateTokenResponse>(
    `/login/forgot/${token}`
  )
  return data
}

const setPassword = async ({ token, password }: SetPasswordPayload) => {
  const { data } = await requestWithoutToken.post<SuccessResponse>(
    `/login/forgot/${token}`,
    { password }
  )
  return data
}

const verifyEmail = async ({ id, token }: VerifyEmailPayload) => {
  const { data } = await requestWithoutToken.patch<SuccessResponse>(
    `/email/${id}/verify`,
    { token }
  )
  return data
}

const validateInvitation = async ({
  subdomain,
  invitation,
}: ValidateInvitationPayload) => {
  const { data } = await requestWithoutToken.get<ValidateInvitationResponse>(
    '/login/accept',
    { params: { subdomain, invitation } }
  )
  return data
}

const signUp = async (body: SignUpPayload) => {
  const { data } = await requestWithoutToken.post<SuccessResponse>(
    '/login/accept',
    body
  )
  return data
}

const getRegistrationChallenge = async ({
  subdomain,
  invitation,
  email,
  client,
}: {
  subdomain?: string
  invitation?: string
  email?: string
  client?: string
}) => {
  if (invitation || email) {
    const { data } = await requestWithoutToken.post<{
      challenge: string
      numCredentials: number
    }>('/login/authenticate', { subdomain, invitation, email, client })
    return data
  } else {
    const { data } = await request.post<{
      challenge: string
      numCredentials: number
    }>('/login/authenticate', { subdomain, client })
    return data
  }
}

const storeRegistration = async ({
  subdomain,
  invitation,
  email,
  registration,
}: {
  subdomain: string
  invitation?: string
  email?: string
  registration: any
}) => {
  if (invitation || email) {
    const requestWithoutToken = axios.create({
      baseURL: import.meta.env.VITE_APP_API_URL,
      timeout: 20000,
    })
    const { data } = await requestWithoutToken.put<{
      success?: boolean
    }>(
      '/login/authenticate/register',
      {
        subdomain,
        invitation,
        email,
        registration,
      },
      {
        params: {
          noSnakeCase: true,
        },
      }
    )
    return data
  } else {
    const { data } = await request.put<{
      success?: boolean
    }>(
      '/login/authenticate/register',
      { subdomain, registration },
      {
        params: {
          noSnakeCase: true,
        },
      }
    )
    return data
  }
}

const loginWithAuthentication = async ({
  subdomain,
  email,
  authentication,
}: {
  subdomain: string
  email?: string
  authentication: any
}) => {
  const requestWithoutToken = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
    timeout: 20000,
  })
  const { data } = await requestWithoutToken.put<{
    success?: boolean
  }>('/login/authenticate', {
    subdomain,
    email,
    authentication,
  })
  return data
}

export type {
  Credentials,
  CurrentUser,
  BorrowerAccount,
  CurrentPerson,
  IPipelineSettingsTime,
  IPipelineSettings,
}
export {
  createSession,
  endSession,
  getCurrentUser,
  resetPassword,
  validateToken,
  verifyEmail,
  setPassword,
  signUp,
  validateInvitation,
  switchSession,
  getRegistrationChallenge,
  storeRegistration,
  loginWithAuthentication,
}
