import DOMPurify from 'dompurify'
import { useEffect, useRef, useState } from 'react'
import { useRemoveMailSignature } from 'admin/hooks/use-thread-signature'
import { Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { IMailSignature } from 'types'

interface Props {
  signature: IMailSignature
  onEdit: (id: string) => void
}
function MailSignatureItem({ signature, onEdit }: Props) {
  const bodyRef = useRef<HTMLIFrameElement>(null)
  const [deleteSignatureId, setDeleteSignatureId] = useState<string>()
  const { mutate: remove, isPending: isRemoving } = useRemoveMailSignature()
  const { name, body, id, isDefault } = signature

  useEffect(() => {
    const setHeight = () => {
      if (bodyRef.current) {
        bodyRef.current.style.height = '1px'
        bodyRef.current.style.height = `${bodyRef.current.contentDocument?.body.scrollHeight ?? 0}px`
      }
    }

    if (bodyRef.current) {
      bodyRef.current.addEventListener('load', setHeight)
      bodyRef.current.contentDocument!.open()
      bodyRef.current.contentDocument!.write(DOMPurify.sanitize(body))
      bodyRef.current.contentDocument!.close()

      bodyRef.current.contentDocument!.body.style.fontFamily =
        'Arial, sans-serif'
      bodyRef.current.contentDocument!.body.style.fontSize = '13px'
      bodyRef.current.contentDocument!.body.style.color = '#343332'
      bodyRef.current.contentDocument!.body.style.overflowY = 'hidden'

      return () => bodyRef.current?.removeEventListener('load', setHeight)
    }
  }, [body])

  return (
    <div
      key={id}
      className="cursor-pointer rounded border border-solid border-grey-200 bg-white-100 p-4"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="baseline">
          <Header className="text-base text-grey-700 font-normal leading-5">
            {name}
          </Header>
          {isDefault && (
            <Badge color="gray" size="sm">
              Default
            </Badge>
          )}
        </Flex>
        <EllipsesActions>
          <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
            <Icon name={IconName.edit} />
            Edit signature
          </EllipsesActions.Item>
          <EllipsesActions.Item
            icon
            onSelect={() => setDeleteSignatureId(id)}
            className="text-red-100"
          >
            <Icon name={IconName.delete} />
            Delete signature
          </EllipsesActions.Item>
        </EllipsesActions>
      </Flex>
      <Flex stack className="mt-4">
        <iframe ref={bodyRef} className="border-0" />
      </Flex>
      {deleteSignatureId && (
        <ModalDelete
          resource="email signature"
          loading={isRemoving}
          onDelete={() =>
            remove(deleteSignatureId, {
              onSuccess: () => {
                setDeleteSignatureId(undefined)
              },
            })
          }
          onCancel={() => setDeleteSignatureId(undefined)}
        />
      )}
    </div>
  )
}

export { MailSignatureItem }
