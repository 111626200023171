import { request } from 'services/request'
import { LoanSubstatus, LoanSubstatusPayload } from 'types'

const getLoanSubstatus = async (): Promise<LoanSubstatus[]> => {
  const {
    data: { substatuses },
  } = await request.get('/substatus')
  return substatuses
}

const addLoanSubstatus = async (
  payload: LoanSubstatusPayload
): Promise<LoanSubstatus> => {
  const {
    data: { substatus },
  } = await request.post('/substatus', payload)

  return substatus
}

const updateLoanSubstatus = async (
  payload: LoanSubstatusPayload
): Promise<LoanSubstatus> => {
  const {
    data: { substatus },
  } = await request.patch(`/substatus/${payload.id}`, payload)
  return substatus
}

const deleteLoanSubstatus = async (substatusId: string) => {
  return request.delete(`/substatus/${substatusId}`)
}

export {
  getLoanSubstatus,
  addLoanSubstatus,
  updateLoanSubstatus,
  deleteLoanSubstatus,
}
