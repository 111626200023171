import { Formik } from 'formik'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field } from 'components/Form'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { subdomain } from 'constants/domain'
import { useCreateUserAccount } from 'hooks/use-users'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  pathTo: any
  investor?: boolean
}

const CreateAccountScheme = createScheme({
  email: mergeRules(email, required),
  name: required,
})

type FormType = {
  companyName?: string
  name: string
  email: string
}

const initialValues: FormType = {
  email: '',
  name: '',
  companyName: '',
}

function CreateAccount({ pathTo, investor = false }: Props) {
  const navigate = useNavigate()
  const [isAccountCreated, setIsAccountCreated] = useState(false)
  const [isExistingEmail, setIsExistingEmail] = useState(false)

  const create = useCreateUserAccount({
    investor,
    onError: (error) => {
      if (error?.response?.status === 409) {
        setIsExistingEmail(true)
        return true
      }
    },
  })

  const handleSubmit = ({ email, name, companyName }: FormType) => {
    create.mutate(
      { email, name, companyName, subdomain },
      {
        onSuccess: () => setIsAccountCreated(true),
      }
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo
          type="statement"
          to={pathTo('signIn')}
          data-testid="create-account-logo"
          logoClassName="max-h-20 max-w-40"
        />
        {isAccountCreated ? (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              You’ve created an account!
            </Header>
            <Text className={styles.description}>
              Check your email for a link to verify your account and get
              logged-in.
            </Text>
          </div>
        ) : isExistingEmail ? (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              You already have an account
            </Header>
            <Text className={styles.description}>
              We found an active account with that email address.
            </Text>
            <Button
              onClick={() => navigate(pathTo('signIn'))}
              className="mb-8"
              data-testid="create-account-login-button"
            >
              Log in now
            </Button>
            <div className={styles.divider} />
            <Flex gap={32}>
              <NavLink
                to={pathTo('forgotPassword')}
                className="link"
                data-testid="create-account-forgot-password"
              >
                Forgot password?
              </NavLink>
            </Flex>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={CreateAccountScheme}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Form
              className={styles.form}
              method="post"
              data-testid="create-account-form"
            >
              <Header variant="h1" className={styles.title}>
                Create Account
              </Header>
              <Text className={styles.description}>
                Please tell us a bit about yourself. We&apos;ll send you an
                email to confirm your account and setup your password.
              </Text>

              <Field
                name="companyName"
                label="Company Name"
                data-testid="create-account-company"
              />
              <Field
                name="name"
                label="Your Name"
                data-testid="create-account-name"
              />
              <Field
                name="email"
                label="Your Email"
                autoComplete="email"
                data-testid="create-account-email"
              />
              <Button
                type="submit"
                className="w-full"
                size="large"
                data-testid="create-account-submit"
              >
                Create
              </Button>
              <div className={styles.divider} />
              <NavLink
                to={pathTo('signIn')}
                className="link"
                data-testid="create-account-login-link"
              >
                Have an existing account? Click here to login
              </NavLink>
            </Form>
          </Formik>
        )}

        <div />
      </div>
    </div>
  )
}

export { CreateAccount }
