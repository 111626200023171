import { Formik, FieldArray } from 'formik'
import { isString, toNumber, toString } from 'lodash'
import * as yup from 'yup'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, FieldIcon, Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { TextLink } from 'components/TextLink'
import { Class } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

const FormSchema = createScheme({
  name: required,
  type: required,
  rate: yup.string().when(['type'], {
    is: (type: 'Fixed' | 'Tiered Rate') => type === 'Fixed',
    then: () => required,
  }),
  tiers: yup.array().when(['type'], {
    is: (type: 'Fixed' | 'Tiered Rate') => type === 'Tiered Rate',
    then: () =>
      yup
        .array()
        .of(
          yup.object().shape({
            amount: yup.string().required('Required'),
            percentage: yup.string().required('Required'),
          })
        )
        .test({
          name: 'next-greater',
          message:
            'The amount of each subsequent tier must be greater than the previous one.',
          test: (tiers) => {
            return tiers?.every(({ amount }, index) => {
              if (index > 1) {
                const compareToIndex = index === 2 ? 0 : index - 1

                return (
                  amount &&
                  toNumber(amount) > toNumber(tiers[compareToIndex].amount)
                )
              }
              return true
            })
          },
        }),
  }),
})

type FormValues = {
  name: string
  type: 'Fixed' | 'Tiered Rate'
  tiers?: {
    amount: string
    percentage: string
  }[]
  rate?: string
}

interface Props {
  saving: boolean
  classItem?: Class
  onSave: (classItem: Partial<Class>) => void
  onCancel: () => void
}

function ModalClass({ classItem, saving, onSave, onCancel }: Props) {
  const isEditing = !!classItem
  const initialValue: FormValues = {
    name: classItem?.name || '',
    type: classItem?.type || 'Fixed',
    rate: classItem?.rate ? classItem?.rate.toString() : '',
    tiers: classItem?.tiers?.map(({ amount, percentage }) => ({
      amount: toString(amount),
      percentage: toString(percentage),
    })) || [
      { amount: '', percentage: '' },
      { amount: '', percentage: '' },
    ],
  }

  return (
    <Modal
      title={isEditing ? 'Edit Class' : 'Add a New Class'}
      onClose={onCancel}
      className={styles.modal}
      asChild
    >
      <Formik
        initialValues={initialValue}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          onSave({
            name: values.name,
            type: values.type,
            rate: values.type === 'Fixed' ? toNumber(values.rate) : undefined,
            tiers:
              values.type === 'Tiered Rate'
                ? values.tiers?.map(({ amount, percentage }) => ({
                    amount: toNumber(amount),
                    percentage: toNumber(percentage),
                  }))
                : undefined,
          })
        }}
        validateOnChange={false}
      >
        {({ values }) => (
          <Form modal>
            <div>
              <Grid className={styles.form} columnGap={16}>
                <Grid.Item xs={12}>
                  <Field name="name" label="Class" />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Select
                    name="type"
                    label="Rate type"
                    portal
                    options={[
                      { value: 'Fixed', label: 'Fixed' },
                      { value: 'Tiered Rate', label: 'Tiered Rate' },
                    ]}
                  />
                </Grid.Item>
                {values.type === 'Fixed' && (
                  <Grid.Item xs={12}>
                    <FieldIcon
                      type="percentage"
                      name="rate"
                      label="Rate"
                      fullAccuracy
                    />
                  </Grid.Item>
                )}
                {values.type === 'Tiered Rate' && (
                  <Grid.Item xs={12}>
                    <Header variant="h4" className="mb-4">
                      Tiers
                    </Header>

                    <FieldArray name="tiers">
                      {(tiersHelpers) => (
                        <>
                          {isString(tiersHelpers.form.errors.tiers) && (
                            <div className="text-red-100 mb-4">
                              {tiersHelpers.form.errors.tiers}
                            </div>
                          )}
                          <table className="w-full">
                            <tbody>
                              {values.tiers?.map((value, index) => (
                                <tr key={index}>
                                  <td className="pr-1 align-top">
                                    <Flex stack gap={8}>
                                      <div>
                                        When investor&apos;s balance in fund is{' '}
                                        <span className="font-bold underline">
                                          {index === 0
                                            ? 'less than'
                                            : 'greater or equal to'}
                                        </span>
                                      </div>
                                      <FieldIcon
                                        type="currency"
                                        fieldClassName="mb-4"
                                        disabled={index === 1}
                                        onChange={(e) => {
                                          if (index === 0) {
                                            tiersHelpers.form.setFieldValue(
                                              'tiers.1.amount',
                                              e.target.value
                                            )
                                          }
                                        }}
                                        name={`tiers.${index}.amount`}
                                      />
                                    </Flex>
                                  </td>
                                  <td className="w-28 px-1 align-top">
                                    <Flex stack gap={8}>
                                      <div>Rate is</div>
                                      <FieldIcon
                                        type="percentage"
                                        fieldClassName="mb-4"
                                        name={`tiers.${index}.percentage`}
                                        fullAccuracy
                                      />
                                    </Flex>
                                  </td>
                                  <td className="w-4 pl-1 align-top">
                                    {index > 1 && (
                                      <div
                                        className="cursor-pointer mt-8"
                                        onClick={() =>
                                          tiersHelpers.remove(index)
                                        }
                                      >
                                        <Icon
                                          className="w-3 h-3"
                                          name={IconName.delete}
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className={styles.addValuation}>
                            <TextLink
                              onClick={() =>
                                tiersHelpers.push({
                                  amount: '',
                                  percentage: '',
                                })
                              }
                            >
                              <Icon name={IconName.plus} size="sm" /> Add Tier
                            </TextLink>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Grid.Item>
                )}
              </Grid>
            </div>
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export { ModalClass }
