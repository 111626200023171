import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewQuotesImg from 'images/empty-view-quotes.png'

interface Props {
  onClick: () => void
}

export const EmptyQuotes = ({ onClick }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={'center h-50 w-50 !bg-cover !bg-center !bg-no-repeat'}
            style={{ background: `url(${emptyViewQuotesImg})` }}
          />
        }
      />
      <EmptyView.Title title="Create a quote" />
      <EmptyView.Description description="Easily generate quotes and share them with your customers." />
      <Flex className="mt-6" justifyContent="center">
        <Button onClick={onClick}>Get Started</Button>
      </Flex>
    </EmptyView>
  )
}
