import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useUpdateQuote, useSendQuote } from 'admin/hooks/use-quotes'
import { pathTo } from 'admin/path-to'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Indicator } from 'components/Indicator'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalConvertQuoteToLoan } from 'components/Modal/ConvertQuoteToLoan'
import { ModalSendQuote } from 'components/Modal/SendQuote'
import { QuoteTermSheetPage } from 'components/QuoteTermSheetPage'
import { useDownloadPdf } from 'hooks/use-pdf'
import { useQuoteVersion } from 'hooks/use-quote'
import { Quote } from 'types'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

const breadcrumbs = { title: 'Quotes', link: pathTo('quotes') }

const QuotePreview = () => {
  const { id } = useParams() as { id: string }
  const { data: quoteVersion } = useQuoteVersion(id)
  const { mutate: sendQuote, isPending: isSendingQuote } = useSendQuote(id)
  const { mutate: updateQuote, isPending: isUpdatingQuote } = useUpdateQuote()
  const { mutate: generatePdf, isPending: isGenerating } = useDownloadPdf()
  const navigate = useNavigate()
  const [isSendVisible, setIsSendVisible] = useState(false)
  const [isSendConfirmVisible, setIsSendConfirmVisible] = useState(false)
  const [isArchiveConfirmVisible, setIsArchiveConfirmVisible] = useState(false)
  const [isConvertVisible, setIsConvertVisible] = useState(false)
  const isSent = quoteVersion?.status === 'Sent'
  const isArchived = ['Archived', 'Converted'].includes(
    quoteVersion?.status as string
  )

  const handleBack = useCallback(() => {
    navigate(pathTo('quote', 'all', id as string))
  }, [id])

  return (
    <>
      {quoteVersion ? (
        <Flex stack gap={32} className={styles.container}>
          <Helmet>
            <title>{quoteVersion.name}</title>
          </Helmet>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className={styles.header}
          >
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {quoteVersion.dateSent && (
              <Flex alignItems="center" gap={4}>
                <Indicator color="yellow" />
                Sent to borrower on {friendlyDateTime(quoteVersion.dateSent)}
              </Flex>
            )}
            <Flex>
              {!isArchived && (
                <Button variant="tertiary" onClick={handleBack}>
                  Edit
                </Button>
              )}
              {!isArchived && (
                <Button
                  variant="tertiary"
                  onClick={() => navigate(pathTo('quotes', 'all'))}
                >
                  Save &amp; Exit
                </Button>
              )}
            </Flex>
          </Flex>

          <div className={styles.page}>
            <QuoteTermSheetPage quote={quoteVersion} />
          </div>

          {!isArchived && (
            <Flex justifyContent="space-between" className={styles.footer}>
              <Button onClick={() => handleBack()} variant="tertiary">
                Back
              </Button>
              <Flex>
                {!isSent && (
                  <Button
                    loading={isGenerating}
                    onClick={() => {
                      generatePdf(
                        {
                          url: borrowerPathTo(
                            'quoteTermSheet',
                            quoteVersion?.id as string
                          ),
                        },
                        {
                          onSuccess: () => {
                            setIsSendConfirmVisible(true)
                          },
                        }
                      )
                    }}
                    variant="tertiary"
                  >
                    Download PDF
                  </Button>
                )}
                {isSent && (
                  <Button
                    variant="tertiary"
                    onClick={() => setIsArchiveConfirmVisible(true)}
                  >
                    Archive
                  </Button>
                )}
                {isSent && (
                  <Button onClick={() => setIsConvertVisible(true)}>
                    Convert to Loan
                  </Button>
                )}
                {!isSent && (
                  <Button onClick={() => setIsSendVisible(true)}>Send</Button>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      ) : (
        <MainContent>
          <PageLoader />
        </MainContent>
      )}
      {isSendConfirmVisible && (
        <ModalConfirm
          title="Manually Sending Quote?"
          text="Please confirm if you will be sending the quote to the borrower yourself, so we can mark it as sent."
          loading={isUpdatingQuote}
          onConfirm={() => {
            updateQuote(
              {
                id,
                status: 'Sent',
              },
              {
                onSuccess: () => {
                  setIsSendConfirmVisible(false)
                },
              }
            )
          }}
          onCancel={() => setIsSendConfirmVisible(false)}
        />
      )}
      {isArchiveConfirmVisible && (
        <ModalConfirm
          title="Archive quote"
          text="Are you sure you want to archive this quote?"
          loading={isUpdatingQuote}
          onConfirm={() => {
            updateQuote(
              {
                id,
                status: 'Archived',
              },
              {
                onSuccess: () => {
                  setIsArchiveConfirmVisible(false)
                },
              }
            )
          }}
          onCancel={() => setIsArchiveConfirmVisible(false)}
        />
      )}
      {isSendVisible && (
        <ModalSendQuote
          quote={quoteVersion as Quote}
          saving={isSendingQuote}
          onSave={({ subject, message }) => {
            sendQuote(
              { subject, message },
              {
                onSuccess: () => {
                  setIsSendVisible(false)
                },
              }
            )
          }}
          onCancel={() => setIsSendVisible(false)}
        />
      )}
      {isConvertVisible && (
        <ModalConvertQuoteToLoan
          quote={quoteVersion as Quote}
          onCancel={() => setIsConvertVisible(false)}
        />
      )}
    </>
  )
}

export { QuotePreview }
