import { Phone } from 'components/Form/index'
import { Field, FieldType } from 'types/field'
import DateField from './Date'
import { CustomFieldProps } from './Field'
import FieldIcon from './FieldIcon'
import Select from './Select'

type Props = CustomFieldProps & {
  portal?: boolean
  type?: FieldType
  field: Pick<
    Field,
    'id' | 'name' | 'type' | 'options' | 'mask' | 'fullAccuracy'
  >
}

function LoanField({ field, label, type, portal = true, ...props }: Props) {
  const fieldType = type || field.type.slice(-1)[0]
  switch (fieldType) {
    case 'option':
      return (
        <Select
          name={field.id}
          label={label ?? field.name}
          options={[{ label: '(blank)', value: '' }, ...(field.options || [])]}
          portal={portal}
          {...props}
        />
      )
    case 'yes-no':
      return (
        <Select
          name={field.id}
          label={label ?? field.name}
          options={[
            { label: '(blank)', value: '' },
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          portal
          {...props}
        />
      )
    case 'date':
      return (
        <DateField name={field.id} label={label ?? field.name} {...props} />
      )
    case 'phone':
      return <Phone name={field.id} label={label ?? field.name} {...props} />
    case 'document':
      return <div />
    default:
      return (
        <FieldIcon
          type={fieldType}
          name={field.id}
          label={label ?? field.name}
          mask={field.mask}
          fullAccuracy={field.fullAccuracy}
          {...props}
        />
      )
  }
}

export default LoanField
