import { get, set } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { TableSpreadAllocation } from 'admin/components/table/TableSpreadAllocation'
import {
  useLoanSpreadCurrent,
  useInvalidateSpreadCurrent,
} from 'admin/hooks/use-loan-spread'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { ModalDefaultRecipient } from 'admin/pages/Loan/ModalDefaultRecipient'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'
import { Loan, PhaseRecipient } from 'types'
import { sumDecimal } from 'utils/math'

interface Props {
  loan: Loan
}

const PanelSpreadAllocation = ({ loan }: Props) => {
  const navigate = useNavigate()
  const { user } = useSession()
  const ability = useAbilitiesContext()
  const { mutate: update, isPending } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    defaultSpreadRecipient: '',
  })
  const [defaultRecipient, setDefaultRecipient] = useState(
    settings.defaultSpreadRecipient
  )
  const [isDefaultRecipientVisible, setIsDefaultRecipientVisible] =
    useState(false)
  const { data: spread } = useLoanSpreadCurrent(loan.id)
  const invalidateSpreadCurrent = useInvalidateSpreadCurrent(loan.id)
  const [isLoaded, setIsLoaded] = useState(false)
  const [recipients, setRecipients] = useState<
    (PhaseRecipient & { isDefault?: boolean })[]
  >([])

  useEffect(() => {
    if (spread) {
      const assignedPercent =
        sumDecimal(spread?.people?.map(({ percentage }) => percentage)) || 0

      setRecipients([
        ...(spread?.people || []),
        ...(spread?.defaultRecipient
          ? [
              {
                id: spread.defaultRecipient.id,
                name: spread.defaultRecipient.name,
                percentage: Math.max(
                  (loan?.spreadRate || 0) - assignedPercent,
                  0
                ),
                isDefault: true,
              },
            ]
          : []),
      ])
      setIsLoaded(true)
    }
  }, [loan, spread])

  return (
    <>
      <Panel
        title="Spread Allocation"
        loading={!isLoaded}
        onEdit={
          defaultRecipient && ability.can('read', 'investors')
            ? () => navigate(pathTo('loanSpreadAllocation', loan.id))
            : undefined
        }
      >
        {defaultRecipient ? (
          <>
            <div className="-mt-3 text-grey-700">As of today</div>
            <TableSpreadAllocation data={recipients} />
          </>
        ) : (
          <Flex
            alignItems="center"
            className="bg-grey-50 px-3 py-4 rounded border border-dashed border-grey-400"
          >
            Before allocating spread, you must first assign a recipient of
            surplus or deficit spread.
            <Button
              variant="secondary"
              onClick={() => setIsDefaultRecipientVisible(true)}
            >
              Assign
            </Button>
          </Flex>
        )}
      </Panel>
      {isDefaultRecipientVisible && (
        <ModalDefaultRecipient
          title="Who should be assigned surplus or deficit spread?"
          loading={isPending}
          onSelect={(recipient) => {
            const updatedSettings = {
              ...settings,
              defaultSpreadRecipient: recipient.id,
            }
            set(user as any, 'client.settings', updatedSettings)
            update(
              { clientId, settings: updatedSettings },
              {
                onSuccess: () => {
                  setIsDefaultRecipientVisible(false)
                  setDefaultRecipient(recipient.id)
                  invalidateSpreadCurrent()
                },
              }
            )
          }}
          onClose={() => setIsDefaultRecipientVisible(false)}
        />
      )}
    </>
  )
}

export default PanelSpreadAllocation
