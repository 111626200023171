import clsx from 'clsx'
import { Formik } from 'formik'
import { isEqual, isUndefined } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Success } from 'borrower/components/DraftApplication/Success'
import {
  schemeToValues,
  schemeToValidationSchema,
  isFieldVisible,
  isFieldRequired,
  isPageVisible,
} from 'borrower/components/DraftApplication/helpers'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import {
  useAddDraftApplication,
  useSignGuestApplication,
} from 'hooks/use-application'
import {
  saveDraftGuestApplication,
  deleteDraftGuestApplication,
} from 'services/storage'
import { IApplicationScheme } from 'types'
import { ApplicationField } from './ApplicationField'
import styles from './styles.module.scss'

interface Props {
  clientId: string
  scheme: IApplicationScheme
}

function GuestApplication({ clientId, scheme: initialScheme }: Props) {
  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [scheme, setScheme] = useState<IApplicationScheme>(initialScheme)

  const pages = useMemo(
    () => scheme.pages.filter((page) => isPageVisible(scheme, page)),
    [scheme]
  )
  const pageIds = useMemo(() => pages.map(({ id }) => id), [pages])
  const defaultPageId = useMemo(() => {
    const defaultPageIndex = Math.max(
      0,
      ...pages
        .map(({ complete }, index) => ({ index, complete }))
        .filter(({ complete }) => complete)
        .map(({ index }) => index)
    )
    return pages[defaultPageIndex].id
  }, [pages])
  const [currentPageId, setCurrentPageId] = useState<string>(defaultPageId)
  const currentPageIndex = useMemo(
    () => pageIds.indexOf(currentPageId),
    [pageIds, currentPageId]
  )
  const { mutate: save } = useAddDraftApplication(clientId, true)
  const { mutate: submit } = useSignGuestApplication(clientId)
  const maxAvailablePageIndex = useMemo(() => {
    return Math.max(
      Number(currentPageIndex),
      ...pages
        .map(({ complete }, index) => ({ index, complete }))
        .filter(({ complete }) => complete)
        .map(({ index }) => index)
    )
  }, [currentPageIndex, pages])
  const page = useMemo(
    () => pages[currentPageIndex]!,
    [pages, currentPageIndex]
  )
  const goToPage = useCallback(
    (pageId: string) => {
      const nextPage = pages.find((p) => p.id === pageId)
      if (isUndefined(nextPage)) {
        setIsSubmitting(true)
        save(
          { ...scheme, submitted: true },
          {
            onSuccess: ({ id }) => {
              submit(
                { applicationId: id },
                {
                  onSettled: () => {
                    setIsSubmitting(false)
                  },
                  onSuccess: () => {
                    deleteDraftGuestApplication()
                    setIsSubmitted(true)
                  },
                }
              )
            },
          }
        )
      } else {
        setCurrentPageId(pageId)
      }
    },
    [scheme, pages, currentPageIndex, submit, save]
  )
  const goToNextPage = useCallback(() => {
    goToPage(pageIds[currentPageIndex + 1])
  }, [goToPage, pageIds, currentPageIndex])
  const handleBack = useCallback(() => {
    goToPage(pageIds[currentPageIndex - 1])
  }, [goToPage, pageIds, currentPageIndex])
  const initialValues = useMemo(() => schemeToValues(page.fields), [page])
  const validationSchema = useMemo(() => {
    return schemeToValidationSchema(scheme, {
      ...page,
      fields: page.fields.map((field) => ({
        ...field,
        required:
          isFieldVisible(scheme, field) && isFieldRequired(scheme, field),
      })),
    })
  }, [page, scheme])
  const updateScheme = useCallback(
    (values, complete = false) => {
      const nextScheme = {
        ...scheme,
        pages: scheme.pages.map((page) => ({
          ...page,
          complete: page.id === currentPageId ? complete : page.complete,
          fields: page.fields.map((field) => ({
            ...field,
            ...(field.type === 'signature' &&
            !isUndefined(values[`${field.id}_checkbox`])
              ? {
                  signatureConfirmationCheckbox: values[`${field.id}_checkbox`]
                    ? new Date().toISOString()
                    : '',
                }
              : {}),
            value: isUndefined(values[field.id])
              ? field.value
              : values[field.id],
          })),
        })),
      }
      if (!isEqual(nextScheme, scheme)) {
        setScheme(nextScheme)
        saveDraftGuestApplication(nextScheme)
      }
    },
    [scheme, currentPageId]
  )

  return isSubmitted ? (
    <Success>
      <Button onClick={() => navigate(pathTo('signIn'))}>Back to Login</Button>
    </Success>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        updateScheme(values, true)
        goToNextPage()
      }}
    >
      {(formContext) => (
        <Form onChange={updateScheme}>
          <Flex stack gap={32} className={styles.panel}>
            <Flex stack gap={16}>
              <Flex>
                <div className={styles.steps}>
                  Step {currentPageIndex + 1}/{pageIds.length}
                </div>
                <EllipsesActions
                  placement="bottom-start"
                  trigger={
                    <Flex className={styles.step} alignItems="center" gap={4}>
                      {page.tag} <Icon name={IconName.arrowDown} />
                    </Flex>
                  }
                >
                  {pages.map(({ id, tag }, index) => (
                    <EllipsesActions.Item
                      key={id}
                      disabled={index > maxAvailablePageIndex}
                      onSelect={() => goToPage(id)}
                    >
                      {tag}
                    </EllipsesActions.Item>
                  ))}
                </EllipsesActions>
              </Flex>
              <Header>{page.header}</Header>
              {page.description && (
                <Text className="text-grey-600">{page.description}</Text>
              )}
            </Flex>
            <div className={styles.labels}>
              {page.fields
                .filter((field) =>
                  isFieldVisible(scheme, field, formContext.values)
                )
                .map((field) => (
                  <ApplicationField
                    key={field.id}
                    scheme={scheme}
                    field={field}
                  />
                ))}
            </div>
            <div className={styles.controls}>
              <div />
              <div className={styles.buttons}>
                {currentPageIndex !== 0 && (
                  <Button
                    variant="tertiary"
                    className={clsx('h-11 w-11 rounded-full text-xl')}
                    onClick={handleBack}
                  >
                    ←
                  </Button>
                )}
                <Button
                  type="submit"
                  loading={isSubmitting}
                  className={clsx('h-11 rounded-full px-6 text-xl shadow-0')}
                >
                  {currentPageIndex + 1 === pageIds.length
                    ? 'Submit'
                    : 'Continue →'}
                </Button>
              </div>
            </div>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export { GuestApplication }
