import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  sentence: string
  disabled?: boolean
  onBack: () => void
  onDone?: () => void
}

const MenuHeader = ({ sentence, disabled, onBack, onDone }: Props) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className="p-3 pb-0"
    >
      <Flex alignItems="center" gap={4}>
        <Icon
          name={IconName.arrowLeftLong}
          className="text-grey-600 cursor-pointer flex-shrink-0"
          onClick={onBack}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: sentence.replace('{{value}}', ''),
          }}
        />
      </Flex>
      {onDone && (
        <Button disabled={disabled} onClick={onDone}>
          Done
        </Button>
      )}
    </Flex>
  )
}

export { MenuHeader }
