import { useMemo } from 'react'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IThreadTab } from 'types'

interface Props {
  tab: IThreadTab
  loanMode?: boolean
}

const icon = {
  '': IconName.envelope,
  inbox: IconName.envelope,
  draft: IconName.draftThread,
  sent: IconName.sendThread,
  archived: IconName.archive,
  trash: IconName.delete,
}

const title = {
  '': 'Nothing to see here',
  inbox: 'Still waiting for mail',
  drafts: 'No drafts in sight',
  sent: 'Send something',
  archived: 'Nothing archived',
  trash: 'Your trash is empty',
}

const description = {
  '': 'You haven’t received any messages',
  inbox: 'You haven’t received any messages',
  drafts: 'Hit compose to start a new message',
  sent: 'You haven’t sent any messages... yet',
  archived:
    'This is where you’ll find all your messages that have been archived',
  trash: 'You haven’t moved any messages to trash yet',
}

const loanDescription = {
  '': 'You don’t have any messages linked to this loan',
  inbox: 'You haven’t received any messages that have been linked to this loan',
  drafts: 'Hit compose to start a new message',
  sent: 'You haven’t sent any messages related this loan... yet',
  archived:
    'This is where you’ll find all your messages that have been archived',
  trash: 'You haven’t moved any messages to trash yet',
}

export const Empty = ({ tab, loanMode }: Props) => {
  const emptyDescription = useMemo(
    () => (loanMode ? loanDescription : description),
    [loanMode]
  )
  const iconName = (tab === 'drafts' ? 'draft' : icon[tab]) as IconName

  return (
    <EmptyView className="mt-5">
      <EmptyView.Icon
        icon={
          <Flex
            justifyContent="center"
            alignItems="center"
            className={'h-20 w-20 bg-blueberry-25 rounded-[20px]'}
          >
            <Icon name={iconName} className="text-blueberry-200 h-12 w-12" />
          </Flex>
        }
      />
      <EmptyView.Title title={title[tab]} className="mt-5" />
      <EmptyView.Description
        description={emptyDescription[tab]}
        className="!mt-2"
      />
    </EmptyView>
  )
}
