import { ReactNode } from 'react'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewBorrowersImg from 'images/empty-view-borrowers.png'

interface Props {
  children: ReactNode
}

export const EmptyContacts = ({ children }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className="center h-50 w-50 !bg-cover !bg-center !bg-no-repeat"
            style={{ background: `url(${emptyViewBorrowersImg})` }}
          />
        }
      />
      <EmptyView.Title title="Add your contacts" />
      <EmptyView.Description description="Add contacts to connect them with their loans and track all of their information in one place." />
      <Flex className="mt-6" justifyContent="center">
        {children}
      </Flex>
    </EmptyView>
  )
}
