import { compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useGenerateLoanDocument } from 'admin/hooks/use-loan-documents'
import { useLoanType } from 'admin/hooks/use-loan-type'
import { useLoanTemplates } from 'admin/hooks/use-templates'
import styles from 'admin/pages/Loan/styles.module.scss'
import {
  mergeTemplatesWithDefault,
  defaultTemplates,
} from 'admin/services/api/templates'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { ITemplate, Loan } from 'types'
import { message } from 'utils/message'
import { ModalGeneratePayoff } from './ModalGeneratePayoff'

interface Props {
  loan: Loan
  onGenerate?: (id: string) => void
}

const GenerateDocument = ({ loan, onGenerate }: Props) => {
  const [generatingTemplateModal, setGeneratingTemplateModal] =
    useState<ITemplate>()

  const { mutate: generate } = useGenerateLoanDocument()
  const { data: customTemplates = [] } = useLoanTemplates({ loanId: loan.id })
  const { isOrigination, isServicing } = useLoanType()

  const templates: ITemplate[] = useMemo(
    () =>
      compact([
        isServicing && defaultTemplates.find((t) => t.type === 'payoff'),
        isOrigination &&
          defaultTemplates.find((t) => t.type === 'loan_documents'),
        isOrigination &&
          defaultTemplates.find((t) => t.type === 'commitment_letter'),
        isOrigination &&
          defaultTemplates.find((t) => t.type === 'preapproval_letter'),
      ]),
    [isOrigination, isServicing]
  )

  const handleGenerate = useCallback(
    (
      templateId: string,
      templateName: string,
      payload?: Record<string, unknown>
    ) => {
      const url = window.location.href
      generate(
        { loanId: loan.id, templateId, templateName, payload },
        {
          onSuccess: ({ id }) => {
            if (url === window.location.href) {
              message.success(`${templateName} generated`)
              onGenerate?.(id)
            }
          },
        }
      )
    },
    [generate, loan.id, onGenerate]
  )

  return (
    <>
      <EllipsesActions
        trigger={
          <Button
            className={styles.btnActions}
            iconRight={<Icon name={IconName.arrowDown} />}
          >
            Generate
          </Button>
        }
      >
        {mergeTemplatesWithDefault(customTemplates, templates).map(
          (template) => (
            <EllipsesActions.Item
              key={template.id}
              disabled={loan.lock && loan.status !== 'liquidated'}
              onSelect={() => {
                if (
                  template.type === 'payoff' ||
                  template.missingMergeTags?.length
                ) {
                  setGeneratingTemplateModal(template)
                } else {
                  handleGenerate(template.id, template.name)
                }
              }}
            >
              {template.name}
            </EllipsesActions.Item>
          )
        )}
      </EllipsesActions>

      {generatingTemplateModal && (
        <ModalGeneratePayoff
          dateClosing={loan.dateClosing ?? loan.datePaidto}
          template={generatingTemplateModal}
          onSubmit={(data) => {
            handleGenerate(
              generatingTemplateModal.id,
              generatingTemplateModal.name,
              data
            )
            setGeneratingTemplateModal(undefined)
          }}
          onCancel={() => setGeneratingTemplateModal(undefined)}
        />
      )}
    </>
  )
}

export { GenerateDocument }
