import { requestWithoutToken } from 'services/request'

type AuthIntegration = {
  logoUrl: string
  name: string
  type: string
}

const getAuthIntegrations = async (): Promise<AuthIntegration[]> => {
  const { data } = await requestWithoutToken.get(
    'https://api.unified.to/unified/integration/workspace/6670ecd29da376c87a94a653?categories=auth&active=true&summary=true&env=Production'
  )
  return data
}

export { getAuthIntegrations }
