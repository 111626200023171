import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewOfferingsImg from 'images/empty-view-offerings.png'

interface Props {
  onClick: () => void
}

export const EmptyOfferings = ({ onClick }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={'center h-50 w-50 !bg-cover !bg-center !bg-no-repeat'}
            style={{ background: `url(${emptyViewOfferingsImg})` }}
          />
        }
      />
      <EmptyView.Title title="Create a loan offering" />
      <EmptyView.Description description="Create loan offerings to showcase investment opportunities to your investor audiences." />
      <Flex className="mt-6" justifyContent="center">
        <Button onClick={onClick}>Add Offering</Button>
      </Flex>
    </EmptyView>
  )
}
