import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { toNumber } from 'lodash'
import { useMemo } from 'react'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import formStyles from 'components/Form/styles.module.scss'
import { InputCurrency } from 'components/InputCurrency'
import { Redact } from 'components/Redact'
import { Table } from 'components/Table'
import { formatUsd } from 'utils/currency'
import styles from './styles.module.scss'
import { ISource } from './types'

type ITableInvestmentRow = ISource['investments']['items'][0] & {
  onChange: (rowId: string | null, value: string) => void
}

type Props = {
  investmentItems?: ISource['investments']['items']
  onChange: (rowId: string, value: string) => void
  loading: boolean
}

export const TableInvestments = ({
  investmentItems = [],
  onChange,
  loading,
}: Props) => {
  const ability = useAbilitiesContext()
  const rows = investmentItems.map((investmentItem) => ({
    ...investmentItem,
    onChange,
  })) as unknown as ITableInvestmentRow[]

  const columns: ColumnDef<ITableInvestmentRow>[] = useMemo(
    () => [
      {
        header: 'Investor',
        accessorKey: 'fundingSource.investor.name',
        cell: ({ getValue }) => (
          <Redact
            value={getValue() as string}
            hide={ability.cannot('read', 'investors')}
          />
        ),
      },
      {
        header: 'Remaining Commitment',
        accessorKey: 'remainingCommitment',
        size: 200,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Release Amount',
        accessorKey: 'amount',
        size: 200,
        cell: ({ row, getValue }) => {
          const hasError =
            (toNumber(row.original.remainingCommitment) || 0) <
            (toNumber(row.original.amount) || 0)
          return (
            <InputCurrency
              className={clsx(
                styles.alignRight,
                hasError && formStyles.errorField
              )}
              value={getValue() as string}
              onChange={(e) =>
                row.original.onChange(row.original.id, e.target.value)
              }
            />
          )
        },
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={rows}
      loading={loading}
    />
  )
}
