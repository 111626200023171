import DOMPurify from 'dompurify'
import { compact } from 'lodash'
import { useMemo } from 'react'
import {
  IActionConfig,
  IActionValue,
  IConditionConfig,
  IConditionValue,
  ITriggerConfig,
  ITriggerValue,
} from 'types'
import { formatUsd } from 'utils/currency'
import { formatNumber } from 'utils/number'
import { formatPercent } from 'utils/percent'

const conditions = {
  eq: '',
  gt: '<span class="font-bold">greater than</span>',
  gte: '<span class="font-bold">greater than or equal to</span>',
  lt: '<span class="font-bold">less than</span>',
  lte: '<span class="font-bold">less than or equal to</span>',
}

const getOptionsHtml = (
  item: ITriggerConfig | IConditionConfig | IActionConfig,
  value: ITriggerValue | IConditionValue | IActionValue
) => {
  const labels = (value.value as string[])?.map((val) => {
    const options = [
      ...compact(item.options),
      ...compact(item.options?.map((option) => option.options).flat()),
    ]
    const option = options.find((option) => option.value === val)
    return option?.label || val
  })
  const lastLabel = labels?.length > 1 ? labels.pop() : undefined
  const pills = labels
    ?.map(
      (val: string) =>
        `<span class="inline-block leading-6 align-middle px-2 bg-grey-100 rounded-sm font-bold max-w-80 truncate">${val}</span>`
    )
    .join(', ')
  const lastPill = lastLabel
    ? ` ${item.logic || 'or'} <span class="inline-block leading-6 align-middle px-2 bg-grey-100 rounded-sm font-bold max-w-80 truncate">${lastLabel}</span>`
    : ''

  return DOMPurify.sanitize(
    `${item.sentence.replace('{{value}}', `${pills}${lastPill}`)}`
  )
}

interface Props {
  itemConfig: ITriggerConfig | IConditionConfig | IActionConfig
  value: ITriggerValue | IConditionValue | IActionValue
}

const MenuItem = ({ itemConfig, value }: Props) => {
  const itemByType = useMemo(
    () =>
      new Map([
        [
          'single',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(itemConfig.sentence),
              }}
            />
          ),
        ],
        [
          'text',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    value.value as string
                  )
                ),
              }}
            />
          ),
        ],
        [
          'currency',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    `${conditions[(value as IConditionValue).condition as string]} ${formatUsd(value.value as string)}`
                  )
                ),
              }}
            />
          ),
        ],
        [
          'percentage',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    `${conditions[(value as IConditionValue).condition as string]} ${formatPercent(value.value as string)}`
                  )
                ),
              }}
            />
          ),
        ],
        [
          'number',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    `${conditions[(value as IConditionValue).condition as string]} ${formatNumber(value.value as string)}`
                  )
                ),
              }}
            />
          ),
        ],
        [
          'options',
          () => (
            <div
              className="leading-8"
              dangerouslySetInnerHTML={{
                __html: getOptionsHtml(
                  itemConfig,
                  value as ITriggerValue | IActionValue
                ),
              }}
            />
          ),
        ],
      ]),
    [itemConfig, value]
  )

  return itemByType.get(itemConfig?.type)?.() || <div></div>
}

export { MenuItem }
