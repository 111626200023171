import { useQueryClient } from '@tanstack/react-query'
import { debounce, isArray, isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { components, GroupBase, MenuProps, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { AssociatedLabels } from 'admin/components/AssociatedLabels'
import { fetchBorrowers } from 'admin/hooks/use-borrowers'
import { Borrower } from 'admin/services/api/borrowers'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Option } from 'components/Select'
import { CreateOption } from 'components/Select/CreateOption'
import { Filter } from 'types'

interface Props {
  defaultOptions: Borrower[] | undefined
  value?: any
  isMulti?: boolean
  className?: string
  entity: string
  filter?: Filter
  onSelect: (id: string | string[]) => void
  onCreate?: (name: string) => void
}

const borrowersToOptions = (borrowers: Borrower[]) =>
  borrowers.map(({ name, id, associatedWith }) => ({
    label: <AssociatedLabels name={name} associatedWith={associatedWith} />,
    value: id,
  }))

export const SelectBorrower = ({
  defaultOptions,
  value,
  className,
  isMulti,
  filter,
  entity,
  onSelect,
  onCreate,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedOption, setSelectedOption] = useState<any>()
  const queryClient = useQueryClient()
  const loadSuggestedOptions = useCallback(
    debounce((inputValue, callback) => {
      fetchBorrowers(queryClient, {
        filter,
        search: { name: inputValue },
      }).then(({ borrowers }) => callback(borrowersToOptions(borrowers)))
    }, 500),
    []
  )

  useEffect(() => {
    if (
      (isMulti &&
        isArray(value) &&
        value.length &&
        !isEqual(
          value.sort(),
          selectedOption?.map(({ value }) => value).sort()
        )) ||
      (!isMulti && value && value !== selectedOption?.value)
    ) {
      fetchBorrowers(queryClient, {
        filter: { id: value },
      }).then(({ borrowers }) => {
        setSelectedOption(
          isMulti
            ? borrowersToOptions(borrowers)
            : borrowersToOptions(borrowers)[0]
        )
      })
    }
  }, [value])

  const Menu = useCallback(
    (props: MenuProps<unknown, boolean, GroupBase<unknown>>) =>
      onCreate ? (
        <div className="absolute top-full w-full z-1">
          <components.Menu className="!relative" {...props}>
            {props.children}
          </components.Menu>
          <CreateOption onClick={() => onCreate(inputValue)}>
            <Flex
              gap={4}
              alignItems="center"
              className="py-2 font-bold text-blue-200 hover:text-black-100"
            >
              <Icon name={IconName.plus} size="sm" />
              Create a new {entity}
            </Flex>
          </CreateOption>
        </div>
      ) : (
        components.Menu(props)
      ),
    [inputValue]
  )

  const Option = useCallback(
    (props: OptionProps) => {
      return isMulti ? (
        <components.Option {...props}>
          <Flex gap={6} alignItems="center">
            <Checkbox checked={props.isSelected} onChange={() => {}} />
            {props.children}
          </Flex>
        </components.Option>
      ) : (
        components.Option(props)
      )
    },
    [isMulti]
  )

  return (
    <AsyncSelect
      classNamePrefix="formFieldSelect"
      className={className}
      value={selectedOption}
      placeholder="Select..."
      menuPortalTarget={document.getElementById('select-portal')}
      isMulti={isMulti}
      isClearable={false}
      loadOptions={loadSuggestedOptions}
      defaultOptions={borrowersToOptions(defaultOptions || [])}
      components={{ Menu, Option }}
      noOptionsMessage={() => <>None found</>}
      onInputChange={(newValue) => setInputValue(newValue)}
      hideSelectedOptions={false}
      closeMenuOnSelect={!isMulti}
      onChange={(option) => {
        setSelectedOption(option)
        onSelect(
          isMulti
            ? ((option as Option[])?.map(({ value }) => value) as string[])
            : ((option as Option)?.value as string)
        )
      }}
    />
  )
}
