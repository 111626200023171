import { useQuery } from '@tanstack/react-query'
import { getAuthIntegrations } from 'services/api/auth-integrations'

const useAuthIntegrations = () =>
  useQuery({
    queryKey: ['auth-integrations'],
    queryFn: getAuthIntegrations,
  })

export { useAuthIntegrations }
