import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import * as yup from 'yup'
import { Flex } from 'components/Flex'
import { Checkbox, FieldIcon, Select, Toggle } from 'components/Form'
import { useSession } from 'hooks/use-session'
import { IPipelineSettingsTime } from 'services/api/session'
import { createScheme } from 'utils/schemas'

const TimeBasedAlertFormScheme = createScheme({
  attentionTime: yup
    .number()
    .nullable()
    .when(['attentionEnabled'], {
      is: (attentionEnabled: boolean) => attentionEnabled,
      then: () =>
        yup
          .number()
          .moreThan(0, 'The amount must be more than 0')
          .required('The field is required'),
    }),
  criticalTime: yup
    .number()
    .nullable()
    .when(['criticalEnabled'], {
      is: (criticalEnabled: boolean) => criticalEnabled,
      then: () =>
        yup
          .number()
          .moreThan(0, 'The amount must be more than 0')
          .required('The field is required'),
    }),
})

type TimeBasedAlertFormValues = {
  enabled: boolean
  attentionEnabled: boolean
  attentionTime: number | null
  attentionUnit: 'hours' | 'days'
  criticalEnabled: boolean
  criticalTime: number | null
  criticalUnit: 'hours' | 'days'
  attentionNotificationEnabled: boolean
  criticalNotificationEnabled: boolean
  owners: { id: string; name: string }[]
  otherOwners: string[]
}

const TimeBasedAlertSettings = () => {
  const { values, setFieldValue } = useFormikContext<TimeBasedAlertFormValues>()
  const { user } = useSession()
  const timerType = user?.client?.pipelineSettings
    ?.time as IPipelineSettingsTime

  useEffect(() => {
    if (timerType === null) {
      setFieldValue('enabled', false)
    }
  }, [user])

  useEffect(() => {
    if (!values.enabled) {
      if (!values.attentionTime) {
        setFieldValue('attentionTime', null)
      }
      setFieldValue('attentionEnabled', false)
      if (!values.criticalTime) {
        setFieldValue('criticalTime', null)
      }
      setFieldValue('criticalEnabled', false)
    }
  }, [values])

  return (
    <Flex stack gap={16}>
      <label className="flex gap-2 text-grey-900 cursor-pointer">
        <Toggle
          name="enabled"
          fieldClassName="mb-0 w-11"
          disabled={timerType === null}
        />
        Enable time-based alerts
      </label>
      {values.enabled && (
        <>
          <Flex
            className="border border-solid border-grey-200 rounded p-4"
            stack
          >
            <label className="flex gap-2 text-grey-900 cursor-pointer flex-items-center">
              <Checkbox name="attentionEnabled" fieldClassName="mb-0 w-6" />
              <div>
                Flag loan for <b className="text-peach-100">attention</b>{' '}
                {timerType === 'since_substatus'
                  ? 'when inactive for'
                  : 'when time to closing is less than'}
              </div>
            </label>
            <Flex gap={10} className="ml-8">
              <FieldIcon
                name="attentionTime"
                type="number"
                fieldClassName="inline-flex w-40 mb-0"
                disabled={!values.attentionEnabled}
              />
              <Select
                name="attentionUnit"
                portal
                options={[
                  { value: 'days', label: 'Days' },
                  { value: 'hours', label: 'Hours' },
                ]}
                className="inline-flex w-36"
                fieldClassName="mb-0"
                disabled={!values.attentionEnabled}
              />
            </Flex>
          </Flex>
          <Flex
            className="border border-solid border-grey-200 rounded p-4"
            stack
          >
            <label className="flex gap-2 text-grey-900 cursor-pointer flex-items-center">
              <Checkbox name="criticalEnabled" fieldClassName="mb-0 w-[26px]" />
              <div>
                Flag loan as <b className="text-red-100">critical</b>{' '}
                {timerType === 'since_substatus'
                  ? 'when inactive for'
                  : 'when time to closing is less than'}
              </div>
            </label>
            <Flex gap={10} className="ml-8">
              <FieldIcon
                name="criticalTime"
                type="number"
                fieldClassName="inline-flex w-40 mb-0"
                disabled={!values.criticalEnabled}
              />
              <Select
                name="criticalUnit"
                portal
                options={[
                  { value: 'days', label: 'Days' },
                  { value: 'hours', label: 'Hours' },
                ]}
                className="inline-flex w-36"
                fieldClassName="mb-0"
                disabled={!values.criticalEnabled}
              />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}

export type { TimeBasedAlertFormValues }
export { TimeBasedAlertSettings, TimeBasedAlertFormScheme }
