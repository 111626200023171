import clsx from 'clsx'
import { FormikProps } from 'formik'
import { PlacesAutocomplete } from '../PlacesAutocomplete'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

interface Props extends CustomFieldProps {
  onSelect?: (
    value: google.maps.places.AutocompletePrediction,
    form: FormikProps<any>
  ) => void
}

function Address({
  placeholder,
  onSelect,
  autoFocus,
  disabled,
  'data-testid': dataTestId,
  ...rest
}: Props) {
  return (
    <Field {...rest}>
      {({ meta: { touched, error }, form, field }: FieldChildProps) => (
        <PlacesAutocomplete
          className={clsx(styles.input, {
            [styles.errorField]: touched && error,
          })}
          disabled={disabled}
          placeholder={placeholder}
          name={field.name}
          value={field.value}
          onChange={(value) => form.setFieldValue(field.name, value)}
          onSelect={(data: google.maps.places.AutocompletePrediction) => {
            if (onSelect) {
              onSelect(data, form)
            }
          }}
          autoFocus={autoFocus}
          data-testid={dataTestId}
        />
      )}
    </Field>
  )
}

export default Address
