import clsx from 'clsx'
import { differenceInDays } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDateTime } from 'utils/date'

interface IProps {
  loans: Loan[]
}

export const LoansTiles = ({ loans }: IProps) => {
  const navigate = useNavigate()

  const loanTiles = useMemo(
    () =>
      loans.map((loan) => {
        const isFirstPayment = !loan.dateLastpayment
        const isLiquidated = loan.status === 'liquidated'
        const isLate = loan.daysLate > 0 && !isLiquidated
        const isDue = loan.daysPastDue > 0 && !isLate
        const daysBetweenPayments = Math.abs(
          differenceInDays(loan.datePaidto, loan.dateNextpayment)
        )
        const daysToNextPayment = differenceInDays(
          loan.dateNextpayment,
          new Date()
        )
        const progress = Math.min(
          ((daysBetweenPayments - daysToNextPayment) / daysBetweenPayments) *
            100,
          100
        )

        return {
          id: loan.id,
          name: loan.name,
          nextPaymentAmount: loan.nextPaymentAmount,
          dateNextpayment: loan.dateNextpayment,
          daysLate: loan.daysLate,
          principalBalance: loan.principalBalance,
          paymentsRemaining: loan.paymentsRemaining,
          isFirstPayment,
          isLate,
          isDue,
          daysToNextPayment,
          progress,
          hasActiveBudget: loan?.hasActiveBudget ?? false,
        }
      }),
    [loans]
  )

  const handleOpenGeneralTab = useCallback(
    (loanId: string) => {
      navigate(pathTo('loan', loanId))
    },
    [navigate]
  )

  const handleOpenScopeOfWork = useCallback(
    (loanId: string) => {
      navigate(pathTo('loanTab', loanId, 'scopeOfWork'))
    },
    [navigate]
  )

  return (
    <Flex stack>
      {loanTiles.map((loan) => (
        <Panel
          key={loan.id}
          title={loan.name}
          onClick={() => handleOpenGeneralTab(loan.id)}
          className="relative pt-5 pb-20 cursor-pointer hover:bg-grey-50 md:pb-5"
          actionChildren={
            <Flex
              gap={8}
              className="absolute right-5 bottom-5 md:static md:flex"
            >
              <Button
                variant="secondary"
                size="large"
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpenGeneralTab(loan.id)
                }}
                className="rounded-full"
              >
                View Details
              </Button>
              {loan.hasActiveBudget && (
                <Button
                  variant="primary"
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenScopeOfWork(loan.id)
                  }}
                  className="rounded-full"
                >
                  Draw Funds
                </Button>
              )}
            </Flex>
          }
        >
          <Flex gap={0} className="!flex-col md:!flex-row">
            <Flex stack gap={20} className="md:flex-[1] md:pr-8">
              <div>
                <div className="text-sm text-grey-700 leading-[18px]">
                  {loan.isFirstPayment ? 'First' : 'Next'} Payment
                </div>
                <Flex gap={10} className="text-xl font-bold">
                  <div>
                    {formatUsd(loan.nextPaymentAmount, { showZero: true })}
                  </div>
                  <div className="text-grey-500">&bull;</div>
                  <div>
                    {friendlyDateTime(loan.dateNextpayment, 'EEEE MMM d, y')}
                  </div>
                </Flex>
              </div>
              <Flex stack gap={5}>
                <Text variant="s" className="leading-[18px] text-grey-700">
                  {loan.isLate && `${loan.daysLate} days late`}
                  {loan.isDue && 'Due'}
                  {!loan.isLate &&
                    !loan.isDue &&
                    `${loan.daysToNextPayment || '-'} days`}
                </Text>
                <Flex className="w-full h-[5px] bg-grey-100 rounded-[3px]">
                  <div
                    style={{ width: `${loan.progress}%` }}
                    className={clsx(
                      'h-full inline-block rounded-[3px]',
                      !loan.isLate && !loan.isDue && 'bg-green-50',
                      loan.isLate && 'bg-red-50',
                      loan.isDue && 'bg-yellow-100'
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              gap={32}
              className="py-6 !justify-between border-b border-0 border-solid border-grey-200 md:flex-[2] md:pl-8 md:!justify-start md:border-l md:border-b-0"
            >
              <div>
                <div className="text-sm text-grey-700 leading-[18px]">
                  Principal Balance
                </div>
                <div className="text-xl font-bold">
                  {formatUsd(loan.principalBalance, { showZero: true })}
                </div>
              </div>
              <div>
                <div className="text-sm text-grey-700 leading-[18px]">
                  Remaining Payments
                </div>
                <div className="text-xl font-bold">
                  {loan.paymentsRemaining || '-'}
                </div>
              </div>
            </Flex>
          </Flex>
        </Panel>
      ))}
    </Flex>
  )
}
