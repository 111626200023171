import { EmptyView } from 'components/EmptyView'
import emptyViewPayoutsImg from 'images/empty-view-payouts.png'

export const EmptyPayouts = () => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={'center h-50 w-50 !bg-cover !bg-center !bg-no-repeat'}
            style={{ background: `url(${emptyViewPayoutsImg})` }}
          />
        }
      />
      <EmptyView.Title title="All your payouts in one place" />
      <EmptyView.Description description="This is where you’ll see a detailed view of all your past and scheduled payouts to investors." />
    </EmptyView>
  )
}
