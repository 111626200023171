import { LoanSubstatusColor } from 'types'

const legacySubstatusOptions: {
  name: string
  color: LoanSubstatusColor
}[] = [
  {
    name: 'New Application',
    color: 'blue',
  },
  { name: 'Under Review', color: 'yellow' },
  {
    name: 'Initial Review Completed',
    color: 'green',
  },
  {
    name: 'Term Sheet Sent',
    color: 'blue',
  },
  {
    name: 'Terms Accepted',
    color: 'green',
  },
  {
    name: 'Pending Appraisal',
    color: 'yellow',
  },
  {
    name: 'Underwriting Review',
    color: 'yellow',
  },
  {
    name: 'Credit Committee',
    color: 'yellow',
  },
  {
    name: 'Closing Review',
    color: 'yellow',
  },
  {
    name: 'Pending Closing',
    color: 'green',
  },
  {
    name: 'Pending Servicing Approval',
    color: 'blue',
  },
  {
    name: 'Transferred',
    color: 'yellow',
  },
]

export function substatusToColor(substatus: string): LoanSubstatusColor {
  const options = legacySubstatusOptions.find((o) => o.name === substatus)

  return options?.color || 'grey'
}
