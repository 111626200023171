import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  pills?: { value: string; label: string }[]
  onRemove: (value: string) => void
}

const Pills = ({ pills, onRemove }: Props) => {
  return (
    <Flex
      gap={8}
      flexWrap="wrap"
      className="px-2 py-1.5 rounded-sm border border-solid border-grey-200 min-h-9 max-h-24 overflow-y-auto"
    >
      {!pills?.length && <div className="text-grey-600 py-1">Select...</div>}
      {pills?.map(({ value, label }) => (
        <Flex
          key={value}
          alignItems="center"
          gap={4}
          className="px-2 py-1 bg-grey-100 rounded-sm truncate"
        >
          <div className="font-bold max-w-80 truncate">{label}</div>
          <Icon
            name={IconName.close}
            onClick={() => onRemove(value)}
            className="h-3 w-3 text-grey-600 cursor-pointer flex-shrink-0"
          />
        </Flex>
      ))}
    </Flex>
  )
}

export { Pills }
