import { isObject, size } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useApplicationFields,
  useApplicationScheme,
  useDeleteApplicationScheme,
  useSaveApplicationScheme,
} from 'admin/hooks/use-application'
import { pathTo } from 'admin/path-to'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalCustomApplicationField } from 'components/Modal/CustomApplicationField'
import { ModalCustomApplicationPage } from 'components/Modal/CustomApplicationPage'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { savePreviewApplication } from 'services/storage'
import {
  IApplicationScheme,
  IApplicationSchemeField,
  IApplicationSchemePage,
  ICustomApplicationField,
  IApplicationCondition,
} from 'types'
import { Conditions } from './Conditions'
import { FormQuestions } from './FormQuestions'
import { Page } from './Page'
import { PromoPanel } from './PromoPanel'
import { fields } from './fields'
import {
  deleteFieldById,
  formatField,
  getMapToFields,
  parseField,
  updateField,
} from './helpers'
import { scheme as initialScheme } from './initialScheme'

function Application() {
  const { user } = useSession()
  const navigate = useNavigate()
  const [isPageModalOpen, setIsPageModalOpen] = useState(false)
  const [isFieldModalOpen, setIsFieldModalOpen] = useState(false)
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false)
  const [editingPage, setEditingPage] = useState<IApplicationSchemePage>()
  const [editingField, setEditingField] = useState<IApplicationSchemeField>()
  const [scheme, setScheme] = useState<IApplicationScheme>(initialScheme)
  const { data: mapToFields = [] } = useApplicationFields()
  const { data: savedScheme, isLoading } = useApplicationScheme({
    clientId: (user as CurrentUser)?.client?.id,
  })
  const { mutate: saveScheme } = useSaveApplicationScheme()
  const { mutate: resetScheme } = useDeleteApplicationScheme()
  const isSignatureInScheme = useMemo(
    () =>
      scheme.pages.some((page) =>
        page.fields.some(({ type }) => type === 'signature')
      ),
    [scheme]
  )
  const savePage = useCallback(
    (page) => {
      setScheme({
        ...scheme,
        pages: editingPage
          ? scheme.pages.map((existingPage) =>
              existingPage.id === editingPage.id
                ? { ...editingPage, ...page }
                : existingPage
            )
          : [...scheme.pages, { ...page, id: uuidv4(), fields: [] }],
      })
    },
    [editingPage, scheme]
  )
  const movePage = useCallback(
    (page, dir: 'up' | 'down') => {
      const index = scheme.pages.findIndex(({ id }) => id === page.id)

      scheme.pages.splice(
        dir === 'up' ? index - 1 : index + 1,
        0,
        scheme.pages.splice(index, 1)[0]
      )

      setScheme({
        ...scheme,
        pages: [...scheme.pages],
      })
    },
    [scheme]
  )
  const deletePage = useCallback(
    (page) => {
      setScheme({
        ...scheme,
        pages: scheme.pages.filter(({ id }) => id !== page.id),
      })
    },
    [scheme]
  )
  const handleDrop = useCallback(
    (
      field: { type: string } & Partial<IApplicationSchemeField>,
      target: IApplicationSchemeField | null,
      targetPage: IApplicationSchemePage
    ) => {
      const isNewField = !field?.id
      const customApplicationField = (
        isNewField ? fields.find(({ type }) => type === field.type) : {}
      ) as ICustomApplicationField
      const movedField: IApplicationSchemeField = isNewField
        ? {
            id: uuidv4(),
            label: '',
            type: customApplicationField.type,
            enabled: true,
            required: true,
            mapTo: '',
          }
        : (field as IApplicationSchemeField)
      const schemeWithoutField = isNewField
        ? scheme
        : deleteFieldById(scheme, movedField.id)
      const targetPageIndex = schemeWithoutField.pages.findIndex(
        ({ id }) => id === targetPage.id
      )
      const targetIndex = target
        ? schemeWithoutField.pages[targetPageIndex].fields.findIndex(
            ({ id }) => id === target?.id
          )
        : schemeWithoutField.pages[targetPageIndex].fields.length || 1 - 1

      // if it was not find, it means it's moved to the borrower/guarantor section
      if (targetIndex === -1) {
        schemeWithoutField.pages[targetPageIndex].fields =
          schemeWithoutField.pages[targetPageIndex].fields.map((field) =>
            field.individualFields && field.entityFields
              ? {
                  ...field,
                  individualFields: field.individualFields
                    .map((f) => {
                      if (
                        `${field.type}-individual-${f.id}` === target?.fullId
                      ) {
                        movedField.fullId = `${field.type}-individual-${movedField.id}`
                        return [f, movedField]
                      }
                      return f
                    })
                    .flat(),
                  entityFields: field.entityFields
                    .map((f) => {
                      if (`${field.type}-entity-${f.id}` === target?.fullId) {
                        movedField.fullId = `${field.type}-entity-${movedField.id}`
                        return [f, movedField]
                      }
                      return f
                    })
                    .flat(),
                }
              : field
          )
      } else {
        schemeWithoutField.pages[targetPageIndex].fields.splice(
          targetIndex + 1,
          0,
          movedField
        )
      }

      setScheme({ ...schemeWithoutField })
      if (isNewField) {
        setEditingField(movedField)
        setIsFieldModalOpen(true)
      }
    },
    [scheme]
  )
  const saveField = useCallback(
    (field: IApplicationSchemeField) => {
      setScheme(updateField(scheme, field))
    },
    [scheme]
  )
  const toggleField = useCallback(
    (field) => {
      setScheme(updateField(scheme, { ...field, enabled: !field.enabled }))
    },
    [scheme]
  )
  const deleteField = useCallback(
    (field) => {
      setScheme(deleteFieldById(scheme, field.id))
    },
    [scheme]
  )

  useEffect(() => {
    if (isObject(savedScheme) && size(savedScheme)) {
      setScheme(savedScheme as IApplicationScheme)
    }
  }, [savedScheme])

  useEffect(() => {
    savePreviewApplication(scheme)
  }, [scheme])

  return (
    <MainContent className="p-0">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex gap={0} className="h-full">
          <DndProvider backend={HTML5Backend}>
            <Flex stack gap={0} className="flex-grow">
              <Flex
                className="pl-4 pr-5 pb-4 pt-4"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex alignItems="center" gap={10}>
                  <Button
                    variant="ghost"
                    icon
                    size="large"
                    onClick={() => navigate(pathTo('settings'))}
                  >
                    <Icon name={IconName.arrowLeftLong} size="md" />
                  </Button>
                  <Header variant="h4">Company Settings</Header>
                </Flex>
                <Flex gap={10} alignItems="center">
                  <EllipsesActions>
                    <EllipsesActions.Item
                      icon
                      onSelect={() => setIsRestoreModalVisible(true)}
                    >
                      <Icon name={IconName.restore} />
                      Restore to default
                    </EllipsesActions.Item>
                  </EllipsesActions>
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      window.open(borrowerPathTo('previewApplication'))
                    }}
                  >
                    Preview
                  </Button>
                  <Button onClick={() => saveScheme(scheme)}>Save</Button>
                </Flex>
              </Flex>
              <Tabs
                rootClassName="flex flex-col flex-grow !items-start"
                className="w-full"
                defaultActiveId="form"
              >
                <Tabs.Pane
                  tab={
                    <Flex alignItems="center" gap={6}>
                      <Icon name={IconName.draft} />
                      Form Builder
                    </Flex>
                  }
                  id="form"
                  className="p-0 flex-grow w-full"
                  tabClassName="ml-16"
                >
                  <Flex gap={0} className="flex-grow">
                    <Flex
                      stack
                      gap={16}
                      className="flex-grow bg-grey-50 px-12 py-9"
                    >
                      <PromoPanel />
                      <Header variant="h2">Edit Loan Application</Header>
                      {scheme.pages.map((page, index) => (
                        <Page
                          key={page.id}
                          page={page}
                          index={index}
                          pagesCount={scheme.pages.length}
                          saveField={saveField}
                          onMove={movePage}
                          onDrop={(field, target) =>
                            handleDrop(field, target, page)
                          }
                          onEdit={(page) => {
                            setEditingPage(page)
                            setIsPageModalOpen(true)
                          }}
                          onDelete={(page) => {
                            deletePage(page)
                          }}
                          onEditField={(field) => {
                            setEditingField(field)
                            setIsFieldModalOpen(true)
                          }}
                          onDeleteField={(field) => deleteField(field)}
                          onToggleField={(field) => toggleField(field)}
                        />
                      ))}
                      <Button
                        variant="tertiary"
                        onClick={() => setIsPageModalOpen(true)}
                      >
                        Add Page
                      </Button>
                    </Flex>
                    <FormQuestions isSignatureDisabled={isSignatureInScheme} />
                  </Flex>
                </Tabs.Pane>
                <Tabs.Pane
                  tab={
                    <Flex alignItems="center" gap={6}>
                      <Icon name={IconName.conditions} />
                      Conditional Logic
                    </Flex>
                  }
                  className="p-0 flex-grow w-full"
                  id="conditions"
                >
                  <Conditions
                    scheme={scheme}
                    onChange={(conditions: IApplicationCondition[]) =>
                      setScheme({ ...scheme, conditions })
                    }
                  />
                </Tabs.Pane>
              </Tabs>
            </Flex>
          </DndProvider>
          {isPageModalOpen && (
            <ModalCustomApplicationPage
              page={editingPage}
              onSave={(page) => {
                savePage(page)
                setEditingPage(undefined)
                setIsPageModalOpen(false)
              }}
              onCancel={() => {
                setEditingPage(undefined)
                setIsPageModalOpen(false)
              }}
            />
          )}
          {isFieldModalOpen && editingField && (
            <ModalCustomApplicationField
              mapToFields={getMapToFields(
                scheme,
                mapToFields,
                parseField(editingField)
              )}
              field={parseField(editingField)}
              onSave={(field) => {
                saveField({
                  id: editingField.id,
                  fullId: editingField?.fullId,
                  ...formatField(field),
                } as IApplicationSchemeField)
                setIsFieldModalOpen(false)
              }}
              onCancel={() => {
                if (!editingField.label) {
                  deleteField(editingField)
                }
                setIsFieldModalOpen(false)
              }}
            />
          )}
          {isRestoreModalVisible && (
            <ModalConfirm
              title="Restore to default"
              text="Are you sure you want to reset the application template to the default? All customizations will be removed."
              onConfirm={() => {
                resetScheme()
                setIsRestoreModalVisible(false)
              }}
              onCancel={() => setIsRestoreModalVisible(false)}
            />
          )}
        </Flex>
      )}
    </MainContent>
  )
}

export { Application }
