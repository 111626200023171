import { cloneDeep, isEqual, omit, size } from 'lodash'
import { useCallback, useMemo, useState, useEffect, memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { TopMenu } from 'admin/components/TopMenu'
import {
  useTask,
  useTaskActivities,
  useAddTask,
  useEditTask,
} from 'admin/hooks/use-tasks'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { TaskForm } from 'admin/pages/Tasks/TaskDrawer/TaskForm'
import { Button } from 'components/Button'
import { Drawer } from 'components/Drawer'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { Task } from 'types'

interface Props {
  onBack?: () => void
  backButtonText?: string
  onClose: () => void
  onSave?: (task: Task) => void
  onDelete: (id: string) => void
  loanId?: string
  taskId?: string
}
const TaskDrawer = memo(
  ({
    onBack,
    backButtonText,
    onClose,
    onSave,
    onDelete,
    loanId,
    taskId,
  }: Props) => {
    const { user } = useSession()
    const ability = useAbilitiesContext()
    const { data: lastSavedTask, isLoading } = useTask(taskId)
    const [searchParams] = useSearchParams()
    const tab = (searchParams.get('tab') || 'all') as 'all' | 'my'
    const [task, setTask] = useState<Partial<Task>>(
      taskId
        ? {}
        : {
            name: '',
            description: '',
            subtasks: [],
            dateDue: '',
            owners:
              user?.admin &&
              (tab === 'my' ||
                backButtonText === 'My Tasks' ||
                ability.cannot('read', 'tasks'))
                ? [{ id: user.admin.id, name: user.admin.name }]
                : [],
            loanId,
            status: 'To Do',
          }
    )

    // set initial state
    useEffect(() => {
      if (lastSavedTask) {
        setTask((prevTask) =>
          size(prevTask) ? prevTask : cloneDeep(lastSavedTask)
        )
      }
    }, [lastSavedTask])

    const loanContext = useLoanContext()
    const { data: activities } = useTaskActivities(taskId)
    const { mutate: addTask, isPending: isAdding } = useAddTask()
    const { mutate: editTask, isPending: isEditing } = useEditTask({
      invalidateQueries: !onSave,
    })

    const handleSave = useCallback(() => {
      const nextTask = {
        ...task,
        owners: task.owners?.map(({ id }) => id) as unknown as Task['owners'],
      }
      if (task.id) {
        editTask(nextTask, {
          onSuccess: (task) => {
            onBack?.()
            onSave?.(task)
          },
        })
      } else {
        addTask(nextTask, { onSuccess: onBack })
      }
    }, [task, addTask])

    const handleTaskChange = useCallback((nextTask: Partial<Task>) => {
      setTask((prevTask) => ({ ...prevTask, ...nextTask }))
    }, [])

    const isEditDirty = useMemo(() => {
      return (
        !!lastSavedTask &&
        !isEqual(
          omit(task, 'status', 'subtasks', 'order', 'updatedAt'),
          omit(lastSavedTask, 'status', 'subtasks', 'order', 'updatedAt')
        )
      )
    }, [task, lastSavedTask])

    return (
      <Drawer onClose={onClose} className="px-4 max-w-[640px]">
        <Drawer.Header className="pl-2.5 pr-0 -mx-4">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className="h-full"
          >
            <Flex alignItems="center">
              <Button variant="ghost" onClick={onClose} className="w-7 h-7">
                <Icon
                  name={IconName.doubleArrowRight}
                  className="text-black-100"
                />
              </Button>
              <Text className="font-bold">{taskId ? '' : 'New Task'}</Text>
            </Flex>
            {!isLoading && (isEditDirty || !lastSavedTask) ? (
              <Flex alignItems="center" gap={10} className="h-12">
                <Button variant="secondary" onClick={onBack}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={isAdding || isEditing}
                  onClick={handleSave}
                  className="mr-4"
                >
                  Save
                </Button>
              </Flex>
            ) : (
              <TopMenu
                messagesCount={loanContext?.comments?.total}
                onCommentsClick={loanContext?.openComments}
                onTasksClick={loanId ? onClose : undefined}
                onHistoryClick={
                  loanContext?.isOrigination
                    ? undefined
                    : loanContext?.openTimeline
                }
                owners={loanContext?.loan?.owners}
                onOwnersChange={loanContext?.handleOwnersChange}
              />
            )}
          </Flex>
        </Drawer.Header>
        <Drawer.Content className="w-auto mt-4 pt-2 pl-0 pr-4 mr-[-14px]">
          {!size(task) ? (
            <PageLoader />
          ) : (
            <TaskForm
              loanId={loanId}
              task={task}
              activities={activities}
              backButtonText={backButtonText}
              onChange={handleTaskChange}
              onDelete={onDelete}
              onBack={onBack}
            />
          )}
        </Drawer.Content>
      </Drawer>
    )
  }
)

TaskDrawer.displayName = 'TaskDrawer'

export { TaskDrawer }
