import { request } from 'services/request'
import {
  Filter,
  IMail,
  IThreadRequest,
  IThreadTab,
  Meta,
  Pagination,
} from 'types'
import { IThreadListItem, IThread } from 'types/thread'

const getThreads = async (params: {
  folder?: IThreadTab
  search?: string
  page?: Pagination
  loanId?: string
  personId?: string
  filter?: Filter
  include?: string[]
  exclude?: string[]
  checkMail?: boolean
}): Promise<{ threads: IThreadListItem[]; meta: Meta }> => {
  let url = '/thread'
  if (params.loanId) {
    url = `/loan/${params.loanId}/thread`
  }
  if (params.personId) {
    url = `/person/${params.personId}/thread`
  }

  const {
    data: { threads, pagination },
  } = await request.get(url, { params })
  return { threads, meta: pagination }
}

const getThreadMail = async ({
  mailId,
  filename,
}: {
  mailId: string
  filename?: string
}): Promise<IMail> => {
  const {
    data: { mail },
  } = await request.get(`/mail/${mailId}`, { params: { filename } })
  return mail
}

const updateThreads = async ({
  folder,
  search,
  filter,
  include,
  exclude,
  payload,
}: {
  folder?: IThreadTab
  search?: string
  filter?: Filter
  include?: string[]
  exclude?: string[]
  payload: IThreadRequest
}): Promise<IThread[]> => {
  const {
    data: { threads },
  } = await request.patch(
    '/thread',
    { ...payload, include, exclude },
    { params: { filter, folder, search } }
  )
  return threads
}

const getThread = async (threadId: string): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.get(`/thread/${threadId}`)
  return thread
}

const addThread = async (payload: IThreadRequest): Promise<IThread> => {
  let url = '/thread'
  if (payload.loanId) {
    url = `/loan/${payload.loanId}/thread`
  }
  if (payload.personId) {
    url = `/person/${payload.personId}/thread`
  }
  const {
    data: { thread },
  } = await request.post(url, payload)
  return thread
}

const addThreadMail = async ({
  threadId,
  replyTo,
  replyAllTo,
  forwardTo,
}: {
  threadId: string
  replyTo?: string
  replyAllTo?: string
  forwardTo?: string
}): Promise<{ mail: IMail; thread: IThread }> => {
  const {
    data: { mail, thread },
  } = await request.post(`/thread/${threadId}`, {
    replyTo,
    replyAllTo,
    forwardTo,
  })
  return { mail, thread }
}

const sendThreadMail = async ({
  mailId,
}: {
  mailId: string
}): Promise<{ mail: IMail; thread: IThread }> => {
  const {
    data: { mail, thread },
  } = await request.put(`/mail/${mailId}`)
  return { mail, thread }
}

const updateThread = async (payload: IThreadRequest): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.patch(`/thread/${payload.id}`, payload)
  return thread
}

const updateThreadMail = async (
  payload: Partial<IMail>
): Promise<{ thread: IThread; mail: IMail }> => {
  const {
    data: { thread, mail },
  } = await request.patch(`/mail/${payload.id}`, payload)
  return { thread, mail }
}

const removeThread = async (id: string) => {
  return request.delete(`/thread/${id}`)
}

const removeThreadMail = async (id: string): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.delete(`/mail/${id}`)
  return thread
}

export {
  getThreads,
  getThreadMail,
  updateThreads,
  getThread,
  addThread,
  addThreadMail,
  updateThread,
  removeThread,
  removeThreadMail,
  sendThreadMail,
  updateThreadMail,
}
