import { getInvestors } from 'admin/services/api/investors'
import { Row, Sort, Filter } from 'types'
import { formatUsd } from 'utils/currency'

const downloadInvestors = async (
  sort?: Sort,
  search?: string,
  filter?: Filter
): Promise<Row[]> => {
  const { investors } = await getInvestors({
    sort,
    search,
    filter: {
      isAccount: [true],
      ...(filter || {}),
    },
  })
  return investors.map((borrower) => ({
    Name: borrower.name,
    Type: borrower.type === 'individual' ? 'Personal' : 'Company',
    Invested: formatUsd(borrower.investedBalance),
    Email: borrower.email,
    Status: borrower.status,
  }))
}

export { downloadInvestors }
