import { useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { WorkItemAttributes } from 'admin/pages/Settings/Worksheet/WorkItemAttributes'
import { WorkItems } from 'admin/pages/Settings/Worksheet/WorkItems'
import { pathTo } from 'admin/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import { useBudgetWorksheet } from 'hooks/use-budget-worksheet'

const SettingsWorksheet = () => {
  const { id } = useParams() as { id: string }
  const { data: worksheet } = useBudgetWorksheet(id)

  return (
    <MainContent className="bg-grey-50">
      {worksheet ? (
        <>
          <PageTop
            breadcrumbs={{
              title: 'Budget Worksheets',
              link: pathTo('settingsWorksheets'),
            }}
            title={worksheet.name}
          />
          <WorkItemAttributes worksheet={worksheet} />
          <WorkItems />
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { SettingsWorksheet }
