import { ReactNode, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { Tabs } from 'components/Tabs'
import { IMail } from 'types'
import { IThread } from 'types/thread'
import { Section } from 'utils/loan-document-sections'
import { TabBrowseDocuments } from './TabBrowseDocuments'
import { TabUpload } from './TabUpload'

interface Props {
  thread: IThread
  mail: IMail
  onClose: () => void
}

interface Item {
  id: string
  name: string
  type: 'loan' | 'borrower' | 'investor'
}

interface ItemWithDocuments {
  item: Item
  sections: Section[]
}

const ModalAttachDocs = ({ thread, mail, onClose }: Props) => {
  const [mode, setMode] = useState<'browse' | 'upload'>('browse')
  const [title, setTitle] = useState<ReactNode>('Attach Documents')
  const [uploading, setUploading] = useState<boolean>(false)

  return (
    <Modal
      crossClose={!uploading}
      onClose={onClose}
      title={title}
      className="w-[564px]"
    >
      <Tabs
        tab={mode || 'upload'}
        onTabSelect={(mode) => setMode(mode as 'browse' | 'upload')}
      >
        <Tabs.Pane
          id="browse"
          disabled={uploading}
          tab={
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.documents} />
              Browse Documents
            </Flex>
          }
        >
          <TabBrowseDocuments
            thread={thread}
            mail={mail}
            setTitle={setTitle}
            onClose={onClose}
          />
        </Tabs.Pane>
        <Tabs.Pane
          id="upload"
          tab={
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.upload} />
              Upload
            </Flex>
          }
        >
          <TabUpload
            mail={mail}
            uploading={uploading}
            onUploading={setUploading}
            onClose={onClose}
          />
        </Tabs.Pane>
      </Tabs>
    </Modal>
  )
}

export type { Item, ItemWithDocuments }
export { ModalAttachDocs }
