import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import { AbilitiesProvider } from 'admin/components/Abilities'
import { DockProvider } from 'admin/components/Dock'
import { SessionProvider as AdminSessionProvider } from 'admin/pages/Session/SessionProvider'
import { TasksProvider } from 'admin/pages/Tasks/TasksProvider'
import { SessionProvider as BorrowerSessionProvider } from 'borrower/pages/Session/SessionProvider'
import { AppCustomization } from 'components/AppCustomization'
import { AppTheme } from 'components/AppTheme'
import { AppVersion } from 'components/AppVersion'
import { BugsnagErrorBoundary } from 'components/BugsnagErrorBoundary'
import Portal from 'components/Modal/Portal'
import { useAnalytics } from 'hooks/use-analytics'
import { handleErrorResponse } from 'services/request'
import { isAdminRoute } from 'utils/routes'
import Routes from './routes'
import './styles/styles.scss'

const App = () => {
  useAnalytics()
  const isAdmin = isAdminRoute()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => handleErrorResponse(error as AxiosError),
    }),
  })

  return (
    <BugsnagErrorBoundary>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AppVersion />
          {isAdmin ? (
            <AdminSessionProvider>
              <AbilitiesProvider>
                <AppCustomization>
                  <DockProvider>
                    <TasksProvider>
                      <Routes admin />
                    </TasksProvider>
                  </DockProvider>
                </AppCustomization>
              </AbilitiesProvider>
            </AdminSessionProvider>
          ) : (
            <BorrowerSessionProvider>
              <AppCustomization>
                <AppTheme />
                <Routes borrower />
              </AppCustomization>
            </BorrowerSessionProvider>
          )}
          <Portal>
            <ToastContainer
              position="bottom-left"
              transition={Slide}
              pauseOnFocusLoss={false}
              hideProgressBar
            />
          </Portal>
        </QueryClientProvider>
      </BrowserRouter>
    </BugsnagErrorBoundary>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
