import clsx from 'clsx'
import { useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Select } from 'components/Select'
import { sortConfig, ISortItem } from './config'

interface Props {
  item: ISortItem
  selectedItems: ISortItem[]
  onDelete: (item: ISortItem) => void
  onChange: (item: ISortItem) => void
  onDrop: (dragItem: ISortItem, targetItem: ISortItem) => void
}

function DropdownItem({
  item,
  selectedItems,
  onDelete,
  onChange,
  onDrop,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const visibleOptions = useMemo(() => {
    const selectedOptionsId = selectedItems.map(({ id }) => id)
    return sortConfig.filter(
      ({ id }) => item.id === id || !selectedOptionsId.includes(id)
    )
  }, [item, selectedItems])

  const [{ isOver }, drop] = useDrop<ISortItem, void, { isOver: boolean }>({
    accept: 'sorting-item',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop(dragItem: ISortItem) {
      onDrop(dragItem, item)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'sorting-item',
    item: () => item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <Flex
      gap={10}
      alignItems="center"
      ref={ref}
      className={clsx(
        'border-b border-solid border-0 border-transparent py-1.5',
        isOver && '!border-blue-100 border-b-[3px]',
        isDragging && 'hidden'
      )}
    >
      <Icon
        name={IconName.dndHandler}
        className="flex-shrink-0 text-grey-500 m-2 cursor-move"
      />
      <Select
        className="w-40"
        options={visibleOptions.map(({ label, id }) => ({
          label: (
            <Flex alignItems="center" gap={6}>
              {label}
            </Flex>
          ),
          value: id,
        }))}
        value={item.id}
        onChange={({ value }) => {
          onChange({ ...item, id: value as string })
        }}
      />
      <Select
        className="w-40"
        options={[
          {
            label: (
              <Flex alignItems="center" gap={8}>
                <Icon name={IconName.sortAsc} />
                Ascending
              </Flex>
            ),
            value: 'asc',
          },
          {
            label: (
              <Flex alignItems="center" gap={8}>
                <Icon name={IconName.sortDesc} />
                Descending
              </Flex>
            ),
            value: 'desc',
          },
        ]}
        value={item.dir}
        onChange={({ value }) => {
          onChange({ ...item, dir: value as 'asc' | 'desc' })
        }}
      />
      <Button variant="ghost" icon onClick={() => onDelete(item)}>
        <Icon name={IconName.close} className="text-grey-600" />
      </Button>
    </Flex>
  )
}

export { DropdownItem }
