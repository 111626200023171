import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useDeleteBorrower } from 'admin/hooks/use-borrowers'
import { useFieldsByPersonId } from 'admin/hooks/use-person-fields'
import { TabMail } from 'admin/pages/Contacts/TabMail'
import { pathTo } from 'admin/path-to'
import { BorrowerDetails } from 'admin/services/api/borrowers'
import { AccountTypeBadge, BorrowerStatusBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { useBorrower, useUpdateBorrowerOwners } from 'hooks/use-borrower'
import { useSession } from 'hooks/use-session'
import TabDocuments from './TabDocuments'
import TabGeneral from './TabGeneral'
import TabLoans from './TabLoans'
import styles from './styles.module.scss'

function Borrower() {
  const { user } = useSession()
  const ability = useAbilitiesContext()
  const [secure, setSecure] = useState<boolean>()
  const [borrower, setBorrower] = useState<BorrowerDetails>()
  const { id, tab } = useParams() as { id: string; tab: string }
  const { data } = useBorrower({ id, params: { secure } })
  const { data: fields } = useFieldsByPersonId({
    personType: 'borrower',
    personId: id,
  })
  const { mutate: updateOwners } = useUpdateBorrowerOwners()
  const navigate = useNavigate()

  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id, owners })
    },
    [id, updateOwners]
  )

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mutate: deleteBorrower } = useDeleteBorrower()

  const handleClickDelete = () => {
    if (borrower) {
      deleteBorrower(borrower.id, {
        onSuccess: () => navigate(pathTo('borrowers')),
      })
    }
  }

  useEffect(() => {
    if (borrower && id !== borrower.id) {
      setBorrower(undefined)
      setSecure(false)
    }
  }, [id, borrower])

  useEffect(() => {
    if (data && fields) {
      setBorrower(data)
    }
  }, [data, fields])

  return (
    <MainContent>
      {borrower ? (
        <>
          <PageTop
            title={borrower.name}
            breadcrumbs={{ title: 'Borrowers', link: pathTo('borrowers') }}
            owners={borrower.owners || []}
            onOwnersChange={handleOwnersChange}
          />
          <div className={styles.badges}>
            <AccountTypeBadge
              type={borrower.type}
              isAccount={borrower.isAccount}
            />
            <BorrowerStatusBadge status={borrower.status} />
          </div>
          <PageTopFields
            fields={[
              { key: 'ID', value: borrower.id },
              ...(borrower?.type === 'individual'
                ? [
                    {
                      key: 'Email',
                      value: LinkEmail({ email: borrower.email }),
                    },
                  ]
                : []),
            ]}
          />
          <Tabs
            defaultActiveId={tab || 'general'}
            onTabSelect={(tabId) => navigate(pathTo('borrowerTab', id, tabId))}
            className={styles.tabs}
            actions={[
              {
                label: 'Delete',
                variant: 'danger',
                icon: IconName.delete,
                onSelect: () => setIsModalVisible(true),
              },
            ]}
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral
                borrower={borrower}
                fields={fields}
                onSecure={setSecure}
              />
            </Tabs.Pane>
            <Tabs.Pane tab="Loans" id="loans">
              <TabLoans loans={borrower.loans} />
            </Tabs.Pane>
            {ability.can('list', 'loan-documents') ? (
              <Tabs.Pane tab="Documents" id="documents">
                <TabDocuments borrower={borrower} />
              </Tabs.Pane>
            ) : null}
            {/* fake tab with empty id to add grouping before Mail tab*/}
            <Tabs.Pane tab="" id="" group="actions">
              {' '}
            </Tabs.Pane>
            {ability.can('read', 'communications') &&
            user?.client.settings?.showCommunications ? (
              <Tabs.Pane
                tab={
                  <Flex alignItems="center" gap={4}>
                    <Icon name={IconName.envelope} /> Mail
                  </Flex>
                }
                tabClassName="relative ml-8 before:-top-0 before:-left-6 before:absolute before:h-[34px] before:content-[''] before:border-0 before:border-l before:border-grey-200 before:border-solid"
                id="mail"
              >
                <TabMail personId={borrower.id} personType="borrower" />
              </Tabs.Pane>
            ) : (
              <></>
            )}
          </Tabs>
          {isModalVisible && (
            <ModalDelete
              resource="borrower"
              name={borrower.name}
              onDelete={handleClickDelete}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Borrower }
