import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useFieldsByPersonId } from 'admin/hooks/use-person-fields'
import { useReport } from 'admin/hooks/use-report'
import {
  useUpdateVendorOwners,
  useVendor,
  useRemoveVendor,
} from 'admin/hooks/use-vendors'
import { TabMail } from 'admin/pages/Contacts/TabMail'
import { pathTo } from 'admin/path-to'
import { Vendor as VendorDetails } from 'admin/services/api/vendors'
import { AccountTypeBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { formatUsd } from 'utils/currency'
import TabGeneral from './TabGeneral'
import TabTransactions from './TabTransactions'
import styles from './styles.module.scss'

function Vendor() {
  const { user } = useSession()
  const ability = useAbilitiesContext()
  const [vendor, setVendor] = useState<VendorDetails>()
  const { id, tab = 'general' } = useParams() as { id: string; tab: string }
  const { data } = useVendor(id)
  const { mutate: updateOwners } = useUpdateVendorOwners()
  const navigate = useNavigate()
  const { data: cashBalances } = useReport(
    `investor/${id}/cash-balances`
  ) as unknown as {
    data: { cash: string; availableCash: string }
  }
  const { data: fields } = useFieldsByPersonId({
    personType: 'vendor',
    personId: id,
  })
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mutate: deleteVendor } = useRemoveVendor({ exact: true })

  const handleClickDelete = () => {
    if (vendor) {
      deleteVendor(vendor.id, {
        onSuccess: () => navigate(pathTo('vendors')),
      })
    }
  }

  useEffect(() => {
    if (data && fields) {
      setVendor(data)
    }
  }, [data, fields])

  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id, owners })
    },
    [id, updateOwners]
  )

  return (
    <MainContent>
      {vendor ? (
        <>
          <PageTop
            title={vendor.name}
            breadcrumbs={{ title: 'Vendors', link: pathTo('vendors') }}
            owners={vendor.owners || []}
            onOwnersChange={handleOwnersChange}
          />
          <div className={styles.badges}>
            <AccountTypeBadge type={vendor.type} isAccount={vendor.isAccount} />
          </div>
          <PageTopFields
            className="mb-6"
            fields={[
              {
                key: 'Cash Balance',
                value: formatUsd(cashBalances?.cash || 0),
              },
            ]}
          />
          <Tabs
            defaultActiveId={tab}
            className="mb-2"
            onTabSelect={(tabId) => navigate(pathTo('vendor', id, tabId))}
            actions={[
              {
                label: 'Delete',
                variant: 'danger',
                icon: IconName.delete,
                onSelect: () => setIsModalVisible(true),
              },
            ]}
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral vendor={vendor} fields={fields} />
            </Tabs.Pane>
            <Tabs.Pane tab="Transactions" id="transactions">
              <TabTransactions vendor={vendor} />
            </Tabs.Pane>
            {/* fake tab with empty id to add grouping before Mail tab*/}
            <Tabs.Pane tab="" id="" group="actions">
              {' '}
            </Tabs.Pane>
            {ability.can('read', 'communications') &&
            user?.client.settings?.showCommunications ? (
              <Tabs.Pane
                tab={
                  <Flex alignItems="center" gap={4}>
                    <Icon name={IconName.envelope} /> Mail
                  </Flex>
                }
                tabClassName="relative ml-8 before:-top-0 before:-left-6 before:absolute before:h-[34px] before:content-[''] before:border-0 before:border-l before:border-grey-200 before:border-solid"
                id="mail"
              >
                <TabMail personId={vendor.id} personType="vendor" />
              </Tabs.Pane>
            ) : (
              <></>
            )}
          </Tabs>
          {isModalVisible && (
            <ModalDelete
              resource="vendor"
              name={vendor.name}
              onDelete={handleClickDelete}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Vendor }
