import {
  RowData,
  ColumnDef,
  getCoreRowModel,
  useReactTable,
  getFacetedUniqueValues,
} from '@tanstack/react-table'
import clsx from 'clsx'
import {
  DragEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { BodyLoader } from 'components/Table/BodyLoader'
import { BodyRow } from 'components/Table/BodyRow'
import { Empty } from 'components/Table/Empty'
import { Thead } from 'components/Table/Thead'
import { useScrollToId } from 'hooks/use-scroll-to-id'
import { Sort, ColumnMeta } from 'types'
import TableCell from './Cell'
import { getColumnSizes } from './helpers'
import styles from './styles.module.scss'

interface Props<T> {
  loading?: boolean
  className?: string
  wrapperClassName?: string
  minWidth?: number
  columns: Array<ColumnDef<T>>
  data?: Array<T>
  sort?: Sort
  noResultsEmptyScreen?: boolean
  onSort?: (sort: Sort | string | undefined) => void
  onFileDrop?: (row: T, files: FileList) => void
  onClick?: (row?: T) => void
}

const Table = <T extends RowData>({
  loading,
  className,
  wrapperClassName,
  minWidth = 0,
  data = [],
  sort,
  noResultsEmptyScreen = false,
  onSort,
  columns,
  onFileDrop,
  onClick,
}: Props<T>) => {
  const [hadData, setHadData] = useState(false)
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
  const [columnWidths, setColumnWidths] = useState<number[]>([])
  const tableWrapperEl = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(
    tableWrapperEl.current?.offsetWidth || 0
  )
  const [rowDropId, setRowDropId] = useState<string>()
  useEffect(() => {
    setTimeout(() => {
      if (!loading && tableWrapperEl.current) {
        setIsScrollbarVisible(
          tableWrapperEl.current.scrollWidth >
            tableWrapperEl.current.clientWidth + 1
        )
      }
    }, 1)
  }, [
    tableWrapperEl.current?.scrollWidth,
    tableWrapperEl.current?.clientWidth,
    loading,
  ])

  useLayoutEffect(() => {
    setTableWidth(tableWrapperEl.current?.offsetWidth || 0)
  }, [])

  useEffect(() => {
    if (columns.length !== columnWidths.length && data.length) {
      setColumnWidths(getColumnSizes(columns, data, tableWidth))
    }
  }, [columnWidths, columns, data, tableWidth])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect
      // resize should happen only when table was initially rendered
      if (tableWidth !== rect.width && columnWidths.length) {
        setTableWidth(rect.width)
        setColumnWidths(getColumnSizes(columns, data, rect.width))
      }
    })
    if (tableWrapperEl.current) {
      resizeObserver.observe(tableWrapperEl.current)
    }
    return () => resizeObserver?.disconnect()
  }, [columns, data, tableWidth, columnWidths])

  useEffect(() => {
    if (data.length) {
      setHadData(true)
    }
  }, [data])

  const table = useReactTable({
    data,
    columns: columns.map(
      (column, index) =>
        ({
          ...column,
          size: (columnWidths?.[index] || 'auto') as unknown as number,
          meta: column.meta || {},
        }) as ColumnDef<T>
    ),
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const handleDrag = useCallback(
    (rowId: string, e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (onFileDrop && (e.type === 'dragenter' || e.type === 'dragover')) {
        setRowDropId(rowId)
      }
    },
    [onFileDrop]
  )
  const handleDragLeave = useCallback(() => {
    setRowDropId(undefined)
    setTimeout(() => setRowDropId(undefined), 1)
  }, [])

  const isEmpty = !loading && table.getRowModel().rows.length === 0
  const isLoaderVisible =
    (loading && table.getRowModel().rows.length === 0) ||
    (columnWidths.length === 0 && !isEmpty)
  const isTopTotalRowVisible = !!(
    table.getRowModel().rows?.[0]?.getVisibleCells()?.[0]?.column?.columnDef
      ?.meta as ColumnMeta
  )?.getTopCellValue
  useScrollToId({ enabled: !isLoaderVisible })

  return (
    <div
      className={clsx(styles.tableWrapper, wrapperClassName)}
      ref={tableWrapperEl}
    >
      <table
        className={clsx(
          styles.table,
          {
            [styles.firstColumnBorder]: isScrollbarVisible,
            [styles.loading]: isLoaderVisible,
            [styles.empty]: isEmpty,
          },
          className
        )}
        style={{ minWidth }}
        onDragLeave={handleDragLeave}
      >
        <Thead
          isLoaderVisible={isLoaderVisible}
          headerGroups={table.getHeaderGroups()}
          sort={sort}
          onSort={onSort}
        />

        <tbody onDragLeave={(e) => e.stopPropagation()}>
          {isEmpty && (
            <tr>
              <td colSpan={columns.length}>
                <Empty hadData={hadData || noResultsEmptyScreen} />
              </td>
            </tr>
          )}
          {isLoaderVisible ? (
            <BodyLoader />
          ) : (
            <>
              {isTopTotalRowVisible ? (
                <tr>
                  {table
                    .getRowModel()
                    .rows[0].getVisibleCells()
                    .map((cell) => {
                      const meta = cell.column.columnDef.meta as ColumnMeta
                      const row = table.getRowModel().rows[0]

                      return (
                        <td
                          key={cell.id}
                          data-label={
                            typeof cell.column.columnDef.header === 'string'
                              ? cell.column.columnDef.header
                              : meta?.plainHeader || ''
                          }
                          className={clsx(
                            styles.td,
                            '!bg-grey-75',
                            (row.original as any)?.rowClassName
                          )}
                        >
                          <TableCell {...cell.getContext()}>
                            {meta?.getTopCellValue?.({
                              rows: table.getRowModel().rows,
                            })}
                          </TableCell>
                        </td>
                      )
                    })}
                </tr>
              ) : null}
              {table.getRowModel().rows.map((row) => (
                <BodyRow
                  key={row.id}
                  row={row}
                  onClick={onClick}
                  onDragEnter={handleDrag}
                  updateRowDropId={setRowDropId}
                  rowDropId={rowDropId}
                  onFileDrop={onFileDrop}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

Table.displayName = 'Table'

export default Table
