import { DragEvent, useEffect, useState } from 'react'
import { useUploadAttachment } from 'admin/hooks/use-thread-attachments'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { IMail } from 'types'
import { openBrowseFile } from 'utils/file'

interface Props {
  mail: IMail
  uploading: boolean
  onUploading: (uploading: boolean) => void
  onClose: () => void
}

const TabUpload = ({ mail, uploading, onUploading, onClose }: Props) => {
  const [isDragActive, setIsDragActive] = useState(false)
  const { mutate: upload, isPending: isUploading } = useUploadAttachment({
    mail,
    onUpload: onClose,
  })
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => {
        upload(files)
      },
      accept: '*',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files)
  }

  useEffect(() => {
    if (isUploading) {
      onUploading(isUploading)
    }
  }, [onUploading, isUploading])

  return (
    <div className="h-50 mt-4 relative rounded" onDragEnter={handleDrag}>
      <Flex
        alignItems="center"
        justifyContent="center"
        className="absolute inset-0"
      >
        <Button onClick={handleUpload} loading={uploading}>
          Upload from Computer
        </Button>
      </Flex>
      {isDragActive && (
        <DragDropFile
          onLeave={() => setIsDragActive(false)}
          onDrop={handleDrop}
          accept={['*']}
          className="rounded"
        >
          <Flex stack gap={24} alignItems="center">
            <Icon
              name={IconName.uploadCloud}
              size="xl"
              className="text-blue-200"
            />
            <Header variant="h3">Drop a file</Header>
          </Flex>
        </DragDropFile>
      )}
    </div>
  )
}

export { TabUpload }
