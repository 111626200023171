import clsx from 'clsx'
import { useDrop } from 'react-dnd'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { IApplicationSchemeField, IApplicationSchemePage } from 'types'
import { PageBorrower } from './PageBorrower'
import { PageField } from './PageField'

interface Props {
  page: IApplicationSchemePage
  index: number
  pagesCount: number
  saveField: (field: IApplicationSchemeField) => void
  onMove: (page: IApplicationSchemePage, dir: 'up' | 'down') => void
  onDrop: (
    item: { type: string } & Partial<IApplicationSchemeField>,
    target: IApplicationSchemeField | null
  ) => void
  onEdit: (page: IApplicationSchemePage) => void
  onDelete: (page: IApplicationSchemePage) => void
  onEditField: (field: IApplicationSchemeField) => void
  onDeleteField: (field: IApplicationSchemeField) => void
  onToggleField: (field: IApplicationSchemeField) => void
}

function Page({
  index,
  page,
  pagesCount,
  saveField,
  onMove,
  onDrop,
  onEdit,
  onDelete,
  onEditField,
  onDeleteField,
  onToggleField,
}: Props) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['item', 'signature-item'],
      drop: (item) => {
        onDrop(
          item as { type: string } & Partial<IApplicationSchemeField>,
          null
        )
      },
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop]
  )

  return (
    <Panel>
      <Flex stack gap={16}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex stack gap={8}>
            <Text variant="xs" className="font-bold text-grey-600">
              PAGE {index + 1}/{pagesCount}
            </Text>
            <Header variant="h3">{page.header}</Header>
          </Flex>
          <EllipsesActions>
            <EllipsesActions.Item
              icon
              disabled={index === 0}
              onSelect={() => onMove(page, 'up')}
            >
              <Icon name={IconName.moveUp} />
              Move Up
            </EllipsesActions.Item>
            <EllipsesActions.Item
              icon
              disabled={index === pagesCount - 1}
              onSelect={() => onMove(page, 'down')}
            >
              <Icon name={IconName.moveDown} />
              Move Down
            </EllipsesActions.Item>
            <EllipsesActions.Item icon onSelect={() => onEdit(page)}>
              <Icon name={IconName.edit} />
              Edit
            </EllipsesActions.Item>
            <EllipsesActions.Item
              icon
              disabled={page.fields.some(({ type }) =>
                ['borrower', 'guarantor'].includes(type)
              )}
              onSelect={() => onDelete(page)}
              className="text-red-100"
            >
              <Icon name={IconName.delete} />
              Delete
            </EllipsesActions.Item>
          </EllipsesActions>
        </Flex>
        <Flex stack gap={0}>
          {!page.fields.length && (
            <Flex
              ref={drop}
              justifyContent="center"
              alignItems="center"
              className={clsx(
                'h-20 rounded border border-dashed border-grey-500 bg-grey-25',
                isOver && '!bg-grey-75'
              )}
            >
              <Flex gap={8}>
                <Icon name={IconName.dndCross} />
                Drag a question from the right
              </Flex>
            </Flex>
          )}
          {page.fields.map((field) =>
            ['borrower', 'guarantor'].includes(field.type) ? (
              <PageBorrower
                key={field.id}
                type={field.type as 'guarantor' | 'borrower'}
                field={field}
                saveField={saveField}
                onDrop={onDrop}
                onEdit={onEditField}
                onDelete={onDeleteField}
                onToggleField={onToggleField}
              />
            ) : (
              <PageField
                key={field.id}
                field={field}
                onDrop={onDrop}
                onEdit={onEditField}
                onDelete={onDeleteField}
                onToggleField={onToggleField}
              />
            )
          )}
        </Flex>
      </Flex>
    </Panel>
  )
}

export { Page }
