import { isUndefined } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ConditionsEmptyView } from 'admin/pages/Settings/Application/ConditionsEmptyView'
import { ModalCondition } from 'admin/pages/Settings/Application/ModalCondition'
import { getValidFullConditions } from 'admin/pages/Settings/Application/helpers'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import {
  IApplicationCondition,
  IApplicationScheme,
  IApplicationFullCondition,
} from 'types'
import { Condition } from './Condition'

interface Props {
  scheme: IApplicationScheme
  onChange: (conditions: IApplicationCondition[]) => void
}

const Conditions = ({ scheme, onChange }: Props) => {
  const [isConditionModalOpen, setIsConditionModalOpen] = useState(false)
  const [editingCondition, setEditingCondition] =
    useState<IApplicationFullCondition>()
  const [deletingCondition, setDeletingCondition] =
    useState<IApplicationFullCondition>()
  const [conditions, setConditions] = useState<IApplicationCondition[]>(
    scheme.conditions || []
  )

  const fullConditions = useMemo(
    () => getValidFullConditions(scheme, conditions),
    [scheme, conditions]
  )

  const handleEditCondition = useCallback(
    (condition: IApplicationFullCondition) => {
      setEditingCondition(condition)
      setIsConditionModalOpen(true)
    },
    []
  )
  const handleShowDeleteModal = useCallback(
    (condition: IApplicationFullCondition) => {
      setDeletingCondition(condition)
    },
    []
  )
  const handleDeleteCondition = useCallback(() => {
    setConditions((prevConditions) =>
      prevConditions.filter(({ id }) => id !== deletingCondition?.id)
    )
    setDeletingCondition(undefined)
  }, [deletingCondition])

  const handleSaveCondition = useCallback(
    (condition: IApplicationCondition) => {
      setConditions((prevConditions) => {
        if (editingCondition) {
          return prevConditions.map((prevCondition) =>
            prevCondition.id === editingCondition.id ? condition : prevCondition
          )
        }
        return [...prevConditions, condition]
      })
    },
    [editingCondition]
  )

  useEffect(() => {
    onChange(conditions)
  }, [conditions])

  return (
    <Flex
      stack
      alignItems="center"
      className="flex-grow h-full bg-grey-50 py-9 px-16 md:px-32"
    >
      <Flex stack className="w-full max-w-[1040px] margin-auto">
        <Flex justifyContent="space-between" alignItems="center">
          <Header>Conditions</Header>
          <Button onClick={() => setIsConditionModalOpen(true)}>
            <Icon name={IconName.conditions} />
            Create Condition
          </Button>
        </Flex>
        {fullConditions.length === 0 && <ConditionsEmptyView />}
        <Flex stack gap={16}>
          {fullConditions.map((condition) => (
            <Condition
              key={condition.id}
              condition={condition}
              onEdit={handleEditCondition}
              onDelete={handleShowDeleteModal}
            />
          ))}
        </Flex>
      </Flex>
      {!isUndefined(deletingCondition) && (
        <ModalDelete
          resource="condition"
          onDelete={handleDeleteCondition}
          onCancel={() => setDeletingCondition(undefined)}
        />
      )}
      {isConditionModalOpen && (
        <ModalCondition
          scheme={scheme}
          condition={editingCondition}
          onSave={handleSaveCondition}
          onClose={() => {
            setIsConditionModalOpen(false)
            setEditingCondition(undefined)
          }}
        />
      )}
    </Flex>
  )
}

export { Conditions }
