import clsx from 'clsx'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IBudgetWorksheetAttribute } from 'types'

interface Props {
  index: number
  attribute: IBudgetWorksheetAttribute
  onEdit: (attribute: IBudgetWorksheetAttribute) => void
  onDelete: (attribute: IBudgetWorksheetAttribute) => void
  onDrop: (dragIndex: number, dropIndex: number) => void
}

const WorkItemAttributesTableRow = ({
  index,
  attribute,
  onEdit,
  onDelete,
  onDrop,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['item'],
      drop: (item: { attribute: IBudgetWorksheetAttribute; index: number }) => {
        onDrop(item.index, index)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    []
  )
  const [{ isDragging }, drag] = useDrag(
    {
      type: 'item',
      item: () => ({
        index,
        attribute,
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [index, attribute]
  )

  drag(drop(ref))

  return (
    <Flex
      ref={ref}
      className={clsx(
        'px-3 border-0 border-b border-solid border-grey-200 h-14',
        isOver && '!border-blue-100',
        isDragging && 'hidden'
      )}
      alignItems="center"
    >
      <Icon
        name={IconName.dndHandler}
        className="text-grey-600 flex-shrink-0"
      />
      <div className="flex-grow truncate">{attribute.name}</div>
      {!attribute.readonly && (
        <EllipsesActions className="flex-shrink-0">
          <EllipsesActions.Item
            icon
            onSelect={() => {
              onEdit(attribute)
            }}
          >
            <Icon name={IconName.edit} />
            Edit
          </EllipsesActions.Item>
          <EllipsesActions.Item
            icon
            onSelect={() => onDelete(attribute)}
            className="text-red-100"
          >
            <Icon name={IconName.delete} />
            Delete
          </EllipsesActions.Item>
        </EllipsesActions>
      )}
    </Flex>
  )
}

export { WorkItemAttributesTableRow }
