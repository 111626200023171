import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { pathToLoan, pathTo, isLoanServicing } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Redact } from 'components/Redact'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { OfferingLoan } from 'types'
import { formatUsd } from 'utils/currency'

function TableOfferingLoans({
  data = [],
  loading,
  onDelete,
}: {
  data?: OfferingLoan[]
  loading: boolean
  onDelete: (loan: OfferingLoan) => void
}) {
  const ability = useAbilitiesContext()
  const navigate = useNavigate()
  const columns: ColumnDef<OfferingLoan>[] = useMemo(
    () => [
      {
        header: 'Loan',
        id: 'loanName',
        cell: ({ row }) => (
          <TextLink
            onClick={() =>
              (!isLoanServicing(row.original.loan.status) ||
                ability.can('read', 'servicing')) &&
              navigate(pathToLoan(row.original.loan, 'loanFunding'))
            }
          >
            <Redact
              value={row.original.loan?.name || ''}
              hide={
                isLoanServicing(row.original.loan.status) &&
                ability.cannot('read', 'servicing')
              }
            />
          </TextLink>
        ),
      },
      {
        header: 'Funding Source',
        id: 'name',
        cell: ({ row }) =>
          row.original.fundingSource ? (
            <TextLink
              onClick={() =>
                ((!isLoanServicing(row.original.loan.status) ||
                  ability.can('read', 'servicing')) &&
                  navigate(pathToLoan(row.original.loan, 'loanFunding'))) ||
                (ability.can('read', 'investors') &&
                  navigate(
                    pathTo('investor', row.original.fundingSource.investorId)
                  ))
              }
            >
              <Redact
                value={row.original.fundingSource?.investor?.name || '-'}
                hide={
                  isLoanServicing(row.original.loan.status) &&
                  ability.cannot('read', 'investors')
                }
              />
            </TextLink>
          ) : (
            'New Funding'
          ),
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
        size: 140,
      },
      {
        id: 'actions',
        header: '',
        size: 46,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TableOfferingLoans
