import { sortBy } from 'lodash'
import { request } from 'services/request'
import { ITemplate } from 'types'

const defaultTemplates: ITemplate[] = [
  { id: 'payoff', name: 'Payoff', type: 'payoff', isDefault: true },
  {
    id: 'loan_documents',
    name: 'Loan Documents',
    type: 'loan_documents',
    isDefault: true,
  },
  {
    id: 'commitment_letter',
    name: 'Commitment Letter',
    type: 'commitment_letter',
    isDefault: true,
  },
  {
    id: 'preapproval_letter',
    name: 'Pre-Approval Letter',
    type: 'preapproval_letter',
    isDefault: true,
  },
  {
    id: 'rehab-budget',
    name: 'Budget Worksheet',
    type: 'rehab-budget',
    isDefault: true,
  },
  {
    id: 'investor_agreement',
    name: 'Investor Agreement',
    type: 'investor_agreement',
    isDefault: true,
  },
]

const getTemplateUrl = async (
  name: string
): Promise<{ url: string; uploadUrl: string }> => {
  const {
    data: { template, upload },
  } = await request.get(`/document/template/${name}`)
  return { url: template.url, uploadUrl: upload }
}

const getTemplates = async (loanId?: string): Promise<ITemplate[]> => {
  const {
    data: { templates },
  } = await request.get(
    loanId ? `/loan/${loanId}/document/template` : '/document/template'
  )
  return templates
}

const addTemplate = async (name: string): Promise<any> => {
  const {
    data: { template },
  } = await request.post('/document/template', { name })
  return template
}

const updateTemplate = async (
  id: string,
  params: { name?: string }
): Promise<any> => {
  const {
    data: { template },
  } = await request.patch(`/document/template/${id}`, params)
  return template
}

const deleteTemplate = async (id: string): Promise<any> => {
  const {
    data: { templates },
  } = await request.delete(`/document/template/${id}`)
  return templates
}

const resetTemplate = async (name: string): Promise<void> => {
  return request.delete(`/document/template/${name}`)
}

const mergeTemplatesWithDefault = (
  customTemplates: ITemplate[],
  passedDefaultTemplates?: ITemplate[]
): ITemplate[] => {
  if (!passedDefaultTemplates) {
    passedDefaultTemplates = defaultTemplates
  }

  return sortBy(
    [
      ...passedDefaultTemplates.map((defaultTemplate) => {
        const customTemplate = customTemplates.find(
          (custom) => custom.type === defaultTemplate.id
        )

        if (customTemplate) {
          return {
            ...customTemplate,
            name: defaultTemplate.name,
            isDefault: true,
          }
        } else {
          return {
            ...defaultTemplate,
            isUploaded: false,
          }
        }
      }),
      ...customTemplates.filter(
        ({ name, type }) =>
          !type && !passedDefaultTemplates.some(({ id }) => id === name)
      ),
    ],
    ({ name }) => name.toLowerCase()
  )
}

export {
  defaultTemplates,
  getTemplateUrl,
  resetTemplate,
  getTemplates,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  mergeTemplatesWithDefault,
}
