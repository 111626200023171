import { useQueryClient } from '@tanstack/react-query'
import { compact, debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  components,
  GroupBase,
  MenuProps,
  Props as ReactSelectProps,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { fetchInvestors, useInvestors } from 'admin/hooks/use-investors'
import { fetchVendors, useVendors } from 'admin/hooks/use-vendors'
import { ModalCreateRecipient } from 'admin/pages/LoanSpreadAllocation/ModalRecipient'
import { Vendor } from 'admin/services/api/vendors'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { CreateOption } from 'components/Select/CreateOption'
import { Person } from 'types'

interface Props {
  unavailableRecipientIds?: string[]
  value?: any
  className?: string
  menuPlacement?: ReactSelectProps['menuPlacement']
  placeholder?: string
  allowEmpty?: boolean
  onSelect: ({ id, name }: { id: string; name: string }) => void
  // allows to create new recipient and return it in the onSelect callback
  allowCreate?: boolean
  // callback when created is selected in the menu
  onCreate?: (name: string) => void
}

export const SelectRecipient = ({
  unavailableRecipientIds = [],
  value,
  placeholder,
  className,
  menuPlacement = 'auto',
  allowEmpty,
  onSelect,
  allowCreate,
  onCreate,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(!!value)
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>()
  const queryClient = useQueryClient()
  const { data: vendors } = useVendors()
  const { data: investors } = useInvestors()
  const personsToOptions = useCallback(
    (persons: (Person | Vendor)[]) =>
      persons
        .filter(({ id }) => !unavailableRecipientIds?.includes(id))
        .map(({ name, id }) => ({
          label: name,
          value: id,
        })),
    [unavailableRecipientIds]
  )
  const loadSuggestedOptions = useCallback(
    debounce((inputValue, callback) => {
      Promise.all([
        fetchVendors(queryClient, {
          search: { name: inputValue },
        }),
        fetchInvestors(queryClient, {
          search: { name: inputValue },
        }),
      ]).then(([{ vendors }, { investors }]) =>
        callback(personsToOptions([...vendors, ...investors]))
      )
    }, 500),
    []
  )

  useEffect(() => {
    if (value && value !== selectedOption?.value) {
      Promise.all([
        fetchVendors(queryClient, {
          filter: { id: value },
        }),
        fetchInvestors(queryClient, {
          filter: { id: value },
        }),
      ]).then(([{ vendors }, { investors }]) => {
        setSelectedOption(personsToOptions([...vendors, ...investors])[0])
        setIsLoading(false)
      })
    } else {
      setSelectedOption(undefined)
      setIsLoading(false)
    }
  }, [value])

  const handleCreate = useCallback(() => {
    onCreate?.(inputValue)
    if (allowCreate) {
      setIsCreateModalVisible(true)
    }
  }, [onCreate, inputValue, allowCreate])

  const Menu = useCallback(
    (props: MenuProps<unknown, boolean, GroupBase<unknown>>) => (
      <div className="absolute top-full w-full z-1">
        <components.Menu className="!relative" {...props}>
          {props.children}
        </components.Menu>
        <CreateOption onClick={handleCreate}>
          <Flex
            gap={4}
            alignItems="center"
            className="py-2 font-bold text-blue-200 hover:text-black-100"
          >
            <Icon name={IconName.plus} size="sm" />
            Create a new recipient
          </Flex>
        </CreateOption>
      </div>
    ),
    [inputValue, handleCreate]
  )

  return (
    <>
      <AsyncSelect
        classNamePrefix="formFieldSelect"
        className={className}
        value={selectedOption}
        placeholder={placeholder || 'Select...'}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.getElementById('select-portal')}
        loadOptions={loadSuggestedOptions}
        components={onCreate || allowCreate ? { Menu } : {}}
        defaultOptions={compact([
          allowEmpty ? { label: '(blank)', value: null } : undefined,
          ...personsToOptions(investors?.investors || []),
          ...personsToOptions(vendors?.vendors || []),
        ])}
        isLoading={isLoading}
        noOptionsMessage={() => <>None found</>}
        onInputChange={(newValue) => setInputValue(newValue)}
        onChange={(option) => {
          setSelectedOption(option)
          onSelect({
            id: option?.value as string,
            name: option?.label as string,
          })
        }}
      />
      {isCreateModalVisible && (
        <ModalCreateRecipient
          onClose={() => setIsCreateModalVisible(false)}
          onAdd={({ id, name }) => {
            onSelect({ id, name })
            setIsCreateModalVisible(false)
          }}
        />
      )}
    </>
  )
}
