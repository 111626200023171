import clsx from 'clsx'
import DOMPurify from 'dompurify'
import { useState } from 'react'
import { Avatar } from 'components/Avatar'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { useSession } from 'hooks/use-session'
import { IComment } from 'types'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  namespace: string
  comment: IComment
  hidePinIcon?: boolean
  onPin?: (comment: IComment) => void
  onUnpin?: (comment: IComment) => void
  onView?: (comment: IComment) => void
  onDelete?: (comment: IComment) => void
  onEdit?: (comment: IComment) => void
}

const Comment = ({
  namespace,
  comment,
  hidePinIcon,
  onPin,
  onUnpin,
  onView,
  onEdit,
  onDelete,
}: Props) => {
  const { isAdminManager, user } = useSession()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Flex
      id={`${namespace}_${comment.id}`}
      gap={12}
      className={clsx('group pt-2 px-2', !!onView && 'cursor-pointer')}
      onClick={() => onView?.(comment)}
    >
      {comment.by.id === 'baseline' ? (
        <Avatar
          id={comment.by.id}
          name={comment.by.name}
          className="mt-1.5 !bg-blue-100"
        />
      ) : (
        <Tooltip content={comment.by.name}>
          <Avatar
            id={comment.by.id}
            name={comment.by.name}
            className="mt-1.5"
          />
        </Tooltip>
      )}

      <div className="w-full overflow-x-auto pb-3 px-1">
        <Flex stack gap={6}>
          <Flex gap={4}>
            <div className="font-bold leading-4">
              {comment.by.id === 'baseline' ? 'Baseline' : comment.by.name}
            </div>
            {!hidePinIcon && comment.isPinned && (
              <Icon
                name={IconName.pin}
                className="text-yellow-100 flex-shrink-0"
              />
            )}
          </Flex>
          <div
            className={styles.comment}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(comment.text),
            }}
          />
          <Text variant="s" className="text-grey-600 normal-nums">
            {friendlyDateTime(comment.createdAt, 'EEEE MMM d, y @ h:mm aaa')}
          </Text>
        </Flex>
      </div>

      <EllipsesActions
        onOpenChange={setIsMenuOpen}
        triggerClassName={clsx(
          'h-7 group-hover:visible',
          isMenuOpen ? 'visible' : 'invisible'
        )}
      >
        {!comment.isPinned && onPin && (
          <EllipsesActions.Item onSelect={() => onPin(comment)}>
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.pin} className="text-yellow-100" />
              Pin
            </Flex>
          </EllipsesActions.Item>
        )}
        {comment.isPinned && onUnpin && (
          <EllipsesActions.Item onSelect={() => onUnpin(comment)}>
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.pin} className="text-yellow-100" />
              Unpin
            </Flex>
          </EllipsesActions.Item>
        )}
        {onView && (
          <EllipsesActions.Item onSelect={() => onView(comment)}>
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.comments} className="text-grey-600" />
              View in Comments
            </Flex>
          </EllipsesActions.Item>
        )}
        {comment.by.id === user?.admin?.id && onEdit && (
          <EllipsesActions.Item onSelect={() => onEdit(comment)}>
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.edit} className="text-grey-600" />
              Edit
            </Flex>
          </EllipsesActions.Item>
        )}
        {(isAdminManager || comment.by.id === user?.admin?.id) && onDelete && (
          <EllipsesActions.Item
            variant="danger"
            onSelect={() => onDelete(comment)}
          >
            <Flex alignItems="center" gap={4}>
              <Icon name={IconName.delete} />
              Delete
            </Flex>
          </EllipsesActions.Item>
        )}
      </EllipsesActions>
    </Flex>
  )
}

export { Comment }
