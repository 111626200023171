import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { getBillingUrl } from 'admin/services/api/billing'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PanelLinks } from 'components/PanelLinks'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'

function CompanySettings() {
  const navigate = useNavigate()
  const { user, isAdminManager } = useSession()
  const redirectToBilling = async () => {
    window.location.href = await getBillingUrl((user as CurrentUser).client.id)
  }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Company Settings" />
        {isAdminManager && (
          <PanelLinks>
            <PanelLinks.Link
              icon={<Icon name={IconName.settings} />}
              title="General Settings"
              description="Tailor Baseline to your company, setup Trust accounts, and NACHA settings."
              onClick={() => navigate(pathTo('settingsGeneralSettings'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.settings} />}
              title="Portal Settings"
              description="Customize settings for the borrower and investor portals."
              onClick={() => navigate(pathTo('settingsPortalSettings'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.team} />}
              title="Team"
              description="Manage your team and permissions within Baseline."
              onClick={() => navigate(pathTo('settingsTeam'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.roles} />}
              title="Roles and Permissions"
              description="Manage your team and permissions within Baseline."
              onClick={() => navigate(pathTo('settingsRoles'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.notification} />}
              title="Notifications"
              description="Control how team members, borrowers, investors and brokers receive notifications."
              onClick={() => navigate(pathTo('settingsNotifications'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.applicationSettings} />}
              title="Loan Application"
              description="Modify the borrower loan application experience."
              onClick={() => navigate(pathTo('settingsApplication'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.workspace} />}
              title="Workspace"
              description="Modify your domain settings and the appearance of Baseline."
              onClick={() => navigate(pathTo('settingsWorkspace'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.pen} />}
              title="Lenders of Record"
              description="Add or modify your Lender of Record information."
              onClick={() => navigate(pathTo('settingsLenderOfRecord'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.pipeline} />}
              title="Pipeline"
              description="Customize your loan origination pipeline."
              onClick={() => navigate(pathTo('settingsPipeline'))}
            />
            <PanelLinks.Link
              icon={<Icon name={IconName.dollar} />}
              title="Billing"
              description="Setup your billing information for your Baseline subscription."
              onClick={redirectToBilling}
            />
          </PanelLinks>
        )}
      </Flex>
    </MainContent>
  )
}

export { CompanySettings }
