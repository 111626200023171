import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { Text } from 'components/Text'

const WorkItems = () => {
  return (
    <Flex className="pt-6 pb-10" gap={40}>
      <Flex flexDirection="column" gap={20} className="basis-[376px]">
        <Header variant="h4">Work Items</Header>
        <Text className="text-grey-700">
          This is the list of work items available for this document to assign
          to line numbers. Once line items match the Work items in bulk, or edit
          sections and cost numbers.
        </Text>
      </Flex>

      <Panel className="flex-grow">
        <Flex flexDirection="column" gap={16}>
          <Flex gap={10} alignItems="center">
            <Search
              fieldClassName="flex-grow"
              className="w-full"
              onSearch={() => {}}
              search=""
            />
            <Button variant="secondary">
              Edit in Bulk{' '}
              <Icon name={IconName.linkExternal} className="text-grey-600" />
            </Button>
          </Flex>

          <div>Work Items Table</div>

          <div>
            <Button variant="ghost">
              <Icon name={IconName.addCircle} className="text-grey-600" />
              Add Work Item
            </Button>
          </div>
        </Flex>
      </Panel>
    </Flex>
  )
}

export { WorkItems }
