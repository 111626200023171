import { substatusToColor } from 'constants/substatus'
import { Indicator, IndicatorProps } from '../Indicator'
import { Text } from '../Text'
import styles from './styles.module.scss'

interface StatusBadgeProps {
  substatus: string
  color?: IndicatorProps['color']
}

function LoanSubstatusBadge({ substatus, color }: StatusBadgeProps) {
  return (
    <Text className={styles.substatus}>
      <Indicator color={color || substatusToColor(substatus)} />
      {substatus}
    </Text>
  )
}

export default LoanSubstatusBadge
