import clsx from 'clsx'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'

interface Props {
  description: string
  className?: string
}

export const Description = ({ description, className }: Props) => {
  return (
    <Flex className={clsx('mt-4', className)} justifyContent="center">
      <Text className="max-w-115 text-center leading-5 text-grey-700">
        {description}
      </Text>
    </Flex>
  )
}
