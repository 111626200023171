import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewLoansImg from 'images/empty-view-loans.png'

interface Props {
  onClick: () => void
}

export const EmptyLoans = ({ onClick }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={'center h-50 w-50 !bg-cover !bg-center !bg-no-repeat'}
            style={{ background: `url(${emptyViewLoansImg})` }}
          />
        }
      />
      <EmptyView.Title title="Add your first loan to the pipeline" />
      <EmptyView.Description description="From lead to funding - use your pipeline to manage all your loans throughout the origination process." />
      <Flex className="mt-6" justifyContent="center">
        <Button onClick={onClick}>Add Loan</Button>
      </Flex>
    </EmptyView>
  )
}
