import { without } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalAttribute } from 'admin/pages/Settings/Worksheet/ModalAttribute'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { useUpdateBudgetWorksheet } from 'hooks/use-budget-worksheet'
import { IBudgetWorksheet, IBudgetWorksheetAttribute } from 'types'
import { WorkItemAttributesTable } from './WorkItemAttributesTable'

interface Props {
  worksheet: IBudgetWorksheet
}

const WorkItemAttributes = ({ worksheet }: Props) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingAttribute, setEditingAttribute] =
    useState<IBudgetWorksheetAttribute>()
  const [deletingAttribute, setDeletingAttribute] =
    useState<IBudgetWorksheetAttribute>()
  const { mutate: updateWorksheet, isPending: saving } =
    useUpdateBudgetWorksheet()

  const handleSave = (attribute: IBudgetWorksheetAttribute) => {
    let attributes: IBudgetWorksheetAttribute[] = []
    if (editingAttribute) {
      attributes = without(worksheet.attributes, editingAttribute)
    } else {
      attributes = [...worksheet.attributes, attribute]
    }

    updateWorksheet(
      {
        ...worksheet,
        attributes,
      },
      {
        onSuccess: () => {
          setIsModalOpen(false)
          setEditingAttribute(undefined)
        },
      }
    )
  }
  const handleDelete = () => {
    updateWorksheet(
      {
        ...worksheet,
        attributes: without(worksheet.attributes, deletingAttribute!),
      },
      {
        onSuccess: () => {
          setDeletingAttribute(undefined)
        },
      }
    )
  }

  return (
    <Flex
      className="pt-12 pb-10 border-solid border-0 border-b border-grey-200"
      gap={40}
    >
      <Flex
        flexDirection="column"
        gap={20}
        className="basis-[376px] flex-shrink-0"
      >
        <Header variant="h4">Work Item Attributes</Header>
        <Text className="text-grey-700">
          Work item attributes may appear as columns in your budget worksheet.
          Add or remove attributes to your choice of work items in fields, drop
          downs, currency fields and more.
        </Text>
        <div className="px-3 py-2 bg-purple-25 rounded-sm border border-solid border-purple-50 text-grey-700">
          <span className="font-bold text-purple-300">Note:</span>{' '}
          <span className="text-grey-900">Work Item</span> and{' '}
          <span className="text-grey-900">Amount</span> are essential attributes
          and cannot be hidden or removed from your worksheet.
        </div>
      </Flex>

      <Panel
        title="Work Item Attributes"
        className="flex-grow overflow-hidden"
        actionChildren={
          <Flex gap={10}>
            <Button
              variant="secondary"
              onClick={() =>
                navigate(
                  pathTo('settingsWorksheetAttributesPreview', worksheet.id)
                )
              }
            >
              Preview Worksheet
              <Icon name={IconName.linkExternal} className="text-grey-600" />
            </Button>
            <Button onClick={() => setIsModalOpen(true)}>Add Attribute</Button>
          </Flex>
        }
      >
        <WorkItemAttributesTable
          attributes={worksheet.attributes}
          onReorder={(attributes) =>
            updateWorksheet({
              ...worksheet,
              attributes,
            })
          }
          onEdit={(attribute) => {
            setIsModalOpen(true)
            setEditingAttribute(attribute)
          }}
          onDelete={setDeletingAttribute}
        />
        <Button
          variant="ghost"
          className="mt-4"
          onClick={() => setIsModalOpen(true)}
        >
          <Icon name={IconName.addCircle} className="text-grey-600" />
          Add Attribute
        </Button>
      </Panel>

      {isModalOpen && (
        <ModalAttribute
          attribute={editingAttribute}
          saving={saving}
          onSave={handleSave}
          onCancel={() => {
            setIsModalOpen(false)
            setEditingAttribute(undefined)
          }}
        />
      )}

      {deletingAttribute && (
        <ModalDelete
          resource="attribute"
          loading={saving}
          onDelete={handleDelete}
          onCancel={() => setDeletingAttribute(undefined)}
        />
      )}
    </Flex>
  )
}

export { WorkItemAttributes }
