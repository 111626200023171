import Field, { CustomFieldProps, FieldChildProps } from 'components/Form/Field'
import { Editor } from './Editor'

function FieldWysiwyg(props: CustomFieldProps) {
  return (
    <Field {...props}>
      {({ form, field }: FieldChildProps) => (
        <Editor
          value={field.value}
          placeholder={props.placeholder}
          onChange={(value) => {
            form.setFieldValue(field.name, value)
          }}
        />
      )}
    </Field>
  )
}

export { FieldWysiwyg }
