import clsx from 'clsx'
import { compact } from 'lodash'
import { useCallback, useState } from 'react'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IConditionConfig, IConditionValue } from 'types'
import { Menu } from './Menu'
import { Rule } from './Rule'

interface Props {
  conditionsConfig: IConditionConfig[]
  rules: IConditionValue[]
  onChange: (value: IConditionValue[]) => void
}

const Conditions = ({ conditionsConfig, rules, onChange }: Props) => {
  const [open, setOpen] = useState(false)
  const handleChange = useCallback(
    (index: number, nextValue: IConditionValue | undefined) => {
      rules[index] = nextValue as IConditionValue
      onChange(compact([...rules]))
      setOpen(false)
    },
    [rules, onChange]
  )

  return (
    <Flex stack gap={8}>
      {rules.length > 0 && (
        <Flex stack gap={0}>
          {rules.map((rule, index) => (
            <Rule
              key={index}
              configs={conditionsConfig}
              value={rule}
              className={clsx(
                'rounded-t-none border-t-0',
                index !== rules.length - 1 && 'rounded-b-none'
              )}
              type="condition"
              onChange={(value) => handleChange(index, value)}
            />
          ))}
        </Flex>
      )}

      <Dropdown
        open={open}
        onOpenChange={setOpen}
        triggerFixedWidth
        trigger={
          <Flex
            alignItems="center"
            gap={8}
            className="font-bold cursor-pointer pl-3 my-2"
          >
            <Icon name={IconName.addCircle} />
            {rules.length > 0 ? 'Add additional condition' : 'Add condition'}
          </Flex>
        }
      >
        <Menu
          menuItems={conditionsConfig}
          value={undefined}
          onSelect={(value) => handleChange(rules.length, value)}
          type="condition"
        />
      </Dropdown>
    </Flex>
  )
}

export { Conditions }
