import { useState } from 'react'
import { Outlet, useLocation, matchPath } from 'react-router-dom'
import { routerPath, pathTo } from 'admin/path-to'
import { MainLayout } from 'components/layout'
import { SideNavigation } from '../SideNavigation'

function AdminMainLayout() {
  const location = useLocation()
  const isSideMenuHidden = [
    routerPath.loanDocument,
    routerPath.servicingLoanDocument,
    routerPath.offeringDocument,
    routerPath.personDocument,
    routerPath.fundDocument,
    routerPath.loanSpreadAllocation,
    routerPath.settingsApplication,
  ].some((path) => !!matchPath(path, location.pathname))
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)
  const [isContactsOpen, setIsContactsOpen] = useState(false)

  return (
    <MainLayout
      navigation={
        isSideMenuHidden && !isMobileNavVisible ? null : (
          <SideNavigation
            isContactsOpen={isContactsOpen}
            setIsMobileNavVisible={setIsMobileNavVisible}
            setIsContactsOpen={setIsContactsOpen}
          />
        )
      }
      logoUrl={pathTo('dashboard')}
      isMobileNavVisible={isMobileNavVisible}
      setIsMobileNavVisible={setIsMobileNavVisible}
    >
      <Outlet />
    </MainLayout>
  )
}

export default AdminMainLayout
