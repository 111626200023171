import clsx from 'clsx'
import { isString } from 'lodash'
import {
  forwardRef,
  ForwardedRef,
  memo,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  ButtonHTMLAttributes,
} from 'react'
import { Icon, IconName } from 'components/Icon'
import { useAnalytics } from 'hooks/use-analytics'
import styles from './styles.module.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  className?: string
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'panel'
  color?: 'default' | 'negative' | 'positive'
  size?: 'medium' | 'small' | 'large'
  type?: 'button' | 'submit'
  icon?: boolean
  disabled?: boolean
  loading?: boolean
  active?: boolean
  iconLeft?: ReactElement
  iconRight?: ReactElement
  onClick?: (e: SyntheticEvent) => void
}

const Button = memo(
  forwardRef(
    (
      {
        children,
        className,
        variant = 'primary',
        color = 'default',
        size = 'medium',
        icon = false,
        disabled = false,
        iconLeft,
        iconRight,
        onClick,
        loading = false,
        type = 'button',
        active = false,
        ...rest
      }: ButtonProps,
      ref: ForwardedRef<HTMLButtonElement | null>
    ) => {
      const { trackEvent } = useAnalytics()

      const classNames = clsx(className, styles.button, {
        [styles.sizeSmall]: size === 'small',
        [styles.sizeLarge]: size === 'large',
        [styles.variantPrimary]: variant === 'primary',
        [styles.variantSecondary]: variant === 'secondary',
        [styles.variantTertiary]: variant === 'tertiary',
        [styles.variantGhost]: variant === 'ghost',
        [styles.variantPanel]: variant === 'panel',
        [styles.colorNegative]: color === 'negative',
        [styles.colorPositive]: color === 'positive',
        [styles.onlyIcon]: icon,
        [styles.active]: active,
        [styles.loading]: loading,
      })

      return (
        <button
          type={type}
          className={classNames}
          disabled={loading || disabled}
          onClick={(e) => {
            if (!loading && onClick) {
              onClick(e)
              trackEvent({
                eventName: isString(children)
                  ? `${children} Click`
                  : 'Button Click',
              })
            }
          }}
          {...rest}
          ref={ref}
        >
          {loading && (
            <Icon
              name={IconName.loaderSpinner}
              className={clsx('spinner', styles.spinner)}
            />
          )}
          <div className={clsx(loading && 'invisible', styles.wrapper)}>
            {iconLeft}
            {children}
            {iconRight}
          </div>
        </button>
      )
    }
  )
)

export type { ButtonProps }
export default Button
