/**
 * Translation helper
 */
function T(t: string) {
  return t
}

/**
 * Default context menu items
 * @param {object} obj - worksheet
 * @param {number} x
 * @param {number} y
 * @param {object} event
 * @param {object} items
 * @param {string} section
 * @param {object\|number} argument1
 * @param {number} argument2
 * @return {array} Return the items to create the contextmenu
 */
function contextmenu(obj, x, y, e, items, section) {
  // Reset all default items
  items = []

  // Is mac
  const ctrl = navigator.userAgent.indexOf('Mac') !== -1 ? '⌘' : 'Ctrl'

  // Sections that affect the selection
  if (
    section === 'header' ||
    section === 'row' ||
    section === 'cell' ||
    section === 'nested'
  ) {
    // Cut
    items.push({
      title: T('Cut'),
      icon: 'content_cut',
      shortcut: ctrl + ' + X',
      onclick: function () {
        obj.cut()
      },
    })

    // Copy
    items.push({
      title: T('Copy'),
      icon: 'content_copy',
      shortcut: ctrl + ' + C',
      onclick: function () {
        obj.copy()
      },
    })

    // Paste
    if (navigator && navigator.clipboard && navigator.clipboard.readText) {
      items.push({
        title: T('Paste'),
        icon: 'content_paste',
        shortcut: ctrl + ' + V',
        onclick: function () {
          if (obj.selectedCell) {
            navigator.clipboard.readText().then(function (text) {
              if (text) {
                const px = Math.min(obj.selectedCell[0], obj.selectedCell[2])
                const py = Math.min(obj.selectedCell[1], obj.selectedCell[3])
                obj.paste(px, py, text)
              }
            })
          }
        },
      })
    }
  }

  // Row only
  if (section === 'cell' || section === 'row') {
    items.push({ type: 'line' })

    // Insert new row
    if (obj.options.allowInsertRow !== false) {
      items.push({
        title: T('Insert a new row before'),
        onclick: function () {
          obj.insertRow(1, parseInt(y), 1)
        },
      })

      items.push({
        title: T('Insert a new row after'),
        onclick: function () {
          obj.insertRow(1, parseInt(y))
        },
      })
    }

    if (obj.options.allowDeleteRow !== false) {
      items.push({
        title: T('Delete selected rows'),
        onclick: function () {
          obj.deleteRow(obj.getSelectedRows(true))
        },
      })
    }
  }

  return items
}

export { contextmenu }
