import clsx from 'clsx'
import { CSSProperties, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { MainContent } from 'borrower/components/layout/MainContent'
import { PanelMyInvestments } from 'borrower/pages/Offering/PanelMyInvestments'
import styles from 'borrower/pages/Offering/styles.module.scss'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Indicator } from 'components/Indicator'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { useLoan } from 'hooks/use-loans'
import { useSession } from 'hooks/use-session'
import { LoanFundingSource } from 'types'

const getStatus = (
  fundingSources: LoanFundingSource[] | undefined = []
): 'Active' | 'Pending' | 'Liquidated' => {
  const statuses =
    fundingSources.map((source) => {
      if (
        source.dateFunded &&
        source.amount !== 0 &&
        source.loan?.status !== 'liquidated'
      ) {
        return 'Active'
      }
      if (source.dateFunded) {
        return 'Liquidated'
      }
      return 'Pending'
    }) || []
  if (statuses.some((status) => status === 'Active')) {
    return 'Active'
  }
  if (statuses.some((status) => status === 'Pending')) {
    return 'Pending'
  }
  return 'Liquidated'
}

function OfferingIndividualLoan() {
  const { user } = useSession()
  const { loanId } = useParams() as { loanId: string }
  const { data: loan } = useLoan({ id: loanId })
  const imageUrl = loan?.image?.url
  const status = useMemo(
    () => getStatus(loan?.fundingSources),
    [loan?.fundingSources]
  )

  return (
    <MainContent className="!max-w-none p-0">
      {loan ? (
        <div
          style={{ '--offer-image-url': `url(${imageUrl})` } as CSSProperties}
          className={clsx(
            'relative',
            "before:absolute before:content-[''] before:bg-cover before:-z-1 before:h-[250px] before:w-full",
            imageUrl
              ? 'before:bg-[image:var(--offer-image-url)] before:blur-md before:bg-center before:scale-110'
              : 'before:bg-grey-100',
            "after:absolute after:content-[''] after:bg-grey-50 after:h-full after:w-full after:-z-1 after:top-[250px]"
          )}
        >
          <Flex className={styles.container} stack gap={24}>
            <Panel className="p-6 sm:p-12">
              <Flex className="flex-wrap sm:flex-nowrap">
                <Flex stack gap={32} className={styles.info}>
                  <Flex stack gap={16}>
                    <div className="text-grey-600 font-bold text-sm uppercase">
                      {user?.client.name}
                    </div>
                    <Header className="truncate md:whitespace-normal text-[44px]">
                      {loan.name}
                    </Header>
                  </Flex>
                  <Flex
                    gap={8}
                    alignItems="center"
                    className="text-grey-900 text-5xl"
                  >
                    <Indicator
                      color="green"
                      className={clsx(
                        'w-2.5 h-2.5 flex-shrink-0 rounded-[3px] bg-green-50',
                        status === 'Active' && 'bg-green-50',
                        status === 'Pending' && 'bg-yellow-100',
                        status === 'Liquidated' && 'bg-grey-300'
                      )}
                    />
                    {status}
                  </Flex>
                </Flex>
                <div
                  className={styles.image}
                  style={
                    imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}
                  }
                />
              </Flex>
            </Panel>
            <Panel className="p-6 sm:p-12">
              {!!loan.fundingSources?.length && (
                <div className="pb-12">
                  <PanelMyInvestments fundingSources={loan.fundingSources} />
                </div>
              )}
            </Panel>
          </Flex>
        </div>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { OfferingIndividualLoan }
