import { useEffect, useMemo, useState } from 'react'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { filterIconByType } from 'components/Filter/filter-conditions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Search } from 'components/Search'
import { Text } from 'components/Text'
import { sortConfig, ISortItem } from './config'

interface Props {
  onSelect: (item: ISortItem) => void
  selectedOptions: ISortItem[]
}

function ColumnDropdown({ onSelect, selectedOptions }: Props) {
  const [search, setSearch] = useState<string>()
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState<boolean>(false)

  const visibleSortOptions = useMemo(() => {
    const selectedIds = selectedOptions.map(({ id }) => id)

    return sortConfig.filter(
      ({ id, label }) =>
        !selectedIds.includes(id) &&
        label.toLowerCase().includes(search?.toLowerCase() || '')
    )
  }, [search, selectedOptions])

  useEffect(() => {
    setSearch('')
  }, [isSortDropdownOpen])

  return (
    <Dropdown
      open={isSortDropdownOpen}
      onOpenChange={setIsSortDropdownOpen}
      trigger={
        <Button variant="ghost" disabled={!visibleSortOptions.length}>
          <Icon name={IconName.addCircle} />
          Add Sort
        </Button>
      }
      placement="bottom"
    >
      <div className="py-1">
        <Flex
          gap={10}
          alignItems="center"
          className="py-0.5 px-1 border-solid border-0 border-b-[1px] border-grey-200"
        >
          <Search
            fieldClassName="flex-grow"
            className="!border-0 !w-full"
            placeholder="Sort by..."
            delay={0}
            autoFocus={false}
            search={search}
            onSearch={setSearch}
          />
        </Flex>
        <Flex stack gap={0} className="py-1">
          {visibleSortOptions.map(({ id, label, type }) => (
            <Flex
              key={id}
              gap={6}
              alignItems="center"
              className="mx-1 py-1.5 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
              onClick={() => {
                setIsSortDropdownOpen(false)
                onSelect({ id, dir: 'asc' })
              }}
            >
              <Icon
                name={filterIconByType.get(type) || IconName.boldListUl}
                className="text-grey-900"
              />
              <div className="leading-5 text-grey-900">{label}</div>
            </Flex>
          ))}
          {!visibleSortOptions.length && (
            <Flex
              stack
              alignItems="center"
              justifyContent="center"
              className="pt-12 pb-16"
            >
              <Icon
                name={IconName.magnifyingGlass}
                className="text-grey-500 w-7 h-7"
              />
              <Text variant="l">No search results</Text>
            </Flex>
          )}
        </Flex>
      </div>
    </Dropdown>
  )
}

export { ColumnDropdown }
