import { request } from 'services/request'
import { Filter, Meta, Pagination, Search, Transaction } from 'types'

export type VendorPayment = {
  amount: number
  type: 'Deposit' | 'Withdrawal'
  date: string
  description: string
}

const getVendorTransactions = async ({
  id,
  ...params
}: {
  id: string
  search?: Search
  filter?: Filter
  page?: Pagination
}): Promise<{ transactions: Transaction[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.get(`/vendor/${id}/transaction`, { params })
  return { transactions, meta: pagination }
}

const addVendorPayment = (
  vendorId: string,
  payload: VendorPayment
): Promise<void> => {
  return request.post(`vendor/${vendorId}/payment`, payload)
}

const updateVendorPayment = (
  vendorId: string,
  payload: VendorPayment
): Promise<void> => {
  return request.patch(`/payment/${vendorId}`, payload)
}

const deleteVendorPayment = async (paymentId: string): Promise<void> => {
  return request.delete(`/payment/${paymentId}`)
}

export {
  getVendorTransactions,
  addVendorPayment,
  updateVendorPayment,
  deleteVendorPayment,
}
