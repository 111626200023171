import clsx from 'clsx'
import { isNil, pick } from 'lodash'
import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { useMobile } from 'hooks/use-mobile'
import Pane, { PaneProps } from './Pane'
import TabDropdown from './TabDropdown'
import styles from './styles.module.scss'

interface TabsProps {
  children: (ReactElement<PaneProps> | null)[]
  onTabSelect?: (tabId: string) => void
  tab?: string
  beforeTabs?: ReactNode
  afterTabs?: ReactNode
  actions?: {
    label: string
    icon: string
    disabled?: boolean
    variant?: 'default' | 'danger'
    onSelect: () => void
  }[]
  defaultActiveId?: string
  rootClassName?: string
  className?: string
  variant?: 'default' | 'blue'
}

type Tab = Pick<
  PaneProps,
  'id' | 'tab' | 'group' | 'disabled' | 'tabClassName'
> & {
  tabs?: { id: string; tab: string }[]
}

function Tabs({
  defaultActiveId,
  tab,
  variant,
  actions,
  beforeTabs,
  afterTabs,
  onTabSelect,
  rootClassName,
  className,
  children,
}: TabsProps) {
  const { isMobile } = useMobile()
  const [activeId, setActiveId] = useState(defaultActiveId || tab)
  let tabs = children
    .reduce((acc, child) => {
      return [
        ...acc,
        child?.type === Fragment ? child?.props.children : child,
      ].flat()
    }, [] as ReactNode[])
    .filter((tab) => tab)
    .map((child) =>
      pick(
        (child as ReactElement).props,
        'id',
        'tab',
        'group',
        'disabled',
        'tabClassName'
      )
    )
  tabs = tabs.map((tab) => ({
    ...tab,
    group: isMobile && tabs.length > 2 ? '' : tab.group,
  }))
  const visibleTabs = tabs.reduce((acc, tab) => {
    if (!isNil(tab.group)) {
      const dropdownTab = acc.find(({ group }) => group === tab.group)
      if (!dropdownTab) {
        return [
          ...acc,
          { ...tab, tabs: tabs.filter(({ group }) => group === tab.group) },
        ]
      }
      return acc
    }
    return [...acc, tab]
  }, [] as Tab[])
  const handleTabSelect = (tabId: string) => {
    const disabled = tabs.find(({ id }) => id === tabId)?.disabled
    if (!disabled) {
      setActiveId(tabId)
      onTabSelect && onTabSelect(tabId)
    }
  }
  useEffect(() => {
    if (tab) {
      setActiveId(tab)
    }
  }, [tab])

  return (
    <div className={rootClassName}>
      <div className={clsx(styles.tabs, className)}>
        {beforeTabs}
        {visibleTabs.map((tab: Tab) => {
          if (tab.tabs) {
            return (
              <div
                className={clsx({
                  [styles.disabled]: tab.disabled && isNil(tab.group),
                })}
                key={tab.id}
              >
                <TabDropdown
                  name={tab.group as string}
                  tabs={tabs.filter(({ group }) => group === tab.group)}
                  actions={isMobile ? undefined : actions}
                  onSelect={(id: string) => handleTabSelect(id)}
                  activeId={activeId}
                />
              </div>
            )
          }
          return (
            <div
              key={tab.id}
              onClick={() => handleTabSelect(tab.id)}
              className={clsx(styles.tab, tab.tabClassName, {
                [styles.blue]: variant === 'blue',
                [styles.selected]: tab.id === activeId,
                [styles.disabled]: tab.disabled,
              })}
            >
              <div className="font-bold invisible absolute">{tab.tab}</div>
              {tab.tab}
            </div>
          )
        })}
        {isMobile && !!actions?.length && (
          <EllipsesActions>
            {
              actions?.map((action) => (
                <EllipsesActions.Item
                  key={action.label}
                  icon
                  disabled={action.disabled}
                  variant={action.variant}
                  onSelect={action.onSelect}
                >
                  <Icon
                    name={action.icon as IconName}
                    className="text-grey-600"
                  />
                  {action.label}
                </EllipsesActions.Item>
              )) as any
            }
          </EllipsesActions>
        )}
        {afterTabs && <div className={styles.tabMore}>{afterTabs}</div>}
      </div>
      {children
        .reduce((acc, child) => {
          return [
            ...acc,
            child?.type === Fragment ? child?.props.children : child,
          ].flat()
        }, [] as ReactNode[])
        .filter((tab) => tab)
        .map((child) =>
          (child as ReactElement).props.id === activeId ? child : null
        )}
    </div>
  )
}

Tabs.Pane = Pane

export default Tabs
