interface Props {
  value: any
  hide?: boolean
  showLast?: number
}

const Redact = ({ value, hide = true, showLast }: Props) => {
  let numDots = value?.toString?.().length ?? 9
  let text = ''

  if (hide) {
    if (showLast) {
      numDots -= showLast
      text = value.toString().slice(-showLast)
    }

    // Requires a margin or the browser makes the dots change line height in the middle
    return (
      <span>
        {''.padStart(numDots, '•')}
        {text ? <span className="ml-[1px]">{text}</span> : ''}
      </span>
    )
  }
  return value
}

export { Redact }
