import { Formik, FormikProps } from 'formik'
import { toNumber } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { components, SingleValueProps } from 'react-select'
import * as yup from 'yup'
import { useLoanFundingSources } from 'admin/hooks/use-loan-funding-sources'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, FieldIcon, Select } from 'components/Form'
import { Option } from 'components/Form/Select'
import { Grid } from 'components/Grid'
import { useLoans } from 'hooks/use-loans'
import { NewOfferingLoan } from 'types'
import { formatUsd } from 'utils/currency'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const amountComparison = (principalBalance: number) =>
  yup
    .number()
    .required('The field is required')
    .moreThan(0, 'The amount must be more than 0')
    .max(
      principalBalance,
      'The amount must be less than the principal balance of the funding source'
    )

type FormValues = {
  loanId: string
  fundingSourceId: string
  amount: string
}

interface LoanOption extends Option {
  amount?: number | undefined
}

interface Props {
  saving: boolean
  onSubmit: (values: NewOfferingLoan) => void
  onCancel: () => void
}

function ModalAddOfferingLoan({ saving, onSubmit, onCancel }: Props) {
  const form = useRef<FormikProps<FormValues>>(null)
  const [selectedLoan, setSelectedLoan] = useState<{
    id: string
    amount: number | undefined
  }>({ id: '', amount: 0 })
  const { data: loans, isPending } = useLoans({
    filter: {
      status: ['lead', 'processing', 'underwriting', 'approved', 'servicing'],
    },
    sort: ['name', 'ASC'],
  })
  const { data: fundingSources, isFetching: isFetchingFundingSources } =
    useLoanFundingSources({
      loanId: selectedLoan.id,
    })
  const initialValue: FormValues = {
    loanId: '',
    fundingSourceId: '',
    amount: '',
  }
  const totalInvested = fundingSources?.total.invested || 0

  const LoanSingleValue = useCallback(
    ({ children, ...props }: SingleValueProps<LoanOption>) => (
      <components.SingleValue {...props}>
        <Flex stack gap={4} className="py-1.5">
          <div className="font-bold text-grey-900">{children}</div>
          <div className="text-grey-700 text-sm">
            {isFetchingFundingSources
              ? '...'
              : formatUsd(totalInvested, { showZero: true })}
            /{formatUsd(props.data.amount, { showZero: true })} Funded
          </div>
        </Flex>
      </components.SingleValue>
    ),
    [selectedLoan, fundingSources, totalInvested, isFetchingFundingSources]
  )

  const FundingSourceSingleValue = useCallback(
    ({ children, ...props }: SingleValueProps<LoanOption>) => (
      <components.SingleValue {...props}>
        <Flex stack gap={4} className="py-1.5">
          {props.data.value ? (
            <>
              <div className="font-bold text-grey-900">{children}</div>
              <div className="text-grey-700 text-sm">
                {formatUsd(props.data.amount, { showZero: true })}
              </div>
            </>
          ) : (
            <div>{children}</div>
          )}
        </Flex>
      </components.SingleValue>
    ),
    [fundingSources]
  )

  const fundingSourcesOptions = useMemo(
    () => [
      {
        value: '',
        label: 'New Funding',
      },
      ...(fundingSources?.fundingSources
        ?.filter(({ amount, dateFunded }) => amount > 0 && !!dateFunded)
        .map(({ id, investor, amount }) => ({
          value: id,
          label: investor?.name,
          amount,
        })) || []),
    ],
    [fundingSources]
  )

  const FundingSchema = useMemo(
    () =>
      createScheme({
        loanId: required,
        amount: selectedLoan
          ? amountComparison(
              fundingSources?.fundingSources?.find(
                ({ id }) => id === selectedLoan.id
              )?.principalBalance || 10000000
            )
          : required,
      }),
    [fundingSources]
  )

  const handleLoanChange = useCallback((loanOption: LoanOption) => {
    setSelectedLoan({
      id: loanOption.value as string,
      amount: loanOption.amount,
    })
    form.current?.setFieldValue('fundingSourceId', '')
    form.current?.setFieldValue('amount', '')
  }, [])

  const handleFundingSourcesChange = useCallback(
    (fundingSourceOption: LoanOption) => {
      if (fundingSourceOption.amount) {
        form.current?.setFieldValue('amount', fundingSourceOption.amount)
      } else if (
        selectedLoan.amount &&
        selectedLoan.amount - totalInvested > 0
      ) {
        form.current?.setFieldValue(
          'amount',
          selectedLoan.amount - totalInvested
        )
      } else {
        form.current?.setFieldValue('amount', '')
      }
    },
    [selectedLoan, totalInvested]
  )

  useEffect(() => {
    if (!isFetchingFundingSources) {
      if (selectedLoan.amount && selectedLoan.amount - totalInvested > 0) {
        form.current?.setFieldValue(
          'amount',
          selectedLoan.amount - totalInvested
        )
      } else {
        form.current?.setFieldValue('amount', '')
      }
    }
  }, [totalInvested, selectedLoan, isFetchingFundingSources])

  return (
    <Modal
      title="Add Loan"
      loading={isPending}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        innerRef={form}
        initialValues={initialValue}
        validationSchema={FundingSchema}
        onSubmit={({ loanId, amount, fundingSourceId }) =>
          onSubmit({
            loanId: loanId,
            amount: toNumber(amount),
            fundingSourceId: fundingSourceId || null,
          })
        }
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Select
                label="Loan"
                name="loanId"
                portal
                options={
                  loans?.loans?.map(({ id, name, amount }) => ({
                    value: id,
                    label: name,
                    amount,
                  })) || []
                }
                components={{
                  SingleValue: LoanSingleValue,
                }}
                styles={{
                  input: (baseStyles) => ({
                    ...baseStyles,
                    alignSelf: 'flex-start',
                    paddingTop: '3px',
                  }),
                }}
                onChange={handleLoanChange}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Select
                label="Funding Source"
                name="fundingSourceId"
                portal
                options={fundingSourcesOptions}
                components={{
                  SingleValue: FundingSourceSingleValue,
                }}
                styles={{
                  input: (baseStyles) => ({
                    ...baseStyles,
                    alignSelf: 'flex-start',
                    paddingTop: '3px',
                  }),
                }}
                onChange={handleFundingSourcesChange}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <FieldIcon
                type="currency"
                label="Amount of Funding"
                name="amount"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalAddOfferingLoan
