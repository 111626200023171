import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { isEqual, isNil } from 'lodash'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Search } from 'components/Search'
import { Text } from 'components/Text'
import { getLoan } from 'services/api/loans'
import { Field, Loan } from 'types'
import { formatField } from 'utils/fields'

interface Props {
  onInsert: (value: string) => void
}

function ControlInsertFields({ onInsert }: Props) {
  const { thread } = useThreadContext()
  const [loans, setLoans] = useState<Loan[]>([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string>()

  const visibleLoans = useMemo(
    () =>
      loans
        .map((loan) => ({
          ...loan,
          fields: loan.fields
            .filter(
              (field) =>
                field.section !== 'Fees' &&
                !['documents', 'collateral'].includes(field.page?.toLowerCase())
            )
            .filter(
              (field) =>
                (!search && !isNil(field.property.value?.[0])) ||
                (search &&
                  field.name.toLowerCase().includes(search.toLowerCase()))
            ),
        }))
        .filter(({ fields }) => fields.length > 0),
    [loans, search]
  )

  const fetchLoans = useCallback(async () => {
    setLoading(true)
    const data = await Promise.all(thread.loans.map(({ id }) => getLoan(id)))
    setLoans(data)
    setLoading(false)
  }, [thread.loans])

  const handleSelect = useCallback(
    (field: Field) => {
      onInsert(formatField(field))
      setOpen(false)
    },
    [onInsert]
  )

  useEffect(() => {
    if (!open) {
      setSearch('')
    }
  }, [open])

  useEffect(() => {
    if (
      open &&
      !isEqual(
        loans.map(({ id }) => id),
        thread.loans.map(({ id }) => id)
      )
    ) {
      fetchLoans()
    }
  }, [open, loans, fetchLoans, thread.loans])

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [flip()],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click', keyboardHandlers: false }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  return (
    <Flex
      gap={4}
      className="pl-1 border-0 border-l border-solid border-grey-200"
    >
      <Button
        variant="ghost"
        active={open}
        disabled={thread.loans.length === 0}
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        <Icon name={IconName.mergeField} size="md" className="text-grey-600" />
        Add field
      </Button>

      {open && (
        <FloatingPortal>
          <div
            className="bg-white-100 shadow-300 rounded w-100 pt-1 overflow-hidden"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1,
              },
            })}
          >
            {loading ? (
              <PageLoader />
            ) : (
              <>
                <div className="pb-0.5">
                  <Search
                    className="!border-0 !w-full"
                    placeholder="Search for a field"
                    search={search}
                    onSearch={setSearch}
                  />
                </div>
                <Flex
                  justifyContent="space-between"
                  className="pb-1 border-0 border-t border-solid border-grey-200"
                >
                  <div className="px-1 max-h-85 overflow-auto w-full">
                    {visibleLoans.length === 0 && (
                      <Flex
                        stack
                        alignItems="center"
                        justifyContent="center"
                        className="pt-12 pb-16"
                      >
                        <Icon
                          name={IconName.magnifyingGlass}
                          className="text-grey-500 w-7 h-7"
                        />
                        <Text variant="l">No search results</Text>
                      </Flex>
                    )}
                    {visibleLoans.map((loan, index) => (
                      <Fragment key={loan.id}>
                        <div
                          className={clsx(
                            'text-base text-grey-900 -mx-1 pt-2 pb-2 px-3 font-bold bg-white-100',
                            index !== 0 &&
                              'border-0 border-t border-solid border-grey-200 pt-3.5 mt-1.5'
                          )}
                        >
                          {loan.name}
                        </div>
                        {loan.fields.map((field) => (
                          <div
                            className="p-2 cursor-pointer hover:bg-grey-75 hover:rounded"
                            key={field.id}
                            {...getItemProps({
                              onClick: () => handleSelect(field),
                            })}
                          >
                            {field.name}
                          </div>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                </Flex>
              </>
            )}
          </div>
        </FloatingPortal>
      )}
    </Flex>
  )
}

export { ControlInsertFields }
