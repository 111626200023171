import { without } from 'lodash'
import { useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import { TableFundingInvestors } from 'admin/components/table/TableFundingInvestors'
import { useGenerateLoanDocument } from 'admin/hooks/use-loan-documents'
import {
  useAddLoanFundingSource,
  useLoanFundingSources,
  useUpdateLoanFundingSource,
  useDeleteLoanFundingSource,
} from 'admin/hooks/use-loan-funding-sources'
import { pathTo } from 'admin/path-to'
import { downloadFundingInvestors } from 'admin/services/csv/download-funding-investors'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalFundingHistory } from 'components/Modal/FundingHistory'
import { ModalPayment } from 'components/Modal/Payment'
import { Panel } from 'components/Panel'
import { TableStickyFooter } from 'components/TableStickyFooter'
import { FUNDING_IMPORT_BANNER } from 'constants/local-storage-keys'
import { Loan, LoanFundingSource } from 'types'
import { getFieldsBySection } from 'utils/fields'
import { message } from 'utils/message'
import { AlertFunding } from './AlertFunding'
import { ModalFunding } from './ModalFunding'

interface Props {
  adding: boolean
  setAdding: (adding: boolean) => void
  setIsTransferVisible: (visible: boolean) => void
  loan: Loan
}

export default function PanelFundingInvestors({
  loan,
  adding,
  setAdding,
  setIsTransferVisible,
}: Props) {
  const [checkboxes, setCheckboxes] = useState<string[]>([])
  const [isPaymentVisible, setIsPaymentVisible] = useState(false)
  const [fundSource, setFundSource] = useState<string | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(FUNDING_IMPORT_BANNER)
  )

  const [transactionDetailsId, setTransactionDetailsId] = useState<
    string | null
  >(null)
  const { data: fundingSources, isPending: loading } = useLoanFundingSources({
    loanId: loan.id,
  })
  const { mutate: add, isPending: saving } = useAddLoanFundingSource({
    loanId: loan.id,
  })
  const { mutate: edit, isPending: updating } = useUpdateLoanFundingSource({
    loanId: loan.id,
  })
  const { mutate: remove, isPending: removing } = useDeleteLoanFundingSource({
    loanId: loan.id,
  })
  const { mutate: generate } = useGenerateLoanDocument()
  const navigate = useNavigate()
  const tranches = getFieldsBySection(loan.fields, 'Tranches')
  const selectableIds = useMemo(
    () =>
      fundingSources?.fundingSources
        ?.filter(
          ({ committedAmount, investedBalance }) =>
            (committedAmount as number) > (investedBalance as number)
        )
        .map(({ id }) => id) || [],
    [fundingSources, loading]
  )

  const handleCheckboxChange = useCallback(
    (id: string) => {
      if (id === 'master' && checkboxes.length) {
        setCheckboxes([])
      } else if (id === 'master' && !checkboxes.length) {
        setCheckboxes(selectableIds)
      } else if (checkboxes.includes(id)) {
        setCheckboxes(without(checkboxes, id))
      } else {
        setCheckboxes([...checkboxes, id])
      }
    },
    [checkboxes, selectableIds]
  )

  return (
    <Panel
      title="Funding"
      className={checkboxes.length > 0 ? 'pb-4' : ''}
      actionChildren={
        <Flex>
          <ImportEntity
            entityType="funding"
            loanId={loan.id}
            setAlertData={() => setAlertData(true)}
          />
          <Download
            filename="funding"
            download={() => downloadFundingInvestors(loan.id)}
          />
          <Button
            onClick={() => setIsTransferVisible(true)}
            variant="secondary"
          >
            Transfer
          </Button>
          <Button
            onClick={() => {
              setAdding(true)
            }}
          >
            Add Funding
          </Button>
        </Flex>
      }
    >
      {alertData && (
        <AlertFunding alertData={alertData} setAlertData={setAlertData} />
      )}
      <TableFundingInvestors
        loading={loading}
        wrapperClassName={checkboxes.length > 0 ? 'mb-2' : ''}
        selectedCount={checkboxes.length}
        data={fundingSources?.fundingSources}
        total={fundingSources?.total}
        loanStatus={loan.status}
        onClick={(fundingSource) => {
          if (fundingSource) {
            setTransactionDetailsId(fundingSource.id)
          }
        }}
        onEdit={setEditingId}
        onDelete={setRemovingId}
        onGenerateAgreement={(fundingSourceId: string) => {
          const investor_id = fundingSources?.fundingSources?.find(
            (s) => s.id === fundingSourceId
          )?.investor?.id
          return generate(
            {
              loanId: loan.id,
              templateId: 'investor_agreement',
              templateName: 'Investor Agreement',
              payload: { fundingSourceId },
            },
            {
              onSuccess: ({ id }) => {
                message.success('Investor Agreement generated')
                if (investor_id) {
                  navigate(
                    pathTo('personDocument', 'investors', investor_id, id)
                  )
                }
              },
            }
          )
        }}
        masterChecked={
          checkboxes.length === selectableIds.length && checkboxes.length > 0
        }
        selectableIds={selectableIds}
        checkboxes={checkboxes}
        onCheck={handleCheckboxChange}
        onFundSelected={(id) => setFundSource(id)}
      />
      {checkboxes.length > 0 ? (
        <TableStickyFooter>
          <Button
            disabled={!checkboxes.length}
            onClick={() => setIsPaymentVisible(true)}
          >
            Release Funds
          </Button>
        </TableStickyFooter>
      ) : null}
      {(adding || editingId) && (
        <ModalFunding
          loanAmount={loan.amount}
          loanRate={loan.rate || ''}
          releasedAmount={fundingSources?.total?.invested as number}
          committedAmount={fundingSources?.total?.committed as number}
          fundingSource={fundingSources?.fundingSources?.find(
            ({ id }) => id === editingId
          )}
          tranches={tranches.map(({ name, property }) => ({
            name,
            rate: (property.value[1] || '') as string,
          }))}
          onSave={(values) =>
            adding
              ? add(values, { onSuccess: () => setAdding(false) })
              : edit(
                  {
                    id: editingId as string,
                    ...values,
                  } as LoanFundingSource,
                  { onSuccess: () => setEditingId(null) }
                )
          }
          onCancel={() => {
            setAdding(false)
            setEditingId(null)
          }}
          saving={saving || updating}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="funding"
          loading={removing}
          onDelete={() =>
            remove(removingId, { onSuccess: () => setRemovingId(null) })
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
      {transactionDetailsId && (
        <ModalFundingHistory
          loanId={loan.id}
          fundingSource={
            fundingSources?.fundingSources?.find(
              ({ id }) => id === transactionDetailsId
            ) as LoanFundingSource
          }
          onCancel={() => setTransactionDetailsId(null)}
        />
      )}
      {(isPaymentVisible || !!fundSource) && (
        <ModalPayment
          loan={loan}
          type="Funding"
          isReleaseFunding
          selectedFundingSources={fundingSources?.fundingSources?.filter(
            ({ id }) =>
              fundSource ? id === fundSource : checkboxes.includes(id)
          )}
          onSubmit={() => {
            setFundSource(null)
            setIsPaymentVisible(false)
            setCheckboxes([])
          }}
          onCancel={() => {
            setFundSource(null)
            setIsPaymentVisible(false)
          }}
        />
      )}
    </Panel>
  )
}
