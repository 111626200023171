import { without } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDockContext } from 'admin/components/Dock/DockContext'
import { DockThreadTab } from 'admin/components/DockThreadTab/DockThreadTab'
import { pathToLoan } from 'admin/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoanStatus } from 'constants/loan-status'
import { useThreadContext } from '../ThreadContext'
import { ModalLinkLoan } from './ModalLinkLoan'

const LinkedLoans = () => {
  const navigate = useNavigate()
  const [isLinkingToLoanModal, setIsLinkingToLoanModal] = useState(false)
  const { thread, updateThread } = useThreadContext()
  const { addTab } = useDockContext()

  const handleLoanClick = useCallback(
    (loan: { id: string; name: string; status: LoanStatus }) => {
      navigate(pathToLoan(loan))
      addTab({
        id: thread.id as string,
        title: thread.mails[0]?.subject as string,
        content: <DockThreadTab threadId={thread.id as string} />,
        expandByDefault: true,
        onMaximizeClick: () =>
          navigate(
            pathToLoan(loan, 'loanTabEntityId', 'mail', thread.id as string)
          ),
      })
    },
    [thread, addTab, navigate]
  )

  const unlinkLoan = useCallback(
    (id: string) => {
      updateThread({
        loans: without(
          thread.loans.map(({ id }) => id),
          id
        ),
      })
    },
    [updateThread, thread.loans]
  )

  return (
    <div className="mt-3">
      <div className="uppercase text-[10px] text-grey-600 font-bold">
        linked loans
      </div>
      {thread.loans.length ? (
        <Flex
          stack
          gap={0}
          className="w-full mt-4 mb-2.5 rounded border-solid border-[1px] border-grey-200"
        >
          {thread.loans.map((loan) => (
            <Flex
              key={loan.id}
              gap={8}
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleLoanClick(loan)}
              className="py-2.5 px-2 border-0 border-b last:border-b-0 border-grey-200 border-solid cursor-pointer"
            >
              <Flex gap={12} alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="min-w-7 h-7 rounded border border-solid border-grey-200"
                >
                  <Icon
                    name={IconName.loanNavigation}
                    className="text-grey-600"
                  />
                </Flex>
                <div className="text-black-100">{loan.name}</div>
              </Flex>
              <EllipsesActions>
                <EllipsesActions.Item onSelect={() => unlinkLoan(loan.id)}>
                  <Icon
                    name={IconName.unlinkIcon}
                    className="mr-2 text-grey-600"
                  />
                  Unlink loan
                </EllipsesActions.Item>
              </EllipsesActions>
            </Flex>
          ))}
        </Flex>
      ) : (
        <div className="mt-4 mb-5 text-grey-900 leading-[18px]">
          Find an existing loan to link to.
        </div>
      )}
      <Button
        variant="secondary"
        onClick={() => setIsLinkingToLoanModal(true)}
        className="w-full"
      >
        Link to Loan
      </Button>
      {isLinkingToLoanModal && (
        <ModalLinkLoan onClose={() => setIsLinkingToLoanModal(false)} />
      )}
    </div>
  )
}

export { LinkedLoans }
