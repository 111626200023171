import clsx from 'clsx'
import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useUpdateCurrentUser } from 'admin/hooks/use-users'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'
import googleMailIcon from 'images/googlemail.png'
import microsoftOutlookIcon from 'images/microsoftoutlook.png'

const connections = [
  {
    id: 'microsoftoutlook',
    name: 'Outlook',
    icon: microsoftOutlookIcon,
  },
  {
    id: 'googlemail',
    name: 'Gmail',
    icon: googleMailIcon,
  },
]

const MailConnect = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { user, refreshUser } = useSession()
  const { mutate: updateUser } = useUpdateCurrentUser()

  const isConnected = !!user?.admin?.emailConnectionType

  const handleDisconnect = useCallback(() => {
    updateUser(
      {
        emailConnectionId: 'none',
        emailConnectionType: user?.admin?.emailConnectionType,
      },
      { onSuccess: refreshUser }
    )
  }, [updateUser, refreshUser, isConnected])

  useEffect(() => {
    const emailConnectionId = searchParams.get('id')
    const emailConnectionType = searchParams.get('type')

    if (emailConnectionId) {
      updateUser(
        {
          emailConnectionId,
          emailConnectionType,
        },
        {
          onSuccess: () => {
            setSearchParams()
          },
        }
      )
    }
  }, [searchParams, setSearchParams])

  let unifiedEnv = 'dev'
  switch (import.meta.env.VITE_APP_API_URL) {
    case '/production':
      unifiedEnv = 'Production'
      break
    case '/staging':
      unifiedEnv = 'staging'
      break
  }

  return (
    <Panel title="Connect to Gmail or Outlook" className="max-w-panel">
      <div className="text-grey-700">
        Securely connect with either Gmail or Outlook to begin sending and
        receiving mail within Baseline.
      </div>
      <Flex stack gap={0}>
        {connections.map((connection, index) => (
          <Flex
            key={connection.id}
            alignItems="center"
            gap={16}
            className={clsx(
              'px-2 py-5',
              index !== 0 && 'border-0 border-solid border-t border-grey-200'
            )}
          >
            <div className="p-3 border border-solid border-grey-200 rounded">
              <img
                src={connection.icon}
                alt={connection.name}
                className="h-5 w-5"
              />
            </div>
            <Flex stack gap={4}>
              <div className="font-bold text-lg">{connection.name}</div>
              {isConnected &&
              user?.admin?.emailConnectionType !== connection.id ? (
                <Flex alignItems="center" gap={4} className="text-grey-700">
                  <Icon name={IconName.info} className="text-grey-600" />
                  To sync with {connection.name}, you’ll first need to
                  disconnect from your existing connection
                </Flex>
              ) : (
                <div className="text-grey-700">
                  Sync your inbox with {connection.name}
                </div>
              )}
            </Flex>
            {user?.admin?.emailConnectionType === connection.id && (
              <Flex className="ml-auto">
                <Button size="large" variant="ghost" onClick={handleDisconnect}>
                  Disconnect
                </Button>
                <Button size="large" variant="secondary" disabled>
                  <Icon name={IconName.check} />
                  Connected
                </Button>
              </Flex>
            )}
            {!isConnected && (
              <Button
                className="ml-auto"
                size="large"
                onClick={() => {
                  document.location = `https://api.unified.to/unified/integration/auth/6670ecd29da376c87a94a653/${connection.id}?redirect=1&external_xref=${
                    user?.admin?.id
                  }&scopes=messaging_message_read%2Cmessaging_message_write%2Cmessaging_channel_read%2C&env=${unifiedEnv}&success_redirect=${encodeURIComponent(window.location.href)}&failure_redirect${encodeURIComponent(window.location.href)}`
                }}
              >
                Connect
              </Button>
            )}
          </Flex>
        ))}
      </Flex>
    </Panel>
  )
}

export { MailConnect }
