import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSession } from './use-session'

export const useIntercom = () => {
  const { user } = useSession()
  const location = useLocation()
  const [isIntercomVisible, setIsIntercomVisible] = useState(false)
  const isEnabled =
    !!import.meta.env.VITE_APP_INTERCOM &&
    window.location.hostname !== 'localhost'

  const toggleIntercom = () => {
    isEnabled && setIsIntercomVisible(!isIntercomVisible)
  }

  useEffect(() => {
    if (isEnabled && user) {
      window.Intercom('boot', {
        app_id: import.meta.env.VITE_APP_INTERCOM,
        hide_default_launcher: true,
        user_id: user.admin?.id,
        name: user.admin?.name,
        email: user.admin?.email,
        phone: user.admin?.phone || undefined,
        access_level: user.admin?.permission,
        company: {
          id: user.client.id,
          name: user.client.name,
          website: user.client.url,
        },
        alignment: 'left',
      })
      window.Intercom('onHide', () => setIsIntercomVisible(false))
    }
  }, [isEnabled, user])

  useEffect(() => {
    if (isEnabled && window.Intercom) {
      if (isIntercomVisible) {
        window.Intercom('showMessages')
      } else {
        window.Intercom('hide')
      }
    }
  }, [isEnabled, isIntercomVisible])

  useEffect(() => {
    if (isEnabled && window.Intercom) {
      window.Intercom('update')
    }
  }, [location])

  return { toggleIntercom }
}
