import { getFundFundingSources } from 'admin/services/api/fund-payment'
import { Row } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatPercent } from 'utils/percent'

const downloadFundingSource = async (fundId: string): Promise<Row[]> => {
  const sources = await getFundFundingSources({ fundId })

  return sources.map((source) => ({
    Name: source.loan?.name,
    Yield: formatPercent(source.rate),
    Class: source.class,
    Invested: formatUsd(source.committedAmount),
    'Date Invested': formatDate(source.dateInvested),
    Returned: formatUsd(source.principalBalance),
    Balance: formatUsd(source.amount),
    Income: formatUsd(source.interestBalance),
    Status: (() => {
      if (
        source.dateFunded &&
        source.amount !== 0 &&
        source.loan.loanStatus !== 'liquidated'
      ) {
        return 'Active'
      }
      if (source.dateFunded) {
        return 'Liquidated'
      }
      return 'Pending'
    })(),
  }))
}

export { downloadFundingSource }
