import { Formik } from 'formik'
import { FieldWysiwyg } from 'admin/components/InlineWysiwyg/FieldWysiwyg'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Checkbox, Field, Form } from 'components/Form'
import { Modal } from 'components/Modal'
import { IMailSignature } from 'types'
import { createScheme, required } from 'utils/schemas'

const Schema = createScheme({
  name: required,
})

type FormValues = {
  name: string
  body: string
  isDefault: boolean
}

interface Props {
  signature?: IMailSignature | null
  saving: boolean
  onClose: () => void
  onSave: (values: FormValues) => void
}

function ModalSignature({ signature, onClose, onSave, saving }: Props) {
  const isEditing = !!signature
  const initialValue: FormValues = {
    name: signature?.name || '',
    body: signature?.body || '',
    isDefault: signature?.isDefault ?? false,
  }

  return (
    <Modal
      title={isEditing ? 'Edit Email Signature' : 'New Email Signature'}
      onClose={onClose}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <div className="mb-4">
            <Field label="Signature Name" name="name" />
          </div>
          <div className="mb-4">
            <div className="text-lg mb-2">Signature Design</div>
            <FieldWysiwyg
              label=""
              name="body"
              placeholder="Insert or design your signature here"
            />
          </div>
          <div className="mb-6">
            <Checkbox
              className="items-center"
              name="isDefault"
              label="Make this my default signature"
            />
          </div>
          <Flex justifyContent="end" gap={10}>
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={saving}>
              {isEditing ? 'Save' : 'Add'}
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalSignature }
