import { LexicalEditor } from 'lexical'
import { debounce } from 'lodash'
import { DragEvent, useEffect, useMemo, useState } from 'react'
import { MailEditor } from 'admin/components/InlineWysiwyg/MailEditor'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { Icon, IconName } from 'components/Icon'
import { IMail } from 'types'

interface Props {
  autoFocus?: boolean
  isNewMail: boolean
  mail: IMail
  onInit?: (editor: LexicalEditor) => void
  onChange: (payload: Partial<IMail>) => void
}

const Body = ({ autoFocus, isNewMail, mail, onInit, onChange }: Props) => {
  const [isDragActive, setIsDragActive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(isNewMail)
  const [body, setBody] = useState(mail.bodyShort)
  const onChangeDebounced = useMemo(() => debounce(onChange, 300), [onChange])

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      // setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    console.log(files)
  }

  useEffect(() => {
    onChangeDebounced({
      id: mail.id,
      [isExpanded ? 'body' : 'bodyShort']: body,
    })
  }, [onChangeDebounced, mail.id, isExpanded, body])

  return (
    <div
      onDragEnter={handleDrag}
      className="relative min-h-1 mx-5 flex-grow overflow-auto"
    >
      <MailEditor
        key={isExpanded ? 'expanded' : 'collapsed'}
        placeholder="Enter your message here or choose from a template"
        autoFocus={autoFocus}
        value={body}
        onCreate={onInit}
        onChange={setBody}
      />
      {!isExpanded && (
        <Button
          variant="ghost"
          onClick={() => {
            setBody(mail.body)
            setIsExpanded(true)
          }}
        >
          <Icon name={IconName.ellipses} />
        </Button>
      )}
      {isDragActive && (
        <DragDropFile
          onLeave={() => setIsDragActive(false)}
          onDrop={handleDrop}
          className="rounded border-[2px] border-dashed border-purple-200 bg-purple-25"
        >
          <div className="text-5xl text-purple-300 leading-5">
            Drop to upload
          </div>
          <div className="text-lg text-grey-800 text-center">
            Upload files to your email by dropping them here
          </div>
        </DragDropFile>
      )}
    </div>
  )
}

export { Body }
